import { StyleSheet, Text, View, Pressable, ScrollView, TextInput, Image } from 'react-native'
import React, { useMemo, useRef } from 'react'
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { useSelector } from 'react-redux';
import { AntDesign } from '@expo/vector-icons';
import { t } from 'i18n-js';
import ShortenWord from '../../../../modules/ShortenWord';
import CheckBox from '../../../../Elements/CheckBox';

export default function PlayerFilter({ flagFilter, setFlagFilter, flagSelect, setFlagSelect, arrSearch, arrSelect, arr, searchItem, addItem, removeItem, refPopup, reset, players }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();

    const colors = useSelector((state) => state.style.color);
    const backgrounds = useSelector((state) => state.style.background);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(width, height, heightS, widthS, backgrounds, colors, direction);

    const images = useSelector((state) => state.style.images);

    const inputRef = useRef(null);
    const items = arrSelect.split(",").filter(item => item.trim() !== "");
    return (
        <View style={[styles.filterView, { borderBottomColor: colors.infoBorder, zIndex: 2 }]}>
            {/* top */}
            <View style={styles.topFilterView}>
                <Pressable onPress={() => setFlagFilter(!flagFilter)}>
                    <View style={{ alignItems: "center", flexDirection: "row" }}>
                        <Text style={styles.text}>
                            {t("by_players")} {items?.length > 0 && `(${items?.length})`}
                        </Text>
                        <AntDesign
                            name="caretdown"
                            style={{ marginLeft: widthS * 5, transform: [{ rotate: flagFilter ? "180deg" : "0deg" }] }}
                            size={widthS * 5}
                            color={colors.buildText}
                        />
                    </View>
                </Pressable>
                {flagFilter && <Pressable onPress={() => reset("player")}>
                    <Text style={[styles.clear, { color: colors.placeholder, textDecorationColor: colors.placeholder }]}>{t("clear")} {t("all")}</Text>
                </Pressable>}
            </View>
            {/* main-players-filter */}
            {flagFilter &&
                <View style={{ position: "relative" }}>
                    <View ref={refPopup} style={[styles.inputView, { backgroundColor: colors.infoBorder, transition: "all 1s", zIndex: 2 }]}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <TextInput
                                placeholder={t("search")}
                                placeholderTextColor={colors.buildText}
                                ref={inputRef}
                                onChangeText={(txt) => { searchItem(txt, "player"); setFlagSelect(true) }}
                                style={[styles.input, { color: colors.buildText, outlineStyle: "none" }]}
                            />
                            <View style={{ position: "absolute", right: widthS * 3, alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                                <Pressable onPress={() => setFlagSelect(!flagSelect)}>
                                    <AntDesign
                                        name="caretdown"
                                        style={{ marginRight: widthS * 3, }}
                                        size={widthS * 5}
                                        color={colors.buildText}
                                    />
                                </Pressable>
                                <Image source={{ uri: images.searchIcon }} style={{ width: widthS * 11, height: heightS * 11, resizeMode: "contain" }} />
                            </View>
                        </View>
                        {flagSelect &&
                            <View style={[styles.listView, styles.shadow, { backgroundColor: colors.infoBorder, zIndex: 20, }]}>
                                <ScrollView showsVerticalScrollIndicator={false}>
                                    <Text style={styles.textHeaders}>{t("groups")}</Text>
                                    <Pressable style={({ hovered }) => styles.btnOption(hovered)}
                                        onPress={() => { addItem("all", "player"); setFlagSelect(false); inputRef.current.value = "" }}
                                    >
                                        <CheckBox
                                            checked={players?.length > 0 && arrSelect?.split(",")?.length === players?.length}
                                            colorFill={backgrounds.count}
                                            background={backgrounds.infoBody}
                                        />
                                        <Text style={styles.text}>{t("all_players")}</Text>
                                    </Pressable>
                                    <Pressable style={({ hovered }) => styles.btnOption(hovered)}
                                        onPress={() => { addItem("real", "player"); setFlagSelect(false); inputRef.current.value = "" }}
                                    >
                                        <CheckBox
                                            checked={players?.length > 0 && arrSelect?.split(",")?.length === players?.length}
                                            colorFill={backgrounds.count}
                                            background={backgrounds.infoBody}
                                        />
                                        <Text style={styles.text}>{t("real_players")}</Text>
                                    </Pressable>
                                    <Text style={styles.textHeaders}>{t("players")}</Text>
                                    {arrSearch.map((player, index) => {
                                        // if (arrSelect.indexOf(player.id) >= 0) return;
                                        return (
                                            <Pressable key={index} style={({ hovered }) => styles.btnOption(hovered)}
                                                onPress={() => { addItem(player.id, "player"); inputRef.current.value = "" }}
                                            >
                                                <CheckBox
                                                    checked={arrSelect.indexOf(player.id) >= 0}
                                                    colorFill={backgrounds.count}
                                                    background={backgrounds.infoBody}
                                                />
                                                <Text style={styles.text} numberOfLines={1}>{player.name_for_game}</Text>
                                            </Pressable>
                                        )
                                    })}
                                </ScrollView>
                            </View>}
                    </View>
                    {arrSelect.length > 0 &&
                        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                            {arr.map((player, index) => {
                                if (arrSelect.indexOf(player.id) < 0) return;
                                return (
                                    <View key={index}
                                        style={[styles.shadow, {
                                            backgroundColor: colors.infoBorder,
                                            paddingVertical: heightS * 3,
                                            paddingHorizontal: widthS * 3,
                                            width: "max-content",
                                            borderRadius: widthS * 6,
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginVertical: heightS * 2,
                                            marginHorizontal: widthS * 1.5
                                        }]}>
                                        <Text style={styles.text}>{ShortenWord(player.name_for_game, 15)}</Text>
                                        <Pressable
                                            style={{ backgroundColor: backgrounds.realPlayersBackground, borderRadius: "50%", width: widthS * 12, height: widthS * 12, alignItems: "center", justifyContent: "center", marginLeft: widthS * 2 }}
                                            onPress={() => removeItem(player.id, "player")}>
                                            <Text style={{ color: colors.buildText, fontSize: widthS * 7, fontFamily: "Catamaran_400Regular" }}>X</Text>
                                        </Pressable>
                                    </View>
                                )
                            })}
                        </View>}
                </View>}
        </View>
    )
}

const stylesR = (width, height, heightS, widthS, backgrounds, colors, direction) => {
    return StyleSheet.create({
        filterView: {
            width: "100%",
            paddingHorizontal: widthS * 10,
            borderBottomWidth: widthS * 1,
            paddingVertical: heightS * 10
        },
        topFilterView: {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
        clear: {
            fontSize: widthS * 8,
            fontFamily: "Catamaran_400Regular",
            textDecorationLine: "underline",
            marginRight: widthS * 10
        },
        inputView: {
            borderRadius: widthS * 5,
            marginVertical: widthS * 5,
            position: "relative",
            justifyContent: "center"
        },
        input: {
            paddingHorizontal: widthS * 5,
            paddingVertical: widthS * 2,
            borderRadius: widthS * 5,
            fontSize: widthS * 9,
            // lineHeight: widthS * 8,
            fontFamily: "Catamaran_400Regular"
        },
        listView: {
            position: "absolute",
            top: heightS * 22,
            borderRadius: widthS * 5,
            width: "100%",
            maxHeight: heightS * 150,
        },
        btnOption: (hovered) => ({
            backgroundColor: hovered ? backgrounds.infoBox : "",
            paddingVertical: heightS * 3,
            paddingHorizontal: widthS * 3,
            paddingLeft: "1.5vw",
            direction: direction,
            flexDirection: "row", alignItems: "center", gap: "0.5vw"
        }),
        textHeaders: {
            color: colors.buildText,
            fontSize: "0.8vw",
            fontFamily: "Catamaran_500Medium",
            paddingLeft: "0.5vw",
            paddingVertical: "0.5vw",
            paddingHorizontal: "0.5vw"
        },
        text: {
            color: colors.buildText,
            fontSize: "1vw",
            fontFamily: "Catamaran_400Regular",
            writingDirection: direction
        },
        shadow: {
            shadowColor: "black",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.7,
            shadowRadius: 5
        }
    })
}