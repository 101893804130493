import { Animated, Dimensions, Easing, PanResponder, View, } from 'react-native'
import React, { useEffect, useMemo, useRef, useState } from 'react';

export default function DraggedPopup({ renderItem, style, refC, setIndex, flagDrag, setDrag }) {

  const ref = useRef(null);

  const [maxX, setMaxX] = useState(refC.current && refC.current.getBoundingClientRect().width);
  const [maxY, setMaxY] = useState(refC.current && refC.current.getBoundingClientRect().height);
  // const [minX, setMinX] = useState(ref.current && ref.current.getBoundingClientRect().width);
  // const [minY, setMinY] = useState(ref.current && ref.current.getBoundingClientRect().height);
  const [first, setFirst] = useState(false);
  const [flag, setFlag] = useState(flagDrag);


  const pan = useRef(new Animated.ValueXY()).current;
//   const expanded = true;
// const toggleAnim = useRef(new Animated.Value(Number(expanded))).current;
  const responder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        setFirst(true);
        pan.setOffset({
          x: pan.x._value,
          y: pan.y._value
        })
      },
      onPanResponderMove: Animated.event(
        [
          null,
          { dy: pan.y, dx: pan.x },
        ],
        {
          useNativeDriver: true
        }
      ),
      onPanResponderRelease: (event, state) => {
        pan.flattenOffset();
        setDrag();
      }
    })
  ).current;

  useEffect(() => {
    setMaxX((refC.current && refC.current.getBoundingClientRect().width - ref.current.getBoundingClientRect().width) - ref.current.getBoundingClientRect().x);
    setMaxY((refC.current && refC.current.getBoundingClientRect().height - ref.current.getBoundingClientRect().height) - ref.current.getBoundingClientRect().y);
    // setMinX(ref.current && ref.current.getBoundingClientRect().width);
    // setMinY(ref.current && ref.current.getBoundingClientRect().height);
  }, [refC.current, ref.current]);

  const y = first && flag ? pan.y.interpolate({
    inputRange: [0, maxY],
    outputRange: [0, maxY],
    extrapolateRight: 'clamp'
  }) : "";
  const x = first && flag ? pan.x.interpolate({
    inputRange: [0, maxX],
    outputRange: [0, maxX],
    extrapolateRight: 'clamp'
  }) : "";

  // const scale = toggleAnim.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: [0, 1],
  // });

  useMemo(() => {
      setFlag(flagDrag);
  }, [flagDrag]);

  useEffect(() => {
    ref.current && ref.current.addEventListener('mousedown', () => {
      setIndex();
    })
  }, [ref.current]);
  useEffect(() => {
    setIndex();
  }, []);
    // Animated.timing(toggleAnim, {
    //   toValue: 0,
    //   duration: 1000,
    //   easing: Easing.ease
    // }).start()

  return flagDrag ? (
    <Animated.View
      style={{
        transform: [{ translateX: x }, { translateY: y }],
        // transform: [{scale: scale}],
        zIndex: 10,
        // position: widthScreen - widthWindow > 100 ? "absolute" : "",
        ...style,
        shadowColor: "black",
        shadowOffset: {
          height: 0,
          width: 0,
        },
        shadowOpacity: 0.5,
      }}
      ref={ref}
      {...responder.panHandlers}
    >
      {renderItem}
    </Animated.View>
  ) : (
    <Animated.View
      style={{
        transform: [{ translateX: x }, { translateY: y }],
        zIndex: 10,
        ...style,
        shadowColor: "black",
        shadowOffset: {
          height: 0,
          width: 0,
        },
        shadowOpacity: 0.5,
        shadowRadius: 5,
      }}
      ref={ref}
    >
      {renderItem}
    </Animated.View>
  )
}