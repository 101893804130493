import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useMemo, useState } from 'react'
import Statistics from '../analyticsTabels/Statistics'
import HeatMap from "../analyticsTabels/HeatMap";
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { useDispatch, useSelector } from 'react-redux';
import InteractionsMap from '../analyticsTabels/InteractionsMap';
import WordsMap from '../analyticsTabels/WordsMap';
import WordMessages from "../analyticsTabels/WordMessages";
import { ScrollSync } from 'react-scroll-sync';
import FullScreenPopup from '../../../../Elements/FullScreenPopup';
import QuestionnaireMap from '../analyticsTabels/QuestionnaireMap';

export default function RealAnalytics({ stopPlayScroll, isShot, setMaximizeType, statisticsRef, heatMapRef, interactionRef, wordCloudRef, questionnaireRef, num }) {

  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(heightS, widthS);

  const dispatch = useDispatch();

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const timeUnitsSelector = useSelector(state => state.swimlane.timeUnints);


  const [wordsMessages, setWordsMessages] = useState([]);
  const [word, setWord] = useState("");

  const [position, setPosition] = useState();
  const [timeUnits, setTimeUnits] = useState();
  const [maximize, setMaximize] = useState(false);
  const [hoverType, setHoverType] = useState();

  const tables = {
    statistics: (flag) => <Statistics isShot={isShot} refShot={statisticsRef} hoverType={hoverType} setHoverType={setHoverType} maximize={flag} setMaximize={() => setMaximizeType(maximize, "statistics", setMaximize)} type={"real"} position={position} setPosition={setPosition} timeUnits={timeUnits} setTimeUnits={setTimeUnits} stopPlayScroll={stopPlayScroll} />,
    heat: (flag) => <HeatMap isShot={isShot} refShot={heatMapRef} maximize={flag} setMaximize={() => setMaximizeType(maximize, "heat", setMaximize)} type={"real"} position={position} setPosition={setPosition} timeUnits={timeUnits} setTimeUnits={setTimeUnits} stopPlayScroll={stopPlayScroll} />,
    interaction: (flag) => <InteractionsMap isShot={isShot} refShot={interactionRef} maximize={flag} setMaximize={() => setMaximizeType(maximize, "interaction", setMaximize)} type={"real"} position={position} setPosition={setPosition} timeUnits={timeUnits} setTimeUnits={setTimeUnits} stopPlayScroll={stopPlayScroll} />,
    word: (flag) => <View style={styles.wordMap(flag)}>
      <WordsMap isShot={isShot} refShot={wordCloudRef} maximize={flag} setMaximize={() => setMaximizeType(maximize, "word", setMaximize)} type={"real"} setWordsMessages={setWordsMessages} setWord={setWord} position={position} setPosition={setPosition} timeUnits={timeUnits} setTimeUnits={setTimeUnits} stopPlayScroll={stopPlayScroll} />
      <WordMessages wordsMessages={wordsMessages} word={word} stopPlayScroll={stopPlayScroll} />
    </View>,
    questionnaire: (flag) => <QuestionnaireMap stopPlayScroll={stopPlayScroll} isShot={isShot} refShot={questionnaireRef} />
  }

  useEffect(() => {
    setTimeUnits(timeUnitsSelector);
  }, [timeUnitsSelector]);

  const returnMap = (num) => {
    switch (num) {
      case 1: return tables.statistics()
      case 2: return tables.heat()
      case 3: return tables.interaction()
      case 4: return tables.word()
      case 5: return tables.questionnaire()
    }
  }

  return (
    <View>
      <ScrollSync>
        <>
          {/* {tables.statistics()}
          {tables.heat()}
          {tables.interaction()}
          <View style={styles.bottom}>
            {tables.word()}
            {tables.questionnaire()}
          </View> */}
          {returnMap(num)}
        </>
      </ScrollSync>
      {maximize &&
        <FullScreenPopup
          children={
            <ScrollSync>
              {tables[maximize](true)}
            </ScrollSync>
          }
          flag={true}
          setFlag={() => setMaximize(false)}
        />}
    </View>
  )
}

const stylesR = (heightS, widthS) => {
  return StyleSheet.create({
    wordMap: (flag) => ({
      maxWidth: "100%",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      height: "max-content",
      marginBottom: "3vw",
    }),
    bottom: {
      width: "100%",
    }
  })
}