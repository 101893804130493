import { Animated, PanResponder, View } from 'react-native'
import React, { useEffect, useRef } from 'react';

export default function Dragged({ index, items, renderItem, setNewIndex, setNewIndexState, height, setIndex, zIndex, indexFlag, indexCurrent, setIndexCurrent, refScroll, scrollTo }) {
    const pan = useRef(new Animated.ValueXY()).current;

    const responder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: () => true,
            onPanResponderGrant: ({ touchHistory }) => {
                pan.setOffset({
                    y: pan.y._value
                });
            },
            onPanResponderMove: (evt, gesture) => {
                let yNew = gesture.dy;
                if (index === items.length - 1) {
                    if (yNew < 0) {
                        yNew = yNew - (yNew * 2);
                        let move1 = Math.floor(yNew / height);
                        setIndexCurrentCheck(index - move1)
                    }
                } else {
                    if (yNew > 0) {
                        let move2 = Math.floor(yNew / height);
                        setIndexCurrentCheck(index + move2 < items.length ? index + move2 : items.length);
                    }
                    else {
                        yNew = yNew - (yNew * 2);
                        let move3 = Math.floor(yNew / height);
                        setIndexCurrentCheck(index - move3);
                    }
                }
                return Animated.event(
                    [
                        null,
                        { dy: pan.y },
                    ],
                    {
                        useNativeDriver: true
                    }
                )(evt, gesture)
            },
            onPanResponderRelease: (event, state) => {
                pan.flattenOffset();
                let yNew = state.dy;
                if (index === items.length - 1) {
                    if (yNew > 0) {
                        setNewIndex(index, index);
                    }
                    else {
                        yNew = yNew - (yNew * 2);
                        let move1 = Math.floor(yNew / height);
                        // move1 += Math.floor(yNew % height / (height - (height / 2)))
                        setNewIndex(index, index - move1 >= 0 ? index - move1 : 0);
                    }
                } else {
                    if (yNew > 0) {
                        let move2 = Math.floor(yNew / height);
                        // move2 += Math.floor(yNew % height / (height - (height / 2)))
                        setNewIndex(index, index + move2 < items.length ? index + move2 : items.length)
                    }
                    else {
                        yNew = yNew - (yNew * 2);
                        let move3 = Math.floor(yNew / height);
                        // move3 += Math.floor(yNew % height / (height - (height / 2)))
                        setNewIndex(index, index - move3 >= 0 ? index - move3 : 0)
                    }
                }
                setIndex()
                // setNewIndex()
            }
        })
    ).current;

    const setIndexCurrentCheck = (newIndex) => {
        if (newIndex !== index) {
            // setNewIndexState(index, newIndex);
        }
    }

    const movePlayers = () => {
        //    if(indexCurrent){
        //     if(indexFlag === 0 && index <= indexCurrent){
        //         return -height;
        //     } else if(indexFlag > 0 && indexFlag < items.length - 1){
        //         if(index >= indexFlag && indexCurrent >= index){
        //             return -height;
        //         }else{
        //             return 0;
        //         }
        //     }else if(indexFlag === items.length - 1 && index >= indexCurrent){
        //         return height;
        //     }else {
        //         return -height;
        //     }
        //    }else{
        //     return 0;
        //    }
        // if (index >= indexFlag) {
        //     console.log(indexCurrent >= index);
        //     if (indexCurrent >= index) {
        //         return -height;
        //     } else {
        //         return 0;
        //     }
        // }
        // else if (index <= indexFlag && indexCurrent <= index) {
        //     return height;
        // } else {
        //     return 0;
        // }
    }

    useEffect(() => {
        pan.setValue({ y: 0, x: 0 });
    }, [items]);

    window.addEventListener("mouseup", () => {
        setIndex();
    });

    return (
        <>
            {index === indexFlag ? <Animated.View
                style={{
                    transform: [{ translateY: index === indexFlag ? pan.y : 0 }],
                    zIndex: zIndex,
                }}
                {...responder.panHandlers}
            >
                <View style={{
                    transform: [{ scaleX: 1.01 }],
                    transition: "all 0.1s",
                }}>
                    {renderItem}
                </View>
            </Animated.View> :
                <Animated.View
                    style={{
                        transform: [{ translateY: movePlayers() }],
                        zIndex: zIndex,
                        transition: "all 0.2s"
                    }}
                >
                    <View style={{
                        transform: [{ scaleX: 1.00 }],
                        transition: "all 0.1s"
                    }}>
                        {renderItem}
                    </View>
                </Animated.View>}
        </>
    )
}
