import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Modal,
  Image,
  TextInput,
  ScrollView,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { t } from "i18n-js";
import { addAction, loading, removeType, resetActionCurrent, shareAction } from "../../../redux/actions";
import { ActionsContext } from "../../../ContextData/Actions";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import LeftMenuContainerPopups from "../../modules/LeftMenuContainerPopups";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";
import { USER_ID_IN_GAME } from "../../../config.inc";
import { UpdateContext } from "../../../ContextData/Update";

export default function NewAction({
  flag,
  setFlag,
  actionLength,
  showThank,
  closeAction,
  refC,
}) {

  const { getUpdateData } = useContext(UpdateContext);

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const dispatch = useDispatch();

  const popupRef = useRef(null);
  const modalRef = useRef(null);

  const { addActionData, getActionsData } = useContext(ActionsContext);

  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);
  const color = useSelector((state) => state.style.color);
  // const actionState = useSelector((state) => state.actionsArr.currentAction);
  let actionObj = { action: null, solutions: [], decision: null, shareList: null }
  const [currentAction, setCurrentAction] = useState(actionObj);
  const [indexPage, setIndexPage] = useState(1);
  const [showShare, setShowShare] = useState(false);
  const [chooseSolution, setChooseSolution] = useState(false);

  const nextStep = (type) => {
    switch (type) {
      case "+":
        if (indexPage < 4) {
          setIndexPage((i) => i + 1);
        }
        return;
      case "-":
        if (indexPage > 0) {
          setIndexPage((i) => i - 1);
        }
        return;
    }
  };


  const steps = () => {
    switch (indexPage) {
      case 1:
        return (
          <Step1
            nextStep={nextStep}
            addDetails={(event, des, name) => {
              let actionNew = {
                subject: name,
                creator_id: sessionStorage.getItem(USER_ID_IN_GAME),
                action_body: des,
                action_type: "1",
                event_id: event.id,
                event_name: event.name,
              };
              setCurrentAction({ ...currentAction, action: actionNew })
              nextStep("+");
            }}
            actionLength={actionLength}
            newChallenge={currentAction}
            setFlag={setFlag}
            setEvent={(id, name) => setEvent({ id: id, name: name })}
          />
        );
      case 2:
        return (
          <Step2
            nextStep={nextStep}
            newChallenge={currentAction}
            addSolutions={(solutions, flag) => {
              let arr = [];
              solutions &&
                solutions.length &&
                solutions.map((e, i) => {
                  let newSolution = {
                    subject: `${t("challenge")} #${actionLength + 1}`,
                    creator_id: sessionStorage.getItem(USER_ID_IN_GAME),
                    action_body: e.action_body,
                    action_type: "2",
                    related_actions: currentAction.action.id,
                    admin_order: `${i += 1}`,
                    event_id: currentAction.action.event_id,
                    real_event_id: currentAction.action.event_id,
                  };
                  arr.push(newSolution);
                });
              setCurrentAction({ ...currentAction, solutions: arr });
              if (flag) nextStep("+");
            }}
            actionLength={actionLength}
          />
        );
      case 3:
        return (
          <Step3
            nextStep={nextStep}
            newChallengeProp={currentAction}
            actionLength={actionLength}
            chooseSolution={chooseSolution}
            setChooseSolution={setChooseSolution}
            addSolutionsFlag={(decitionIndex) => {
              nextStep("+");
              setCurrentAction({ ...currentAction, decision: decitionIndex + 1 })
            }}
          />
        );
      case 4:
        return (
          <Step4
            newChallenge={currentAction}
            addNewChallenge={(flag) => addNewChallenge(currentAction, flag)}
            actionLength={actionLength}
            showShare={showShare}
            setShowShare={(flag) => setShowShare(flag)}
            setShare={(share_list) => {
              // let action = currentAction.action;
              // action.to_list = share_list;
              // dispatch(loading(true));
              // addActionData([action])
              //   .then((e) => {
              //     dispatch(loading(false));
              //     if (e) {
              //       getActionsData();
              //     }
              //   })
              //   .catch(() => dispatch(loading(false)));
              setCurrentAction({ ...currentAction, shareList: share_list })
            }}
          />
        );
    }
  };

  const addNewChallenge = (action, flag) => {
    dispatch(loading(true));
    addActionData(currentAction)
      .then((e) => {
        dispatch(loading(false));
        setIndexPage(1);
        setCurrentAction(actionObj);
        // dispatch(addAction(null));
        if (!flag) {
          setFlag();
          closeAction();
          dispatch(removeType("challenges"));
          showThank();
        }
        if (e) {
          getUpdateData();
        }
      })
      .catch(() => dispatch(loading(false)));
  };

  // useEffect(() => {
  //   setCurrentAction(actionState);
  // }, [actionState]);

  return (
    <View style={[styles.container, { display: flag ? "" : "none" }]} ref={modalRef}>
      <LeftMenuContainerPopups refC={refC}>
        <View
          style={[
            styles.main,
            {
              backgroundColor: background.popup1,
            },
            {},
          ]}
          ref={popupRef}
        >
          <View style={[styles.header, { borderBottomColor: color.border }]}>
            <View style={styles.headerLeft}>
              <View
                style={[
                  styles.logo_view,
                  { backgroundColor: background.actionLogo },
                ]}
              >
                <Image source={{ uri: images.action }} style={styles.logo} />
              </View>
              <Text style={styles.textLogo}>
                {currentAction?.action?.subject}
              </Text>
            </View>
            <HeaderPopupButtons
              close={() => { dispatch(resetActionCurrent()); setFlag() }}
              type={true}
            />
          </View>
          <View style={styles.top}>
            <View style={styles.progress_bar}>
              <View style={styles.view_circle}>
                <Text style={styles.num_step}>1</Text>
                <View
                  style={[
                    styles.circle,
                    { backgroundColor: background.actionCircle },
                  ]}
                >
                  {indexPage >= 1 && (
                    <View style={styles.circle_active_view}>
                      <View style={styles.circle_active_shadow} />
                      <View
                        style={[
                          styles.circle_active,
                          { backgroundColor: background.actionCircleActive },
                        ]}
                      />
                    </View>
                  )}
                </View>
              </View>
              <View
                style={[
                  styles.progress_bar_inner,
                  { backgroundColor: background.actionProgress },
                ]}
              >
                {indexPage >= 1 && (
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={[
                        { color: color.actionProgressDot1 },
                        styles.dots,
                      ]}
                    >
                      ......
                    </Text>
                    <Text
                      style={[
                        {
                          color:
                            indexPage >= 2
                              ? color.actionProgressDot1
                              : color.actionProgressDot2,
                        },
                        styles.dots,
                      ]}
                    >
                      ......
                    </Text>
                    <Text
                      style={[
                        {
                          color:
                            indexPage >= 2
                              ? color.actionProgressDot1
                              : color.actionProgressDot3,
                        },
                        styles.dots,
                      ]}
                    >
                      ......
                    </Text>
                  </View>
                )}
              </View>
              <View style={styles.view_circle}>
                <Text style={styles.num_step}>2</Text>
                <View
                  style={[
                    styles.circle,
                    { backgroundColor: background.actionCircle },
                  ]}
                >
                  {indexPage >= 2 && (
                    <View style={styles.circle_active_view}>
                      <View style={styles.circle_active_shadow} />
                      <View
                        style={[
                          styles.circle_active,
                          { backgroundColor: background.actionCircleActive },
                        ]}
                      />
                    </View>
                  )}
                </View>
              </View>
              <View
                style={[
                  styles.progress_bar_inner,
                  { backgroundColor: background.actionProgress },
                ]}
              >
                {indexPage >= 2 && (
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={[
                        { color: color.actionProgressDot1 },
                        styles.dots,
                      ]}
                    >
                      ......
                    </Text>
                    <Text
                      style={[
                        {
                          color:
                            indexPage >= 3
                              ? color.actionProgressDot1
                              : color.actionProgressDot2,
                        },
                        styles.dots,
                      ]}
                    >
                      ......
                    </Text>
                    <Text
                      style={[
                        {
                          color:
                            indexPage >= 3
                              ? color.actionProgressDot1
                              : color.actionProgressDot3,
                        },
                        styles.dots,
                      ]}
                    >
                      ......
                    </Text>
                  </View>
                )}
              </View>
              <View style={styles.view_circle}>
                <Text style={styles.num_step}>3</Text>
                <View
                  style={[
                    styles.circle,
                    { backgroundColor: background.actionCircle },
                  ]}
                >
                  {indexPage >= 3 && (
                    <View style={styles.circle_active_view}>
                      <View style={styles.circle_active_shadow} />
                      <View
                        style={[
                          styles.circle_active,
                          { backgroundColor: background.actionCircleActive },
                        ]}
                      />
                    </View>
                  )}
                </View>
              </View>
              <View
                style={[
                  styles.progress_bar_inner,
                  { backgroundColor: background.actionProgress },
                ]}
              >
                {indexPage >= 3 && (
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text
                      style={[
                        { color: color.actionProgressDot1 },
                        styles.dots,
                      ]}
                    >
                      ......
                    </Text>
                    <Text
                      style={[
                        {
                          color:
                            indexPage >= 4
                              ? color.actionProgressDot1
                              : color.actionProgressDot2,
                        },
                        styles.dots,
                      ]}
                    >
                      ......
                    </Text>
                    <Text
                      style={[
                        {
                          color:
                            indexPage >= 4
                              ? color.actionProgressDot1
                              : color.actionProgressDot3,
                        },
                        styles.dots,
                      ]}
                    >
                      ......
                    </Text>
                  </View>
                )}
              </View>
              <View style={styles.view_circle}>
                <Text style={styles.num_step}>4</Text>
                <View
                  style={[
                    styles.circle,
                    { backgroundColor: background.actionCircle },
                  ]}
                >
                  {indexPage >= 4 && (
                    <View style={styles.circle_active_view}>
                      <View style={styles.circle_active_shadow} />
                      <View
                        style={[
                          styles.circle_active,
                          { backgroundColor: background.actionCircleActive },
                        ]}
                      />
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View nativeID="action" style={styles.scroll}>
            {steps()}
          </View>
        </View>
      </LeftMenuContainerPopups>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, direction) =>
  StyleSheet.create({
    container: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      paddingTop: "2%",
      fontFamily: "Roboto",
      height: "100%",
      width: "100%",
      zIndex: 1,
      backgroundColor: "#00000057",
    },
    main: {
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.3,
      shadowRadius: 10,
      position: "absolute",
      width: widthS * 380,
      height: heightS * 410,
      bottom: heightS * 55,
      borderRadius: widthS * 10,
    },
    header: {
      paddingLeft: widthS * 25,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: widthS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
      fontFamily: "Catamaran_700Bold",
    },
    x: {
      fontWeight: "300",
      fontSize: widthS * 14,
      fontFamily: "Catamaran_400Regular",
    },
    top: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    challe_name: {
      fontSize: widthS * 14,
      marginTop: heightS * 15,
      fontFamily: "Catamaran_400Regular",
    },
    progress_bar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: heightS * 15,
      writingDirection: direction,
    },
    view_circle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    circle: {
      width: widthS * 18,
      height: widthS * 18,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    circle_active_view: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    circle_active_shadow: {
      width: widthS * 8,
      height: widthS * 8,
      position: "absolute",
      borderRadius: "50%",
      backgroundColor: "tranparent",
      zIndex: 3,
      shadowColor: "white",
      shadowRadius: 10,
      shadowOpacity: 0.8,
    },
    circle_active: {
      width: widthS * 8,
      height: widthS * 8,
      borderRadius: "50%",
      zIndex: 2,
    },
    progress_bar_inner: {
      width: widthS * 90,
      height: heightS * 6,
      marginHorizontal: widthS * -1,
      alignSelf: "flex-end",
      marginBottom: heightS * 5.5,
      zIndex: -1,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      // paddingTop: 12
    },
    num_step: {
      color: "white",
      fontSize: widthS * 12,
      fontFamily: "Catamaran_400Regular",
    },
    dots: {
      letterSpacing: widthS * 4,
      fontSize: widthS * 8,
      fontWeight: "bold",
      lineHeight: heightS * 11,
    },
    scroll: {
      width: "100%",
      paddingHorizontal: widthS * 20,
      marginTop: heightS * 10,
      // paddingBottom: (height) * 20,
      flex: 1,
    },
  });
