import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';
import canvg from 'canvg';

const filter = (node) => {
    if (node.style) {
        node.style.overflowX = "hidden";
        node.style.overflowY = "hidden";
    }
    if (node.classList && node.classList.contains('vis-tooltip')) {
        return false; // אלמנטים אלו לא ייכללו בייצוא
    }
    return node;
}

export const createPng = (div, fileName) => {
    return new Promise((resolve, reject) => {
        htmlToImage.toPng(div, { filter: (node) => filter(node) })
            .then(function (dataUrl) {
                downloadFile(dataUrl, fileName);
                resolve(true);
            }).catch((e) => {
                console.log(e);
                reject(false);
            });
    })
}

export const createFromSvgPng = (element, element2, fileName) => {
    return new Promise((resolve, reject) => {
        let div = document.createElement("div");
        div.innerHTML = element.innerHTML;
        if (element2) {
            div.innerHTML += element2.innerHTML;
        }
        div.style.zIndex = -9999;
        div.style.display = "flex";
        div.style.flexDirection = "row";
        document.body.appendChild(div);
        const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        const foreignObjectElement = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        svgElement.setAttribute('width', div.offsetWidth);
        svgElement.setAttribute('height', div.offsetHeight);

        // Embed the div element inside a foreignObject element
        foreignObjectElement.setAttribute('width', '100%');
        foreignObjectElement.setAttribute('height', '100%');
        foreignObjectElement.appendChild(div.cloneNode(true));
        svgElement.appendChild(foreignObjectElement);
        html2canvas(div).then((canvas) => {
            const pngUrl = canvas.toDataURL('image/png');
            downloadFile(pngUrl, fileName);
            debugger
            document.body.removeChild(div);
            resolve(true);
        }).catch(() => reject(false));
    })
}

export const downloadFile = (url, fileName) => {
    let link = document.createElement("a");
    link.download = `${fileName}.png`;
    link.href = url;
    link.click();
    link.remove();
}
