import { CHNAGED_POSITION, SET_POSITION } from "../actions";

const position = [
  { x: 1, y: 0, type: "dashboard" },
  { x: 1, y: 1, type: "simulation" },
  { x: 1, y: 2, type: "actions" },
  { x: 1, y: 2, type: "system" },
  { x: 1, y: 3, type: "files" },
  { x: 2, y: 0, type: "build" },
  { x: 2, y: 1, type: "analysis" },
];
const positionPlayer = [
  { x: 1, y: 0, type: "simulation" },
  { x: 1, y: 1, type: "actions" },
  { x: 1, y: 2, type: "files" },
  { x: 1, y: 2, type: "system" },
];

export const Position = (state = { items: [] }, action) => {
  switch (action.type) {
    case SET_POSITION: {
      if (action?.payload) {
        return { ...state, items: positionPlayer };
      } else {
        return { ...state, items: position };
      }
    }
    case CHNAGED_POSITION:
      let newItems = [...state.items];
      let flag = false;
      newItems.forEach((e) => {
        if (e.x === action.x && e.y === action.y) {
          let newX = e.x;
          let newY = e.y;
          e.x = newItems[action.index].x;
          e.y = newItems[action.index].y;
          newItems[action.index].x = newX;
          newItems[action.index].y = newY;
          flag = true;
        }
      });
      if (!flag) {
        newItems[action.index].x = action.x;
        newItems[action.index].y = action.y;
      }
      return { ...state, items: [...newItems] };
    default:
      return state;
  }
}