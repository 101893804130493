import { StyleSheet, Text, View, Modal, Image, Pressable } from 'react-native'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import HeaderPopupButtons from '../../../../Elements/HeaderPopupButtons';
import { t } from 'i18n-js';
import { useState } from 'react';
import { useEffect } from 'react';
import CheckBox from '../../../../Elements/CheckBox';
import { useContext } from 'react';
import { SwimlaneChartContext } from '../../../../../ContextData/Swimlanechart';
import { loading, setErrorMessage } from '../../../../../redux/actions';
import useExitGame from "../../../../modules/ExitGame";

export default function Reset({ setFlag, flag }) {

    const dispatch = useDispatch();
    const exitGame = useExitGame();

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const styles = stylesR(background, color);

    const { resetGame } = useContext(SwimlaneChartContext);

    let resetOptions = {
        "simulation": { name: t("reset_simulation"), checked: false },
        "messages": { name: t("reset_all_messages"), checked: false },
        "mail": { name: t("reset_emails"), checked: false },
        "social": { name: t("reset_social_network"), checked: false },
        "news": { name: t("reset_breaking_news"), checked: false },
        "chat": { name: t("reset_chat"), checked: false },
        // "event": { name: t("reset_events"), checked: false },
        "challenge": { name: t("reset_challenges"), checked: false },
        "questionnaire": { name: t("reset_questionnaire"), checked: false },
        "flow": { name: t("reset_flow"), checked: false },
        "delete": { name: t("reset_delete"), checked: false },
    }

    const [resetOptionsState, setResetOptionsState] = useState({});
    const [ifChooseType, setIfChooseType] = useState(false);

    const handleCheckedOption = (key) => {
        switch (key) {
            case "simulation":
                resetOptionsState["simulation"].checked = !resetOptionsState["simulation"].checked;
                Object.keys(resetOptionsState).forEach((key) => {
                    if (key !== "simulation" && key !== "flow" && key !== "event" && key !== "delete") {
                        resetOptionsState[key].checked = resetOptionsState["simulation"].checked;
                    }
                });
                return setResetOptionsState({ ...resetOptionsState });
            case "messages":
                resetOptionsState["messages"].checked = !resetOptionsState["messages"].checked;
                Object.keys(resetOptionsState).forEach((key) => {
                    if (key === "mail" || key === "social" || key === "news" || key === "chat" || (key === "simulation" && !resetOptionsState["messages"].checked)) {
                        resetOptionsState[key].checked = resetOptionsState["messages"].checked;
                    }
                });
                return setResetOptionsState({ ...resetOptionsState });
            case "delete":
                resetOptionsState["delete"].checked = !resetOptionsState["delete"].checked;
                Object.keys(resetOptionsState).forEach((key) => {
                    if (key !== "delete") {
                        resetOptionsState[key].checked = resetOptionsState["delete"].checked;
                    }
                });
                return setResetOptionsState({ ...resetOptionsState });
            default:
                resetOptionsState[key].checked = !resetOptionsState[key].checked;
                if (!resetOptionsState[key].checked && key !== "delete" && key !== "flow") {
                    resetOptionsState["simulation"].checked = false;
                }
                if (!resetOptionsState[key].checked && (key == "mail" || key == "social" || key === "news" || key === "chat")) {
                    resetOptionsState["messages"].checked = false;
                }
                return setResetOptionsState({ ...resetOptionsState });
        }
    }

    const resetOptionsFunc = () => {
        dispatch(loading(true));
        resetGame(resetOptionsState).then(() => {
            dispatch(loading(false));
            setFlag();
            if (resetOptionsState?.delete?.checked) {
                exitGame();
            } else {
                window.location.reload();
            }
        }).catch(() => {
            dispatch(loading(false));
        });
    }

    useEffect(() => {
        setResetOptionsState(resetOptions);
    }, []);

    useEffect(() => {
        setIfChooseType(Object.keys(resetOptionsState).some(key => resetOptionsState[key].checked));
    }, [resetOptionsState]);

    return (
        <Modal animationType='fade' transparent={true} visible={flag}>
            <View style={styles.container}>
                <View style={styles.main}>
                    <View style={styles.header}>
                        <View style={styles.headerLeft}>
                            <View
                                style={styles.logo_view}
                            >
                                <Image source={{ uri: images.reset }} style={styles.logo} />
                            </View>
                            <Text selectable={false} style={styles.textLogo}>
                                {t("reset_options")}
                            </Text>
                        </View>
                        <HeaderPopupButtons
                            close={() => setFlag()}
                            type={true}
                        />
                    </View>
                    <View style={styles.mainChild}>
                        {Object.keys(resetOptionsState).map((key, index) => {
                            return (
                                <Pressable
                                    key={index}
                                    style={styles.resetOptionsView}
                                    onPress={() => handleCheckedOption(key)}
                                >
                                    <Text style={styles.resetText}>{resetOptionsState[key].name}</Text>
                                    <CheckBox
                                        checked={resetOptionsState[key].checked}
                                        colorFill={background.count}
                                        isDone={false}
                                        background={background.infoBody}
                                    />
                                </Pressable>
                            )
                        })}
                        <Pressable
                            onPress={() => {
                                if (ifChooseType) {
                                    if (resetOptionsState?.delete?.checked) {
                                        return dispatch(
                                            setErrorMessage(
                                                true,
                                                undefined,
                                                `${t("delete_simulation_error")}`,
                                                (e) => {
                                                    if (e) {
                                                        resetOptionsFunc();
                                                    }
                                                }
                                            )
                                        );
                                    }
                                    if (resetOptionsState?.flow?.checked) {
                                        return dispatch(
                                            setErrorMessage(
                                                true,
                                                undefined,
                                                `${t("delete_flow_error")}`,
                                                (e) => {
                                                    if (e) {
                                                        resetOptionsFunc();
                                                    }
                                                }
                                            )
                                        );
                                    }
                                    resetOptionsFunc();
                                }
                            }}
                            style={styles.saveBtn(ifChooseType)}
                        >
                            <Text
                                style={[
                                    styles.saveBtn_text, {
                                        color: color.text2,
                                    }
                                ]}
                            >
                                {t("reset")}
                            </Text>
                        </Pressable>
                    </View>
                </View>
            </View>
        </Modal>
    )
}

const stylesR = (background, color) => StyleSheet.create({
    container: {
        backgroundColor: "#0000005e",
        width: "100%",
        height: "100vh",
        alignItems: "center",
    },
    main: {
        marginTop: "5vw",
        width: "30vw",
        minHeight: "40vh",
        borderRadius: "1.5vw",
        backgroundColor: background.popup1
    },
    header: {
        paddingLeft: "1.6vw",
        borderBottomWidth: "0.1vw",
        borderBottomStyle: "solid",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottomColor: color.border
    },
    headerLeft: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: "0.4vw",
    },
    logo_view: {
        width: "2.6vw",
        height: "2.6vw",
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: background.actionLogo
    },
    logo: {
        width: "1.8vw",
        height: "1.8vw",
        resizeMode: "contain",
    },
    textLogo: {
        color: "white",
        fontFamily: "Catamaran_700Bold",
        fontSize: "1.5vw",
        paddingLeft: "1vw",
    },
    x: {
        fontFamily: "Catamaran_400Regular",
        fontSize: "1.4vw",
    },
    mainChild: {
        marginVertical: "1vw",
        flex: 1,
        alignItems: "center",
        rowGap: "0.3vw"
    },
    resetOptionsView: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "40%",
        alignItems: "center"
    },
    resetText: {
        color: color.text,
        fontSize: "0.8vw",
        fontFamily: "Catamaran_500Medium"
    },
    saveBtn: (flag) => ({
        paddingHorizontal: "0.8vw",
        paddingVertical: "0.4vw",
        borderRadius: "0.5vw",
        alignItems: "center",
        justifyContent: "center",
        borderColor: color.text,
        borderWidth: flag ? "" : "0.1vw",
        marginTop: "1vw",
        width: "5vw",
        backgroundColor: flag ? background.actionLogo : ""
    }),
    saveBtn_text: {
        fontSize: "1vw",
        color: color.text,
        fontFamily: "Catamaran_500Medium"
    },
})