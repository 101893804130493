import { View, Text, Modal, Pressable, StyleSheet, Image } from "react-native";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseResponsiveSize from "../../../../modules/UseResponsiveSize";
import { t } from "i18n-js";
import UseResponsiveScreen from "../../../../modules/UseResponsiveScreen";
import { createFromSvgPng } from "../../../../modules/downloadPNG";
import { loading } from "../../../../../redux/actions";
import { exportFlowExcel } from "../../Analytics/ExportExcel";
import Reset from "./Reset";

const ImportExport = ({ show, showReset, setShowReset, arr, duplicateGame, refCurrent, importContainerRef, permission }) => {
  const dispatch = useDispatch();
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const styles = stylesR(width, height, heightS, widthS, background, color);

  const images = useSelector((state) => state.style.images);
  const gameName = useSelector(state => state.gameInfoArr.gameInfoArr?.game_name);

  const [type, setType] = useState();

  const createArrToExcel = (arr) => {
    let arrToExcel = [];
    arr.forEach((e) => {
      let body = e.body;
      if (e.platform_type === "mail") {
        let p = document.createElement("p");
        p.innerHTML = e.body;
        body = p.innerText;
      }
      arrToExcel.push([
        `T${e.time}`,
        e.type_action,
        e.event,
        e.platform_type,
        e.is_kpi ? "Yes" : "No",
        e.from,
        e?.to_list,
        e?.cc_list,
        e?.bcc_list,
        e.subject,
        body,
        e.files
      ])
    })
    return {
      arrToExcel
    }
  }

  const btnOption = (onPress, iconUrl, name) => {
    return (
      <Pressable
        onPress={onPress}
        style={({ hovered }) => [styles.popupPress, {
          backgroundColor: (hovered || type === "export") ? background.infoBody : "",
        }]}
      >
        <Image
          source={{ uri: iconUrl }}
          resizeMode="contain"
          style={{
            width: 12 * widthS,
            height: 12 * widthS,
            marginRight: 3 * widthS,
          }}
        />
        <Text
          style={{
            fontSize: "1vw",
            color: color.infoHeaderNavText,
            marginLeft: widthS * 3,
          }}
        >
          {name}
        </Text>
      </Pressable>
    )
  }

  const exportComponent = () => {
    return (
      <View style={{ height: "auto", }}>
        <Pressable
          onPress={() => type === "export" ? setType() : setType("export")}
          style={({ hovered }) => [styles.popupPress, {
            backgroundColor: (hovered || type === "export") ? background.infoBody : "",
          }]}
        >
          <Image
            source={{ uri: images.exportIcon }}
            resizeMode="contain"
            style={{
              width: 12 * widthS,
              height: 12 * widthS,
              marginRight: 3 * widthS,
            }}
          />
          <Text
            style={{
              fontSize: "1vw",
              color: color.infoHeaderNavText,
              marginLeft: widthS * 3,
            }}
          >
            {t("export").toUpperCase()}
          </Text>
        </Pressable>
        {type === "export" &&
          <View>
            <Pressable onPress={() => {
              dispatch(loading(true));
              createFromSvgPng(document.getElementById("none-scroll-players"), document.getElementById("export-flow"), `${gameName}_Flowchart"`)
                .then(() => dispatch(loading(false))).catch(() => dispatch(loading(false)));
            }}
              style={({ hovered }) => [styles.popupPressChild, {
                backgroundColor: (hovered) ? background.infoBody : "",
              }]}
            >
              <Text style={styles.popupPressChildText}>PNG</Text>
            </Pressable>
            <Pressable
              style={({ hovered }) => [styles.popupPressChild, {
                backgroundColor: (hovered) ? background.infoBody : "",
              }]}
              onPress={() => {
                dispatch(loading(true));
                let { arrToExcel } = createArrToExcel(arr);
                exportFlowExcel(arrToExcel, `${gameName}_Flowchart"`)
                  .then(() => dispatch(loading(false))).catch(() => dispatch(loading(false)));
              }}
            >
              <Text style={styles.popupPressChildText}>XLS</Text>
            </Pressable>
          </View>}

        {/* {swimlane && <View style={styles.headerRight}>
          <Pressable
            style={[styles.btnSave, { opacity: startAndEndGame.end ? 0.7 : 1, }]}

          >
            <Text style={[styles.btnTextSave]}> duplicate </Text>
          </Pressable>
        </View>} */}
        {permission && btnOption(() => {
          dispatch(loading(true));
          duplicateGame();
        }, images.duplicate, t("duplicate").toUpperCase())}
        {/* <Pressable
          onPress={() => {
            dispatch(loading(true));
            duplicateGame();
          }}
          style={({ hovered }) => [styles.popupPress, {
            backgroundColor: (hovered || type === "export") ? background.infoBody : "",
          }]}
        >
          <Image
            source={{ uri: images.duplicate }}
            resizeMode="contain"
            style={{
              width: 12 * widthS,
              height: 12 * widthS,
              marginRight: 3 * widthS,
            }}
          />
          <Text
            style={{
              fontSize: "1vw",
              color: color.infoHeaderNavText,
              marginLeft: widthS * 3,
            }}
          >
            {t("duplicate").toUpperCase()}
          </Text>
        </Pressable> */}
        {permission && btnOption(() => setShowReset(true), images.reset, t("reset").toUpperCase())}
      </View >
    )
  }

  const importComponent = () => {
    return (
      <Pressable style={{ flexDirection: "row", alignItems: "center" }}>
        <Image
          resizeMode="contain"
          source={{ uri: images.importIcon }}
          style={{
            width: 12 * widthS,
            height: 12 * widthS,
            marginRight: 3 * widthS,
          }}
        />
        <Text
          style={{
            fontSize: 10 * widthS,
            color: color.infoHeaderNavText,
            marginLeft: widthS * 3,
          }}
        >
          {t("import").toUpperCase()}
        </Text>
      </Pressable>
    )
  }

  useEffect(() => {
    setType();
  }, [show]);

  return (
    <Modal animationType="none" transparent={true} visible={show}>
      <View ref={importContainerRef} style={styles.model}>
        <View style={styles.modelView}>
          <View
            style={[
              styles.traingular,
              { borderBottomColor: background.eventActive },
            ]}
          />
          <View
            style={[styles.popup, { backgroundColor: background.eventActive }]}
            ref={refCurrent}
          >
            {exportComponent()}
            {/* {importComponent()} */}
          </View>
        </View>
      </View>
      {showReset && <Reset flag={showReset} setFlag={() => setShowReset(false)} />}
    </Modal>
  );
};

const stylesR = (width, height, heightS, widthS, background, color) =>
  StyleSheet.create({
    model: {
      position: "relative",
      width: "100%",
      height: "100vh"
    },
    modelView: {
      position: "absolute",
      right: 10 * widthS,
      top: 42 * heightS,
      alignItems: "center",
      justifyContent: "center",
      transition: "all .5s"
    },
    traingular: {
      position: "absolute",
      top: -8 * heightS,
      width: 0,
      height: 0,
      borderLeftWidth: widthS * 7,
      borderRightWidth: widthS * 7,
      borderBottomWidth: widthS * 7,
      borderStyle: "solid",
      backgroundColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      zIndex: 3,
    },
    popup: {
      marginTop: -1,
      padding: "1vw",
      width: widthS * 155,
      zIndex: 1,
      borderRadius: 25,
      shadowColor: "#000000",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.5,
      shadowRadius: 5,
    },
    popupPress: {
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: "0.5vw",
      paddingVertical: "0.3vw",
      borderRadius: "0.3vw"
    },
    popupPressChild: {
      paddingHorizontal: "0.5vw",
      paddingVertical: "0.3vw",
      borderRadius: "0.3vw",
    },
    popupText: {
      color: "#dcdcdc",
      fontSize: widthS * 8,
      marginLeft: widthS * 5,
    },
    popupPressChildText: {
      fontSize: "0.8vw",
      color: color.infoHeaderNavText,
      marginLeft: "1vw",
    },
  });
export default ImportExport;