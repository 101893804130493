import { SETTING_FLAG, SET_USER} from "../actions";

export const user = (state = { user: {}, flag: false }, action) => {
    switch (action.type) {
        case SET_USER: {
            let newUser = {
                id: action.payload?.id,
                firstName: action.payload?.first_name ? action.payload.first_name : "",
                lastName: action.payload?.last_name ? action.payload.last_name : "",
                company: action.payload?.company ? action.payload.company : "",
                jobTitle: action.payload?.job_title ? action.payload.job_title : "",
                email: action.payload?.email ? action.payload.email : "",
                phone: action.payload?.phone ? action.payload.phone : "",
                profile_image: action.payload?.profile_image ? action.payload?.profile_image : null
            }
            return { ...state, user: newUser };
        };
        case SETTING_FLAG: {
            return { ...state, flag: !state.flag };
        }
        default: {
            return state;
        }
    }
}