import React, { useState, useRef, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Modal,
  View,
  Pressable,
  Text,
  Image,
  TouchableOpacity
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import MessagesBody from "./MessagesBody";
import { t } from "i18n-js";
import { minimizeNewMail, removeType, setTypeMinimize } from "../../../redux/actions";
import { FontAwesome } from '@expo/vector-icons';
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";

export default function MailBox({ flag, setFlag, popupRef, setDrag, dashboard, minimizeMail, closeAndSave, permission }) {
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(heightS, widthS);

  const modalRef = useRef(null);
  const messageBodyRef = useRef(null);

  const dispatch = useDispatch();
  const sentMessages = useSelector((state) => state.mailArr.sentMails);
  const messages = useSelector((state) => state.mailArr.messages);
  const drafts = useSelector((state) => state.mailArr.drafts);
  const arrangeInbox = useSelector((state) => state.mailArr.arrangeInbox);
  const arrangeSent = useSelector((state) => state.mailArr.arrangeSent);
  const arrangeDrafts = useSelector((state) => state.mailArr.arrangeDrafts);
  const minimizePopups = useSelector((state) => state.minimize.minimize);

  const images = useSelector((state) => state.style.images);
  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);

  const closeMailBoxHandler = () => {
    setFlag();
    dispatch(minimizeNewMail());
  };

  const messageBodycomponent = (
    <MessagesBody
      messageBodyRef={messageBodyRef}
      dashboard={dashboard}
      permission={permission}
      messages={messages}
      sentMessages={sentMessages}
      drafts={drafts}
      type={"mail"}
      arrangeInbox={arrangeInbox}
      arrangeSent={arrangeSent}
      arrangeDrafts={arrangeDrafts}
      minimizeMail={minimizeMail}
      closeAndSave={closeAndSave}
    />
  )

  return !dashboard ? (
    <View
      style={[styles.main, { backgroundColor: background.popup1 }]}
      ref={popupRef}
    >
      <View style={{ width: "100%", height: "100%" }}>
        <TouchableOpacity style={{ cursor: "grabbing" }} onPressIn={() => setDrag(true)}>
          <View style={[styles.header, { borderBottomColor: color.border }]}>
            <View style={styles.headerLeft}>
              <View
                style={[
                  styles.logo_view,
                  { backgroundColor: background.mailLogoColor },
                ]}
              >
                <Image source={{ uri: images.mail }} style={styles.logo} />
              </View>
              <Text selectable={false} style={styles.textLogo}>
                {t("mailbox")}
              </Text>
            </View>
            <HeaderPopupButtons
              close={() => { closeMailBoxHandler(); dispatch(removeType("mail")) }}
              minimize={() => {
                dispatch(setTypeMinimize({ name: "mail", type: 1, show: false }));
              }}
            />
          </View>
        </TouchableOpacity>
        {messageBodycomponent}
      </View>
    </View>
  ) : messageBodycomponent;
}
const stylesR = (heightS, widthS) =>
  StyleSheet.create({
    main: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#00000",
      fontFamily: "Roboto",
      height: "100%",
      borderRadius: widthS * 15,
      borderBottomLeftRadius: widthS * 10,
    },
    header: {
      paddingLeft: widthS * 17.2,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: widthS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
    },
    x: {
      fontFamily: "Catamaran_300Light",
      fontSize: widthS * 14,
    },
  });
