import { StyleSheet, Text, View, Pressable, Image } from 'react-native'
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCircleTimer } from '../modules/CreateTimerCircle';
import { t } from 'i18n-js';
import CircleDotsTimer from '../modules/CircleDotsTimer';
import { setInGame, setStartGame, setToken, showFile } from '../../redux/actions';
import UseResponsiveSize from '../modules/UseResponsiveSize';
import UseResponsiveScreen from '../modules/UseResponsiveScreen';
import { createFileObject } from '../modules/CreateFileObject';

export default function GameTimer() {
    const dispatch = useDispatch();

    const { width, height } = UseResponsiveSize();
    const { heightS, widthS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);
    const color = useSelector(state => state.style.colorClient);
    const background = useSelector(state => state.style.backgroundClient);
    const countdownState = useSelector(state => state.style.countDownVideo);
    const domainNameState = useSelector(state => state.style.domain);
    const images = useSelector(state => state.style.images);

    const domainName = useMemo(() => domainNameState, [domainNameState]);
    const countDownVideo = useMemo(() => countdownState, [countdownState]);

    const [timer, setTimer] = useState();

    const createTimer = (date) => {
        let res = createCircleTimer(date);
        if (res) {
            setTimer(res);
        }
    };

    useEffect(() => {
        if (gameInfo?.scheduale_start_time_current_zone) {
            let myInterval = setInterval(() => createTimer(gameInfo.scheduale_start_time_current_zone), 1000);
            return () => clearInterval(myInterval);
        }
    }, [gameInfo?.scheduale_start_time_current_zone]);
    useEffect(() => {
        if (timer && timer.D.now === "00" && timer.H.now === "00" && timer.M.now === "00" && timer.S.now === "00") {
            dispatch(setInGame());
            dispatch(setStartGame(true));
            dispatch(setToken(true));
        }
    }, [timer]);
    useEffect(() => {
        if (gameInfo && gameInfo.scheduale_start_time_current_zone) {
            let myInterval = setInterval(() => createTimer(gameInfo.scheduale_start_time_current_zone), 1000);
            return () => clearInterval(myInterval);
        }
    }, []);

    useEffect(() => {
        dispatch(setInGame());
    }, [])

    return (
        <>
            {timer && <View style={styles.container}>
                <Text style={[styles.text, { color: color.text, }]}>{t("timer_page_a")}</Text>
                <View style={styles.circles}>
                    <CircleDotsTimer heightP={widthS * 87} widthP={widthS * 87} sizeC={widthS * 2} count={timer && timer.D.remaining} now={timer && timer.D.now} name={t("days")} />
                    <CircleDotsTimer heightP={widthS * 87} widthP={widthS * 87} sizeC={widthS * 2} count={timer && timer.H.remaining} now={timer && timer.H.now} name={t("hours")} />
                    <CircleDotsTimer heightP={widthS * 87} widthP={widthS * 87} sizeC={widthS * 2} count={timer && timer.M.remaining} now={timer && timer.M.now} name={t("minutes")} />
                    <CircleDotsTimer heightP={widthS * 87} widthP={widthS * 87} sizeC={widthS * 2} count={timer && timer.S.remaining} now={timer && timer.S.now} name={t("seconds")} />
                </View>
                <View style={[styles.footer, { backgroundColor: background.popupLogin }]}>
                    <Text style={{ color: color.text, fontSize: widthS * 20, paddingTop: heightS * 30, fontFamily: "Catamaran_400Regular", lineHeight: heightS * 10 }}>{t("timer_page_b")}</Text>
                    <View style={styles.border} />
                    <Text style={[styles.textBody, { color: color.text, }]}>{t("timer_page_c")}</Text>
                    <View style={styles.footer_child}>
                        <Pressable style={styles.btn} disabled={!countDownVideo} onPress={() => {
                            dispatch(showFile({ name: domainName, type: "video/mp4", link: countDownVideo }))
                        }}>
                            <Image source={{ uri: images.fileVideoIcon }} style={[styles.icon, { tintColor: !countDownVideo ? color.placeholder : "" }]} />
                            <Text style={[styles.textBody, { color: countDownVideo ? color.text : color.placeholder, }]}>{`${t("about_")} ${domainName}`}</Text>
                        </Pressable>
                        {/* <Pressable style={styles.btn} disabled={true}>
                            <Image source={{ uri: images.fileVideoIcon }} style={styles.icon} />
                            <Text style={[styles.textBody, { color: color.text, }]}>{t("simulation_tutorial")}</Text>
                        </Pressable> */}
                        <Pressable style={styles.btn} onPress={() => {
                            if (!gameInfo?.countdown_video.slice(gameInfo?.countdown_video.length - 5).indexOf(".")) return;
                            let { link, type } = createFileObject(false, gameInfo.countdown_video);
                            dispatch(showFile({ name: t("_video"), type: type, link: link }))
                        }}>
                            <Image source={{ uri: images.fileVideoIcon }} style={[styles.icon, { tintColor: gameInfo?.countdown_video.indexOf(".") === -1 ? color.placeholder : "" }]} />
                            <Text style={[styles.textBody, { color: gameInfo?.countdown_video.indexOf(".") >= 0 ? color.text : color.placeholder, }]}>{t("intro_video")}</Text>
                        </Pressable>
                    </View>
                </View>
            </View >}
        </>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        circles: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: heightS * 19.5,
            marginBottom: heightS * 21.5,
            width: "100%",
            justifyContent: "space-between"
        },
        footer: {
            borderRadius: widthS * 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingHorizontal: widthS * 20,
            paddingBottom: heightS * 20
        },
        border: {
            width: widthS * 22.5,
            borderTopColor: "#fbf03a",
            borderTopWidth: widthS * 1.5,
            marginVertical: heightS * 13
        },
        footer_child: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            minWidth: widthS * 350,
            justifyContent: "center",
            marginTop: heightS * 17.5
        },
        btn: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: widthS * 12.5
        },
        text: {
            fontSize: widthS * 13,
            fontFamily: "Catamaran_400Regular"
        },
        textBody: {
            fontSize: widthS * 12,
            textAlign: "center",
            lineHeight: heightS * 14,
            fontFamily: "Catamaran_400Regular"
        },
        icon: {
            width: widthS * 15,
            height: widthS * 15,
            resizeMode: "contain",
            marginRight: widthS * 5.5,
        }
    });
}