import { StyleSheet, Text, View, Image } from 'react-native'
import React from 'react'
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { useSelector } from 'react-redux';
import { t } from 'i18n-js';
import ShortenWord from '../../../../modules/ShortenWord';

export default function ActionTooltip() {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const images = useSelector((state) => state.style.images);
    const colors = useSelector((state) => state.style.color);
    const backgrounds = useSelector((state) => state.style.background);
    const { platform, type, is_read, title, y, x } = useSelector(state => state.swimlane.tooltip);

    const printIcon = (type) => {
        switch (type) {
            case "mail":
                return <Image source={{ uri: images.mailBlack }} style={styles.icon} />
            case "news":
                return <Image source={{ uri: images.newsBlack }} style={styles.icon} />
            case "sm":
                return <Image source={{ uri: images.socialBlack }} style={styles.icon} />
        }
    }

    const printHeader = (platform, type, is_read) => {
        switch (platform) {
            case "mail":
                if (type === "2") {
                    return is_read ? t("incoming_mail_read") : t("incoming_mail_unread");
                } else {
                    return is_read ? t("outgoing_mail") : t("outgoing_mail");
                }
            case "news":
                if (type === "2") {
                    return is_read ? t("incoming_news_read") : t("incoming_news_unread");
                } else {
                    return is_read ? t("outgoing_news") : t("outgoing_news");
                }
            case "sm":
                if (type === "2") {
                    return is_read ? t("incoming_sm_read") : t("incoming_sm_unread");
                } else {
                    return is_read ? t("outgoing_sm") : t("outgoing_sm");
                }
        }
    }
    return (
        <View style={[styles.container, { top: y - heightS * 50, left: x - widthS * 228 }]} nativeID="action-tooltip">
            <View style={styles.main}>
                {printIcon(platform)}
                <View style={styles.header}>
                    <Text style={styles.headerText}>{printHeader(platform, type, is_read)}</Text>
                    <Text style={styles.title}>{ShortenWord(title, 28)}</Text>
                </View>
            </View>
            <View style={styles.traingle} />
        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 100,
        },
        main: {
            display: "flex",
            flexDirection: "row",
            paddingTop: heightS * 10,
            paddingBottom: heightS * 15,
            paddingHorizontal: heightS * 8,
            justifyContent: "flex-start",
            width: widthS * 130,
            // height: heightS * 50,
            backgroundColor: "white",
            borderRadius: widthS * 12,
        },
        header: {
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            marginLeft: widthS * 3
        },
        icon: {
            width: widthS * 9,
            height: widthS * 9,
            resizeMode: "contain",
        },
        headerText: {
            fontFamily: "Catamaran_600SemiBold",
            fontSize: widthS * 9.5,
            lineHeight: widthS * 9.5
        },
        title: {
            fontFamily: "Catamaran_500Medium",
            fontSize: widthS * 8,
            lineHeight: widthS * 10
        },
        traingle: {
            width: 0,
            height: 0,
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderLeftWidth: widthS * 10,
            borderRightWidth: widthS * 10,
            borderTopWidth: widthS * 10,
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderTopColor: "white",
        }
    });
};