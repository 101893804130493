export const createFileObject = (file, link) => {
  if (file) {
    let span = document.createElement('span');
    span.innerHTML = file;
    let type = span.childNodes[0].childNodes[0].innerText.lastIndexOf(".");
    type = span.childNodes[0].childNodes[0].innerText.slice(type + 1);
    return {
      name: span.childNodes[0].childNodes[0].innerText,
      link: span.childNodes[0].childNodes[0].href,
      type: `/${type}`
    }
  } else if (link) {
    let type = link.slice(link.lastIndexOf(".") + 1);
    let name = link.slice(link.lastIndexOf("/") + 1, link.lastIndexOf("?"));
    return {
      name: name,
      link: link,
      type: `/${type}`
    }
  }
}

export const createFilesArr = (filesObj) => {
  let files = [];
  if (filesObj) {
    Object.keys(filesObj).forEach((key) => {
      let newFile = {
        id: key,
        name: filesObj[key][0],
        link: filesObj[key][1],
        type: filesObj[key][0].slice(filesObj[key][0].lastIndexOf("."))
      }
      files.push(newFile);
    });
  }
  return files;
}