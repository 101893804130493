import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Dimensions } from 'react-native';

export const ResponsiveScreenContext = createContext();

export default function ResponsiveScreenProvider(props) {
    const { width, height } = Dimensions.get("window");
    const [sizeResWidth, setSizeWidth] = useState();
    const [sizeResHeight, setSizeHeight] = useState();

    const setSizeResponsiveWidth = (width) => {
        if (width <= 1280) {
            return setSizeWidth(width / 720 * 0.77);
        }
        if (width <= 1366) {
            return setSizeWidth(width / 760 * 0.80);
        }
        if (width <= 1440) {
            return setSizeWidth(width / 800 * 0.83);
        }
        if (width <= 1536) {
            return setSizeWidth(width / 830 * 0.86);
        }
        if (width <= 1600) {
            return setSizeWidth(width / 850 * 0.89);
        }
        if (width <= 1680) {
            return setSizeWidth(width / 850* 0.92);
        }
        if (width <= 1792) {
            return setSizeWidth(width / 850 * 0.95);
        }
        if (width <= 1920) {
            return setSizeWidth(width / 900 * 0.98);
        }
        if (width <= 2560 || width >= 2560) {
            return setSizeWidth(width / 950 * 1.01);
        }
    }
    const setSizeResponsiveHeight = (height) => {
        if (height <= 720) {
            return setSizeHeight(height / 470 * 0.77);
        }
        if (height <= 768) {
            return setSizeHeight(height / 490 * 0.80);
        }
        if (height <= 800) {
            return setSizeHeight(height / 500 * 0.83);
        }
        if (height <= 864) {
            return setSizeHeight(height / 520 * 0.86);
        }
        if (height <= 900) {
            return setSizeHeight(height / 540 * 0.89);
        }
        if (height <= 1050) {
            return setSizeHeight(height / 550 * 0.92);
        }
        if (height <= 1080) {
            return setSizeHeight(height / 570 * 0.95);
        }
         if (height <= 1120) {
            return setSizeHeight(height / 590 * 0.98);
        }
        if (height <= 1440 || height >= 1440) {
            return setSizeHeight(height / 590 * 1.01);
        }
    }

    useEffect(() => {
        const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
        //   if(screen.width / 2 > window.width){
            // setSizeResponsiveWidth(screen.width / 2);
            // setSizeResponsiveHeight(screen.height / 2);
        //   }else{
            // setSizeResponsiveWidth(window.width);
            // setSizeResponsiveHeight(window.height);
            setSizeHeight(window.height / 1000 * 2);
            setSizeWidth(window.width / 1000);
        //   }
        });
        return () => subscription?.remove();
    }, []);

    useMemo(() => {
    }, [sizeResHeight, sizeResWidth])


    useEffect(() => {
        // setSizeResponsiveHeight(height);
        // setSizeResponsiveWidth(width);
        setSizeHeight(height / 1000 * 2);
        setSizeWidth(width / 1000);
    }, []);
    return (
        <ResponsiveScreenContext.Provider value={{ sizeResHeight, sizeResWidth }}>
            {props.children}
        </ResponsiveScreenContext.Provider>
    );
};