import { Dimensions, StyleSheet, Text, View } from 'react-native'
import React, { useMemo } from 'react';
import UseResponsiveScreen from './componnets/modules/UseResponsiveScreen';
import { useSelector } from 'react-redux';

export default function InputRadio({ checkd, background, border, borderWidth, circleWidth, isDone, backgroundBorder, err, circle, marginLeft }) {

    const { widthS, heightS } = UseResponsiveScreen();
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(heightS, widthS, direction);

    return (
        <View style={[styles.border_circle(border, borderWidth, backgroundBorder, isDone, marginLeft), err && styles.err,
        !circle && styles.borderCircleRsdius
        ]}>
            {checkd && (
                <View style={[styles.circle(background, circleWidth), !circle && styles.circleRadius]} />
            )}
        </View>
    )
}

const stylesR = (heightS, widthS, direction) => {
    return StyleSheet.create({
        border_circle: (border, borderWidth, backgroundBorder, isDone, marginLeft) => ({
            marginHorizontal: !marginLeft ? 0 : "0.5vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderColor: border,
            borderWidth: border ? 1 : 0,
            marginLeft: direction === "rtl" ? "0.5vw" : 0,
            marginRight: direction !== "rtl" ? "0.5vw" : 0,
            width: borderWidth,
            height: borderWidth,
            backgroundColor: backgroundBorder ? (isDone ? `${backgroundBorder}29` : backgroundBorder) : "white"
        }),
        borderCircleRsdius: {
            borderRadius: widthS * 25
        },
        circle: (background, circleWidth) => ({
            alignSelf: "center",
            backgroundColor: background,
            height: circleWidth,
            width: circleWidth
        }),
        circleRadius: { borderRadius: widthS * 15, },
        err: { borderWidth: "0.1vw", borderColor: "red" }
    });
}


























