import React, { useEffect, useMemo, useState } from "react";
import {
	StyleSheet,
	Text,
	View,
	TextInput,
	Pressable,
} from "react-native";
import { URL_PATH, REGISTER, GET_MAIL_BY_TOKEN } from "@env";
import { useSelector } from "react-redux";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import { t } from "i18n-js";
import { _F_PHPSESSID_, ignore_characters, max_password_length, min_password_length } from "../../config.inc";

export default function Regiser() {

	const { width, height } = UseResponsiveSize();
	const { heightS, widthS } = UseResponsiveScreen();
	const styles = stylesR(width, height, heightS, widthS);

	const background = useSelector(state => state.style.backgroundClient);
	const color = useSelector(state => state.style.colorClient);
	const DOMAIN = useSelector((state) => state.style.domain);

	const productNameState = useSelector((state) => state.style?.productName);

	const productName = useMemo(() => productNameState, [productNameState]);

	const [domain, setDomain] = useState("");
	const [dataUser, setDataUser] = useState({
		email: null
	});
	const [error, setError] = useState("");

	const checkData = (dataUser) => {
		let errText = error || "";
		if (!dataUser?.email) {
			setError(true);
			return false;
		}
		if (!dataUser?.password || dataUser?.password.length < min_password_length || dataUser?.password.match(ignore_characters) !== null) {
			if (errText?.indexOf("password") == -1) {
				errText += "password";
			}
		} else {
			errText = errText.replace("password", "");
		}
		if (!dataUser?.first_name || dataUser?.first_name.length < 2) {
			if (errText?.indexOf("first_name") == -1) {
				errText += "first_name";
			}
		} else {
			errText = errText.replace("first_name", "");
		}
		if (!dataUser?.last_name || dataUser?.last_name.length < 2) {
			if (errText?.indexOf("last_name") == -1) {
				errText += "last_name";
			}
		} else {
			errText = errText.replace("last_name", "");
		}
		setError(errText);
		if (errText.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	const createAccount = () => {
		if (Object.keys(dataUser).length == 4) {
			try {
				const url = `${URL_PATH}${REGISTER}`

				const body = new FormData();
				body.append("token", sessionStorage.getItem("token"));
				body.append("id", sessionStorage.getItem("token_id"));
				body.append("email", dataUser.email);
				body.append("new_password", dataUser.password);
				body.append("first_name", dataUser.first_name);
				body.append("last_name", dataUser.last_name);

				fetch(url, { method: "POST", body: body })
					.then((res) => res.text())
					.then((res) => {
						res = JSON.parse(res);
						if (res.success) {
							window.location = window.location.origin;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			} catch (e) {
				setError("try again, please...");
			}
		}
	}

	useEffect(() => {
		const url = `${URL_PATH}${GET_MAIL_BY_TOKEN}${window.location.search}`;
		try {
			fetch(url, { method: "GET" })
				.then((res) => res.text())
				.then((res) => {
					res = JSON.parse(res);
					// cookie.save("token", window.location.search);
					// cookie.save("token_id", res.id);
					sessionStorage.setItem("token", window.location.search.slice(window.location.search.indexOf("=") + 1));
					sessionStorage.setItem("token_id", res.id);
					setDataUser({ email: res.email });
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (e) {
			console.log(e);
		}
	}, []);

	useEffect(() => {
		if (dataUser.password && dataUser.password.length >= min_password_length) {
			setError(error?.length > 0 && error?.replace("password", ""));
		}
	}, [dataUser.password]);

	useEffect(() => {
		if (dataUser.first_name && dataUser.first_name.length >= 2) {
			setError(error?.length > 0 && error?.replace("first_name", ""));
		}
	}, [dataUser.first_name]);

	useEffect(() => {
		if (dataUser.last_name && dataUser.last_name.length >= 2) {
			setError(error?.length > 0 && error?.replace("last_name", ""));
		}
	}, [dataUser.last_name]);

	useEffect(() => {
		setDomain(DOMAIN);
	}, [DOMAIN]);

	return (
		<View style={[styles.body_main, { backgroundColor: background.popupLogin }]}>
			<View style={styles.body_main_top}>
				<Text style={[styles.body_main_textA, { color: color.text }]}>{t("welcome")} {productName}</Text>
				<Text style={[styles.body_main_textB, { color: color.text }]}>{t("welcome_b")}</Text>
			</View>
			{<Text style={styles.err}>{error?.length > 0 && error?.indexOf("password") >= 0 && t("err_update_password")}</Text>}
			<View style={styles.body_main_bottom(error?.length > 0 && error?.indexOf("password") >= 0 && dataUser?.password?.length > 0 && dataUser?.password?.length < min_password_length)}>
				<TextInput
					style={[styles.inputs, { backgroundColor: background.loginInput, color: color.text }]}
					editable={false}
					textContentType="emailAddress"
					placeholder={dataUser?.email}
				/>
				<TextInput
					placeholder={`${t("password")}`}
					style={[
						styles.inputs, { backgroundColor: background.loginInput, color: color.text }, error?.length > 0 && error?.indexOf("password") >= 0 ? styles.errorStyle : ""
					]}
					secureTextEntry={true}
					textContentType="password"
					onChangeText={(e) => setDataUser({ ...dataUser, password: e })
					}
					maxLength={max_password_length}
				/>
				{/* <Picker
					style={styles.select}
					onValueChange={(e) => setDataUser({ ...dataUser, title: e })}
				>
					<Picker.Item label={`${t("name")} ${t("title")}`} value="" />
					<Picker.Item label={t("miss")} value="Miss" />
					<Picker.Item label={t("mrs")} value="Mrs" />
					<Picker.Item label={t("mr")} value="Mr" />
					<Picker.Item label={t("ms")} value="Ms" />
				</Picker> */}
				<TextInput
					style={[
						styles.inputs, { backgroundColor: background.loginInput, color: color.text }, error?.length > 0 && error?.indexOf("first_name") >= 0 ? styles.errorStyle : ""
					]}
					textContentType="namePrefix"
					onChangeText={(e) => setDataUser({ ...dataUser, first_name: e })}
					placeholder={t("first")}
				/>
				<TextInput
					textContentType="nameSuffix"
					style={[
						styles.inputs, { backgroundColor: background.loginInput, color: color.text }, error?.length > 0 && error?.indexOf("last_name") >= 0 ? styles.errorStyle : ""
					]}
					onChangeText={(e) => setDataUser({ ...dataUser, last_name: e })}
					placeholder={t("last")}
				/>
				<Pressable
					onPress={() => {
						if (checkData(dataUser)) {
							createAccount();
						}
					}}
					style={[
						{
							backgroundColor:
								dataUser && (dataUser.password && dataUser.password?.length >= 8) && dataUser.email
									? background.buttonLoginHover
									: "",
							borderColor: color.text2,
							borderWidth:
								(dataUser && (!dataUser.password || dataUser.password?.length < 8)) ||
									(dataUser && !dataUser.email)
									? widthS * 1.5
									: 0,
						},
						styles.btn,
					]}
				>
					<Text
						style={[
							{ color: color.text2 },
							styles.btn_text,
						]}
					>
						{t("enter_game")}
					</Text>
				</Pressable>
			</View>
		</View>
	);
}
const stylesR = (width, height, heightS, widthS) => {
	return StyleSheet.create({
		body_main: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			// ...style.popup7
			width: widthS * 420,
			minHeight: heightS * 300,
			borderRadius: widthS * 25,
		},
		body_main_top: {
			marginTop: heightS * 24,
		},
		body_main_textA: {
			fontSize: widthS * 18,
			fontWeight: "normal",
			textAlign: "center",
			fontFamily: "Catamaran_400Regular",
		},
		body_main_textB: {
			fontWeight: "300",
			lineHeight: heightS * 12,
			color: "white",
			fontSize: widthS * 10,
			marginTop: heightS * 6,
			textAlign: "center",
			fontFamily: "Catamaran_400Regular",
		},
		// body_main_top: {
		// 	marginTop: size * 30,
		// },
		// body_main_textA: {
		// 	color: "white",
		// 	fontSize: size * 28,
		// 	textAlign: "center",
		// },
		// body_main_textB: {
		// 	marginTop: 15,
		// 	color: "white",
		// 	fontSize: 18,
		// 	textAlign: "center",
		// },
		body_main_bottom: (flag) => ({
			// marginTop: flag ? heightS * 5 : heightS * 20,
			marginBottom: "4vw",
			display: "flex",
			alignItems: "center",
			flex: 1
		}),
		inputs: {
			width: widthS * 220,
			height: heightS * 28,
			borderRadius: widthS * 7.3,
			paddingLeft: widthS * 10,
			marginBottom: heightS * 4.5,
			fontSize: widthS * 9.5,
			fontWeight: "300",
			outlineStyle: "none",
			fontFamily: "Catamaran_400Regular",
		},
		errorStyle: {
			borderWidth: 1,
			borderColor: "red",
		},
		// select: {
		// 	width: size * 250,
		// 	height: size * 30,
		// 	backgroundColor: "white",
		// 	margin: size * 2,
		// 	paddingLeft: size * 10,
		// 	fontSize: size * 10,
		// 	fontWeight: "500",
		// 	outlineStyle: "none"
		// },
		btn: {
			borderStyle: "solid",
			padding: widthS * 4,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginVertical: heightS * 10.2,
			width: widthS * 220,
			height: heightS * 28,
			borderRadius: widthS * 7.3,
		},
		btn_text: {
			fontSize: widthS * 11,
			fontWeight: "normal",
			fontFamily: "Catamaran_400Regular",
		},
		err: {
			fontSize: widthS * 9,
			minHeight: widthS * 9,
			lineHeight: widthS * 14,
			marginHorizontal: "3vw",
			marginVertical: "0.5vw",
			textAlign: "center",
			color: "#d2c935",
			fontFamily: "Catamaran_400Regular",
		},
	});
}
