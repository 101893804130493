export const createCircleTimer = (time) => {
    var date1 = new Date(time);
    var date1time = date1.getTime();
    var date2 = new Date();
    var date2time = date2.getTime();
    let stays = date1time - date2time;
    if (stays > 0) {
        let stayDays = Math.floor(stays / 1000 / 60 / 60 / 24);
        let days = stayDays;
        let stayHours = Math.floor(stays / 1000 / 60 / 60);
        let hours = stayHours % 24;
        let stayMinuts = Math.floor(stays / 1000 / 60);
        let minuts = stayMinuts % 60;
        let staySeconds = Math.floor(stays / 1000);
        let seconds = staySeconds % 60;
        return {
            D: { now: days < 10 ? `0${days}` : days, remaining: 30 },
            H: { now: hours < 10 ? `0${hours}` : hours, remaining: 24 },
            M: { now: minuts < 10 ? `0${minuts}` : minuts, remaining: 60 },
            S: { now: seconds < 10 ? `0${seconds}` : seconds, remaining: 60 }
        }
    }else{
        return {
            D: { now: "0", remaining: 30 },
            H: { now: "0" , remaining: 24 },
            M: { now: "0", remaining: 60 },
            S: { now: "0", remaining: 60 }
        }
    }
}