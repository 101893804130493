import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
    StyleSheet,
    Modal,
    View,
    Text,
    Pressable,
    TextInput,
    Image,
} from "react-native";
import { AntDesign, FontAwesome, Ionicons } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";
import { t } from "i18n-js";
import { useSelector, useDispatch } from "react-redux";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";



export default function FileLimitPopup({ refObj, text }) {
    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const images = useSelector((state) => state.style.images);


    const r = parseInt(refObj?.current?.getBoundingClientRect().width) / 2;
    const u = parseInt(refObj?.current?.getBoundingClientRect().height) / 2;
    
    return (
        <View nativeID="files-des" style={[styles.des_container, {
            right: r,
            top: u,
        }]}>
            <View
                style={[
                    styles.traingular,
                    { borderBottomColor: background.filesDes },
                ]}
            />
            <View
                style={[
                    styles.des_view,
                    { backgroundColor: background.filesDes },
                ]}
            >
                <Text
                    selectable={false}
                    style={[styles.des_text, { color: color.filesText }]}
                >
                    {t("file_size_limit")}
                </Text>
            </View>
        </View>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        des_container: {
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            width: "3vw",
            height: "4vh",
            maxHeight: "7vh",
            zIndex: "10001",
        },
        traingular: {
            width: 0,
            height: 0,
            borderLeftWidth: widthS * 8,
            borderRightWidth: widthS * 8,
            borderBottomWidth: widthS * 8,
            borderStyle: "solid",
            backgroundColor: "transparent",
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            zIndex: "1001",
            marginLeft: widthS * 22,
        },
        des_view: {
            marginTop: height - 2,
            width: "10vw",
            padding: "1vw",
            borderRadius: widthS * 3,
            minHeight: "4vh",
            maxHeight: "7vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1001",
        },
        des_text: {
            textAlign: "left",
            fontSize: widthS * 7,
            width: "90%",
            fontFamily: "Catamaran_400Regular",
            zIndex: "1001",
        },
    })
}