

import { View, Text, Modal, StyleSheet, Pressable, ScrollView } from 'react-native';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { listenerAdd } from '../../AddListener';
import { useSelector } from "react-redux";
import { color } from '../../style/dark';
import { t } from "i18n-js";
import UseResponsiveSize from '../modules/UseResponsiveSize';
import UseResponsiveScreen from '../modules/UseResponsiveScreen';

export default function Help({flag, setFlag}) {

    const {width, height} = UseResponsiveSize();
    const {widthS, heightS} = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const helpRef = useRef(null);

    const background = useSelector(state => state.style.background);
    const color = useSelector(state => state.style.color);
    
    useEffect(() => {
        window.addEventListener("mousedown", listenerAdd(helpRef, flag, setFlag));
        return () => {
            window.removeEventListener("mousedown", listenerAdd(helpRef, flag, setFlag));
        }
    }, [flag]);
    return (
        <Modal transparent={true} animationType="none" visible={flag}>
			<View style={styles.container}>
				<View style={[styles.main, { backgroundColor: background.popup1 }]} ref={helpRef}>
                <View style={[styles.header, { borderBottomColor: color.border }]}>
							<View style={styles.headerLeft}>
								<Text style={styles.textLogo}>{t("help")}</Text>
							</View>
							<Pressable style={styles.x_btn} onPress={() => setFlag()}><Text style={[styles.x_text, { color: color.x }]}>X</Text></Pressable>
						</View>
					 <ScrollView style={styles.body_c} contentContainerStyle={styles.body} nativeID = "mail" >
                        <Text style={styles.h1}>FAQ</Text>
                        <Text style={styles.h2}>Introduction</Text>

                        <Text style={styles.num}>1. <Text style={styles.h3}>What is BeST?</Text></Text>
                        <Text style={styles.p_m_t}>BeST is a web-based solution that enhances organizations' capacities to handle crisis.
                            The Procedure Editor - integrates an organizations' own existing procedures and/or the industry's best practices into the software and evaluates them in order to ensure the processes are as effective and as efficient at handling the crisis scenario(s) as possible. The Procedure Editor not only optimizes an organizations' procedures but it predicts/forecasts the expectations based on how an organization will manage said crisis.</Text>
                        <Text style={styles.p_m_b}>The Crisis Simulator- allows organizations to practice managing scenarios according to their own procedures and offers a graphical overview of the management process as well as the identification of discrepancies between the expected and actual behaviors. The Simulator allows participants to operate in a virtual surrounding, which simulates future scenarios. The simulation reflects prevailing operational procedures designed to confront critical dilemmas.</Text>

                        <Text style={styles.num}>2. <Text style={styles.h3}>What is a crisis management simulation?</Text></Text>
                        <Text style={styles.p_m}>Crisis management simulations are dynamic simulations of real situations providing information and experiences to shape strategic decisions.</Text>

                        <Text style={styles.num}>3.<Text style={styles.h3}>How does the simulation operate?</Text></Text>
                        <Text style={styles.p_m}>Once the simulation has started, the BeST system will start sending messages to all participants. These messages will create a virtual reality in which each participant has to resolve the different challenges he/she faces. Participants will need to interact through our messaging system in order to collect and collate information so that they are able to take informed decisions and actions.
                            As a Game Creator, you will also likely be a Game Controller, meaning you will be charged with facilitating the simulation. Facilitation is done through the Game Dashboard page, which consolidates all of the software's most relevant features for facilitating simulations.
                            You get to decide when to send out which email messages to whom. You also get to simulate various represented players (stakeholders) and to determine the level and substance of their engagement with the simulation's participants.</Text>

                        <Text style={styles.h2}>Building Simulations</Text>

                        <Text style={styles.num}>4. <Text style={styles.h3}>How is a simulation built?</Text></Text>
                        <Text style={styles.p_m_t}>The simulation is built by you- the Game Creator. The process of designing a simulation on the BeST platform is a simple three-step process:</Text>
                        <View style={styles.span}>
                            <Text style={styles.num}>1.<Text style={styles.p_m_t}>The Game Parameters Page: Enter your simulation's parameters into the Game Parameters page- enter your real players (the participants), the represented players (the simulated stakeholders), the simulation goal, the objectives, the language and the time zone from which the simulation will be managed</Text></Text>
                        </View>
                        <View style={styles.span}>
                            <Text style={styles.num}>2.<Text style={styles.p}>The Flow Page: Upload your procedures, expected processes or your industry's best practices into the Flow Page in order to view linear and non-linear processes graphically, with unique insights;</Text></Text>
                        </View>
                        <View style={styles.span}>
                            <Text style={styles.num}>3.<Text style={styles.p}>The Injects Page: Specify the injects (the email messages automatically created the Procedure Editor) in the injects page.</Text></Text>
                        </View>
                        <Text style={styles.p_m_b}>*That's it! It's that simple!*</Text>

                        <Text style={styles.num}>5.<Text style={styles.h3}>How do I build out the "Flow" chart?</Text></Text>
                        <Text style={styles.p_m_t}>Build out the Flow chart to set the expected performance of your simulation. The flowchart is what allows us to compare between expected/forecasted performance and actual performance (as per the simulation).</Text>
                        <Text style={styles.p}>Uploading specific processes into the Flow (such as SOPs, emergency protocol or best practices) will give you the chance to view these processes graphically and to revise them as desired.</Text>
                        <Text style={styles.p_m_b}>Regardless of the processes you are uploading into the Flow- the method of building it is the same:</Text>
                        <View style={styles.span}>
                            <Text style={styles.num}>1.<Text style={styles.h3}>Enter your simulation's relevant events.</Text></Text>
                            <Text style={styles.p_m}>An event is an occurrence that the participants must respond to. As a Game Creator/Controller, it is up to you to define your simulation's events. While the software will suggest a number of events for you based on the type of your simulation, you can always outline events of your own.</Text>
                        </View>
                        <View style={styles.span}>
                            <Text style={styles.num}>2.<Text style={styles.h3}>Enter as many phases as needed to reflect the expected performance of the simulation.</Text></Text>
                            <Text style={styles.p_m}>A Phase is a component of an organization's protocol or expected processes. In short, phases can be seen as a time-steps in the exercise. Since each game expresses time differently, we will use the term 'phase'.
                                There should be only one action taking place in each phase (e.g. "Major hurricane warning" or "Establish crisis team").
                                Keep in mind that when creating phases, you have the ability to integrate email messages, analyses, decisions and 'Calls for Action" (specified requests that will be sent to the participants during a simulation) into your flowchart.</Text>
                        </View>

                        <Text style={styles.num}>6.<Text style={styles.h3}>What are Calls for Action and how do I create them?</Text></Text>
                        <Text style={styles.p_m_t}>The decision-making process is divided into three components: the problem you are facing (the Challenge); the options you have to solve the problem (the Potential Solutions); the solution you choose to resolve the problem (the Decision)- each one of these components- or actions- can be found as 'phase types' in the Procedure Editor.</Text>
                        <Text style={styles.p}>As a Game Controller, you may find value in understanding the decision-making process of your simulation's participants. You can easily prompt participants to identify challenges, potential solutions and decisions by sending a phase type as a "Call for Action Challenge", "a Call for Action Potential Solution", or a "Call for Action Decision" from a represented player to a real player in the Flow.</Text>
                        <Text style={styles.p}>You also have the option to send out a Call for Action Questionnaire. This Call for Action allows you to ask any question/s to any of your simulation's participants. To build the Call for Action Questionnaire, simply enter a phase type as "Call for Action Questionnaire" and send it from a represented player to a real player in the Flow.</Text>
                        <Text style={styles.p_m_b}>The system will automatically turn this phase into an email message in the "Injects" page. To enter your question/s into the questionnaire template, simply click on the "edit" icon (the pencil icon) next to your Questionnaire in the Injects Page.</Text>

                        <Text style={styles.num}>7.<Text style={styles.h3}>How do I specify Messages?</Text></Text>
                        <Text style={styles.p_m_t}>Each message or decision appearing in the Flow representing an action from a Represented Player will be automatically translated into the Injects Page. The software translates only actions from Represented Players because these injects go out as email messages when the simulation begins. Though we can pre-determine Represented Players' responses to the scenarios in the simulation, we cannot pre-determine the Real Players' responses. So- we prepare all reactions from Represented Players so that the Game Controller can manage the exercise effectively. Thus, the Flow automatically turns all of the Represented Players' messages and decisions from the Flow itself into a framework for scenarios.</Text>
                        <Text style={styles.p}>By the time you are done building your flowchart, you will see a number of injects in your Injects Page. As a Game Creator/Controller, it is up to you to specify each inject to be sure it transmits the relevant information during the simulation. Do this by identifying the message you want to convey and adding it as the body each inject (just like you would for the body of an email message!)</Text>
                        <Text style={styles.p}>You can also upload any files that you'd like- documents, spreadsheets, voice notes, videos, images, etc.</Text>
                        <Text style={styles.p_m_b}>*You're now ready to play your simulation!!*</Text>
                        <Text style={styles.h2}>Managing Simulations</Text>

                        <Text style={styles.num}>8. <Text style={styles.h3}>How do I manage a simulation?</Text></Text>
                        <Text style={styles.p_m}>Simulations are managed by sending out injects, observing participants' engagement with the injects and responding to the messages sent to the represented players .
                            All of these actions can be done in the "Game Dashboard" page.</Text>

                        <Text style={styles.num}>9. Sending out injects <Text style={styles.h3}></Text></Text>
                        <Text style={styles.p_m_t}>To send out an inject- simply click on the "Send" icon (the paper arrow icon) in the 'injects' menu in the 'Game Dashboard' page.
                            Once you send the inject, you'll see that the inject will shift upward in the injects menu and will appear in the inbox menu to the left. EVERY inject that is sent out in a simulation will appear in your inbox. As a Game Controller, you can see and track EVERYTHING that goes on in a simulation.</Text>
                        <Text style={styles.p}>To preview the content of an inject before sending it- scroll your mouse over the 'Preview" button (the "eye" icon.)</Text>
                        <Text style={styles.p}>To delete an inject- click the "Delete" button (the trash can icon), but be aware that this will delete the inject from the entire simulation!</Text>
                        <Text style={styles.p_m_b}>If you accidentally send out an inject and don't want it in a participant's inbox- simply go to the 'injects page' and click on the "Recall" button (the curved arrow icon); it will remove the inject from the recipients' inboxes and will return it to your injects menu.</Text>

                        <Text style={styles.num}>10. <Text style={styles.h3}>Responding to Messages in your inbox</Text></Text>
                        <Text style={styles.p_m_t}>As a Game Controller, you may find yourself simulating "represented players" in a simulation. Remember that represented players are notional individuals and groups who we could realistically expect to be involved in a certain scenario (stakeholders) but who we don't necessarily have an interest in exercising.</Text>
                        <Text style={styles.p}>Once a simulation's participants begin engaging with the messages you send them, you'll begin to receive messages in your inbox. Remember that as a Game Creator/Controller- you receive ALL messages that all of the participants send. Keep your eyes open for messages sent to represented players; it's your job to simulate those roles by responding to the messages sent to those players.</Text>
                        <Text style={styles.p}>To respond to a message in your inbox, click on the message of interest and click the "reply" button. You also have the option to "reply to all" and to "forward"- just like any other email platform. You can also attach any file you'd like by clicking on the "Add file" button and choosing a file from your computer.</Text>
                        <Text style={styles.p_m_b}>Be sure that your responding as the represented player who you actually intend to simulate by double-checking to "from" field.</Text>

                        <Text style={styles.num}>11. <Text style={styles.h3}>Chatting as represented players</Text></Text>
                        <Text style={styles.p_m_t}>In addition to sending messages as represented players, you also have the ability to chat as represented players. To chat with a participant in your simulation, click on the "chat" banner on the bottom right-hand-side of your screen. Choose which player you want to simulate in a chat by clicking on the name of a player in the "Chat Manager" box. Then, choose the player you want to chat with by clicking on his-or-her name in the 'Chat' box below.</Text>
                        <Text style={styles.p_m_b}>A new chat box will appear with the name of your chat's recipient at the top. Simply type your chat message in the "enter message" field and click "enter" on your keyboard to send.
                            The participants with green dots next to their names are online and available to chat; those will red dots next to their names are offline. You can still send them chats; the messages will be waiting for them when they logon.</Text>

                        <Text style={styles.num}>12. <Text style={styles.h3}>Tracking players' interactions</Text></Text>
                        <Text style={styles.p_m_t}>Now let's discuss tracking players' interactions. As your simulation's scenarios start to progress, you'll want to keep an eye on the interactions between participants. You can do this quickly and easily by reviewing the "Interaction Map" at the bottom of the Game Dashboard.
                            Who is corresponding closely with whom? Who is less communicative? Is anyone left out of the communication scheme? Check out the Interaction Map to answer these questions.
                            In this map, the real players- the participants of the simulation- will be depicted by white circles; the represented players will be grey. Click on any player to highlight his/her correspondence with others.</Text>
                        <Text style={styles.p_m_b}>If you'd like to know exactly how many messages a participant has sent or received- go to the "Analytics" page; here you'll find all of BeST's analytical tools that will support the evaluation of your simulation.</Text>

                        <Text style={styles.num}>13. <Text style={styles.h3}>Can I flag important messages?</Text></Text>
                        <Text style={styles.p_m}>Yes! Simply click on the flag icon next to the message to mark it as important.</Text>
                    </ScrollView>
				</View>
			</View>
		</Modal>
    );
}
const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            display: "flex",
            alignItems: "center",
            paddingTop: heightS * 40,
            backgroundColor: "#00000057",
            height: "100%",
            fontFamily: "Roboto",
        },
        header: {
            paddingLeft: widthS * 17.2,
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingVertical: heightS * 4
        },
        main: {
            position : "absolute",
            left : "12%",
            width: "50%",
            height: "67%",
            borderRadius : 25,
            shadowColor: "black",
            shadowOffset: {
                height: 0,
                width: 0.1,
            },
            shadowOpacity: 1,
            shadowRadius: 6.65,
        },
        body_c: {
            padding: widthS * 15
        },
        h1: {
            fontSize: widthS * 20,
            fontFamily: "Catamaran_700Bold",
            textDecorationLine: "underline",
            color : color.infoHeaderNavText
        },
        h2: {
            fontSize: widthS * 15,
            fontFamily: "Catamaran_700Bold",
            textDecorationLine: "underline",
            marginLeft: widthS * 10,
            color : color.infoHeaderNavText
            
        },
        h3: {
            fontSize: widthS * 10,
            fontFamily: "Catamaran_700Bold",
            marginLeft: widthS * 3,
            color: "#f85922"
        },
        p_m: {
            fontSize: widthS * 10,
            fontFamily: "Catamaran_400Regular",
            marginLeft: widthS * 15,
            marginVertical: heightS * 5,
            color : color.infoHeaderNavText
            
        },
        p: {
            fontSize: widthS * 10,
            fontFamily: "Catamaran_400Regular",
            marginLeft: widthS * 15,
            color : color.infoHeaderNavText
            
        },
        p_m_t: {
            fontSize: widthS * 10,
            marginLeft: widthS * 15,
            marginTop: heightS * 5,
            fontFamily: "Catamaran_400Regular",
            color : color.infoHeaderNavText
        },
        p_m_b: {
            fontSize: widthS * 10,
            marginLeft: widthS * 15,
            marginBottom: heightS * 5,
            fontFamily: "Catamaran_400Regular",
            color : color.infoHeaderNavText
            
        },
        span: {
            marginLeft: widthS * 20
        },
        num: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            fontFamily: "Catamaran_400Regular",
            color : color.infoHeaderNavText
        },
        textLogo: {
            color: "white",
            fontFamily: "Catamaran_700Bold",
            fontSize: widthS * 15,
            paddingLeft: widthS * 10,
        },
        x_text: {
            fontFamily: "Catamaran_400Regular",
            fontSize: widthS * 10,
        },
    })
}