import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import i18n, { t } from "i18n-js";
import { useDispatch, useSelector } from "react-redux";

import Login from "./componnets/login/Index";
import Main from "./Main";
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import DocsProvider from "./ContextData/Docs";
import GameInfoProvider from "./ContextData/GameInfo";
import SystemProvider from "./ContextData/System";
import MailProvider from "./ContextData/Mail";
import NewsProvider from "./ContextData/News";
import SocialProvider from "./ContextData/Social";
import ChatProvider from "./ContextData/Chat";
import GameListProvider from "./ContextData/GamesList";
import ActionsProvider from "./ContextData/Actions";

import { addDomain, addLogo, addFavicon, addProductName, setColors, setInGame, setToken, setCostumColorsClient, addLogoClient, changeLangType, addCountDownVideo, addProductNameForPrivacy, changeLangDomain } from "./redux/actions/index";
import { _F_PHPSESSID_, F_PHPSESSID, GAME_ID } from "./config.inc";
import { DOMAIN, URL_PATH, GET_CLIENT } from "@env";

import SwimlaneChartProvider from "./ContextData/Swimlanechart";
import ResponsiveProvider from "./ContextData/Responsive";
import ResponsiveScreenProvider from "./ContextData/ResponsiveScreen";
import StatisticsFuncProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/StatisticsFunc";
import HeatMapFuncProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/HeatMapFunc";
import InteractionMapFuncProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/InteractionMapFunc";
import WordMapFuncProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/WordsMapFunc";
import AnalyticsProvider from "./ContextData/Analytics";
import AnalyticstimesProvider from "./componnets/pop-ups/simulationBuild/Analytics/analyticsFunction/Analyticstimes";
import { Linking, } from "react-native";
import { LightenDarkenColor } from "./componnets/modules/LightenDarkenColor";
import { UserContext } from "./ContextData/User";
import UpdateProvider from "./ContextData/Update";
import { getLang } from "./getLang";
import Satisfaction from "./componnets/login/Satisfaction";
import { WebSocketContext } from "./WebSocketProvider";

export default function MainContext() {

    const { sentMessageWebSocket,
        typeRefresh,
        setTypeRefresh,
        closeWebSocket,
        loggedInUsers,
        finishedRound,
        webSocketBlocked,
        isInRoom
    } = useContext(WebSocketContext);

    /// Inactivity
    const timerId = useRef(false);
    const { checkSession } = useContext(UserContext);
    const [timeForInactivityInSecond] = useState(900000);
    const dispatch = useDispatch();
    const lang = useSelector(state => state.setting.setting.domainLang);
    const token = useSelector(state => state.loginType.token);
    const loginType = useSelector(state => state.loginType.type);
    const startGame = useSelector(state => state.loginType.startGame);
    const gameInfoLang = useSelector(state => state.gameInfoArr.gameInfoArr?.game_lang);
    const domain = DOMAIN;
    const gameId = useMemo(() => window.sessionStorage.getItem(GAME_ID), [window.sessionStorage.getItem(GAME_ID)]); // for type data to refresh
    const productName = useSelector(state => state.style.productName);
    const favicon = useSelector(state => state.style.favicon);

    const resetInactivityTimeout = () => {
        clearTimeout(timerId.current)
        timerId.current = setTimeout(() => {
            checkSession().then((res) => {
                if (!res) {
                    sessionStorage.clear()
                    window.location.reload(false);
                } else {
                    resetInactivityTimeout();
                }
            })
        }, timeForInactivityInSecond)
    }

    ///use effect
    useEffect(() => {
        // Get the current URL of the app
        Linking.getInitialURL().then(domainUrl => {
            if (domain != domainUrl) {
                console.log('Current URL:', domainUrl);
                const url = `${URL_PATH}${GET_CLIENT}`;
                const body = new FormData();
                body.append("domain", domainUrl);
                try {
                    fetch(url, {
                        method: "POST",
                        body: body,
                    })
                        .then((res) => res.text())
                        .then((res) => {
                            try {
                                res = JSON.parse(res);
                                if (res?.language) {
                                    if (!gameId) {
                                        dispatch(changeLangType(getLang(res?.language)));
                                    }
                                    dispatch(changeLangDomain(getLang(res?.language)));
                                }
                                if (res?.name) {
                                    if (res?.domain === "terna.cyberjumanji.innovery.net") {
                                        dispatch(addDomain("Innovery"));
                                    } else {
                                        dispatch(addDomain(res.name));
                                    }
                                } else {
                                    dispatch(addDomain("cinten"));
                                }
                                if (res?.logo) {
                                    dispatch(addLogoClient(res?.logo));
                                }
                                if (res?.countdown_about_video) {
                                    dispatch(addCountDownVideo(res?.countdown_about_video));
                                }
                                dispatch(addFavicon(res?.favicon));
                                dispatch(addProductNameForPrivacy(res?.product_name));
                                if (res?.domain === "cyberjumanji.innovery.net" || res?.domain === "terna.cyberjumanji.innovery.net") {
                                    dispatch(addProductName(res?.name));
                                }
                                if (res?.colors) {
                                    let colors = res.colors.split(",");
                                    dispatch(setCostumColorsClient({
                                        color1: `#${colors[0]}`,
                                        colorShade1: LightenDarkenColor(`#${colors[0]}`, 0),
                                        colorCurrent1: `#${colors[0]}`,
                                        color2: `#${colors[1]}`,
                                        colorShade2: LightenDarkenColor(`#${colors[1]}`, 0),
                                        colorCurrent2: `#${colors[1]}`,
                                        color3: `#${colors[2]}`,
                                        colorShade3: LightenDarkenColor(`#${colors[2]}`, 0),
                                        colorCurrent3: `#${colors[2]}`,
                                    }));
                                } else {
                                    dispatch(setColors());
                                }
                            } catch (es) {
                                console.log(es)
                                return;
                            }
                        })
                } catch (e) {
                    console.log(e)
                }
            }


        });

    }, [DOMAIN, loginType, gameId])

    useEffect(() => {
        //set the new name
        if (productName) {
            document.title = productName;
        }
        const currentFavicon = document.querySelector("link[rel='icon']");
        if (currentFavicon) {
            currentFavicon.parentNode.removeChild(currentFavicon);
        }
        if (favicon) {
            const newFavicon = document.createElement('link');
            newFavicon.rel = 'icon';
            newFavicon.href = favicon;
            document.getElementsByTagName('head')[0].appendChild(newFavicon)
        }
    }, [productName, favicon])

    useEffect(() => {
        const currentLang = loginType === "in" ? getLang(gameInfoLang) : lang;
        if (currentLang) {
            i18n.translations = {
                [currentLang]: require(`../translations/${currentLang}.json`),
            };
            i18n.locale = currentLang;
        }
    }, [lang, loginType, gameInfoLang]);

    useEffect(() => {
        if (sessionStorage.getItem(F_PHPSESSID)) {
            resetInactivityTimeout()
        }
    }, [sessionStorage.getItem(F_PHPSESSID)]);

    useEffect(() => {
        if (
            window.sessionStorage.getItem(F_PHPSESSID) &&
            window.sessionStorage.getItem(GAME_ID) &&
            loginType !== "end"
        ) {
            dispatch(setToken(true));
            dispatch(setInGame());

        }
        if (loginType !== "in" && loginType !== "end") {
            dispatch(setToken(false));

        }
        return;
    }, [loginType]);

    const mainPage = (token, startGame) => {
        return token && startGame
            ?
            <SwimlaneChartProvider
                sentMessageWebSocket={sentMessageWebSocket}
                typeRefresh={typeRefresh}
                setTypeRefresh={setTypeRefresh}
                loginType={loginType}
                loggedInUsers={loggedInUsers}
            >
                <SystemProvider
                    sentMessageWebSocket={sentMessageWebSocket}
                    typeRefresh={typeRefresh}
                    loginType={loginType}
                >
                    <DocsProvider
                        sentMessageWebSocket={sentMessageWebSocket}
                        typeRefresh={typeRefresh}
                        loginType={loginType}
                    >
                        <SocialProvider
                            sentMessageWebSocket={sentMessageWebSocket}
                            typeRefresh={typeRefresh}
                            loginType={loginType}
                        >
                            <MailProvider
                                sentMessageWebSocket={sentMessageWebSocket}
                                typeRefresh={typeRefresh}
                                loginType={loginType}
                            >
                                <NewsProvider
                                    sentMessageWebSocket={sentMessageWebSocket}
                                    typeRefresh={typeRefresh}
                                    loginType={loginType}
                                >
                                    <ChatProvider
                                        sentMessageWebSocket={sentMessageWebSocket}
                                        typeRefresh={typeRefresh}
                                        loginType={loginType}
                                    >
                                        <ActionsProvider
                                            sentMessageWebSocket={sentMessageWebSocket}
                                            typeRefresh={typeRefresh}
                                            loginType={loginType}
                                        >
                                            <AnalyticstimesProvider
                                                typeRefresh={typeRefresh}
                                            >
                                                <StatisticsFuncProvider>
                                                    <HeatMapFuncProvider>
                                                        <InteractionMapFuncProvider>
                                                            <WordMapFuncProvider>
                                                                <Main closeWebSocket={closeWebSocket} />
                                                            </WordMapFuncProvider>
                                                        </InteractionMapFuncProvider>
                                                    </HeatMapFuncProvider>
                                                </StatisticsFuncProvider>
                                            </AnalyticstimesProvider>
                                        </ActionsProvider>
                                    </ChatProvider>
                                </NewsProvider>
                            </MailProvider>
                        </SocialProvider>
                    </DocsProvider>
                </SystemProvider>
            </SwimlaneChartProvider> : <Login />
    }

    // version log
    console.log("Version (3.1.1)");

    return (
        <ResponsiveProvider>
            <ResponsiveScreenProvider>
                <UpdateProvider
                    loginType={loginType}
                    typeRefresh={typeRefresh}
                    isInRoom={isInRoom}
                    setTypeRefresh={setTypeRefresh}
                    finishedRound={finishedRound}
                    webSocketBlocked={webSocketBlocked}
                >
                    <AnalyticsProvider typeRefresh={typeRefresh} isInRoom={isInRoom}>
                        <GameInfoProvider
                            sentMessageWebSocket={sentMessageWebSocket}
                            typeRefresh={typeRefresh}
                            loginType={loginType}
                            loggedInUsers={loggedInUsers}
                        >
                            <GameListProvider>
                                {/* <Router>
									<Routes>
										<Route path="/satisfaction" Component={Satisfaction} />
										<Route path="/" Component={(props) => <MainPage {...props} startGame={startGame} token={token} />} />
									</Routes>
								</Router> */}
                                {mainPage(startGame, token)}
                            </GameListProvider>
                        </GameInfoProvider>
                    </AnalyticsProvider>
                </UpdateProvider>
            </ResponsiveScreenProvider>
        </ResponsiveProvider>
    );
}

