export const updateLastTime = (currentTime, newTime) => {
    if (currentTime && currentTime !== "null") {
        let currentDate = new Date(currentTime);
        let newDate = new Date(newTime);
        if (currentDate.getTime() > newDate.getTime()) {
            sessionStorage.setItem("last_update_time", newTime);
        }
    } else {
        if (newTime && newTime !== "null") {
            sessionStorage.setItem("last_update_time", newTime);
        }
    }
}

export const mapMails = (messages, players, playerId, playerPermission) => {
    let mailMessages = [];
    let systemMessages = [];
    let controllers = players.filter(
        (player) => player.permission_for_game === "1"
    );
    if (playerPermission === "1") controllers.push({ id: playerId });
    messages.forEach((msg) => {
        //user or sender are the game controller this is a system message
        if (
            controllers.find(
                (con) =>
                    con.id === msg.sender?.id ||
                    msg.to_list_ids.indexOf(con.id) >= 0
            ) && msg?.is_survey === "0"
        ) {
            systemMessages.push(msg);
        } else {
            mailMessages.push(msg);
        }

    });
    return {
        mailMessages,
        systemMessages,
    };
};

