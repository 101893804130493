// login or register
export const LOGIN = "LOGIN";
export const FORGOT = "FORGOT";
export const END = "END";
export const CREATOR_OPTIONS = "CREATOR_OPTIONS";
export const NEW_GAME_CREATOR = "NEW_GAME_CREATOR"
export const GAME_LIST = "GAME_LIST";
export const IN_GAME = "IN_GAME";
export const START_GAME = "START_GAME";
export const TOKEN = "TOKEN";
export const FACTOR = "FACTOR";
export const POPUPS_COUNT = "POPUPS_COUNT";
export const LOADING = "LOADING";

export const setLogin = () => {
  return { type: LOGIN };
};
export const setForgot = () => {
  return { type: FORGOT };
};
export const end = () => {
  return {
    type: END
  }
}

export const setCreatorOptions = () => {
  return {
    type: CREATOR_OPTIONS
  }
};

export const newGameCreator = () => {
  return {
    type: NEW_GAME_CREATOR
  }
}

export const setGameList = (type) => {
  return {
    type: GAME_LIST,
    payload: type
  };
};
export const setInGame = () => {
  return {
    type: IN_GAME,
  };
};
export const setStartGame = (flag) => {
  return {
    type: START_GAME,
    flag: flag,
  };
};
export const setToken = (token) => {
  return {
    type: TOKEN,
    payload: token,
  };
};
export const setFactor = () => {
  return { type: FACTOR };
};
export const setPopupsCount = (count) => {
  return { type: POPUPS_COUNT, count: count };
};
// user
export const SETTING_FLAG = "SETTING_FLAG";
export const SET_USER = "SET_USER";
export const settingFlag = (flag) => {
  return {
    type: SETTING_FLAG,
    flag: flag,
  };
};
export const setUser = (data) => {
  return {
    type: SET_USER,
    payload: data,
  };
};
// options
export const CHANGE_LANG = "CHANGE_LANG";
export const CHANGE_LANG_FLAG = "CHANGE_LANG_FLAG";
export const HELP_FLAG = "HELP_FLAG";
export const CHANGE_LANG_DOMAIN = "CHANGE_LANG_DOMAIN";

export const changeLangType = (lang) => {
  return {
    type: CHANGE_LANG,
    lang: lang,
  };
};
export const changeLangFlag = (flag) => {
  return {
    type: CHANGE_LANG_FLAG,
    flag: flag,
  };
};
export const helpFlag = () => {
  return {
    type: HELP_FLAG,
  };
};
export const changeLangDomain = (lang) => {
  return {
    type: CHANGE_LANG_DOMAIN,
    lang: lang,
  }
};
// set game info data
export const SET_GAME_INFO = "SET_GAME_INFO";
export const SET_SHOW_GAME_INFO = "SET_SHOW_GAME_INFO";
export const SET_GAME_PARMISSION = "SET_GAME_PARMISSION";
export const REMOVE_BACKGROUND_FILE = "REMOVE_BACKGROUND_FILE";
export const REMOVE_COUNTDOWN_VIDEO = "REMOVE_COUNTDOWN_VIDEO";
export const SET_GAME_LOGO = "SET_GAME_LOGO";
export const SET_GAME_COLORS = "SET_GAME_COLORS";
export const SET_CLIENTS_FOR_USER = "SET_CLIENTS_FOR_USER";

export const setGameInfo = (data) => {
  return {
    type: SET_GAME_INFO,
    payload: data,
  };
};
export const setShowGameInfo = (flag) => {
  return {
    type: SET_SHOW_GAME_INFO,
    flag: flag,
  };
};
export const setGamePermission = (permission) => {
  return {
    type: SET_GAME_PARMISSION,
    permission: permission
  }
}
export const removeBackgroundFile = (id) => {
  return {
    type: REMOVE_BACKGROUND_FILE,
    id: id,
  }
}
export const removeCountdownVideo = () => {
  return {
    type: REMOVE_COUNTDOWN_VIDEO,
  }
}
export const setGameLogo = (gameLogo) => {
  return {
    type: SET_GAME_LOGO,
    payload: gameLogo
  }
}
export const setGameColors = (gameColors) => {
  return {
    type: SET_GAME_COLORS,
    payload: gameColors
  }
}
export const setClientsForUser = (data) => ({
  type: SET_CLIENTS_FOR_USER,
  payload: data
})
// set action data
export const SET_ACTIONS = "SET_ACTIONS";
export const SET_SHOW_ACTIONS = "SET_SHOW_ACTIONS";
export const ADD_ACTION = "ADD_ACTION";
export const SHARE_ACTION = "SHARE_ACTION";
export const SHOW_ACTION_INFO = "SHOW_ACTION_INFO";
export const SET_ACTION_INFO = "SET_ACTION_INFO";
export const SHOW_NEW_ACTION = "SHOW_NEW_ACTION";
export const RESET_ACTION_CURRENT = "RESET_ACTION_CURRENT";

export const setAction = (data, permission, eventsArr) => {
  return {
    type: SET_ACTIONS,
    payload: data,// filter by current user
    permission: permission,
    eventsArr: eventsArr
  };
};
export const setShowActions = (flag) => {
  return {
    type: SET_SHOW_ACTIONS,
    flag: flag,
  };
};
export const addAction = (action) => {
  return {
    type: ADD_ACTION,
    payload: action,
  };
};
export const shareAction = (share) => {
  return {
    type: ADD_ACTION,
    payload: share,
  };
};
export const showActionInfo = (flag) => {
  return {
    type: SHOW_ACTION_INFO,
    flag: flag,
  };
};
export const setActionInfo = (obj) => {
  return {
    type: SET_ACTION_INFO,
    payload: obj,
  };
};
export const showNewAction = (flag) => {
  return {
    type: SHOW_NEW_ACTION,
    flag: flag,
  };
};

export const resetActionCurrent = () => {
  return {
    type: RESET_ACTION_CURRENT
  }
}
// set chat data
export const SET_CHAT = "SET_CHAT";
export const SET_CHAT_FLAG = "SET_CHAT_FLAG";
// Expo Tel-Aviv
export const NEW_CHAT = "NEW_CHAT";
export const DELETE_CHAT = "DELETE_CHAT";
export const MARK_CHAT = "MARK_CHAT";
export const NEW_MSG_CHAT = "NEW_MSG_CHAT";
export const setChat = (data, isPlayer, players) => {
  return {
    type: SET_CHAT,
    payload: data,
    is_player: isPlayer,
    players: players,
  };
};

export const setChatFlag = (flag) => {
  return {
    type: SET_CHAT_FLAG,
    flag: flag,
  };
};

export const newChat = (player) => {
  return {
    type: NEW_CHAT,
    player: player,
  };
};
export const deleteChat = (id) => {
  return {
    type: DELETE_CHAT,
    payload: id
  }
}
export const markChat = (from, to, isAdmin, group) => {
  return {
    type: MARK_CHAT,
    from: from,
    to: to,
    isAdmin: isAdmin,
    group: group
  };
};
export const newMsgChat = (newMsg, id) => {
  return {
    type: NEW_MSG_CHAT,
    payload: newMsg,
    id: id,
  };
};
// set mail data
export const SET_MAIL = "SET_MAIL";
export const MAIL_FLAG = "MAIL_FLAG";
export const CHANGE_ARRANGE_MAIL = "CHANGE_ARRANGE_MAIL";
export const SET_DRAFTS_MAIL = "SET_DRAFTS_MAIL";
export const DELETE_DRAFT = "DELETE_DRAFT";
// Expo Tel-Aviv
export const ADD_REPLY_MAIL = "ADD_REPLY_MAIL";
export const MSG_FLAG_MAIL = "MSG_FLAG_MAIL";
export const NEW_MASSGAE_FLAG = "NEW_MESSAGE_FLAG";
export const NEW_MESSAGE_CURRENT = "NEW_MESSAGE_CURRENT";
export const NEW_MESSAGE_CURRENT_TYPE = "NEW_MESSAGE_CURRENT_TYPE";
export const SET_SENT_MAIL = "SET_SENT_MAIL";
export const MARK_MAIL = "MARK_MAIL";

export const setSentMail = (data, id, permission, lang) => {
  return {
    type: SET_SENT_MAIL,
    payload: data,
    id: id,
    permission: permission,
    lang: lang
  };
};
export const setMail = (data, id, permission, lang) => {
  return {
    type: SET_MAIL,
    payload: data,
    id: id,
    permission: permission,
    lang: lang
  };
};
export const mailFlag = (flag) => {
  return {
    type: MAIL_FLAG,
    flag: flag,
  };
};
export const addReplyMail = (msg, sender, from) => {
  return {
    type: ADD_REPLY_MAIL,
    payload: msg,
    sender: sender,
    from: from,
  };
};
export const msgFlagMail = (id, type, permission) => {
  return {
    type: MSG_FLAG_MAIL,
    payload: id,
    typeMsg: type,
    permission: permission,
  };
};

export const changeArrangeMail = (type, value, name) => {
  return {
    type: CHANGE_ARRANGE_MAIL,
    typeMsg: type,
    value: value,
    name: name,
  };
};
export const setDraftsMail = (data) => {
  return {
    type: SET_DRAFTS_MAIL,
    payload: data,
  };
};
export const deleteDraftsMail = (data) => ({
  type: DELETE_DRAFT,
  payload: data,
})
export const setNewMessageFlag = (flag) => {
  return {
    type: NEW_MASSGAE_FLAG,
    flag: flag,
  };
};
export const setNewMessageCurrent = (message) => {
  return {
    type: NEW_MESSAGE_CURRENT,
    payload: message,
  };
};
export const setNewMessageCurrentType = (type) => {
  return {
    type: NEW_MESSAGE_CURRENT_TYPE,
    payload: type,
  };
};
export const markMail = (id, isQuest) => {
  return {
    type: MARK_MAIL,
    payload: id,
    isQuest: isQuest
  }
}
// set docs data
export const SET_DOCS = "SET_DOCS";
export const SET_SHOW_DOCS = "SET_SHOW_DOCS";
export const ADD_DOC = "ADD_DOC";
export const SHOW_FILE_FLAG = "SHOW_FILE_FLAG";
export const SHOW_FILE = "SHOW_FILE";
export const setDocs = (data) => {
  return {
    type: SET_DOCS,
    payload: data,
  };
};
export const setShowDocs = (flag) => {
  return {
    type: SET_SHOW_DOCS,
    flag: flag,
  };
};
export const addDoc = (doc) => {
  return {
    type: ADD_DOC,
    payload: doc,
  };
};
export const showFile = (file) => {
  return {
    type: SHOW_FILE,
    file: file,
  };
};
// set news data
export const SET_NEWS = "SET_NEWS";
export const EDIT_OR_NEW = "EDIT_OR_NEW";
export const NEWS_FLAG = "NEWS_FLAG";
export const FIRST_COME_NEWS = "FIRST_COME_NEWS";
// Expo Tel-Aviv
export const ADD_NEWS = "ADD_NEWS";
export const EDIT_NEWS = "EDIT_NEWS";
export const MARK_NEWS = "MARK_NEWS";

export const setNews = (data) => {
  return {
    type: SET_NEWS,
    payload: data,
  };
};
export const editOrNew = (type, post) => {
  return {
    type: EDIT_OR_NEW,
    typeP: type,
    post: post,
  };
};
export const newsFlag = (flag) => {
  return {
    type: NEWS_FLAG,
    flag: flag,
  };
};
export const firstComeInNews = () => {
  return {
    type: FIRST_COME_NEWS,
  };
};
// Expo Tel-aviv
export const addNews = (post, read) => {
  return {
    type: ADD_NEWS,
    payload: post,
    read: read,
  };
};
export const editNews = (post, id, read) => {
  return {
    type: EDIT_NEWS,
    payload: post,
    id: id,
    read: read,
  };
};
export const markNews = (id) => {
  return {
    type: MARK_NEWS,
    payload: id,
  };
};
// set social-media data
export const SET_SOCIAL_MEDIA = "SET_SOCIAL_MEDIA";
export const SOCIAL_FLAG = "SOCIAL_FLAG";
//Expo Tel-Aviv
export const MARK_SOCIAL = "MARK_SOCIAL";
export const CHANGE_LIKE_POST = "CHANGE_LIKE_POST";
export const setSocialMedia = (data, id, players, is_admin) => {
  return {
    type: SET_SOCIAL_MEDIA,
    payload: data,
    id: id,
    players: players,
    is_admin: is_admin
  };
};
export const socialFlag = (flag) => {
  return {
    type: SOCIAL_FLAG,
    flag: flag,
  };
};
export const markSocial = (id) => {
  return {
    type: MARK_SOCIAL,
    payload: id,
  };
};
export const changeLikePost = (id, from) => {
  return {
    type: CHANGE_LIKE_POST,
    payload: id,
    from: from,
  }
}
// set system-admin-messages data
export const SET_SYSTEM_MESSAGES = "SET_SYSTEM_MESSAGES";
export const SET_SHOW_SYSTEM_MESSAGES = "SET_SHOW_SYSTEM_MESSAGES";
export const SET_SENT_SYSTEM = "SET_SENT_SYSTEM";
export const CHANGE_ARRANGE_SYSTEM = "CHANGE_ARRANGE_SYSTEM";
export const SET_DRAFTS_SYSTEM = "SET_DRAFTS_SYSTEM";
// Expo Tel-Aviv
export const ADD_NEW_SYSTEM = "ADD_NEW_SYSTEM";
export const ADD_REPLY_SYSTEM = "ADD_REPLY_SYSTEM";
export const MARK_SYSTEM = "MARk_SYSTEM";
export const NEW_SYSTEM_FLAG = "NEW_SYSTEM_FLAG";
export const NEW_SYSTEM_CURRENT = "NEW_SYSTEM_CURRENT";
export const NEW_SYSTEM_CURRENT_TYPE = "NEW_SYSTEM_CURRENT_TYPE";
export const setSystemMessages = (data, id, permission) => {
  return {
    type: SET_SYSTEM_MESSAGES,
    payload: data,
    id: id,
    permission: permission
  };
};
export const setShowSystemMessages = (flag) => {
  return {
    type: SET_SHOW_SYSTEM_MESSAGES,
    flag: flag,
  };
};
export const setSentSystem = (data, id, permission) => {
  return {
    type: SET_SENT_SYSTEM,
    payload: data,
    id: id,
    permission: permission
  };
};
export const addNewSystem = (msg) => {
  return {
    type: ADD_NEW_SYSTEM,
    payload: msg,
  };
};
export const addReply = (msg, sender) => {
  return {
    type: ADD_REPLY_SYSTEM,
    payload: msg,
    sender: sender,
  };
};
export const markSystem = (id) => {
  return {
    type: MARK_SYSTEM,
    payload: id,
  };
};

export const changeArrangeSystem = (type, value, name) => {
  return {
    type: CHANGE_ARRANGE_SYSTEM,
    typeMsg: type,
    value: value,
    name: name,
  };
};
export const setDraftsSystem = (data, remove = false) => {
  return {
    type: SET_DRAFTS_SYSTEM,
    payload: data,
    remove: remove,
  };
};
export const setNewSystemFlag = (flag) => {
  return {
    type: NEW_SYSTEM_FLAG,
    flag: flag,
  };
};
export const setNewSystemCurrent = (message) => {
  return {
    type: NEW_SYSTEM_CURRENT,
    payload: message,
  };
};
export const setNewSystemCurrentType = (type) => {
  return {
    type: NEW_SYSTEM_CURRENT_TYPE,
    payload: type,
  };
};
// drag position

export const CHNAGED_POSITION = "CHANGE_POSITION";
export const SET_POSITION = "SET_POSITION";

export const changePosition = (x, y, index) => {
  return {
    type: CHNAGED_POSITION,
    x: x,
    y: y,
    index: index,
  };
};

export const setPositions = (isPlayer) => {
  return {
    type: SET_POSITION,
    payload: isPlayer
  }
}

export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_LIGHT_MODE = "SET_LIGHT_MODE";
export const SET_COLORS = "SET_COLORS";
export const SET_COSTUM_COLORS = "SET_COSTUM_COLORS";
export const SET_COSTUM_COLORS_CLIENT = "SET_COSTUM_COLORS_CLIENT";
export const ADD_LOGO = "ADD_LOGO";
export const ADD_LOGO_CLIENT = "ADD_LOGO_CLIENT";
export const ADD_COUNT_DOWN_VIDEO = "ADD_COUNT_DOWN_VIDEO";
export const ADD_DOMAIN = "ADD_DOMAIN";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_NAME_FOR_PRIVACY = "ADD_PRODUCT_NAME_FOR_PRIVACY";
export const ADD_FAVICON = "ADD_FAVICON";

export const setDarkMode = () => {
  return {
    type: SET_DARK_MODE,
  };
};

export const setLightMode = () => {
  return {
    type: SET_LIGHT_MODE,
  };
};

export const setColors = () => {
  return {
    type: SET_COLORS
  }
}

export const setCostumColors = (obj, isColors) => {
  return {
    type: SET_COSTUM_COLORS,
    payload: obj,
    flag: isColors
  }
}

export const setCostumColorsClient = (obj) => {
  return {
    type: SET_COSTUM_COLORS_CLIENT,
    payload: obj
  }
}

export const addLogo = (logo) => {
  return {
    type: ADD_LOGO,
    payload: logo
  }
}
export const addLogoClient = (logo) => {
  return {
    type: ADD_LOGO_CLIENT,
    payload: logo
  }
}

export const addCountDownVideo = (video) => {
  return {
    type: ADD_COUNT_DOWN_VIDEO,
    payload: video
  }
}

export const addDomain = (domain) => {
  return {
    type: ADD_DOMAIN,
    payload: domain
  }
}
export const addProductName = (productName) => {
  return {
    type: ADD_PRODUCT,
    payload: productName
  }
}

export const addProductNameForPrivacy = (name) => {
  return {
    type: ADD_PRODUCT_NAME_FOR_PRIVACY,
    payload: name
  }
}

export const addFavicon = (favicon) => {
  return {
    type: ADD_FAVICON,
    payload: favicon
  }
}

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";

export const setErrorMessage = (flag, title, body, deleteDraftHandler, clickOkFunc, success,) => {
  return {
    type: SET_ERROR_MESSAGE,
    flag: flag,
    title: title,
    body: body,
    deleteDraftHandler: deleteDraftHandler,
    clickOkFunc: clickOkFunc,
    success: success
  };
};
// minimize - popup's

export const SET_TYPE_MINIMIZE = "SET_TYPE_MINIMIZE";
export const REMOVE_TYPE = "SET_TYPE";
export const SET_INDEX_CURRENT = "SET_INDEX_CURRENT";
export const MINIMIZE_NEW_SYSTEM = "MINIMIZE_NEW_SYSTEM";
export const MINIMIZE_NEW_MAIL = "MINIMIZE_NEW_MAIL";
export const MAXIMIZE_MAIL = "MAXIMIZE_MAIL";
export const MAXIMIZE_SYSTEM = "MAXIMIZE_SYSTEM";
export const BUILD_SHOW_FLAG = "BUILD_SHOW_FLAG";
export const MINIMIZE_DASHBOARD = "MINIMIZE_DASHBOARD";

export const setTypeMinimize = (obj) => {
  return {
    type: SET_TYPE_MINIMIZE,
    payload: obj,
  }
}

export const removeType = (type) => {
  return {
    type: REMOVE_TYPE,
    payload: type
  }
}

export const setIndexCurrent = (index) => {
  return {
    type: SET_INDEX_CURRENT,
    payload: index
  }
}

export const buildShowFlag = (flag) => {
  return {
    type: BUILD_SHOW_FLAG,
    payload: flag
  }
}

export const DESTROY_SESSION = "DESTROY_SESSION";

export const destroySession = () => {
  return {
    type: DESTROY_SESSION
  }
}

export const minimizeNewMail = (obj, show, id, deleteObj = false) => {
  // action.object = { show: true, id: Math.random(), message: message, type: "reply All" }
  return {
    type: MINIMIZE_NEW_MAIL,
    obj: obj,
    show: show,
    id: id,
    delete: deleteObj
  }
}

export const minimizeNewSystem = (obj, show, id, deleteObj = false) => {
  return {
    type: MINIMIZE_NEW_SYSTEM,
    obj: obj,
    show: show,
    id: id,
    delete: deleteObj
  }
}

export const maximizeMail = (flag, id) => ({
  type: MAXIMIZE_MAIL,
  flag,
  id
});

export const maximizeSystem = (flag, id) => ({
  type: MAXIMIZE_SYSTEM,
  flag,
  id
});

export const loading = (flag) => {
  return {
    type: LOADING,
    payload: flag
  }
}

export const minimizeDashbord = (obj, show, id, deleteObj = false) => ({
  type: MINIMIZE_DASHBOARD,
  obj: obj,
  show: show,
  id: id,
  delete: deleteObj
})