import React, { useState, useEffect, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { ShortenFile } from "../../../../modules/ShortenFile";
import * as DocumentPicker from "expo-document-picker";
import { AllowFilesUploaded } from "../../../../Elements/AllowFilesUploaded";
import { MAX_FILES_SIZE } from "@env";
import TextCustomize from '../../../../Elements/TextCustomize';
import { loading, setErrorMessage, showFile } from '../../../../../redux/actions';
import { convertByte } from '../../../../modules/ConvertByte';
import { t } from 'i18n-js';

const AttachmentsCell = ({ cellStyle, files, index, updateAction, isNew, platform, isDone, disabeld }) => {

    const dispatch = useDispatch();

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background, color);

    const [filesCurrent, setFilesCurrent] = useState();

    const createUri = (file) => {
        return new Promise((reslove, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                reslove(reader.result);
            }
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(file);
        });
    }

    const errorByPlatform = (platform) => {
        switch (platform) {
            case "mail":
                return `${t("err_files")}`;
            case "sm":
            case "news":
                return `${t("news_err_type_files")}`;
        }
    };

    const createFilesArray = async (res, AllowFilesUploaded) => {
        return [] = await Promise.all(Object.keys(res.output).map(async (key) => {
            if (AllowFilesUploaded.indexOf(res.output[key].type) < 0) {
                dispatch(
                    setErrorMessage(
                        true,
                        undefined,
                        errorByPlatform(platform),
                    )
                );
                return;
            } else if (convertByte(res.assets[0].size) >= MAX_FILES_SIZE) {
                dispatch(
                    setErrorMessage(
                        true,
                        undefined,
                        `${t("err_fileSize_mail")}`,
                    )
                );
                return;
            }
            return createUri(res.output[key]).then((uri) => {
                let newFile = {
                    name: res.output[key].name,
                    type: res.output[key].type,
                    uri: uri,
                    file: res.output[key],
                    size: res.output[key].size
                }
                return newFile;
            }).catch((err) => {
                dispatch(loading(false));
            });
        }));
    }

    const openFilesBrowser = (platform) => {
        let typesFiles = "";
        if (platform === "mail") {
            typesFiles = AllowFilesUploaded.join(",");
        } else if (platform === "sm") {
            typesFiles = "image/jpg, image/jpeg, image/png, audio/mp3, audio/mpeg, video/mp4";
        } else {
            typesFiles = "image/jpg, image/jpeg, image/png, , video/mp4";
        }
        DocumentPicker.getDocumentAsync({
            type: typesFiles,
            allowsEditing: true,
            multiple: platform === "mail" ? true : false,
        }).then((res) => {
            dispatch(loading(true));
            createFilesArray(res, typesFiles).then((arr) => {
                let updates = {};
                if (platform === "mail") {
                    arr = arr.filter(e => e); // סינון של המערך לאיברים תקפים בלבד
                    let temp = [...arr, ...filesCurrent];
                    updates["file"] = temp;
                    updates["has_attachments"] = arr?.length > 0 ? 1 : 0;
                } else {
                    updates["file"] = arr;
                }

                // קריאה אחת ל-updateAction עם כל העדכונים
                updateAction(index, updates, !isNew, isNew);
                dispatch(loading(false));
            }).catch(() => {
                dispatch(loading(false));
            });
        }).catch(() => {
            dispatch(loading(false));
        });
    }

    const fileItem = (file, indexFile) => {
        if (file?.name) {
            return (
                <TouchableOpacity
                    key={indexFile}
                    style={styles.fileView(direction)}
                    onPress={() => dispatch(showFile({ link: file?.link || file?.uri, ...file }))}
                >
                    <TextCustomize fontSize={"0.7vw"} style={styles.attachText(direction)} text={ShortenFile(file?.name).fileName} numberOfLines={1} />
                    <TextCustomize fontSize={"0.7vw"} style={styles.attachText(direction)} text={ShortenFile(file?.name).fileType} />
                    <TouchableOpacity disabled={isDone} onPress={() => {
                        if (platform === "news") {
                            openFilesBrowser(platform);
                        } else {
                            let updates = {}; // Initialize an object to hold all updates
                            if (platform === "mail") {
                                updates["files_to_remove"] = file?.id; // Assuming 'file?.id' is the identifier of the file to remove
                                updates["file"] = filesCurrent.filter((e, i) => i !== indexFile); // Remove file from the current list
                            } else {
                                updates["file"] = "";
                            }
                            updateAction(index, updates, !isNew, isNew);
                        }
                    }}>
                        <Image source={{ uri: images.plusIcon }} style={styles.iconX(direction)} />
                    </TouchableOpacity>
                </TouchableOpacity>
            )
        }
    }

    useEffect(() => {
        setFilesCurrent(files);
    }, [files]);

    return (
        <View style={cellStyle("17vw", false, true)}>
            <View style={styles.filesContainer}>
                {Array.isArray(filesCurrent) ? filesCurrent.map((file, index) => {
                    return fileItem(file, index);
                }) : fileItem(filesCurrent)}
            </View>
            <TouchableOpacity disabled={isDone || disabeld} style={styles.attachmentBtn(direction)} onPress={() => openFilesBrowser(platform)}>
                <Image source={{ uri: images.attachmentIcon }} style={styles.attachmentIcon} />
            </TouchableOpacity>
        </View>
    )
};

const stylesR = (background, color) => StyleSheet.create({
    filesContainer: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        rowGap: "0.3vw",
        columnGap: "0.3vw",
    },
    fileView: (direction) => ({
        maxWidth: "fit-content",
        flex: 1,
        height: "1.2vw",
        ...(direction == "rtl" ? { paddingRight: "0.3vw", } : { paddingLeft: "0.3vw", }),
        paddingVertical: "0.1vw",
        borderRadius: "0.4vw",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        minWidth: "4vw",
        backgroundColor: background.lineHover
    }),
    iconX: (direction) => ({
        tintColor: background.infoBox,
        transform: [{ rotate: "45deg" }],
        ...(direction === "rtl" ? {
            marginRight: "0.5vw",
            marginLeft: "0.3vw",
        } : {
            marginRight: "0.3vw",
            marginLeft: "0.5vw",
        }),
        width: "0.7vw",
        height: "0.7vw"
    }),
    attachmentBtn: (direction) => ({
        backgroundColor: background.infoBody,
        width: "1.2vw",
        height: "1.2vw",
        borderRadius: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...(direction === "rtl" ? {
            marginRight: "0.5vw",
            marginLeft: "0.3vw",
        } : {
            marginRight: "0.3vw",
            marginLeft: "0.5vw",
        }),
        alignSelf: "center"
    }),
    attachmentIcon: {
        tintColor: color.text,
        width: "0.6vw",
        height: "0.6vw"
    },
    attachText: (direction) => ({
        writingDirection: direction,
    })
});
export default memo(AttachmentsCell);