import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import React, { useMemo, useState } from 'react'
import DatePickerM, { CalendarContainer } from "react-datepicker";
import { getMonth, getYear, subDays } from 'date-fns';
import range from "lodash/range";
import "./datePicker.css";
import UseResponsiveSize from './UseResponsiveSize';
import UseResponsiveScreen from './UseResponsiveScreen';
import DateTimePicker from 'react-native-ui-datepicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

export default function DatePicker({ onChange, value, colorText, colorTextCurrent, background, borderColor, close, calendarClassName }) {
    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const lang = useSelector((state) => state.setting.setting.lang);
    const styles = stylesR(width, height, heightS, widthS);

    const [startDate] = useState(new Date().setDate(new Date().getDate() - 1));
    const [date, setDate] = useState(new Date(new Date(value || new Date()).getTime()));
    // const years = range(new Date().getFullYear(), getYear(new Date()) + 50, 1);

    // const [flagYears, setFlagYears] = useState(false);
    // const [flagMonth, setFlagMonth] = useState(false);

    // const months = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December",
    // ];
    // const MyHeader = ({
    //     date,
    //     changeYear,
    //     changeMonth,
    //     decreaseMonth,
    //     increaseMonth,
    //     prevMonthButtonDisabled,
    //     nextMonthButtonDisabled,

    // }) => {
    //     return (
    //         <View style={styles.header}>
    //             <Pressable onPress={decreaseMonth} style={[styles.arrow]}>
    //                 <Text style={[styles.arrowText, { color: colorText }]}>{"<"}</Text>
    //             </Pressable>
    //             <View>
    //                 <Text style={[styles.text, { color: colorText, fontWeight: "600", fontSize: widthS * 9 }]}>{getYear(date)}</Text>
    //                 {!flagYears && <ScrollView showsVerticalScrollIndicator={false} style={styles.scroll}>
    //                     {years.map((year, i) => {
    //                         return <Pressable onPress={() => {
    //                             debugger
    //                             changeYear(year)
    //                         }}>
    //                             <Text style={[styles.text, { color: colorText }]}>{year}</Text>
    //                         </Pressable>
    //                     })}
    //                 </ScrollView>}
    //             </View>
    //             <View>
    //                 <Text style={[styles.text, { color: colorText, fontWeight: "600", fontSize: widthS * 9 }]}>{months[getMonth(date)]}</Text>
    //                 {!flagMonth && <ScrollView showsVerticalScrollIndicator={false} style={styles.scroll}>
    //                     {months.map((month, i) => {
    //                         return <Pressable onPress={() => changeMonth(months.indexOf(month))}>
    //                             <Text style={[styles.text, { color: colorText }]}>{month}</Text>
    //                         </Pressable>
    //                     })}
    //                 </ScrollView>}
    //             </View>
    //             <Pressable onPress={increaseMonth} style={[styles.arrow]}>
    //                 <Text style={[styles.arrowText, { color: colorText }]}>{">"}</Text>
    //             </Pressable>
    //         </View>
    //     )
    // }

    // const MyContainer = ({ className, children }) => {
    //     return (
    //         <View style={styles.container}>
    //             <CalendarContainer className={className}>
    //                 <div style={{ position: "relative", }}>{children}</div>
    //             </CalendarContainer>
    //         </View>
    //     );
    // };


    return (
        <View style={styles.container}>
            <DateTimePicker
                mode="single"
                locale={lang}
                minDate={startDate}
                date={date}
                headerContainerStyle={{
                    paddingBottom: 0,
                    marginBottom: 0,
                }}
                calendarTextStyle={{ color: colorText, fontSize: "1vw" }}
                headerTextContainerStyle={{
                    color: colorText,
                }}
                weekDaysContainerStyle={{
                    borderWidth: 0,
                    marginBottom: 0,
                    paddingBottom: 0,
                }}
                headerButtonColor={colorText}
                headerTextStyle={{
                    color: colorText
                }}
                weekDaysTextStyle={{
                    color: colorText,
                    fontFamily: "Catamaran_700Bold"
                }}
                selectedTextStyle={{
                    fontWeight: "700",
                    fontSize: "1vw",
                    color: background,
                }}
                monthContainerStyle={{
                    backgroundColor: "transparent",
                    borderWidth: 0
                }}
                yearContainerStyle={{
                    backgroundColor: "transparent",
                    borderWidth: 0
                }}
                todayContainerStyle={{
                    borderWidth: 1,
                    borderColor: colorText
                }}
                selectedItemColor={colorText}
                todayTextStyle={{
                    color: colorText,
                    fontWeight: "700",
                    fontSize: "1vw"
                }}
                height={"16vw"}
                onChange={(params) => {
                    setDate(params.date);
                    onChange(new Date(params.date.$d).toDateString());
                    close();
                }}
            />
        </View>
    );
};

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            position: "relative",
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: "1vw",
            width: "19vw",
        },
        header: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: widthS * 160,
            height: heightS * 50
        },
        scroll: {
            height: heightS * 30,
        },
        calendarContainer: {
            width: widthS * 200,
            height: heightS * 200,
            backgroundColor: "blue",
            position: "absolute",
        },
        calendar: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: widthS * 200,
            height: heightS * 200,
            backgroundColor: "blue",
            // position: "absolute",
        },
        arrow: {
            paddingHorizontal: widthS * 3,
            paddingBottom: heightS * 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: widthS * 3
        },
        arrowText: {
            fontWeight: "bold",
            fontSize: widthS * 10
        },
        text: {
            fontSize: widthS * 8
        }
    })
}