import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import UseResponsiveScreen from '../modules/UseResponsiveScreen';
import UseResponsiveSize from '../modules/UseResponsiveSize';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
export default function ShadeSlider({ color, onChange, y, setY, position  }) {

    const { height, width } = UseResponsiveSize();
    const { heightS, widthS } = UseResponsiveScreen();
    const style = stylesR(height, width, heightS, widthS);

    const [backgroundShade, setBackgroundShade] = useState("");
    const [div, setDiv] = useState();


    const background = useSelector((state) => state.style.background);

    function dragElement(elmnt) {
        elmnt.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            document.onmouseup = closeDragElement;
            e.preventDefault();
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            // e = e || window.event;
            e.preventDefault();
            let move = e.layerY + e.movementY;
            setTimeout(() => {
                if (move > 240) {
                    return setY(240);
                } else if (move < 0) {
                    return setY(3);
                } else {
                    return setY(move);
                }
            }, 1);
        }

        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    const drewImage = (url) => {
        let img = new Image();
        let canvas = document.getElementById("color-canvas");
        canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
        let ctx = canvas.getContext("2d");

        let svg = document.getElementById("svg-shade");
        svg.style.background = `linear-gradient(to top, #212120, ${color?.value1})`; 
        svg.style.display = "";
        let svgString = new XMLSerializer().serializeToString(svg);
        let decoded = unescape(encodeURIComponent(svgString));
        let base64 = btoa(decoded);

        img.src = url + base64;
        img.onload = () => {
            ctx.drawImage(img, 0, 0, img.width, img.height);
        }
        svg.style.display = "none";
    }

    const getShade = (y) => {
        let canvas = document.getElementById("color-canvas");
        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }
        function rgbToHex(arr) {
            return "#" + componentToHex(arr[0]) + componentToHex(arr[1]) + componentToHex(arr[2]);
        }
        onChange(rgbToHex(canvas.getContext("2d").getImageData( widthS * 10, y / 1.7, 1, 1,).data));
    }

    const getShadeCurrent = () => {
        let canvas = document.getElementById("color-canvas");
        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }
        function rgbToHex(arr) {
            return "#" + componentToHex(arr[0]) + componentToHex(arr[1]) + componentToHex(arr[2]);
        }

        for(let i = 0; i < canvas.clientHeight; i ++){
            if(rgbToHex(canvas.getContext("2d").getImageData(15, i, 1, 1).data) === color.value){
                setY(i);
                break;
            };
        }
    }

    useMemo(() => {
        if (div) {
            dragElement(div);
            getShadeCurrent();
        }
    }, [document.getElementById("indictor"), position]);
    useMemo(() => {
        if(div){
            drewImage(`data:image/svg+xml;base64,`);
        }
    }, [color?.value, document.getElementById("color-canvas")]);

    useEffect(() => {
        setDiv(document.getElementById("indictor"))
    }, []);

    useMemo(() => {
        if (document.getElementById("color-canvas")) {
            getShade(y);
        }
    }, [y]);

    return (
        <div style={{
            position: "relative",
            display: "flex",
            justifyContent: "center"
        }}>
            <div
                id="indictor"
                style={{
                    // top: y,
                    transform: `translateY(${y}px)`,
                    width: widthS * 20,
                    height: heightS * 2.8,
                    position: "absolute",
                    zIndex: 10,
                    borderWidth: widthS * 1,
                    borderColor: background.popupLogin,
                    borderRadius: widthS * 2,
                    borderStyle: "solid"
                }} />
            <canvas id='color-canvas' style={{width: widthS * 19, height: heightS * 145, borderRadius: widthS * 6, }} />
            <svg 
            xmlns='http://www.w3.org/2000/svg' 
            // viewBox='0 0 512 512' 
            id='svg-shade'
            style={{
                display: "none", 
                width: "100%", 
                height: heightS * 145, 
                }}></svg>
        </div>
    )
}

const stylesR = () => {
    return StyleSheet.create({})
}