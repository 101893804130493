import { link } from 'd3-shape';
import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';

import { SET_DOCS, ADD_DOC, SHOW_FILE, SHOW_FILE_FLAG, SET_SHOW_DOCS } from '../actions/index';
import { addOrReplaceItemInArr } from "../function/addOrReplaceItemInArr";
export const docsReducer = (state = { filesArr: [], fileShow: { name: "", type: "", link: "" }, flag: false }, action) => {
      switch (action.type) {
            case SET_DOCS: {
                  let docsArr = [...state.filesArr];
                  let docsArrNew = [];
                  action.payload.forEach((e) => {
                        let doc = {
                              id: e.id,
                              is_read: e.is_read,
                              link: e.link,
                              name: e.name = checkIfIsEncodeText(e.name, "string"),
                              description: e.description = checkIfIsEncodeText(e.description, "string"),
                              type: e.type,
                              uploader_id: e.uploader_id,
                              status: e.status ? e.status : "1"
                        }
                        if (docsArr?.length > 0) {
                              const { addFlag, arrCurrent } = addOrReplaceItemInArr(docsArr, doc, false, false);
                              docsArr = arrCurrent;
                        } else {
                              docsArrNew.push(doc);
                        }

                  })
                  return { ...state, filesArr: [...docsArrNew, ...docsArr] };
            }
            case ADD_DOC: {
                  action.payload.name = checkIfIsEncodeText(action.payload.name, "string");
                  state.filesArr.push(action.payload);
                  return { ...state, filesArr: [...state.filesArr] };
            }
            case SHOW_FILE: {
                  if (action.file) {
                        let file = {
                              name: action.file.name,
                              type: action.file.type,
                              link: action.file.link,
                        }
                        return { ...state, fileShow: { ...file } }
                  } else {
                        return { ...state, fileShow: { name: "", type: "", link: "" } };
                  }
            }
            case SET_SHOW_DOCS: {
                  return { ...state, flag: action.flag }
            }
            default: {
                  return state;
            }
      }
}