import { StyleSheet, Text, View, ScrollView, Pressable, Image, Modal } from 'react-native';
import React, { createRef, Fragment, Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { t } from 'i18n-js';
import Natwork from '../modules-vis/vis-react';
import { AntDesign, Entypo } from '@expo/vector-icons';
import { InteractionMapFuncContext } from '../analyticsFunction/InteractionMapFunc';
import TimeUnits from '../../../../Elements/TimeUnits';
import ShortenWord from '../../../../modules/ShortenWord';
import IconButton from "../../../../Elements/IconButton";
import AnimationLoading from '../../../../modules/AnimationLoading';
import { vwToPx } from "../../../../modules/ConvertPXToVw";

export default function InteractionsMap({ type, real, position, setPosition, timeUnits, setTimeUnits, stopPlayScroll, maximize, setMaximize, isShot, refShot }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();

    const {
        arrScore,
        arrScoreReal,
        times,
        timesReal,
        maxCount,
        maxCountReal,
        filterStatistics,
        refresh,
        setRefresh
    } = useContext(InteractionMapFuncContext);

    const colors = [
        { title: "Low", colors: ["#5CE5CB", "#00A081"] },
        { title: "Medium", colors: ["#55ABE5", "#006ABC"] },
        { title: "high", colors: ["#EFACED", "#D669D3"] },
        { title: "Extreme", colors: ["#EAA553", "#D98000"] },
    ];

    const size = [
        "1.5vw",
        "1.5vw",
        "1.5vw",
        "1.5vw",
    ]

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(width, height, heightS, widthS, background, color, direction);

    const images = useSelector((state) => state.style.images);
    const players = useSelector(state => state.gameInfoArr.gameInfoArr.players);

    const timesRef = useRef(null);
    const scrollTimesRef = useRef(null);
    const conatinerRef = useRef(null);

    const graphRef = useRef(null);
    const graphRealRef = useRef(null);

    const ifIsUpdate = useRef(false);

    const [showIndictor, setShowIndictor] = useState(false);
    const [arrScoreState, setArrScoreState] = useState([]);
    const [maxCountState, setMaxCountState] = useState();
    const [timesState, setTimesState] = useState();
    const [showReal, setShowReal] = useState(false);
    const [showRep, setShowRep] = useState(false);
    const [delay, setDelay] = useState(true);

    const [graph, setGraph] = useState({
        nodes: [],
        edges: []
    });
    const [graphReal, setGraphReal] = useState({
        nodes: [],
        edges: []
    });

    const [zoomMap, setZoomMap] = useState(100);
    const [zoomMapReal, setZoomMapReal] = useState(100);

    const optionsNew = useMemo(() => {
        return {
            edges: {
                arrows: {
                    to: {
                        enabled: true,
                        scaleFactor: 0.5,
                        type: "arrow"
                    },
                    middle: {
                        enabled: false,
                        scaleFactor: 0.5,
                        type: "arrow"
                    },
                    from: {
                        enabled: true,
                        scaleFactor: 0.5,
                        type: "arrow"
                    }
                },
                color: {
                    color: "#61596C",
                    highlight: "#fcf000"
                },
                width: vwToPx(0.1),
                selectionWidth: 0.4,
                font: {
                    color: "gray",
                },
                scaling: {
                    label: {
                        enabled: true
                    },
                },
                shadow: {
                    enabled: true
                },
            },
            layout: {
                improvedLayout: true,
            },
            nodes: {
                borderWidth: widthS * 1,
                borderWidthSelected: widthS * 2,
                brokenImage: undefined,
                chosen: true,
                shape: "dot",
                color: {
                    hover: {
                        border: '#fcf000', // צבע גבול בריחוף
                    },
                    highlight: {
                        borderWidth: widthS * 2, // רוחב גבול במצב highlight
                        color: {
                            border: "#fcf000" // צבע גבול במצב highlight
                        }

                    },
                },
                borderWidth: widthS * 1, // רוחב גבול רגיל
                borderWidthSelected: widthS * 2, // רוחב גבול בריחוף
                font: {
                    size: vwToPx(0.8),
                    color: "#F8EBF6",
                    bold: {
                        color: "#F8EBF6",
                        size: vwToPx(1),
                        vadjust: 0,
                        mod: "bold"
                    },
                    face: "Catamaran_300Light"
                }
            },
            physics: {
                enabled: true, // הפעלת הפיזיקה
                repulsion: false,
                barnesHut: {
                    gravitationalConstant: -80000,
                    centralGravity: 0.1,
                    springLength: 200,
                    springConstant: 0,
                    damping: 0.2
                },
                solver: "forceAtlas2Based",
                // timestep: 0.9,
                adaptiveTimestep: true,
                stabilization: {
                    enabled: true,
                    iterations: 500,
                    updateInterval: 10,
                    fit: true,
                },
            },
            interaction: {
                navigationButtons: false,
                hover: true,
                tooltipDelay: 200,
                // dragNodes: true,
                // keyboard: {
                //     autoFocus: false, // Set autoFocus to false to disable keyboard control
                // },
                hideEdgesOnDrag: true,
            },
        };
    }, []);

    const handleNodeClick = (event, graphRef) => {
        const { nodes } = event;

        if (nodes.length > 0) {
            const nodeId = nodes[0];

            // ביטול סינון הצמתים והקשתות הלא קשורים לצומת הנבחרת הקודמת
            Object.keys(graphRef.current.Network.body.nodes).forEach(nodeId => {
                graphRef.current.Network.body.nodes[nodeId].options.color.opacity = 1;
            });
            Object.keys(graphRef.current.Network.body.edges).forEach(edgeId => {
                graphRef.current.Network.body.edges[edgeId].options.color.opacity = 1;
            });

            // קבלת הצמתים והקשתות הקשורות לצומת החדשה שנבחרה
            const connectedNodes = graphRef.current.Network.getConnectedNodes(nodeId);
            const connectedEdges = graphRef.current.Network.getConnectedEdges(nodeId);

            // הסתרת כל הצמתים והקשתות שאינם קשורים לצומת החדשה שנבחרה
            const otherNodes = Object.keys(graphRef.current.Network.body.nodes).filter(id => !connectedNodes.includes(id) && id !== nodeId);
            const otherEdges = Object.keys(graphRef.current.Network.body.edges).filter(id => !connectedEdges.includes(id));

            otherNodes.forEach(nodeId => {
                graphRef.current.Network.body.nodes[nodeId].options.color.opacity = 0;
            });

            otherEdges.forEach(edgeId => {
                graphRef.current.Network.body.edges[edgeId].options.color.opacity = 0;
            });

            // // ממוקד את התצוגה על הצומת החדשה שנבחרה והצמתים המקושרות אליה
            // graphRef.current.Network.fit({
            //     nodes: connectedNodes.concat(nodeId),
            //     animation: {
            //         duration: 0, // אפס את משך האנימציה
            //     }
            // });
        }
    };

    const handleNodeRelease = (graphRef) => {
        const { nodes, edges } = graphRef.current.Network.body;

        // לחזור על כל הצמתים ולהחזיר את ההסתרה שלהם לערך המקורי (התצוגה שלהם)
        Object.keys(nodes).forEach(nodeId => {
            nodes[nodeId].options.color.opacity = 1; // או ערך ההסתרה המקורי של הצומת
        });

        // לחזור על כל הקשתות ולהחזיר את ההסתרה שלהם לערך המקורי (התצוגה שלהם)
        Object.keys(edges).forEach(edgeId => {
            edges[edgeId].options.color.opacity = 1; // או ערך ההסתרה המקורי של הקשת
        });

        // // מחזיר את התצוגה למצב המקורי שנשמר במשתנים connectedNodes ו-connectedEdges
        // graphRef.current.Network.fit({
        //     nodes: Object.keys(nodes).concat(Object.keys(graphRef.current.Network.body.nodes)),
        //     animation: {
        //         duration: 0, // אפס את משך האנימציה
        //     }
        // });
    };


    const findName = (id, players) => {
        let image, realName, gameName;
        if (players?.length > 0) {
            let e = players.find((e) => e.id === id);
            image = e.profile_image ? e.profile_image : null;
            realName = e.real_name;
            gameName = e.name_for_game;
        }
        return {
            image,
            realName,
            gameName
        };
    }

    const createTooltip = () => {
        let divC = document.createElement("div");
        let divM = document.createElement("div");
        let divImage = document.createElement("div");
        let divText = document.createElement("div");
        let img = document.createElement("img");
        let pTitle = document.createElement("p");
        let pName = document.createElement("p");
        let pLevel = document.createElement("p");
        divC.appendChild(divM);
        divM.appendChild(divImage);
        divM.appendChild(divText);
        divImage.appendChild(img);
        divText.appendChild(pTitle)
        divText.appendChild(pName);
        divText.appendChild(pLevel);
        divC.classList = "vis-tooltip-c";
        divM.classList = "tooltip-main";
        divImage.className = "tooltip-div-img";
        divImage.style.backgroundColor = background.loginFooter;
        pTitle.classList = "tooltip-title";
        pName.classList = "tooltip-name";
        pLevel.classList = "tooltip-name";
        return {
            divC,
            img,
            pTitle,
            pName,
            pLevel
        }
    }

    const createTable = (edges, nodes, maxCount, type) => {

        let nodeState = [];
        if (nodes?.length > 0) {
            nodeState = [...nodes];
            nodeState?.forEach((e) => {
                let details = findName(e.id, players);
                const { divC, img, pLevel, pName, pTitle } = createTooltip();
                img.src = details?.image ? details?.image : images.user_image;
                pTitle.innerText = ShortenWord(details.gameName, 15);
                pName.innerText = details.realName ? ShortenWord(details.realName, 15) : "";
                pLevel.innerText = e.real ? e.level : t("represented_player");

                e.color.border = "transparent";
                e.font = {
                    // size: widthS * 12,
                    color: "#F8EBF6",
                    bold: {
                        color: "#F8EBF6",
                        // size: widthS * 12,
                        vadjust: 0,
                        mod: "bold"
                    },
                    face: "Catamaran_300Light"
                }
                e.label = details.gameName
                e.user_image = details.image;
                e.name = details.realName;
                e.title = divC
            });
            if (type === "real") {
                setGraphReal({
                    nodes: nodeState,
                    edges: edges,
                });
            } else {
                setGraph({
                    nodes: nodeState,
                    edges: edges,
                });
            }
        }
    }

    const play = (i) => {

    }

    const setCurrentTimeFromScroll = (partOfScroll, scrollLeft, flag) => {
        let count = Math.round(scrollLeft / partOfScroll);
        count = count >= timesState.times.length ? timesState.times.length - 1 : count;
    }

    useEffect(() => {
        if (type === "expected") {
            setArrScoreState(arrScore);
            setMaxCountState(maxCount);
            setTimesState(times);
        } else if (type === "compared") {
            setArrScoreState(arrScore);
            setMaxCountState(maxCountReal);
            setTimesState(timesReal);
        } else {
            setArrScoreState(arrScoreReal);
            setMaxCountState(maxCountReal);
            setTimesState(timesReal);
        }
    }, [arrScore, arrScoreReal, maxCount, maxCountReal, refresh, times, timesReal]);

    useEffect(() => {
        if (arrScoreState) {
            if (type === "compared") {
                createTable(arrScoreReal?.edges, arrScoreReal?.nodes, maxCountReal, "real");
                createTable(arrScore?.edges, arrScore?.nodes, maxCount, "expected");
            } else {
                createTable(arrScoreState?.edges, arrScoreState?.nodes, maxCountState, "expected");
            }
            ifIsUpdate.current = true;
        }
    }, [arrScoreState, maxCountState, maximize]);

    useEffect(() => {
        setTimeout(() => {
            if (type === "compared") {
                filterStatistics(timeUnits, timesState?.count + 1 || 0, type, position >= 0 ? position : 0);
                filterStatistics(timeUnits, timesState?.count + 1 || 0, type, position >= 0 ? position : 0, real);
            } else {
                filterStatistics(timeUnits, timesState?.count + 1 || 0, type, position >= 0 ? position : 0);
            }
            setDelay(false);
        }, 2000);
    }, [refresh, type, real]);

    useEffect(() => {
        if (conatinerRef.current) {
            conatinerRef.current.addEventListener("mousemove", (e) => {
                stopPlayScroll(false);
            });
            conatinerRef.current.addEventListener("mouseleave", (e) => {
                stopPlayScroll(true);
            });
        }
    }, [conatinerRef.current]);

    const handleZoomIn = (ref, setZoomMap) => {
        console.log(ref.current.Network.getScale())
        if (ref.current.Network.getScale() < 1.5) {
            setZoomMap((z) => z += 10);
            ref.current.Network.moveTo({ scale: ref.current.Network.getScale() * 1.1 });
        }
    };

    const handleZoomOut = (ref, setZoomMap) => {
        console.log(ref.current.Network.getScale());
        if (ref.current.Network.getScale() > 0.5) {
            setZoomMap((z) => z -= 10);
            ref.current.Network.moveTo({ scale: ref.current.Network.getScale() / 1.1 });
        }
    };

    const zoom = (ref, state, setState) => {
        return (
            <View style={styles.zoomView}>
                <Text style={styles.filterText}>{t("zoom")}</Text>
                <View style={styles.zoomButtonView}>
                    <Pressable onPress={() => handleZoomOut(ref, setState)}>
                        <Entypo name="minus" size={widthS * 7} color={color.infoHeaderNavText} />
                    </Pressable>
                    <Text style={[styles.filterText, { width: widthS * 25 }]}>{state}%</Text>
                    <Pressable onPress={() => handleZoomIn(ref, setState)}>
                        <Entypo name="plus" size={widthS * 7} color={color.infoHeaderNavText} />
                    </Pressable>
                </View>
            </View>
        )
    }

    useEffect(() => {
        if (graphRef.current?.Network) {
            graphRef.current?.Network.on('zoom', (params) => {
                if (params.scale > 1) {
                    graphRef.current.Network.moveTo({ scale: 1 });
                    setZoomMap(100);
                } else if (params.scale < 0.4) {
                    graphRef.current.Network.moveTo({ scale: 0.4 });
                    setZoomMap(0);
                } else {
                    let num = params.scale - 0.4;
                    let step = 0.6 / 100;
                    num = num / step;
                    setZoomMap(Math.round(num));
                }
            });
            graphRef.current?.Network.on('click', (e) => handleNodeClick(e, graphRef));
            graphRef.current?.Network.on('click', (event) => {
                if (event.nodes.length === 0) { // אם לא נבחרה שום צומת בלחיצה
                    handleNodeRelease(graphRef);
                }
            });

            return () => {
                graphRef.current?.Network.off('click', (e) => handleNodeClick(e, graphRef));
                graphRef.current?.Network.off('click', (event) => {
                    if (event.nodes.length === 0) { // אם לא נבחרה שום צומת בלחיצה
                        handleNodeRelease(graphRef);
                    }
                });
            };
        }
    }, [graphRef.current?.Network]);

    useEffect(() => {
        if (graphRealRef.current?.Network) {
            graphRealRef.current?.Network.on('zoom', (params) => {
                if (params.scale > 1) {
                    graphRealRef.current.Network.moveTo({ scale: 1 });
                    setZoomMapReal(100);
                } else if (params.scale < 0.4) {
                    graphRealRef.current.Network.moveTo({ scale: 0.4 });
                    setZoomMapReal(0);
                } else {
                    let num = params.scale - 0.4;
                    let step = 0.6 / 100;
                    num = num / step;
                    setZoomMapReal(Math.round(num));
                }
            });
            graphRealRef.current?.Network.on('click', (e) => handleNodeClick(e, graphRealRef));
            graphRealRef.current?.Network.on('click', (event) => {
                if (event.nodes.length === 0) { // אם לא נבחרה שום צומת בלחיצה
                    handleNodeRelease(graphRealRef);
                }
            });

            return () => {
                graphRealRef.current?.Network.off('click', (e) => handleNodeClick(e, graphRealRef));
                graphRealRef.current?.Network.off('click', (event) => {
                    if (event.nodes.length === 0) { // אם לא נבחרה שום צומת בלחיצה
                        handleNodeRelease(graphRealRef);
                    }
                });
            };
        }
    }, [graphRealRef.current?.Network]);

    const body = (isMaximize) => (
        <View ref={refShot} nativeID='interactionMap' style={{ width: "100%", position: "relative", height: isMaximize ? "100%" : "" }}>
            <View style={{ flexDirection: "row", zIndex: 1, justifyContent: "space-between", alignItems: "center" }}>
                <Text style={[styles.headers, { color: color.infoHeaderNavText }]}>{t("INTERACTION").toUpperCase()}</Text>
                {(type !== "compared" || real) && !maximize &&
                    <IconButton
                        flag={isMaximize}
                        iconName1={images.maximize}
                        iconName2={images.xIcon}
                        setFlag={() => setMaximize(!maximize)}
                        style={{ width: "0.8vw", height: "0.8vw", resizeMode: "contain" }}
                        style2={{ width: "1vw", height: "1vw", resizeMode: "contain", }}
                    />}
                {/* {(real || type === "real") && <TimeUnits setTimeUnits={(t) => { setTimeUnits(t); setRefresh(!refresh) }} />} */}
            </View>
            <View ref={conatinerRef} style={[styles.table, { height: isMaximize ? "95%" : "" }]}>
                <View
                    style={{
                        position: "relative",
                        flexDirection: "row",
                        backgroundColor: background.infoHeader,
                        width: "100%",
                        height: "2vw",
                        borderTopLeftRadius: widthS * 15,
                        borderTopRightRadius: widthS * 15,
                        zIndex: 10
                    }}>
                    <View style={{ width: "11vw" }}></View>
                    <View
                        ref={scrollTimesRef}
                        nativeID='scroll-time'
                        onScroll={(e) => {
                            let scrollWidth = e.target.scrollWidth - e.target.clientWidth;
                            let partOfScroll = (scrollWidth / timesState?.times?.length);
                            if (e.currentTarget.scrollWidth - e.currentTarget.scrollLeft - 50 <= (e.currentTarget.clientWidth)) {
                                setCurrentTimeFromScroll(partOfScroll, e.target.scrollWidth - e.currentTarget.scrollLeft < 15 ? e.target.scrollWidth : e.target.scrollLeft, true);
                            } else {
                                setCurrentTimeFromScroll(partOfScroll, e.target.scrollWidth - e.currentTarget.scrollLeft < 15 ? e.target.scrollWidth : e.target.scrollLeft, false);
                            }
                        }}
                        style={{
                            display: "none",
                            overflowX: "scroll",
                            overflowY: "hidden",
                            flex: 1,
                            borderTopLeftRadius: widthS * 15,
                            borderTopRightRadius: widthS * 15,
                            marginBottom: "-1.2vw",
                        }}
                    >
                        <View
                            style={styles.headerTable}
                        >
                            {/* shahak asked to make this button disappear for now */}
                            {/* <Pressable style={{
                            position: "sticky",
                            left: widthS * 10,
                            zIndex: 5,
                            backgroundColor: background.infoHeader,
                        }}
                            onPress={() => play()}
                        >
                            <AntDesign name="play" size={widthS * 12} color="#61596C" />
                        </Pressable> */}
                            {/* {timesState?.times?.length > 0 && timesState.times.map((e, i) => {
                                let date1, data2;
                                if (type === "real" || (type === "compared" && real)) {
                                    date1 = new Date(timesState?.times[i]?.date).toString();
                                    data2 = new Date(timesState?.times[position >= 0 ? position : 0]?.date).toString();
                                } else {
                                    date1 = timesState?.times[i]?.time;
                                    data2 = timesState?.times[position >= 0 ? position : 0]?.time;
                                }
                                return (
                                    <Pressable
                                        ref={(ref) => {
                                            if (!ref) return;
                                            if (position >= 0 && data2 === date1) {
                                                let t = ref.offsetLeft;
                                                scrollTimesRef.current.scrollLeft = t - 100
                                            }
                                        }}
                                        style={{
                                            marginHorizontal: widthS * 5,
                                            paddingVertical: heightS * 5,
                                            backgroundColor: date1 === data2 ? background.loginFooter : "",
                                            paddingHorizontal: widthS * 2,
                                            paddingVertical: widthS * 1,
                                            marginVertical: widthS * 1,
                                            borderRadius: widthS * 3,
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                        key={i}
                                        onPress={async () => {
                                            // filterStatistics(timesState?.count > 0 ? timesState?.count : 1, type, i, real);
                                            setPosition(i);
                                        }} >
                                        <Text style={[styles.filterText, { color: color.infoHeaderNavText, fontFamily: date1 === data2 ? "Catamaran_700Bold" : "Catamaran_300Light" }]}>{type === "expected" && "T"}{e.time}</Text>
                                    </Pressable>
                                )
                            })} */}
                        </View>
                    </View>
                </View>
                <View style={[styles.tableBody, { height: isMaximize ? "95%" : "" }]}>
                    <View style={[styles.leftTableBody, { height: isShot === "interaction" ? "30vw" : isMaximize ? "100%" : "26vw", }]}>
                        {zoom(graphRef, zoomMap, setZoomMap)}
                        <View style={{ flexDirection: "column" }}>
                            <Text style={[styles.filterText, { color: color.infoHeaderNavText, fontSize: widthS * 7, marginVertical: heightS * 7, alignSelf: direction === "rtl" ? "flex-end" : "flex-start", marginHorizontal: widthS * 7, }]}>{t("LEVEL-OF-INFLUENCE")}</Text>
                            <View style={{ marginHorizontal: widthS * 8 }}>
                                <View style={{ flexDirection: "row", alignItems: "center", marginHorizontal: widthS * 3, marginBottom: heightS * 3 }}>
                                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <View style={{ width: size[0], height: size[0], backgroundColor: "#61596C", borderRadius: "50%", marginRight: widthS * 2 }} />
                                    </View>
                                    <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("represented")}</Text>
                                </View>
                                <View style={{ flexDirection: "row", alignItems: "center", marginHorizontal: widthS * 3, }}>
                                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <View style={{
                                            width: size[0],
                                            height: size[0],
                                            background: `linear-gradient(${colors[0].colors[0]} 50%, ${colors[0].colors[1]} 50%)`,
                                            borderRadius: "50%",
                                            marginRight: widthS * 2
                                        }} />
                                    </View>
                                    <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("low")}</Text>
                                </View>
                                <View style={{ flexDirection: "row", alignItems: "center", marginHorizontal: widthS * 3, }}>
                                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <View style={{
                                            width: size[1],
                                            height: size[1],
                                            background: `linear-gradient(${colors[1].colors[0]} 50%, ${colors[1].colors[1]} 50%)`,
                                            borderRadius: "50%",
                                            marginRight: widthS * 2
                                        }} />
                                    </View>
                                    <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("medium")}</Text>
                                </View>
                                <View style={{ flexDirection: "row", alignItems: "center", marginHorizontal: widthS * 3, }}>
                                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <View style={{
                                            width: size[2],
                                            height: size[2],
                                            background: `linear-gradient(${colors[2].colors[0]} 50%, ${colors[2].colors[1]} 50%)`,
                                            borderRadius: "50%",
                                            marginRight: widthS * 2
                                        }} />
                                    </View>
                                    <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("high")}</Text>
                                </View>
                                <View style={{ flexDirection: "row", alignItems: "center", marginHorizontal: widthS * 3, }}>
                                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <View style={{
                                            width: size[3],
                                            height: size[3],
                                            background: `linear-gradient(${colors[3].colors[0]} 50%, ${colors[3].colors[1]} 50%)`,
                                            borderRadius: "50%",
                                            marginRight: widthS * 2
                                        }} />
                                    </View>
                                    <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{t("extreme")}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <Suspense fallback={() => null}>
                        <View style={[styles.expectedTableMain, { maxHeight: isShot === "interaction" ? "40vw" : isMaximize ? "100%" : "26vw", }]}>
                            {showRep &&
                                tooltipIteraction(showRep)}
                            <View nativeID={`table-map${isMaximize ? "max" : ""}`} style={[styles.expectedTableMap, { height: isMaximize ? "100%" : "26vw", }, !real && styles.repTableMapExpectedCompared]}>
                                {!delay ? (graph?.nodes?.length > 0 &&
                                    <Natwork
                                        ref={graphRef}
                                        graph={graph}
                                        options={optionsNew}
                                    />) : <AnimationLoading flag={true} widget={true} />}
                            </View>
                        </View>
                    </Suspense>
                    {real &&
                        <Suspense fallback={() => null}>
                            <View style={[styles.realTableMain, { maxHeight: isShot === "interaction" ? "40vw" : isMaximize ? "100%" : "26vw", }]}>
                                {showReal &&
                                    tooltipIteraction(showReal)}
                                <View nativeID={`table-map-real${isMaximize ? "max" : ""}`} style={[styles.realTableMap, { height: isMaximize ? "100%" : "26vw", }]} >
                                    <View style={{ position: "absolute", top: 0, left: 0, zIndex: 10 }}>
                                        {zoom(graphRealRef, zoomMapReal, setZoomMapReal)}
                                    </View>
                                    {!delay ? (graphReal?.nodes?.length > 0 &&
                                        <Natwork
                                            ref={graphRealRef}
                                            graph={graphReal}
                                            options={optionsNew}
                                        />) : <AnimationLoading flag={true} widget={true} />
                                    }
                                </View>
                            </View>
                        </Suspense>}
                </View>
            </View>
        </View >
    )


    return (
        <>
            <Suspense fallback={() => null}>
                {body(maximize)}
            </Suspense>
        </>
    )
}

const stylesR = (width, height, heightS, widthS, background, color, direction) => {
    return StyleSheet.create({
        headers: {
            fontSize: widthS * 12,
            marginLeft: widthS * 6,
            marginBottom: heightS * 3,
            fontFamily: "Catamaran_300Light"
        },
        headers1: {
            fontSize: widthS * 9,
            fontFamily: "Catamaran_300Light"
        },
        table: {
            width: "100%",
            borderRadius: widthS * 19,
            flexDirection: "column",
            alignItems: "center"
        },
        tableBody: {
            flexDirection: "row",
            width: "100%"
        },
        headerTable: {
            alignItems: "center",
            minWidth: "fit-content",
            paddingHorizontal: widthS * 10,
            borderTopLeftRadius: widthS * 15,
            borderTopRightRadius: widthS * 15,
            flexDirection: "row",
            minHeight: "2vw"
        },
        leftTableBody: {
            width: "11vw",
            backgroundColor: background.leftInfo,
            borderBottomLeftRadius: widthS * 15,
            borderRightColor: color.border,
            borderRightWidth: widthS * 1,
        },
        zoomView: {
            backgroundColor: background.infoBody,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingHorizontal: widthS * 6,
            writingDirection: direction
        },
        zoomButtonView: {
            flexDirection: "row",
            alignItems: "center",
        },
        leftPlayerView: {
            flex: 2,
            height: "100%",
            position: "relative"

        },
        rightMapView: {
            height: "100%",
            flex: 9,
            position: "relative"
        },
        filterText: {
            color: color.infoHeaderNavText,
            fontSize: widthS * 8,
            marginLeft: widthS * 3,
            flex: 1,
            fontFamily: "Catamaran_300Light"
        },
        playersName: {
            paddingLeft: widthS * 20,
            fontSize: widthS * 9,
            fontFamily: "Catamaran_300Light"
        },
        btnNum: {
            width: widthS * 34,
            marginHorizontal: widthS * 0.5,
            marginVertical: widthS * 0.5,
            borderRadius: widthS * 6,
            alignItems: "center",
            justifyContent: "center"
        },
        expectedTableMain: {
            position: "relative",
            flex: 1,
            zIndex: 2
        },
        expectedTableMap: {
            flex: 1,
            width: "100%",
            backgroundColor: background.infoBox
        },
        expectedTableMapCompared: {
            borderBottomRightRadius: widthS * 15,
        },
        realTableMain: {
            position: "relative",
            flex: 1,
            zIndex: 1
        },
        realTableMap: {
            flex: 1,
            borderBottomRightRadius: widthS * 15,
            backgroundColor: background.infoBox,
            marginLeft: widthS * 10
        },
        tooltipText: {
            fontSize: "0.7vw",
            lineHeight: "1.6vh",
            fontFamily: "Catamaran_300Light",
            fontWeight: "600",
            maxWidth: "30vw",
        }
    })
}
