import React, { useMemo, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Image,
  ImageBackground,
} from "react-native";
import { Entypo, Feather } from "@expo/vector-icons";
import ListSharePost from "../social-media/ListSharePost";
import { useDispatch, useSelector } from "react-redux";
import { editOrNew, mailFlag, minimizeNewMail, setIndexCurrent, setNewMessageFlag, setTypeMinimize } from "../../../redux/actions";
import { t } from "i18n-js";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
// import { printPost } from "../../../redux/reducers/SocialMedia";
import { USER_GAME_NAME, USER_ID_IN_GAME } from "../../../config.inc";
import { vwToPx } from "../../modules/ConvertPXToVw";
import { GenerateId } from "../../modules/GenerateId";
import { editDate, getDateTimeObject } from "../../../EditDate";

export default function NewsPost({
  post,
  setIndexPost,
  index,
  zIndex,
  openNewNewsPost,
  markAsReadNewsPost,
}) {

  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(heightS, widthS);

  const shareBtnRef = useRef(null);

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const eventsState = useSelector((state) => state.events.events);
  const images = useSelector((state) => state.style.images);
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);
  const gameInfoState = useSelector(state => state.gameInfoArr.gameInfoArr.players);

  const gameInfoPlayers = useMemo(() => {
    let playersArr = [];
    gameInfoState?.forEach((e) => {
      if (e.permission_for_game === "15") {
        playersArr.push({ id: e.id, name: e.name_for_game });
      }
    });
    return playersArr;
  }, [gameInfoState]);

  const realplayers = useMemo(() => {
    return gameInfoState?.filter((e) => e.is_represented === "0" && e.permission_for_game === "15")
  }, [gameInfoState]);

  const [showListShare, setShowListshare] = useState(false);
  const [shareIds, setShareIds] = useState([]);
  const dispatch = useDispatch();
  const editPost = () => {
    dispatch(editOrNew("edit", post));
    openNewNewsPost();
  };

  const sharePost = (idPost, shareIds) => {
    if (shareIds.length > 0) {
      let names = [];
      gameInfoPlayers.forEach((e) => {
        if (shareIds.indexOf(e.id) >= 0) {
          names.push(e.name);
        }
      });
      let newMessage = {
        subject: "",
        post: <span id="news-post-mail" style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          maxWidth: "28vw",
          marginBottom: heightS * 19,
          zIndex: zIndex
        }}>
          <span style={{ display: "flex", position: "relative" }}>
            <img id="img-post-share" src={images.newsFake} style={{
              height: `${heightS * 40}px`,
              width: `${widthS * 56}px`,
              position: "absolute",
              resizeMode: "contain",
              top: `${heightS * -5}px`,
              left: `${widthS * 4}px`,
              zIndex: 3,
            }} />
            <span style={{
              backgroundImage: `url(${post.image})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "100%",
              height: `${vwToPx(15)}px`,
              display: "flex",
            }} >
              {isVideo(post.image) && (
                <video
                  style={{
                    width: "100%",
                    objectFit: "fill",
                    position: "absolute",
                    height: vwToPx(15),
                  }}
                  controls
                >
                  <source src={post.image} />
                </video>)}
              <span style={{ backgroundColor: background.newsLogo, position: "absolute", left: 0, alignSelf: "end", }}>
                <p style={{
                  color: "white",
                  fontSize: `${widthS * 11}px`,
                  fontFamily: "Catamaran_700Bold",
                  paddingBottom: `${heightS * 2}px`,
                  paddingTop: `${heightS * 4}px`,
                  lineHeight: `${widthS * 11}px`,
                  paddingLeft: `${widthS * 8}px`,
                  paddingRight: `${widthS * 10}px`,
                }}>{t("breaking-news")}</p>
                <span
                  style={{
                    width: 0,
                    height: 0,
                    top: 0,
                    backgroundColor: "transparent",
                    borderBottom: `${widthS * 17}px solid ${background.newsLogo}`,
                    borderRight: `${widthS * 16}px solid transparent`,
                    position: "absolute",
                    right: `${widthS * -15}px`,
                  }}
                />
              </span>
            </span>
          </span>
          <span
            style={{
              backgroundColor: background.newsPostBody,
              minHeight: `${heightS * 103.65}px`,
              paddingLeft: `${widthS * 8}px`,
              paddingTop: `${heightS * 8}`,
              paddingRight: `${widthS * 10.2}px`,
              borderBottomLeftRadius: `${widthS * 11}px`,
              position: "relative"
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                zIndex: 100
              }}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "10px"
                }}>
                <p
                  style={{
                    fontSize: `${widthS * 12.5}px`,
                    fontFamily: "Catamaran_800ExtraBold",
                    color: "#021c32",
                    lineHeight: `${heightS * 12}px`,
                    width: `${widthS * 250}px`,
                  }}>{post && post.title.toUpperCase()}</p>
                <p style={{
                  color: "#949494",
                  fontSize: `${widthS * 9.15}px`,
                  fontFamily: "Catamaran_400Regular",
                  lineHeight: `${heightS * 22}px`,
                }}>{post && (() => {
                  const { date, time } = getDateTimeObject(`${post.time} utc`);
                  return `${date} | ${time}`
                })()}</p>
              </span>
            </span>
            <span style={{ zIndex: 1 }}>
              <p style={{
                fontSize: `${widthS * 9}px`,
                fontFamily: "Catamaran_500Medium",
                marginTop: `${heightS * 3}px`,
                whiteSpace: 'pre-wrap',
                marginBottom: `${heightS * 10}px`,
                paddingRight: `${widthS * 8}px`,
                position: "relative",
                lineHeight: `${widthS * 9.5}px`
              }}>{post && post.text}</p>
            </span>
            <span
              style={{
                zIndex: showListShare ? -1 : 2,
                width: 0,
                height: 0,
                backgroundColor: "transparent",
                borderLeft: `${widthS * 25}px solid transparent`,
                borderBottom: `${widthS * 25}px solid ${background.newsRead}`,
                position: "absolute",
                right: 0,
                bottom: 0,
                zIndex: 1,
                color: "#021c32",
              }}
            />
            <span
              style={{
                zIndex: showListShare ? -1 : 2, position: "absolute",
                bottom: `${heightS * 2}px`,
                right: `${widthS * 2}px`,
                zIndex: 2,
                lineHeight: 0,
                padding: 0,
                margin: 0,
                background: "none",
                border: "none"
              }}
            >
              <img
                id="img-post-share"
                style={{
                  width: `${widthS * 13.2}px`,
                  height: `${heightS * 11.5}px`,
                  resizeMode: "contain",
                }}
                src={images.newsReadIcon}
              />
            </span>
          </span>
        </span>,
        to_list: shareIds.join(","),
        to_list_names: names.join(","),
        flag: false,
        event_id: eventsState[0]?.id,
        event: eventsState[0]?.name
      }
      if (playerPermission === "15") {
        newMessage.sender = {
          id: sessionStorage.getItem(USER_ID_IN_GAME),
          name: sessionStorage.getItem(USER_GAME_NAME),
        }
      }
      dispatch(setTypeMinimize({ name: "mail", type: 2, show: true, num: 9 }));
      dispatch(setIndexCurrent(9));
      dispatch(mailFlag("true"));
      dispatch(setNewMessageFlag(true));
      dispatch(minimizeNewMail({ show: true, maximize: false, id: GenerateId(), type: "news", message: newMessage }));
      setShowListshare(false);
      setShareIds([]);
    }
  };

  const isVideo = (media) => {
    // תחליף את התנאי הזה בהתאם לאיך שאתה זיהוי וידאו
    return media && media.endsWith('.mp4'); // לדוגמה
  };

  return (
    <View style={[styles.container, { zIndex: zIndex }]}>
      <View style={styles.top}>
        <Image source={{ uri: images.newsFake }} style={[styles.logo]} />
        <ImageBackground style={styles.background_img} source={isVideo(post.image) ? null : post.image}>
          {isVideo(post.image) && (
            <video
              style={{
                width: "100%",
                objectFit: "fill",
                position: "absolute",
                height: `${heightS * 134.5}px`,
              }}
              controls
            >
              <source src={post.image} />
            </video>)}
          <View style={{ backgroundColor: background.newsLogo }}>
            <Text seletable={false} style={styles.breaking_text}>{t("breaking-news")}</Text>
            <View
              style={[
                styles.breaking_triangular,
                { borderBottomColor: background.newsLogo },
              ]}
            />
          </View>
        </ImageBackground>
      </View>
      {/* bottom */}
      <View
        style={[styles.bottom, { backgroundColor: background.newsPostBody }]}
      >
        <View style={styles.body_top}>
          <View style={styles.body_top_right}>
            <Text seletable={false} style={styles.title}>{post && post.title.toUpperCase()}</Text>
            <Text seletable={false} style={styles.time}>{post &&
              (() => {
                const { date, time } = getDateTimeObject(`${post.time} utc`);
                return `${date} | ${time}`
              })()
            }</Text>
          </View>
          <View style={styles.body_top_left}>
            {/* <Pressable style={{marginRight: 5}} onPress={() => editPost()}>
  						<Feather name="edit" size={size * 18} color="#464646" />
  					</Pressable> */}
            {playerPermission !== "17" && playerPermission !== "10" && <Pressable
              ref={shareBtnRef}
              onPress={() => {
                setIndexPost();
                setShowListshare(!showListShare);
              }}
            >
              <Entypo name="share" size={widthS * 17} color="#353535" />
            </Pressable>}
            {showListShare && (
              <ListSharePost
                id={post.id}
                shareIds={shareIds}
                setShareIds={(ids) => setShareIds(ids)}
                shareAll={shareIds?.filter((id) => id.trim())?.length === gameInfoPlayers?.length}
                shareReal={realplayers.every(player => shareIds.includes(player.id))}
                showShareList={showListShare}
                buttonRef={shareBtnRef}
                setShowShareList={() => setShowListshare(false)}
                sharePost={sharePost}
                triangleStyle={{ left: widthS * 80 }}
              />
            )}
          </View>
        </View>
        <View style={styles.body_bottom}>
          <Text seletable={false} style={styles.body_text}>{post && post.text}</Text>
        </View>
        <View
          style={[
            styles.flag_view,
            {
              borderBottomColor:
                !post.is_read
                  ? background.newsUnread
                  : background.newsRead,
              zIndex: showListShare ? -1 : 2,
            },
          ]}
        />
        <Pressable
          style={[styles.flag_btn, { zIndex: showListShare ? -1 : 2 }]}
          onPress={() => !post.is_read && markAsReadNewsPost(post.id)}
        >
          <Image
            style={styles.flag_img}
            source={{ uri: images.newsReadIcon }}
          />
        </Pressable>
      </View>
    </View>
  );


}
const stylesR = (heightS, widthS) => {
  return StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "90%",
      marginBottom: heightS * 19,
    },
    top: {
      display: "flex",
    },
    logo: {
      height: heightS * 40,
      width: widthS * 56,
      position: "absolute",
      resizeMode: "contain",
      top: heightS * -5,
      left: widthS * 4,
      zIndex: 3,
    },
    background_img: {
      height: heightS * 134.5,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
    },
    breaking_triangular: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderRightWidth: widthS * 25,
      borderBottomWidth: widthS * 30,
      borderRightColor: "transparent",
      position: "absolute",
      right: widthS * -25,
    },
    breaking_text: {
      color: "white",
      fontSize: widthS * 11,
      fontFamily: "Catamaran_700Bold",
      paddingBottom: heightS * 2,
      paddingTop: heightS * 4,
      lineHeight: widthS * 11,
      paddingLeft: widthS * 8,
      paddingRight: widthS * 10,
    },
    bottom: {
      minHeight: heightS * 103.65,
      paddingLeft: widthS * 8,
      paddingTop: heightS * 8,
      paddingRight: widthS * 10.2,
      borderBottomLeftRadius: widthS * 11,
    },
    body_top: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      zIndex: 100
    },
    body_top_left: {
      display: "flex",
      flexDirection: "row",
    },
    body_top_right: {
      display: "flex",
      flexDirection: "column",
    },
    body_bottom: {
      zIndex: 1,
    },
    title: {
      fontSize: widthS * 12.5,
      fontFamily: "Catamaran_800ExtraBold",
      color: "#021c32",
      lineHeight: heightS * 12,
      width: widthS * 250,
    },
    time: {
      color: "#949494",
      fontSize: widthS * 9.15,
      fontFamily: "Catamaran_400Regular",
      lineHeight: heightS * 22,
    },
    body_text: {
      fontSize: widthS * 9,
      fontFamily: "Catamaran_500Medium",
      marginTop: heightS * 3,
      marginBottom: heightS * 10,
      paddingRight: widthS * 8,
      position: "relative",
      lineHeight: widthS * 9.5
    },
    flag_view: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderLeftWidth: widthS * 25,
      borderBottomWidth: widthS * 25,
      borderLeftColor: "transparent",
      position: "absolute",
      right: 0,
      bottom: 0,
      zIndex: 1,
      color: "#021c32",
    },
    flag_btn: {
      position: "absolute",
      bottom: heightS * 2,
      right: widthS * 2,
      zIndex: 2,
    },
    flag_img: {
      width: widthS * 13.2,
      height: heightS * 11.5,
      resizeMode: "contain",
    },
  });
}
