export const background = {
  /// login \\\
  linear: ["#7db9e800", "#021c3224", "#021c32"],
  login: "#020a1d",
  popupLogin: "#262129",
  loginInput: "#2f2b33",
  loginFooter: "1",
  buttonLogin: "black",
  buttonLoginHover: "1",
  gameListHover: "#2f2b33",
  //
  /// desktop \\\
  bottomMenu: "#1f1c2185",
  bottom: "#1f1c21",
  buttonMenuLeftHover: "#1c2632",
  count: "2",
  countBottom: "#de0807",
  //
  /// desktop-right \\\
  rightMenu: "#1f1c2199",
  rightMenuSearch: "#293746cc",
  rightMenuPopupSetting: "#2f2b33",
  rightMenuPopupSettingHover: "1",
  rightMenuPopupMailAndSocial: "#2f2b33",
  rightMenuPopupHeaderMailAndSocial: "#67676740",
  rightMenuPopupLogoSocial: "#314b7e",
  rightMenuPopupLogoMail: "1",
  //
  /// all pop-up's \\\
  popup1: "#262129",
  popup2: "#231b26",
  //
  /// simulation-Info \\\
  gameInfoLogo: "1",
  gameInfoCard: "#2f2b33",
  //
  /// action/challenges \\\
  actionLogo: "1",
  actionButtonAdd: "1",
  actionHover: "#2f2b33",
  actionCircle: "#000000b8",
  actionCircleActive: "2",
  actionProgress: "#000000b8",
  actionInput: "#312c34",
  actionSelect: "#312c34",
  actionBtnBack: "#253e52",
  //
  /// files/documents \\\
  filesLogo: "1",
  filesInp: "#2f2b33",
  filesDes: "#2f2b33",
  //
  /// social \\\
  socialLogoColor: "#2c4779",
  socialLogo: "1",
  socialSearch: "#2f2b33",
  socialAddPost: "#2f2b33",
  socialPost: "#e4e4e4",
  socialInput: "#eeeeee",
  socialCircle: "#a29ca2",
  socialCircleActive: "1",
  //
  /// news \\\
  news: "#890000",
  newsPopupHeader: "#252525",
  newsHeader: "#2e2e2e",
  newsLogo: "#890000",
  newsSearch: "#323232",
  newsPostBody: "#e4e4e4",
  newsRead: "1",
  newsUnread: "#2f2b33",
  newsTooltip: "#ffffff",
  newsTooltipBottom: "#262329",
  //
  /// Mail-Box/System-Messages \\
  mailLogoColor: "#0e8ed4",
  mailLogo: "1",
  mailBtnNew: "1",
  newMailItemHover: "#3875D7",
  mailPopupArrange: "#2f2b33",
  mailPopupArrangeHover: "#231e27",
  mailItemListRead: "#342d39",
  mailItemList: "#262129",
  mailSearch: "#2f2b33",
  mailBody: "#e4e4e4",
  mailPopup: "#f7f7f7",
  mailPopupItems: "#F3F2F2",
  //
  /// chat \\\
  chatLogoColor: "#3db874",
  chatLogo: "1",
  chatSearch: "#2f2b33",
  chatBody: "#2f2b33",
  chatMsgUser: "#a89da8",
  chatMsg: "#ffffff",
  chatMsgUserActive: "#2f2b33",
  chatSend: "#e8ebf4",
  chatCircle: "#a29ca2",
  chatCount: "2",
  chatCreatorBorder: "#535254",
  //
  /// setting \\\
  setting: "#2f2b33",
  closeBtn: "#877e88",
  saveSettingsBtn: "1",
  //
  /// build-simulation \\\
  leftInfo: "#29252d",
  buildActive: "#2b252f",
  buildNavLeft: "#211c22",
  infoHeader: "#201C21",
  infoBody: "#302B34",
  infoSaveBtn: "1",
  infoBox: "#262129",
  iconBackground: "#3c3641",
  realPlayersBackground: "#29252D",
  addPlayerIconBorder: "#C1B2BF",
  eventUnActive: "#2a262e",
  eventActive: "#252129",
  socialPostDisabled: "#68738A",
  lineHover: "#3D3743"
};
export const color = {
  /// login \\\
  loginPlacehokder: "#a69ba6",
  gameListHover: "2",
  createNewGame: "#bbb2bb",
  //
  /// desktop \\\
  buttonMenuLeft: "1",
  buttonMenuBottom: "#312c34",
  bottomMenuBorder: "#1f1c2100",
  //
  /// desktop-right \\\
  rightMenuPopupSetting: "#edeaea",
  rightMenuSearch: "#314c59",
  carouselDot: "#00628a",
  carouselDotActive: "1",
  rightMenuGameNameAndUserName: "1",
  rightMenuClockAndDate: "gray",
  //
  /// all-pop-up's \\\
  border: "#3c3742",
  x: "#a29ca2",
  text: "#ffffff",
  text2: "#f7edf6",
  placeholder: "#A99BA8",
  //
  /// simulation-Info \\\
  gameInfoGameStart: "2",
  gameInfoStory: "#a29ca2",
  gameInfoPlayerDes: "#a29ca2",
  gameInfoNameS: "#989fa9",
  gameInfoBorderCircle: "1",
  //
  /// action/challenges \\\
  actionTh: "2",
  actionItemHover: "1",
  actionProgressDot1: "2",
  actionProgressDot2: "#7f5180",
  actionProgressDot3: "#583858",
  actionBtnBackBorder: "#ffffff",
  actionChoose: "2",
  //
  /// files/documents \\\
  filesText: "#edeaea",
  //
  /// social \\\
  socialBorder: "#cfcfcf",
  socialSearch: "#a69ba6",
  socialShareWith: "#4f565f",
  socialNewPostIcons: "#4f565f",
  socialPostBorder: "#d5d7dc",
  socialCommentBorder: "#b7b7b7",
  //
  /// news \\\
  newsSearch: "#5a5a5a",
  newsBorderSearch: "#444343",
  //
  /// Mail-Box/System-Messages \\\
  mailText: "white",
  mailNav: "#a69ba6",
  mailItemRead: "#837c8c",
  mailFooter: "1",
  mailBodyBorder: "#9a9a9a",
  mailSearch: "#a69ba6",
  mailAttachName: "1",
  mailBorder: "#877e88",
  mailBorder2: "#bdb8be",
  //
  /// chat \\\
  chatSearch: "#a69ba6",
  //
  /// setting \\\
  setting: "#324456",

  //
  /// build \\\
  buildNav: "#ab9ca9",
  buildNavActive: "#f2e6f0",
  buildTitle: "#a99ba8",
  logoColor: "1",
  infoHeaderNavText: "#F8EBF6",
  infoHeaderNavBorder: "2",
  infoSaveBtn: "3",
  buildText: "#F8EBF6",
  infoBorder: "#3D3743",
  activeStatus: "#25af94",
  inactiveStatus: "#FCF000",
  registeredStatus: "#de82d8",
  addPlayerPopupShadow: "#000000D9",
  addPlayerHeaderBorderColor: "#6b788659",
  eventsIcons: "#9a8f9b"
  //
};
