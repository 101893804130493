import { SET_ERROR_MESSAGE } from "../actions";

let errorMessageTemplate = { flag: false, title: "", body: "", deleteDraftHandler: () => { }, success: false };
export const errorReducer = (
  state = { errorMessage: [] },
  action
) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE: {
      if(action.flag){
         let errorMessage = {
        ...errorMessageTemplate, flag: action.flag,
        title: action.title,
        body: action.body,
        deleteDraftHandler: action.deleteDraftHandler,
        clickOkFunc: action.clickOkFunc,
        success: action.success
      }
      state.errorMessage.unshift(errorMessage);
      }else{
        state.errorMessage.pop();
      }
      return {
        errorMessage: [...state.errorMessage]
      };
    }

    default: {
      return state;
    }
  }
};
