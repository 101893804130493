import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import NewsCarrousel from "./NewsCarousel";
import SocialCarousel from './SocialCarousel';
import MailCarousel from "./MailCarousel";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";

export default function IndexCarousel() {

	const { width, height } = UseResponsiveSize();
	const { widthS, heightS } = UseResponsiveScreen();
	const styles = stylesR(width, height);

	const color = useSelector(state => state.style.color);
	const background = useSelector(state => state.style.background);

	const news = useSelector((state) => state.newsArr.unReadNewsArr);
	const social = useSelector((state) => state.socialMedia.unReadPostsArr);
	const mail = useSelector((state) => state.mailArr.unReadMessagesArr);
	const lang = useSelector(state => state.setting.setting.lang);
	
	const newsPrint = useMemo(() => news, [news]);
	const socialPrint = useMemo(() => social, [social]);
	const mailPrint = useMemo(() => mail, [mail]);

	const search = (arr, txt) => {
		let res = [];
		if (txt.length > 0) {
			if (arr.length > 0) {
				arr.forEach((e) => {
					let flag = false;
					let keys = Object.keys(e);
					keys.forEach((key) => {
						if (typeof e[key] === 'string' && key.indexOf("id") < 0 && e[key].toLowerCase().indexOf(txt.toLowerCase()) >= 0) {
							flag = true;
						}
					});
					if (flag) {
						res.push(e);
					}
				});
			}
			return res;
		}
		return arr;
	}
	const deleteItem = (arr, index) => {
		let temp = [...arr];
		temp = temp.filter((e, i) => i !== index);
		return temp;
	}

	return (
		<View style={styles.container}>
			{/* <View style={[styles.search_c, { borderBottomWidth: newsPrint.length <= 0 && socialPrint.length <= 0 && mailPrint.length <= 0 ? 1 : 0, borderBottomColor: color.border }]}>
				<View style={[styles.search_view, {backgroundColor: background.rightMenuSearch, borderColor: color.rightMenuSearch}]}>
					<TextInput
						placeholderTextColor={color.rightMenuSearch}
						style={[styles.search_inp, { direction: lang === "he" ? "rtl" : "ltr", color: color.rightMenuGameNameAndUserName }]}
						placeholder={`${t("search")}...`}
						onChangeText={(txt) => searchMain(txt)}
					/>
					<EvilIcons
						style={styles.search_icon}
						name="search"
						size={widthS * 18}
						color={color.rightMenuSearch}
					/>
				</View>
			</View> */}
			{newsPrint && newsPrint.length > 0 && (
				<View style={{ paddingTop: heightS * 5, borderTopColor: color.border }}>
					<NewsCarrousel data={newsPrint} />
				</View>
			)}
			{socialPrint && socialPrint.length > 0 && (
				<View style={newsPrint.length > 0 ? [styles.view_items, { borderTopColor: color.border }] : []}>
					<SocialCarousel key={socialPrint[0]?.id} data={socialPrint} />
				</View>
			)}
			{mailPrint && mailPrint.length > 0 && (
				<View style={socialPrint.length > 0 ? [styles.view_items, { borderTopColor: color.border }] : []}>
					<MailCarousel key={mailPrint[0]?.id} data={[...mailPrint]} />
				</View>
			)}
		</View>
	);
}
const stylesR = (width, height, heightS, widthS) => {
	return StyleSheet.create({
		container: {
			display: "flex",
			flexDirection: "column",
		},
		// search_c: {
		// 	display: "flex",
		// 	alignItems: "center",
		// 	paddingRight: widthS * 25,
		// },
		// search_view: {
		// 	width: widthS * 205,
		// 	borderWidth: 1,
		// 	borderRadius: 3,
		// 	display: "flex",
		// 	flexDirection: "row",
		// 	alignItems: "center",
		// 	paddingRight: widthS * 2,
		// 	marginVertical: heightS * 6
		// },
		// search_inp: {
		// 	padding: widht * 2,
		// 	paddingLeft: widthS * 10,
		// 	fontSize: widthS * 9,
		// 	fontWeight: "500",
		// 	width: widthS * 195,
		// 	outlineStyle: "none"
		// },
		view_items: {
			borderTopWidth: 2,
			paddingBottom: heightS * 5,
		},
	});
}
