import { Dimensions, Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import React, { useMemo, useRef, useState } from 'react';
import PieChart from '../../../../modules/Pie_chart';
import { Text as TextSvg, G, Line, Circle, } from 'react-native-svg';
import { useSelector } from 'react-redux';
import ShortenWord from "../../../../modules/ShortenWord";
import { t } from 'i18n-js';
import { vwToPx } from "../../../../modules/ConvertPXToVw";
import { useClickOutside } from '../../../../modules/useClickOutside';
import "./style.css";

export default function TooltipQuestionnaire({ tooltip, colors, latters }) {

    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(color, background, direction);

    const popupRef = useRef(null);

    const players = useSelector(state => state.gameInfoArr.gameInfoArr.players);

    const [activeIndex, setActiveIndex] = useState();
    const [isActive, setIsActive] = useState();

    const pieData = tooltip?.data?.answers?.map((answer, index) => ({
        value: answer?.players?.split(",")?.length || 0,
        svg: { fill: colors[index], },
        key: index,
        arc: { outerRadius: `${activeIndex?.index === index ? 75 : 70}%`, padAngle: 0, },
        hoverIn: (index) => handleMouseOver(index),
        hoverOut: () => handleMouseOut(),
        onClick: (index) => { setIsActive(true); handleMouseOver(index) }
    }));

    const handleMouseOver = (index) => {
        setActiveIndex(index);
    };

    const handleMouseOut = () => {
        if (!isActive) setActiveIndex(null);
    };

    const Labels = ({ slices }) => {
        let total = 0;
        if (tooltip.data.answers != null || tooltip.data.answers != undefined) {
            tooltip.data?.answers?.forEach((e) => {
                total += e?.players?.split(",")?.length || 0;
            })
            return slices.map((slice, index) => {
                const { labelCentroid, pieCentroid, data } = slice;
                let labelText = (data.value / total) * 100;
                labelText = !Number.isInteger(labelText) ? `${labelText.toFixed(1)}%` : `${labelText}%`;
                if (data.value === 0) return null;
                return (
                    <G key={index}>
                        <Line
                            x1={labelCentroid[0]}
                            y1={labelCentroid[1]}
                            x2={pieCentroid[0]}
                            y2={pieCentroid[1]}
                            stroke={"white"}
                        />
                        <TextSvg
                            x={labelCentroid[0]}
                            y={labelCentroid[1]}
                            fill={"white"}
                            textAnchor={"middle"}
                            fontSize={"0.75vw"}
                            fontStyle={"Catamaran_300Light"}
                        >
                            {labelText}
                        </TextSvg>
                    </G>
                )
            })
        }

    }

    const popup = (i) => (
        <View ref={popupRef} style={styles.popupContainer} nativeID='filter-shadow'>
            <View style={styles.triangle} />
            <View style={{ width: "100%", alignItems: "center" }}>
                <Text style={styles.popupTitleText()}>
                    <View style={styles.keyCircle(colors[i], true)} />
                    {t("title_popup_pie_chart").toUpperCase()} {`"${latters[i]}"`}
                </Text>
                <ScrollView nativeID='scrollPopup' style={styles.scrollPopup} contentContainerStyle={styles.scrollMainPopup}>
                    {(() => {
                        return tooltip?.data?.answers[activeIndex?.index]?.players?.split(",").map((id, index) => {
                            return (
                                <Text style={styles.popupTitleText(true)} key={index}>{ShortenWord(players?.find((e) => e.id === id)?.name_for_game, 28)}</Text>
                            )
                        })
                    })()}
                </ScrollView>
            </View>
        </View>
    );

    useClickOutside(popupRef, isActive, () => { setIsActive(); setActiveIndex() });

    return (
        <View style={styles.container}>
            <View style={styles.pieView}>
                {pieData && <PieChart
                    style={{
                        height: vwToPx(10),
                        width: vwToPx(12),
                        position: "relative",
                        transition: "all 10s"
                    }}
                    data={pieData}
                    outerRadius={"100%"}
                    innerRadius={"48%"}
                    labelRadius={"90%"}
                    padAngle
                >
                    <Labels />
                </PieChart>}
                <Text style={styles.centerText} numberOfLines={1}>
                    {t("answers").toUpperCase()}
                </Text>
            </View>
            {activeIndex && popup(activeIndex.index)}
        </View>
    )
}

const stylesR = (color, background, direction) => StyleSheet.create({
    pieView: {
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
    },
    keysView: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center"
    },
    keyView: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: "0.3vw"
    },
    keyText: {
        fontSize: "0.7vw",
        color: color.text2,
        fontFamily: "Catamaran_300Light"
    },
    keyCircle: (color, flag) => ({
        backgroundColor: color,
        width: "0.6vw",
        height: "0.6vw",
        borderRadius: "100%",
        marginHorizontal: "0.1vw",
        marginRight: flag ? "0.5vw" : ""
    }),
    center: {
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    centerText: {
        fontSize: "0.8vw",
        color: "white",
        maxWidth: "4vw",
        position: "absolute",
        fontFamily: "Catamaran_300Light"
    },
    popupContainer: {
        padding: "0.5vw",
        alignItems: "center",
        width: "14vw",
        position: "absolute",
        top: "0",
        ...(direction === "ltr" ? { right: "18vw", } : { left: "18vw" }),
        borderRadius: "1vw",
        backgroundColor: background.infoBox
    },
    triangle: {
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
        borderLeftWidth: "0.8vw",
        borderRightWidth: "0.8vw",
        borderBottomWidth: "0.9vw",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottomColor: background.infoBox,
        position: "absolute",
        ...(direction === "ltr" ? { right: "-1vw" } : { left: "-1vw" }),
        top: "3vw",
        transform: [{ rotate: direction === "ltr" ? "90deg" : "-90deg" }],
    },
    popupTitleText: (flag) => ({
        fontSize: "0.8vw",
        color: "white",
        fontFamily: "Catamaran_300Light",
        marginLeft: flag ? "1.2vw" : ""
    }),
    scrollPopup: {
        maxHeight: "12vw",
        minHeight: "5vw",
        width: "100%"
    }
})