import React, { useState, useEffect, useRef, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, TextInput, Image, useWindowDimensions, TouchableOpacity } from 'react-native';
import TextCustomize from '../../../../Elements/TextCustomize';
import { vwToPx } from '../../../../modules/ConvertPXToVw';
import { listenerAdd } from '../../../../../AddListener';

const SubjectCell = ({ cellStyle, index, subjectText, setIsPopupOpen, setPopupRef, updateAction, isBottom, isNew, isDone }) => {

    const cellRef = useRef(null);
    const inputRef = useRef(null);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background, color);

    const [showPopup, setShowPopup] = useState(false);
    const [numOfLines, setNumOfLines] = useState(null);
    const [subject, setSubject] = useState("");

    const handleLayout = (event) => {
        if (numOfLines === null) {
            const { height } = event.nativeEvent.layout;
            const lineHeight = vwToPx(1.2); // This should be equal to the line-height of the text
            const lines = Math.round(height / lineHeight);
            setNumOfLines(lines);
        }
    }

    useEffect(() => {
        setSubject(subjectText);
    }, [subjectText]);

    useEffect(() => {
        document.addEventListener("mousedown", listenerAdd(cellRef, showPopup, () => setShowPopup(false)));
        return () => {
            document.removeEventListener("mousedown", listenerAdd(cellRef, showPopup, () => setShowPopup(false)));
        }
    }, [cellRef.current, showPopup]);
    return (
        <View style={cellStyle("18vw")} ref={cellRef}>
            <Pressable disabled={isDone} onPress={() => { setIsPopupOpen(index); setShowPopup(true); }} style={styles.btnContainer}>
                <TextCustomize onLayout={handleLayout} fontSize={"0.8vw"} style={styles.subject(direction)} numberOfLines={2} text={subject} />
            </Pressable>
            {showPopup && <View ref={setPopupRef} style={styles.popup(showPopup, isBottom && isNew)}>
                <TextInput
                    ref={inputRef}
                    numberOfLines={numOfLines}
                    multiline={true}
                    style={styles.subjectInput(direction)}
                    onChangeText={(txt) => setSubject(txt)}
                    value={subject}
                    onFocus={() => inputRef.current && inputRef.current.setNativeProps({ selection: { start: subject.length } })}
                />
                <View style={styles.bottomBtns}>
                    <TouchableOpacity style={styles.cancelBtn} onPress={() => { setShowPopup(false); setIsPopupOpen(""); }}>
                        <TextCustomize fontWeight={400} fontSize={"0.9vw"} keyText={"cancel"} />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.saveBtn} disabled={isDone} onPress={() => { updateAction(index, { subject: subject }, true, isNew); setShowPopup(false) }}>
                        <TextCustomize fontWeight={400} fontSize={"0.9vw"} keyText={"save"} />
                    </TouchableOpacity>
                </View>
            </View>}
        </View>
    )
};
const stylesR = (background, color) => StyleSheet.create({
    btnContainer: {
        flex: 1,
        height: "100%",
        paddingVertical: "0.5vw"
    },
    subjectInput: (direction) => ({
        outlineStyle: "none",
        fontFamily: "Catamaran_400Regular",
        backgroundColor: background.infoBox,
        borderRadius: "0.8vw",
        margin: "1vw",
        paddingHorizontal: "1vw",
        paddingVertical: "0.5vw",
        fontSize: "0.8vw",
        color: color.text,
        flex: 1,
        height: "fit-content",
        overflow: "hidden",
        lineHeight: "1.2vw",
        writingDirection: direction
    }),
    subject: (direction) => ({
        writingDirection: direction,
        lineHeight: "1.2vw"
    }),
    popup: (flag, isBottom) => ({
        width: "18vw",
        position: "absolute",
        backgroundColor: background.infoBody,
        borderRadius: "0.8vw",
        display: flag ? "" : "none",
        opacity: flag ? 1 : 0,
        transition: "all .4s",
        shadowColor: "black",
        shadowOffset: { height: 0, width: 0 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        ...(!isBottom ? {
            top: '0.8vw',
            bottom: 'auto'
        } : {
            top: 'auto',
            bottom: '0.8vw'
        }),
    }),
    bottomBtns: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        marginHorizontal: "1vw",
        paddingVertical: "0.5vw",
        borderTopColor: color.border,
        borderTopWidth: "0.1vw"
    },
    saveBtn: {
        backgroundColor: color.logoColor,
        paddingHorizontal: "0.7vw",
        paddingVertical: "0.2vw",
        borderRadius: "0.6vw"
    },
    cancelBtn: {
        paddingHorizontal: "0.7vw",
        paddingVertical: "0.2vw",
    }
});
export default memo(SubjectCell);