import React, { useState, useEffect, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, Image, TextInput, Text, ScrollView, TouchableOpacity } from 'react-native';
import TextCustomize from '../../../../Elements/TextCustomize';
import { AntDesign } from '@expo/vector-icons';
import { listenerAdd } from '../../../../../AddListener';
import { loading } from '../../../../../redux/actions';
import { t } from 'i18n-js';
import Checkbox from '../../../../Elements/CheckBox';
import { GameInfoContext } from '../../../../../ContextData/GameInfo';
import { useContext } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';

const PlayersCell = React.memo(({ width, keyIds, keyName, disabeld, isDone, type, popupRef, setPopupRef, isFrom, updateAction, isNew, actionsArrState, cellStyle, index, isBottom, setIsPopupOpen }) => {

    const dispatch = useDispatch();
    const { addPlayerHandler } = useContext(GameInfoContext);

    const { images, background, color, gameInfo } = useSelector(state => ({
        images: state.style.images,
        background: state.style.background,
        color: state.style.color,
        gameInfo: state.gameInfoArr.gameInfoArr
    }));

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = useMemo(() => stylesR(background, color), [background, color]);

    const cellRef = useRef(null);
    const btnRef = useRef(null);

    const [showOptions, setShowOptions] = useState(false);
    const [popupIndex, setPopupIndex] = useState(0);

    const [allPlayers, setAll] = useState(false);
    const [allReal, setAllReal] = useState(false);


    const [playersToPrint, setPlayersToPrint] = useState([]);
    const [realPlayers, setRealPlayers] = useState([]);
    const [repPlayers, setRepPlayers] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [keyIdState, setKeyIdState] = useState("");
    const [keyNamesState, setKeyNamesState] = useState("");

    const ensureCommaAtEnd = useCallback((inputString) => {
        if (inputString.charAt(inputString.length - 1) !== ',') {
            return inputString + ',';
        } else {
            return inputString;
        }
    }, []);

    const addPlayer = (text) => {
        addPlayerHandler({
            name_for_game: text,
            represented: "on",
        }).then((res) => {
            setSearchText("");
            if (isFrom) {
                closePopup(true, res.id, res.name_for_game,);
                return;
            } else {
                closePopup(false, ensureCommaAtEnd(actionsArrState[keyIds]) + `${res.id}, `, ensureCommaAtEnd(actionsArrState[keyName]) + `${res.name_for_game}, `);
                return;
            }
        }).catch(() => {
        });
    }

    const findNameUser = useCallback((id, full) => {
        const player = playersToPrint.find(user => user.id === id);
        if (player) {
            return full ? player : player.name_for_game;
        }
        return null;
    }, [playersToPrint]);

    const addOrRemoveName = useCallback((id, name, all) => {
        let temp;
        let tempNames;
        if (all) {
            switch (all) {
                case "real":
                    if (!allReal) {
                        temp = actionsArrState[keyIds] || "";
                        tempNames = actionsArrState[keyName] || "";
                        realPlayers.forEach((e) => {
                            if (temp?.indexOf(e.id) < 0) {
                                temp += `${e.id}, `;
                                tempNames += `${e.name_for_game}, `;
                            }
                        });
                        setAllReal(true);
                        closePopup(true, temp, tempNames);
                        return;
                    } else {
                        temp = actionsArrState[keyIds];
                        tempNames = actionsArrState[keyName];
                        realPlayers.forEach((e) => {
                            if (temp?.indexOf(e.id) >= 0) {
                                temp = temp.replace(`${e.id},`, "").replace(`${e.id} ,`, "").replace(`${e.id}`, "");
                                tempNames = tempNames.replace(`${e.name_for_game},`, "").replace(`${e.name_for_game} ,`, "").replace(`${e.name_for_game}`, "");
                            }
                        });
                        setAllReal(false);
                        setAll(false);
                        closePopup(true, temp, tempNames);
                        return;
                    }
                case "all":
                    if (!allPlayers) {
                        temp = actionsArrState[keyIds];
                        tempNames = actionsArrState[keyName];
                        repPlayers.forEach((e) => {
                            if (temp?.indexOf(e.id) < 0) {
                                temp += `${e.id}, `;
                                tempNames += `${e.name_for_game}, `;
                            }
                        });
                        realPlayers.forEach((e) => {
                            if (temp?.indexOf(e.id) < 0) {
                                temp += `${e.id}, `;
                                tempNames += `${e.name_for_game}, `;
                            }
                        });
                        setAll(true);
                        setAllReal(true);
                        closePopup(true, temp, tempNames);
                        return;
                    } else {
                        setAll(false);
                        setAllReal(false);
                        closePopup(false, "", "");
                        return;
                    }
            }
        }
        if (isFrom) {
            closePopup(true, id, name);
            return;
        }
        if (keyIdState?.indexOf(id) >= 0) {
            temp = keyIdState.replace(`${id},`, "").replace(`${id} ,`, "").replace(`${id}`, "");
            tempNames = keyNamesState.replace(`${name},`, "").replace(`${name} ,`, "").replace(`${name}`, "");
            if (showOptions) {
                setKeyIdState(temp);
                setKeyNamesState(tempNames);
            } else {
                const updates = {
                    [keyIds]: temp,
                    [keyName]: tempNames
                };
                updateAction(index, updates, true, isNew);
            }
        } else {
            temp = keyIdState;
            temp = ensureCommaAtEnd(temp) + `${id}, `;
            setKeyIdState(temp);
            tempNames = keyNamesState;
            tempNames = ensureCommaAtEnd(tempNames) + `${name}, `;
            setKeyNamesState(tempNames);
        }
    }, [allReal, actionsArrState, realPlayers, repPlayers, isFrom, keyIdState, keyNamesState, showOptions]);

    const printPlayers = () => {
        if (disabeld) {
            return <TextCustomize numberOfLines={1} fontSize={"0.8vw"} text={"- - -"} />;
        }
        else if (!type || type?.length === 0) {
            return <TextCustomize numberOfLines={1} fontSize={"0.8vw"} text={`${t("add")} ${t("player")}`} />;
        }
        else {
            if (isFrom) {
                return <TextCustomize numberOfLines={1} fontSize={"0.8vw"} text={findNameUser(type)} />;
            } else {
                return (
                    <View style={styles.playersView}>
                        {actionsArrState[keyIds].split(",").map((id, i) => {
                            id = id.trimStart().trimEnd();
                            if (id?.length > 0) {
                                let player = findNameUser(id, true);
                                const uniqueKey = `${id}-${i}`;
                                if (player) {
                                    return <View key={uniqueKey} style={styles.playerView(direction)}>
                                        <View style={styles.playerInfo}>
                                            <TextCustomize numberOfLines={1} fontSize={"0.7vw"} fontWeight={700} text={player?.name_for_game} />
                                        </View>
                                        <TouchableOpacity disabled={isDone} onPress={() => addOrRemoveName(player?.id, player?.name_for_game)}>
                                            <Image source={{ uri: images.plusIcon }} style={styles.iconX(direction)} />
                                        </TouchableOpacity>
                                    </View>
                                }
                            }
                        })}
                    </View>
                )
            }
        }

    }

    const closePopup = useCallback((close, temp, tempNames) => {
        let updates = {}; // אובייקט לאגירת כל העדכונים
        if (temp !== actionsArrState[keyIds]) {
            if (keyName === "to_list") {
                setKeyIdState(temp);
                setKeyNamesState(tempNames);
                updates[keyIds] = temp;
                updates[keyName] = tempNames;
            } else if (isFrom) {
                updates["from_id"] = temp;
                updates["from_name"] = tempNames;
            } else {
                updates[keyIds] = temp;
                updates[keyName] = tempNames;
            }
        }
        if (Object.keys(updates).length > 0) {
            updateAction(index, updates, !close ? false : !isNew, isNew);
        }
        if (close) {
            setKeyIdState("");
            setKeyNamesState("");
            setShowOptions(false);
            setIsPopupOpen("");
            setPopupRef(null);
            setSearchText("");
        }
    }, [actionsArrState, keyName, keyIds, isFrom, index]);

    useEffect(() => {
        setRealPlayers([...playersToPrint.filter((e) => e.is_represented === "0" && e.permission_for_game === "15" && e.name_for_game.toLowerCase()?.indexOf(searchText.toLowerCase()) >= 0)]);
        setRepPlayers([...playersToPrint.filter((e) => e.is_represented === "1" && e.permission_for_game === "15" && e.name_for_game.toLowerCase()?.indexOf(searchText.toLowerCase()) >= 0)]);

    }, [playersToPrint, searchText]);

    useEffect(() => {
        if (gameInfo?.players) {
            setPlayersToPrint(gameInfo.players);
        }
    }, [gameInfo]);

    useEffect(() => {
        setKeyIdState(actionsArrState[keyIds]);
        setKeyNamesState(actionsArrState[keyName]);
    }, [actionsArrState, showOptions]);

    useEffect(() => {
        const listener = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.toElement)) {
                closePopup(true, keyIdState, keyNamesState);
            }
        };
        if (showOptions) {
            window.addEventListener("mousedown", listener);
        }
        return () => {
            window.removeEventListener("mousedown", listener);
        };
    }, [showOptions, popupRef, keyIdState, keyNamesState]);


    return (
        <View style={cellStyle(width, showOptions)} ref={cellRef} key={index}>
            <Pressable style={styles.main(disabeld)} disabled={disabeld || isDone} onPress={() => { setIsPopupOpen(index); setShowOptions(true); }}>
                <View style={styles.textName}>
                    {((!type || type?.length === 0) && !disabeld) && <Image source={{ uri: images.plusIcon }} style={styles.icon(true, "0.5vw")} />}
                    {printPlayers()}
                </View>
            </Pressable>
            {showOptions &&
                <View nativeID='popup-players-table' ref={setPopupRef} style={styles.popup(showOptions, isBottom, direction)}>
                    <TextInput style={styles.search(direction)} value={searchText}
                        onChangeText={(txt) => setSearchText(txt)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && realPlayers.length === 0 && repPlayers.length === 0) {
                                addPlayer(e.target.value);
                            }
                        }}
                    />
                    <ScrollView
                        nativeID="social"
                        style={styles.scroll_c}
                        stickyHeaderIndices={[0]}
                    >
                        {/* <View style={styles.container_nav}>
                    <View style={styles.navView}>
                        <Pressable disabled={isDone} onPress={() => setPopupIndex(0)} style={styles.navBtn(true)}>
                            <TextCustomize keyText={"real_players"} fontSize={"0.9vw"} style={popupIndex === 0 && styles.textNav} />
                        </Pressable>
                        <Text style={styles.borderNav}>|</Text>
                        <Pressable disabled={isDone} onPress={() => setPopupIndex(1)} style={styles.navBtn()}>
                            <TextCustomize keyText={"represented"} fontSize={"0.9vw"} style={popupIndex === 1 && styles.textNav} />
                        </Pressable>
                    </View>
                </View> */}
                        <View style={styles.body}>

                            {!isFrom && <TextCustomize numberOfLines={1} fontSize={"1vw"} style={{ marginHorizontal: "0.5vw", width: "6vw", direction: direction }} fontWeight={600} keyText={"groups"} />}
                            {!isFrom && <Pressable disabled={isDone} style={styles.item}
                                onPress={() => addOrRemoveName("", "", "all")}
                            >
                                {!isFrom && <Checkbox
                                    checked={allPlayers || (keyIdState?.length > 0 && keyIdState.split(",").length - 1 === (realPlayers.length + repPlayers.length))}
                                    isDone={false}
                                    background={background.infoBox}
                                    colorFill={background.count} />}
                                <TextCustomize numberOfLines={1} fontSize={"0.8vw"} style={{ marginHorizontal: "0.5vw", width: "12vw", direction: direction }} keyText={"all_players"} />
                            </Pressable>}
                            {!isFrom && <Pressable disabled={isDone} style={styles.item}
                                onPress={() => addOrRemoveName("", "", "real")}
                            >
                                {!isFrom && <Checkbox
                                    checked={allReal || (keyIdState?.length > 0 && keyIdState.split(",").length - 1 === realPlayers.length)}
                                    isDone={false}
                                    background={background.infoBox}
                                    colorFill={background.count} />}
                                <TextCustomize numberOfLines={1} fontSize={"0.8vw"} style={{ marginHorizontal: "0.5vw", width: "12vw", direction: direction }} keyText={"real_players"} />
                            </Pressable>}
                            {/* real players */}
                            <TextCustomize numberOfLines={1} fontSize={"1vw"} style={{ marginHorizontal: "0.5vw", width: "6vw", direction: direction }} fontWeight={600} keyText={"real_players"} />
                            {realPlayers.map((player, i) => {
                                if (isFrom && type === player.id || actionsArrState["from_id"] === player.id) return;
                                return <TouchableOpacity disabled={isDone} key={i + player.id} style={styles.item}
                                    onPress={() => { addOrRemoveName(player.id, player.name_for_game) }}
                                >
                                    {!isFrom && <Checkbox
                                        checked={keyIdState?.indexOf(player.id) >= 0}
                                        isDone={false}
                                        background={background.infoBox}
                                        colorFill={background.count} />}
                                    <TextCustomize numberOfLines={1} fontSize={"0.8vw"} style={{ width: "6vw", marginHorizontal: "0.5vw", direction: direction }} text={player?.name_for_game} />
                                </TouchableOpacity>
                            })}
                            {/* rep players */}
                            <TextCustomize numberOfLines={1} fontSize={"1vw"} style={{ marginHorizontal: "0.5vw", width: "10vw", direction: direction }} fontWeight={600} keyText={"rep_players"} />
                            {repPlayers.map((player, i) => {
                                if (isFrom && type === player.id || actionsArrState["from_id"] === player.id) return;
                                return <Pressable disabled={isDone} key={player.id} style={styles.item}
                                    onPress={() => addOrRemoveName(player.id, player.name_for_game)}
                                >
                                    {!isFrom && <Checkbox
                                        checked={keyIdState?.indexOf(player.id) >= 0}
                                        isDone={false}
                                        background={background.infoBox}
                                        colorFill={background.count} />}
                                    <TextCustomize numberOfLines={1} style={{ marginHorizontal: "0.5vw", width: "12vw", direction: direction }} fontSize={"0.8vw"} text={player?.name_for_game} />
                                </Pressable>
                            })}
                        </View>
                    </ScrollView>
                </View>
            }
        </View>
    )
}, (prevProps, nextProps) => {
    let shouldUpdate = true; // לא צריך רינדור מחדש
    Object.entries(nextProps).forEach(([key, val]) => {
        if (prevProps[key] !== val) {
            shouldUpdate = false; // אם יש שינוי, עדכן את הפרופס
        }
    });
    return shouldUpdate; // החזר true עבור מניעת רינדור מחדש, false לרינדור
});

const stylesR = (background, color) => StyleSheet.create({
    main: (flag) => ({
        height: "100%",
        flex: 1,
        opacity: flag ? 0.2 : 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    }),
    icon: (flag, width, colorIcon) => ({
        width: width,
        height: width,
        tintColor: colorIcon ? colorIcon : color.text,
        transform: colorIcon ? [{ scale: "45deg" }] : [],
        marginHorizontal: colorIcon ? "0.5vw" : "0"
    }),
    btnsView: (flag) => ({
        height: "100%",
        alignItems: "center",
        justifyContent: flag ? "center" : "space-between",
        paddingVertical: "0.3vw"
    }),
    addBtn: {
        backgroundColor: background.infoBody,
        borderRadius: "100%",
        width: "1.3vw",
        height: "1.3vw",
        alignItems: "center",
        justifyContent: "center"
    },
    textName: {
        flexDirection: "row",
        alignItems: "center",
        flex: 1
    },
    playersView: {
        paddingTop: "0.3vw",
        flexDirection: "row",
        flexWrap: "wrap",
        rowGap: "0.3vw",
        columnGap: "0.3vw",
        flex: 1,
        maxHeight: "3.5vw",
        overflow: "hidden",
    },
    playerView: (direction) => ({
        ...(direction === "rtl" ? { paddingRight: "0.3vw", } : { paddingLeft: "0.3vw", }),
        paddingVertical: "0.1vw",
        borderRadius: "0.4vw",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        minWidth: "4vw",
        backgroundColor: background.lineHover
    }),
    playerInfo: {
        flexDirection: "row",
        alignItems: "center",
    },
    iconX: (direction) => ({
        tintColor: background.infoBox,
        transform: [{ rotate: "45deg" }],
        ...(direction === "rtl" ? {
            marginRight: "0.5vw",
            marginLeft: "0.3vw",
        } : {
            marginRight: "0.3vw",
            marginLeft: "0.5vw",
        }),
        width: "0.7vw",
        height: "0.7vw"
    }),
    popup: (flag, isBottom, direction) => ({
        width: "18vw",
        position: "absolute",
        backgroundColor: background.infoBody,
        borderRadius: "0.8vw",
        display: flag ? "" : "none",
        opacity: flag ? 1 : 0,
        transition: "all 2s",
        shadowColor: "black",
        shadowOffset: { height: 0, width: 0 },
        shadowOpacity: 0.4,
        direction: direction,
        shadowRadius: 5,
        ...(!isBottom ? {
            top: '0.8vw',
            bottom: 'auto'
        } : {
            top: 'auto',
            bottom: '0.8vw'
        }),
    }),
    search: (direction) => ({
        color: color.text,
        fontSize: "0.9vw",
        paddingHorizontal: "1vw",
        paddingVertical: "0.4vw",
        outlineStyle: "none",
        fontFamily: "Catamaran_400Regular",
        borderBottomColor: color.border,
        borderBottomWidth: "0.1vw",
        writingDirection: direction
    }),
    scroll_c: {
        maxHeight: "15vw"
    },
    container_nav: {
        backgroundColor: background.infoBody
    },
    navView: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: "0.4vw",
    },
    navBtn: (flag) => ({
        marginHorizontal: "0.8vw",
        paddingVertical: "0.3vw"
    }),
    borderNav: {
        fontSize: "1vw",
        color: color.border,
    },
    textNav: {
        borderBottomColor: background.count,
        borderBottomWidth: "0.1vw",
    },
    body: {
        paddingHorizontal: "0.4vw"
    },
    item: {
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: "0.4vw",
    }
});

export default PlayersCell;