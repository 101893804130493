import React, { useRef, useState } from "react";
import { StyleSheet, View, Text, TextInput, Pressable } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import i18n, { t } from "i18n-js";
import { setCreatorOptions, setErrorMessage, setGameList, setLogin, setToken, setUser } from "../../redux/actions";
import {
  AUTH,
  FACTOR,
  F_PHPSESSID,
  USER,
  USER_FIRST_NAME,
  USER_LAST_NAME,
  _AUTH_CODE_,
  _F_PHPSESSID_,
  CREATE_GAME_PERMISSION,
  USER_ID_IN_GAME,
} from "../../config.inc";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import { URL_PATH, RESEND_AUTH_CODE, AUTH_CHECK } from "@env";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";

export default function TwoFactor() {

  const { width, height } = UseResponsiveSize();
  const { heightS, widthS } = UseResponsiveScreen();
  const color = useSelector((state) => state.style.colorClient);
  const background = useSelector((state) => state.style.backgroundClient);
  const styles = stylesR(color, background, heightS, widthS);

  const authCodeRef = useRef(null);

  const dispatch = useDispatch();

  const [authCode, setAuthCode] = useState();
  const [error, setError] = useState();

  const submitAuthHandler = () => {
    setError(null);
    const url = `${URL_PATH}${AUTH_CHECK}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    body.append(_AUTH_CODE_, authCode);
    const requestOptions = {
      method: "POST",
      body: body,
    };
    try {
      fetch(url, requestOptions)
        .then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
          if (res.status === "false") {
            setAuthCode("");
            authCodeRef.current.focus();
            switch (res.error) {
              case "Incorrect Code - The code you entered is incorrect (23)":
                return setError(
                  `${t("TF_error_code_1")}`
                );
              case "Invalid length - Authentication codes must be 6 numbers long. Please try again. (1018)":
                return setError(
                  `${t("TF_error_code_2")}.`
                );
              case "Code Expired - The code you entered has expired. A new one has been sent to you. (22)":
                return setError(
                  `${t("TF_error_code_3")}`
                );
              case "Invalid characters - The code you entered includes invalid characters. Please enter numbers only. (2006)":
                return setError(
                  `${t("TF_error_code_4")}`
                );
              case "User locked - Too many failed login attemps, user is now locked. Please use \"Forgot password\" to unlock. (3)":
                return setError(
                  `${t("TF_error_code_5")}`
                );
              default:
                return setError(
                  `${t("TF_error_code")}`
                );
            }
          }

          window.sessionStorage.setItem(FACTOR, true);
          window.sessionStorage.setItem(
            USER_FIRST_NAME,
            res.user.first_name
          );
          window.sessionStorage.setItem(USER_LAST_NAME, res.user.last_name);
          window.sessionStorage.setItem(AUTH, "true");
          window.sessionStorage.setItem(USER, JSON.stringify(res.user));
          dispatch(setUser(res));
          if (res.user.create_game_permission === "1") {
            window.sessionStorage.setItem(CREATE_GAME_PERMISSION, res.user.create_game_permission);
          }
          if (window.sessionStorage.getItem("token_password")) {
            dispatch(setLogin());
            dispatch(setErrorMessage(true, "", t("err_login_password"), false, () => {
              window.location.href = `${window.location.origin}/forgotPassword?token=${window.sessionStorage.getItem("token_password")}`;
            }));
            return;
          } else {
            if (res.user.create_game_permission === "1") {
              dispatch(setCreatorOptions());
            } else {
              dispatch(setGameList());
            }
          }
        })
        .catch((err) => { });
    } catch (err) { }
    // if (authCode.length === 6) {
    //   window.sessionStorage.setItem(FACTOR, true);
    //   dispatch(setGameList());
    // }
  };

  const resendHandler = () => {
    const url = `${URL_PATH}${RESEND_AUTH_CODE}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    const requestOptions = {
      method: "POST",
      body: body,
    };
    try {
      fetch(url, requestOptions)
        .then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
          if (res?.status !== "true") {
            dispatch(setErrorMessage(true, "", t("error_send_two_msg"), false, () => { }))
          }
          // add error message if something wrong happend
        })
        .catch((err) => { });
    } catch (err) { }
  };

  return (
    <View
      style={[styles.body_main, { backgroundColor: background.popupLogin }]}
    >
      <View style={styles.body_main_top}>
        <Text style={[styles.body_main_textA, { color: color.text }]}>
          {i18n.t("authentication")}
        </Text>
        <Text style={[styles.body_main_textB, { color: color.text }]}>
          {i18n.t("authentication_description")}
        </Text>
      </View>
      {/* <View style={[styles.body_main_bottom]}> */}
      <View style={styles.body_main_bottom}>
        {error && <Text style={styles.err}>{error}</Text>}
        <TextInput
          ref={authCodeRef}
          style={[
            styles.inputs,
            { backgroundColor: background.loginInput, color: color.text },
          ]}
          value={authCode}
          onChangeText={(e) => setAuthCode(e)}
          onSubmitEditing={submitAuthHandler}
          placeholder={t("two_factor_placeholder")}
        />
        <Pressable
          onPress={submitAuthHandler}
          style={[
            {
              backgroundColor:
                authCode && authCode.length > 0
                  ? background.buttonLoginHover
                  : "",
              borderColor: color.text2,
              borderWidth:
                !authCode || (authCode && authCode?.length === 0)
                  ? widthS * 1.5
                  : 0,
            },
            styles.btn,
          ]}
        >
          {
            <Text style={[{ color: color.text2 }, styles.btn_text]}>
              {i18n.t("submit")}
            </Text>
          }
        </Pressable>
      </View>
      <View
        style={{
          flex: 0.3,
          paddingBottom: heightS * 15,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Pressable onPress={resendHandler} style={styles.resendContainer}>
          {({ pressed, hovered, focused }) => (
            <Text
              style={[
                { textDecorationLine: hovered ? "underline" : "none" },
                styles.text_forgot,
              ]}
            >
              {i18n.t("resend")}
            </Text>
          )}
        </Pressable>
        <Pressable
          onPress={() => {
            window.sessionStorage.clear();
            dispatch(setLogin());
          }}
          style={{ textAlign: "center" }}
        >
          {({ pressed, hovered, focused }) => (
            <Text
              style={[
                { textDecorationLine: hovered ? "underline" : "none" },
                styles.text_forgot,
              ]}
            >
              {i18n.t("back")}
            </Text>
          )}
        </Pressable>
      </View>
      {/* </View> */}
    </View>
  );
}

const stylesR = (color, background, heightS, widthS) => {
  return StyleSheet.create({
    body_main: {
      display: "flex",
      flexDirection: "column",
      width: widthS * 420,
      height: heightS * 300,
      borderRadius: widthS * 25,
    },
    body_main_top: {
      paddingTop: heightS * 15,
      flex: 0.3,
    },
    body_main_textA: {
      fontSize: widthS * 18,
      fontWeight: "normal",
      textAlign: "center",
      fontFamily: "Catamaran_400Regular",
    },
    body_main_textB: {
      marginTop: heightS * 6,
      fontWeight: "300",
      lineHeight: heightS * 12,
      fontSize: widthS * 10,
      textAlign: "center",
      fontFamily: "Catamaran_400Regular",
    },
    body_main_bottom: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    inputs: {
      width: widthS * 220,
      height: heightS * 28,
      borderRadius: widthS * 7.3,
      paddingLeft: widthS * 10,
      fontSize: widthS * 9.5,
      fontWeight: "300",
      outlineStyle: "none",
      fontFamily: "Catamaran_400Regular",
    },
    btn: {
      padding: widthS * 4,
      display: "flex",
      alignItems: "center",
      marginTop: heightS * 10.2,
      width: widthS * 220,
      height: heightS * 28,
      borderRadius: widthS * 7.3,
    },
    btn_text: {
      fontSize: widthS * 11,
      fontWeight: "normal",
      fontFamily: "Catamaran_400Regular",
    },
    resendContainer: {
      textAlign: "center",
      // paddingTop: "35%",
      marginBottom: heightS * 1,
    },
    text_forgot: {
      color: color.text2,
      fontSize: widthS * 11.2,
      fontWeight: "normal",
      textDecorationStyle: "solid",
      textDecorationColor: "#a69ba6",
      fontFamily: "Catamaran_400Regular",
    },
    err: {
      fontWeight: "300",
      fontSize: widthS * 9,
      textAlign: "center",
      width: "70%",
      color: "#d2c935",
      marginBottom: "1vw",
      fontFamily: "Catamaran_400Regular",
    },
  });
};
