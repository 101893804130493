import {
  View,
  Text,
  StyleSheet,
  Modal,
  Pressable,
  Dimensions,
  TextInput,
  ScrollView,
} from "react-native";
import React, { useState, useMemo, useEffect, useRef } from "react";
import { t } from "i18n-js";
import { useSelector } from "react-redux";
import { UserContext } from "../../ContextData/User";
import { useContext } from "react";
import { Ionicons } from "@expo/vector-icons";
import { listenerAdd, listenerAddBT } from "../../AddListener";
import { background, color } from "../../style/dark";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import HeaderPopupButtons from "../Elements/HeaderPopupButtons";

export default function Setting({ flag, setFlag }) {

  const { changeUserdetails } = useContext(UserContext);
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const popupRef = useRef(null);

  const lang = useSelector((state) => state.setting.setting.lang);
  const user = useSelector((state) => state.user.user);
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);

  const [details, setDetails] = useState();
  const [err, setErr] = useState({
    firstName: false,
    lastName: false,
    jobTitle: false,
    company: false,
    email: false,
    phone: false,
  });

  const handelChange = (value, key) => {
    if (value.length > 0) {
      setDetails({ ...details, [key]: value });
    } else {
      setDetails({ ...details, [key]: "" });
    }
  };
  const submit = () => {
    let flag = true;
    let keys = Object.keys(details);
    let i = 0;
    do {
      let key = keys[i];
      if (
        (!details[key] || (details[key] && details[key].length < 2)) &&
        key !== "phone" &&
        key !== "email"
      ) {
        err[key] = true;
        flag = false;
      } else if (
        key === "phone" &&
        !details[key].match(
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        )
      ) {
        err[key] = true;
        flag = false;
      } else if (
        key === "email" &&
        !console.log(
          details[key].match(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
          )
        )
      ) {
        err[key] = true;
        flag = false;
      } else {
        err[key] = false;
      }
      i++;
    } while (i < keys.length);
    setErr({ ...err });
    if (flag) {
      let newUser = {
        firstName:
          user.firstName === details.firstName ? false : details.firstName,
        lastName: user.lastName === details.lastName ? false : details.lastName,
        jobTitle: user.jobTitle === details.jobTitle ? false : details.jobTitle,
        company: user.company === details.company ? false : details.company,
        email: user.email === details.email ? false : details.email,
        phone: user.phone === details.phone ? false : details.phone,
      };
      changeUserdetails(newUser);
    }
  };
  useMemo(() => {
    setDetails(user);
  }, [user]);
  useEffect(() => {
    setDetails(user);
  }, []);
  useEffect(() => {
    window.addEventListener("mousedown", listenerAdd(popupRef, flag, setFlag));
    return () => {
      window.removeEventListener(
        "mousedown",
        listenerAdd(popupRef, flag, setFlag)
      );
    };
  }, [flag]);
  return (
    <Modal transparent={true} visible={flag} animationType="none">
      <View style={styles.container}>
        <View
          style={[styles.main, { backgroundColor: background.popup1 }]}
          ref={popupRef}
        >
          <View style={[styles.header, { borderColor: color.border }]}>
            <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
              <View style={styles.icon_container}>
                <Ionicons
                  name="settings-sharp"
                  color="#dcdcdc"
                  size={widthS * 13}
                  style={{ padding: widthS * 3 }}
                />
              </View>
              <Text style={styles.logo_text}>{t("settings")}</Text>
            </View>
            <HeaderPopupButtons
              type={true}
              close={() => setFlag()}
            />
          </View>
          <View style={styles.body}>
            <View style={styles.inputs}>
              <TextInput
                placeholderTextColor={"#a3a3a3"}
                style={[
                  styles.inp,
                  {
                    borderColor: err.firstName ? "red" : "transparent",
                    backgroundColor: background.setting,
                    direction: lang === "he" ? "rtl" : "ltr",
                  },
                ]}
                placeholder={details && details.firstName ? "" : t("first")}
                value={details && details.firstName ? details.firstName : ""}
                onChangeText={(txt) => handelChange(txt, "firstName")}
              />
              <TextInput
                placeholderTextColor={"#a3a3a3"}
                style={[
                  styles.inp,
                  {
                    borderColor: err.lastName ? "red" : "transparent",
                    backgroundColor: background.setting,
                    direction: lang === "he" ? "rtl" : "ltr",
                  },
                ]}
                placeholder={details && details.lastName ? "" : t("last")}
                value={details && details.lastName ? details.lastName : ""}
                onChangeText={(txt) => handelChange(txt, "lastName")}
              />
              <TextInput
                placeholderTextColor={"#a3a3a3"}
                style={[
                  styles.inp,
                  {
                    borderColor: err.company ? "red" : "transparent",
                    backgroundColor: background.setting,
                    direction: lang === "he" ? "rtl" : "ltr",
                  },
                ]}
                placeholder={details && details.company ? "" : t("company")}
                value={details && details.company ? details.company : ""}
                onChangeText={(txt) => handelChange(txt, "company")}
              />
              <TextInput
                placeholderTextColor={"#a3a3a3"}
                style={[
                  styles.inp,
                  {
                    borderColor: err.jobTitle ? "red" : "transparent",
                    backgroundColor: background.setting,
                    direction: lang === "he" ? "rtl" : "ltr",
                  },
                ]}
                placeholder={details && details.job_title ? "" : t("job_title")}
                value={details && details.job_title ? details.job_title : ""}
                onChangeText={(txt) => handelChange(txt, "job_title")}
              />
              <TextInput
                placeholderTextColor={"#a3a3a3"}
                style={[
                  styles.inp,
                  {
                    borderColor: err.email ? "red" : "transparent",
                    backgroundColor: background.setting,
                  },
                ]}
                placeholder={details && details.email ? "" : t("email")}
                value={details && details.email ? details.email : ""}
                onChangeText={(txt) => handelChange(txt, "email")}
              />
              <TextInput
                placeholderTextColor={"#a3a3a3"}
                style={[
                  styles.inp,
                  {
                    borderColor: err.phone ? "red" : "transparent",
                    backgroundColor: background.setting,
                  },
                ]}
                placeholder={details && details.phone ? "" : t("phone")}
                value={details && details.phone ? details.phone : ""}
                onChangeText={(txt) => handelChange(txt, "phone")}
              />
            </View>
            <View>
              <Pressable
                style={({ pressed }) => [
                  styles.btn,
                  {
                    backgroundColor: background.saveSettingsBtn,
                    borderColor: background.saveSettingsBtn,
                  },
                ]}
                onPressIn={() => submit()}
              >
                <Text style={styles.btn_text}>{t("save")}</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
}
const stylesR = (width, height, heightS, widthS) => StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    paddingTop: heightS * 80,
    height: "100%",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    backgroundColor: "#00000057",
  },
  main: {
    position: "absolute",
    left: "20%",
    width: widthS * 350,
    maxHeight: "75%",
    borderRadius: widthS * 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    height: heightS * 40,
    borderBottomWidth: 1,
    justifyContent: "space-between",
    borderBottomColor: "#6b788659",
    alignItems: "center",
    flexDirection: "row",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    paddingVertical: heightS * 7,
    paddingLeft: widthS * 15,
    width: "100%",
  },
  logo_text: {
    color: "#e1e1e1",
    fontSize: widthS * 16,
    fontFamily: "Catamaran_700Bold",
  },
  x_btn: {
    position: "absolute",
    right: widthS * 10,
    top: heightS * 4,
  },
  x_text: {
    color: "#877e88",
    fontSize: widthS * 10,
    fontFamily: "Catamaran_400Regular",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  inputs: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingTop: heightS * 20,
    zIndex: 2,
    marginBottom: heightS * 30,
  },
  inp: {
    width: "40%",
    height: heightS * 23,
    borderWidth: 1,
    marginVertical: heightS * 3,
    marginHorizontal: widthS * 4,
    paddingVertical: heightS * 3,
    fontSize: widthS * 10,
    paddingHorizontal: widthS * 5,
    outlineStyle: "none",
    fontFamily: "Catamaran_400Regular",
    borderRadius: 7,
    color: "#e1e1e1",
  },
  view_popup: {
    marginHorizontal: widthS * 5,
  },
  btn: {
    borderWidth: 1,
    width: widthS * 55,
    height: heightS * 18,
    borderStyle: "solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 7,
    marginBottom: heightS * 25,
    padding: 12,
  },
  btn_text: {
    color: "#e1e1e1",
    fontSize: widthS * 9,
    fontFamily: "Catamaran_600SemiBold",
  },
  icon_container: {
    backgroundColor: background.saveSettingsBtn,
    borderRadius: "50%",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    marginHorizontal: widthS * 11,
    padding: widthS * 4,
  },
});
