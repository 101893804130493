import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage } from '../../redux/actions';
import { t } from 'i18n-js';

export default function onClickEndGameCheck() {
    const endGame = useSelector(state => state.gameInfoArr.gameInfoArr.end_time);
    const dispatch = useDispatch();
    const checkEnd = (clickAction) => {
        if (endGame) {
            return dispatch(
                setErrorMessage(
                    true,
                    undefined,
                    `${t("game_ended_popup")}`,
                )
            );
        }
        return clickAction();
    }
    return {checkEnd: checkEnd, endGame: endGame}
}

const styles = StyleSheet.create({})