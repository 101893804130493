import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  URL_PATH,
  GET_CHAT_LIST,
  SEND_CHAT_MESSAGE,
  ADD_EDIT_GROUP_CHAT,
  MARK_AS_READ_CHAT,
} from "@env";
import {
  F_PHPSESSID,
  USER_ID_IN_GAME,
  USER_ID_IN_GAME_OBSERVER,
  _CHAT_MESSAGE_,
  _CREATOR_ID_,
  _CURRENT_USER_ID_,
  _F_PHPSESSID_,
  _GROUPS_COUNT_,
  _GROUP_ID_,
  _GROUP_MEMBERS_,
  _GROUP_NAME_,
  _ID_,
  _LAST_ID_,
  _SENDER_ID_,
  _STATUS_,
  _USER_ID_,
} from "../config.inc";
import { t } from "i18n-js";
import { UpdateContext } from "./Update";
import { loading, markChat, setChat, setErrorMessage } from "../redux/actions/index";

export const ChatContext = createContext();
export default function ChatProvider(props) {
  const dispatch = useDispatch();
  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const gameInfoPermission = useSelector((state) => state.gameInfoArr.permission);

  const { getUpdateData } = useContext(UpdateContext);

  const [hasFetched, setHasFetched] = useState(false);

  const getChatData = (flag) => {
    const url = `${URL_PATH}${GET_CHAT_LIST}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    if (sessionStorage.getItem(_LAST_ID_)) {
      body.append("last_id", window.sessionStorage.getItem(_LAST_ID_));
    }
    try {
      fetch(url, { method: "POST", body: body })
        .then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
          if (res.status === "true") {
            if (res?.messages?.length > 0) {
              window.sessionStorage.setItem(
                "last_id",
                res.messages[res.messages.length - 1].id
              );
            }
            dispatch(
              setChat(
                res,
                (gameInfoPermission !== "1" && gameInfoPermission !== "10") ? (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME)) : false,
                [...gameInfo.players],
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) { }
  };

  const sendMessageData = (message) => {
    return new Promise((reslove, reject) => {
      let id = gameInfoPermission === "1" ? sessionStorage.getItem("creatorUserId") : (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME))
      const url = `${URL_PATH}${SEND_CHAT_MESSAGE}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      body.append(_SENDER_ID_, message?.from);
      body.append(_CHAT_MESSAGE_, encodeURI(message.text));
      if (message.file) {
        body.append("file0", message.file.file);
      }
      if (message.group) {
        body.append(_GROUP_ID_, message.id);
      } else {
        body.append(_USER_ID_, message.to);
      }
      if (sessionStorage.getItem(_LAST_ID_)) {
        body.append(_LAST_ID_, sessionStorage.getItem(_LAST_ID_));
      }
      try {
        fetch(url, { method: "POST", body })
          .then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.message_sent === "true") {
              dispatch(
                setChat(
                  { messages: [...res.messages_array] },
                  gameInfoPermission !== "1" ? (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME)) : false,
                  [...gameInfo.players],
                )
              );
              props.sentMessageWebSocket("chat", "all");
              reslove(true);

            } else if (res.message_sent === "false") {
              reject(false);
            }
          })
          .catch((err) => reject(false));
      } catch (e) { reject(false); }
    })
  };

  const createOrEditGroupData = (message) => {
    return new Promise((reslove, reject) => {
      if (gameInfoPermission === "1" && sessionStorage.getItem("creatorUserId") || sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME)) {
        const url = `${URL_PATH}${ADD_EDIT_GROUP_CHAT}`;
        const body = new FormData();
        body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
        body.append(_GROUP_NAME_, message.group_name);
        body.append(_CREATOR_ID_, gameInfoPermission === "1" && sessionStorage.getItem("creatorUserId") ? sessionStorage.getItem("creatorUserId") : (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME)));
        body.append(_GROUP_MEMBERS_, message.players + `${gameInfoPermission === "1" ? sessionStorage.getItem("creatorUserId") : (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME))},`);
        if (sessionStorage.getItem(_LAST_ID_)) {
          body.append(_LAST_ID_, sessionStorage.getItem(_LAST_ID_));
        }
        if (message.id) {
          body.append(_ID_, message.id);
        }
        try {
          fetch(url, { method: "post", body: body })
            .then((res) => res.text())
            .then((res) => {
              res = JSON.parse(res);
              if (res.success !== "false") {
                getUpdateData();
                props.sentMessageWebSocket("chat", message.players);
                reslove(true);
              } else {
                if (res?.error?.indexOf("(1)") >= 0) {
                  dispatch(setErrorMessage(true, t("oops"), t("error_creator_none_permission")));
                  dispatch(loading(false));
                } else {
                  reject(false);
                }
              }
            })
            .catch((err) => {
              reject(false);
            });
        } catch (e) { reject(false) }
      } else {
        reject(false);
      }
    })
  };

  const markAsReadData = (message) => {
    const url = `${URL_PATH}${MARK_AS_READ_CHAT}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    if (gameInfoPermission === "15") {
      if (message.group_id || message.group) {
        body.append(_GROUP_ID_, message.to);
        body.append("receiver_id", message.from);
      } else {
        body.append("sender_id", message.to);
        body.append("receiver_id", message.from);
      }
    } else {
      if (message.group_id || message.group) {
        body.append(_GROUP_ID_, message.to);
      } else {
        body.append("sender_id", message.to);
        body.append("receiver_id", message.from);
      }
    }
    try {
      fetch(url, { method: "POST", body: body })
        .then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) { }
  };

  useEffect(() => {
    if (props.loginType !== "in") {
      dispatch(setChat([], ""));
    }
  }, [props.loginType]);

  useEffect(() => {
    if (gameInfo?.game_name?.length > 0 && !hasFetched) {
      setHasFetched(true);
      getChatData();
    }
  }, [gameInfo, hasFetched]);

  useEffect(() => {
    window.sessionStorage.removeItem(_GROUPS_COUNT_);
    window.sessionStorage.removeItem(_LAST_ID_);
  }, []);

  return (
    <ChatContext.Provider
      value={{
        getChatData,
        sendMessageData,
        createOrEditGroupData,
        markAsReadData,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
}
