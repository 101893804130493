import { StyleSheet, Text, View, Dimensions } from 'react-native'
import React from 'react';
import { useSelector } from 'react-redux';
import UseResponsiveSize from './UseResponsiveSize';
import UseResponsiveScreen from './UseResponsiveScreen';

export default function CircleDotsTimer({count, now, name, widthP, heightP, sizeC}) {

    const {width, height} = UseResponsiveSize();
    const {heightS, widthS} = UseResponsiveScreen();
	const styles = stylesR(width, height, heightS, widthS);

    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);
    const color = useSelector(state => state.style.color);
    const background = useSelector(state => state.style.background);
    
    const dots = (count, now) => {
        let quantity = 50;
        let p = quantity / count * now;
        let path = [];
        let Wperc = widthP - ((52 / 100) * widthP);
        let Hperc = heightP - ((52 / 100) * heightP);
        let countI = 0;
        for (let i = 25; i >= countI; i--) {
            let x = Wperc + Wperc * Math.sin(2 * Math.PI * i / quantity) + 1 ;
            let y = Hperc + Hperc * Math.cos(2 * Math.PI * i / quantity) + 1 ;
            path.push({ x: x, y: y });
            if(i === 0){
                i = 50;
                countI = 25;
            }
        }
        path = path.reverse();
        return path.map((e, i) => {
                return (
                    <View key={i} style={[styles.dot, {left: e.x, top: e.y, backgroundColor: i <= p  ? "" : "#fbf03a", width: sizeC, height: sizeC }]} />
                )
        })
    }

    return (
        <View style={[styles.circle, {width: widthP, height: heightP, backgroundColor: background.popupLogin}]} nativeID="circle">
            {dots(count, now)}
            <Text style={{color: color.text, fontSize: widthS * 39, fontWeight: "100", lineHeight: heightS * 31, fontFamily: "Catamaran_400Regular"}}>{now}</Text>
            <Text style={{color: color.text, fontSize: widthS * 9, marginTop: heightS * 5, fontFamily: "Catamaran_400Regular"}}>{name}</Text>
        </View>
    )
}

const stylesR = (width, height, heightS, widthS ) => {
    return StyleSheet.create({
        circle: {
            position: "relative",
            display: "flex",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
            // marginHorizontal: widthS * 10
        },
        dot: {
            position: "absolute",
            borderRadius: "50%",
        }
    })
}

