import React, { useCallback, useMemo, memo } from 'react';
import { StyleSheet, View, Pressable, TextInput } from 'react-native';
import CheckBox from '../../../../Elements/CheckBox';
import { useSelector } from 'react-redux';

const TimeAndCheckBoxCell = ({
    time,
    item,
    id,
    index,
    hovered,
    isDone,
    isNew,
    updateAction,
    selectRow,
    setSelectRow,
    isPopupOpen,
    setIsPopupOpen,
}) => {
    // Make sure to pass all the necessary props to use in the cell

    const updateTime = useCallback((txt, update = false) => {
        if (isPopupOpen && !isDone && (!update && Number(txt) || Number(time))) {
            const trimmedTxt = txt.replace(/[^0-9]/g, ""); // Remove non-numeric characters
            const updatedTxt = trimmedTxt.replace(/^0+/, ""); // Remove leading zeros
            updateAction(index, { time: update ? time : updatedTxt }, update, isNew);
        }
    }, [index, isDone, isNew, isPopupOpen, time, updateAction]);

    const handleCheck = useCallback(() => {
        setSelectRow((currentSelectedRows) => {
            // Check if the current id is already selected
            const isSelected = currentSelectedRows.some(obj => obj.id === id);

            // If selected, remove it from the array
            if (isSelected) {
                return currentSelectedRows.filter((obj) => obj.id !== id);
            }
            // If not selected, add the new object to the array
            else {
                return [...currentSelectedRows, item];
            }
        });
    }, [id, setSelectRow]);

    const handleFocus = useCallback(() => {
        if (!isPopupOpen && !isDone) {
            setIsPopupOpen(index);
        }
    }, [index, isDone, isPopupOpen, setIsPopupOpen]);

    const handleBlur = useCallback(() => {
        updateTime("", true);
        setIsPopupOpen("");
    }, [setIsPopupOpen, updateTime]);

    const handleKeyPress = useCallback((e) => {
        const value = Number(e.target.value);
        if (e.nativeEvent.key === 'ArrowUp') {
            updateTime((value + 1).toString());
        }
        if (e.nativeEvent.key === 'ArrowDown') {
            updateTime((value > 1 ? value - 1 : 1).toString());
        }
    }, [updateTime]);

    // Using useMemo to avoid unnecessary re-renders
    const isChecked = useMemo(() => selectRow.some(obj => obj.id === id), [id, selectRow]);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const styles = useMemo(() => stylesR(background, color), [background, color]);

    return (
        <View style={[styles.timeCell]}>
            <Pressable disabled={isNew} onPress={handleCheck}>
                <CheckBox
                    checked={isChecked}
                    big={true}
                    isDone={false}
                    background={background.infoBody}
                    colorFill={background.count}
                />
            </Pressable>
            <TextInput
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                onChangeText={updateTime}
                value={time?.toString().padStart(2, "0")}
                style={styles.timeInput}
            />
        </View>
    );
};

const stylesR = (background, color) => StyleSheet.create({
    timeCell: {
        paddingLeft: "0.5vw",
        width: "5vw",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        borderRightWidth: "0.1vw",
        borderStyle: "solid",
        borderRightColor: color.border,
        paddingHorizontal: "0.5vw",
    },
    timeInput: {
        width: "2vw",
        marginLeft: "0.4vw",
        color: color.text,
        outlineStyle: "none",
        fontSize: "0.8vw",
        fontFamily: "Catamaran_400Regular",
    },
});

export default memo(TimeAndCheckBoxCell);