import { StyleSheet, Text, View, ScrollView } from 'react-native'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UserInfo from '../left-info/UserInfo';
import Endbtn from '../Elements-btn/EndBtn';
import { loading } from '../../../../redux/actions';
import Injects from './Injects';
import InboxChat from './InboxChat';
import ActivePlayers from './ActivePlayers';
import KPIAndWordMap from './KPIAndWordMap';
import AnalyticsDashboard from './AnalyticsDashboard';

export default function DashboardIndex({ permission }) {

    const dispatch = useDispatch();

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const styles = stylesR(background, color);


    /// components

    const headerComponnent = (
        <View style={styles.headerView}>
            <View style={styles.headerLeft}>
                <UserInfo dashboard={true} />
            </View>
            <View style={styles.headerRight}>
                {permission && <Endbtn
                    setLoad={(flag) => dispatch(loading(flag))}
                />}
            </View>
        </View>
    )

    const bodyComponnet = (
        <View style={styles.main}>
            <View style={styles.mainLeft}>
                <Injects permission={permission} />
            </View>
            <ScrollView style={styles.mainRight} nativeID="dashboard-players">
                {/* <View style={styles.topBody}> */}
                <InboxChat permission={permission} />
                {/* </View> */}
                <View style={styles.bottomBody}>
                    <ActivePlayers />
                    <KPIAndWordMap />
                    <AnalyticsDashboard />
                </View>
            </ScrollView>
        </View>
    )


    return (
        <View style={styles.container}>
            {headerComponnent}
            {bodyComponnet}
        </View>
    )
}

const stylesR = (background, color) => {
    return StyleSheet.create({
        container: {
            flex: 10,
            backgroundColor: background.infoBody,
            paddingHorizontal: "2vw"
        },
        headerView: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            zIndex: 1
        },
        main: {
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            paddingVertical: "1vw"
        },
        mainLeft: {
            width: "25vw",
            marginRight: "3vw",
            height: "100%",
            paddingTop: "0.6vw"
        },
        mainRight: {
            width: "67vw",
            paddingRight: "3vw",
            height: "100%",
            paddingTop: "0.6vw"
        },
        topBody: {
            // height: "22.5vw"
            flex: 1
        },
        bottomBody: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "2vw"
        }
    })
} 