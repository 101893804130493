import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADD_SOCIAL_PATH, CHANEG_LIKE_SOCIAL_PATH, GET_SOCIAL_PATH, MARK_AS_READ_SOCIAL_PATH, URL_PATH, ADD_COMMENT_SOCIAL_PATH } from "@env";
import { F_PHPSESSID, GAME_ID, USER_ID_IN_GAME, _FILE0_, _F_PHPSESSID_, _ID_ } from "../config.inc";
import { markSocial, setSocialMedia, sharePost } from "../redux/actions/index";
import { updateLastTime } from "./contextFunction";
import { UpdateContext } from "./Update";

export const SocialContext = createContext();

export default function SocialProvider(props) {
	const dispatch = useDispatch();
	const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);
	const socialArr = useSelector(state => state.socialMedia.socialMediaArr);
	const playerPermission = useSelector((state) => state.gameInfoArr.permission);
	const { getUpdateData } = useContext(UpdateContext);

	const [hasFetched, setHasFetched] = useState(false);

	const getSocialData = () => {
		const url = `${URL_PATH}${GET_SOCIAL_PATH}`;
		const body = new FormData();
		body.append(_F_PHPSESSID_, sessionStorage.getItem(
			F_PHPSESSID
		));
		body.append(_ID_, sessionStorage.getItem(GAME_ID));
		const requestOptions = {
			method: "POST",
			body: body
		};
		try {
			fetch(url, requestOptions)
				.then((response) => response.text())
				.then((response) => {
					response = JSON.parse(response);
					if (response && response.social_list && response.social_list.length > 0 && gameInfo.players) {
						updateLastTime(sessionStorage.getItem("last_update_time"), `${response?.last_update_time} utc`);
						let is_admin = sessionStorage.getItem("create_game_permission") === "1" || playerPermission === "1";
						dispatch(setSocialMedia(response.social_list, sessionStorage.getItem(USER_ID_IN_GAME), gameInfo.players, is_admin));
					} else {
						dispatch(setSocialMedia([], "", []));
					}
				})
				.catch((error) => console.log("error", error));
		} catch (e) { }
	};
	const addNewPost = (newPost) => {
		return new Promise((resolve, reject) => {
			let formData = new FormData();
			//lets check if we have an image
			if (newPost.file) {
				formData.append(_FILE0_, newPost.file.file);
			}
			formData.append(_F_PHPSESSID_, sessionStorage.getItem(
				F_PHPSESSID
			));
			formData.append("message_body", encodeURI(newPost.post));
			formData.append("from_id", newPost.from_id ? newPost.from_id : sessionStorage.getItem(USER_ID_IN_GAME));
			formData.append("to_list", newPost.share_ids);
			const requestOptions = {
				method: "POST",
				headers: {
					Accept: "*/*",
				},
				body: formData,
			};
			const url = `${URL_PATH}${ADD_SOCIAL_PATH}`;
			try {
				fetch(url, requestOptions)
					.then((res) => res.text())
					.then((res) => {
						res = JSON.parse(res);
						if (res.message_sent !== "false") {
							getUpdateData();
							props.sentMessageWebSocket("social_media", "all");
							resolve(true);
						}
					}).catch(() => {
						reject(false);
					});
			} catch (e) { reject(false); }
		})
	};
	const changeLike = (id, user_id, like) => {
		return new Promise((resolve, reject) => {
			let data = new FormData();
			data.append("post_id", id);
			data.append("like", like === "0" ? "1" : "0");
			if (user_id) {
				data.append("user_id", user_id);
			}
			data.append(_F_PHPSESSID_, sessionStorage.getItem(
				F_PHPSESSID
			));
			const url = `${URL_PATH}${CHANEG_LIKE_SOCIAL_PATH}`;
			const requestOptions = { method: "POST", body: data };
			try {
				fetch(url, requestOptions)
					.then((res) => res.text())
					.then((res) => {
						res = JSON.parse(res);
						if (res.like_or_not !== "false") {
							resolve(true);
						} else {
							reject(false);
						}
					})
					.catch((err) => {
						reject(false);
					});
			} catch (e) { reject(false); }
		})
	};
	const markPostAsRead = (id) => {
		let data = new FormData();
		data.append("post_id", id);
		data.append(_F_PHPSESSID_, sessionStorage.getItem(
			F_PHPSESSID
		));
		const url = `${URL_PATH}${MARK_AS_READ_SOCIAL_PATH}`;
		const requestOptions = { method: "POST", body: data };
		try {
			fetch(url, requestOptions)
				.then((res) => res.text())
				.then((res) => {
					dispatch(markSocial(id));
				})
				.catch((e) => {
					console.log(e);
				});
		} catch (e) { }
	};
	const addComment = (id, user_id, text, file) => {
		return new Promise((resolve, reject) => {
			let data = new FormData();
			data.append("post_id", id);
			if (user_id) {
				data.append("user_id", user_id);
			}
			data.append("message_body", text);
			data.append(_F_PHPSESSID_, sessionStorage.getItem(
				F_PHPSESSID
			));
			if (file) {
				data.append(_FILE0_, file.file)
			}
			const url = `${URL_PATH}${ADD_COMMENT_SOCIAL_PATH}`;
			const requestOptions = { method: "POST", body: data };
			try {
				fetch(url, requestOptions)
					.then((res) => res.text())
					.then((res) => {
						res = JSON.parse(res);
						if (res.message_sent !== "false") {
							props.sentMessageWebSocket("social_media", "all");
							resolve(true);
							getUpdateData()
						}
						reject(false);
					})
					.catch((err) => {
						reject(false);
					});
			} catch (e) { reject(false); }
		})
	};
	useEffect(() => {
		if (props.loginType !== "in") {
			dispatch(setSocialMedia([], "", []));
			setHasFetched(false);
		}
	}, [props.loginType]);

	useEffect(() => {
		if (gameInfo?.game_name?.length > 0 && !hasFetched) {
			getSocialData();
			setHasFetched(true);
		}
	}, [gameInfo?.game_name?.length, hasFetched]);
	return (
		<SocialContext.Provider
			value={{
				getSocialData,
				changeLike,
				markPostAsRead,
				addComment,
				addNewPost,
			}}
		>
			{props.children}
		</SocialContext.Provider>
	);
}
