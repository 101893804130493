import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { t } from 'i18n-js';
import { useSelector } from 'react-redux';

export default function TextCustomize({ keyText, text, fontWeight, colorCurrent, style, fontSize, upperCase, ...props }) {

    const color = useSelector((state) => state.style.color);
    const styles = stylesR(color);

    const fontWeightReturn = (value) => {
        switch (value) {
            case 200:
                return "Catamaran_200ExtraLight";
            case 300:
                return "Catamaran_300Light";
            case 400:
                return "Catamaran_400Regular";
            case 500:
                return "Catamaran_500Medium";
            case 600:
                return "Catamaran_600SemiBold";
            case 700:
                return "Catamaran_700Bold";
            case 800:
                return "Catamaran_800ExtraBold";
            case 900:
            case "bold":
                return "Catamaran_900Black";
            default:
                return "Catamaran_400Regular";
        }
    }
    return (
        <Text
            style={[styles.text(colorCurrent, fontWeightReturn(fontWeight), fontSize), style]} {...props}>
            {text ? text : (upperCase && keyText ? t(keyText).toLocaleUpperCase() : keyText ? t(keyText) : "")}
        </Text>
    )
}

const stylesR = (color) => StyleSheet.create({
    text: (colorCurrent, fontFamily, fontSize) => ({
        fontSize: fontSize || "1vw",
        fontFamily: fontFamily,
        color: colorCurrent || color.text
    })
})