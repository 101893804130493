export const getPixelColor = ( x, y) => {
        let canvas = document.getElementById("logo-canvas");
       if(canvas){
        let ctx = canvas.getContext("2d");
        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }
        function rgbToHex(arr) {
            return "#" + componentToHex(arr[0]) + componentToHex(arr[1]) + componentToHex(arr[2]);
        }
        try{
            return rgbToHex(ctx?.getImageData(x, y, 1, 1, { colorSpace: "display-p3" })?.data); 
        }catch(e){}
       }
}

export const createCanvasImage = (uri, width, height) => {
    let img = new Image();
    let div = document.getElementById("div");
    let canvas = document.createElement("canvas"); 
    canvas.id = "logo-canvas";
    canvas.style.borderRadius = "1em"
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext("2d");
    img.src = uri;
    img.onload = () => {
        ctx.drawImage(img, (width - (img.width > width ? width : img.width)) / 2, (height - (img.height > height ? height : img.height)) / 2, img.width > width ? width : img.width, img.height > height ? height : img.height);
        div.appendChild(canvas);
    }
}
