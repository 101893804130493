import { StyleSheet, View, Text as TextNative } from 'react-native'
import React, { useMemo } from 'react'
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import * as d3 from 'd3-shape';
import Svg, { Path, G, Line, Text } from 'react-native-svg'
import { t } from 'i18n-js';
import { useSelector } from 'react-redux';
import { vwToPx } from '../../../../modules/ConvertPXToVw';


const printColors = (type) => {
    switch (type) {
        case "mail":
            return "#6A0084";
        case "sent":
            return "#D98000";
        case "decision":
            return "#006ABC";
    }
}

export default function TooltipHeatMap({ tooltip }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(width, height, heightS, widthS, direction);

    const background = useSelector(state => state.style.background);
    const color = useSelector(state => state.style.color);
    const images = useSelector(state => state.style.images);
    /////

    const pieData = Object.keys(tooltip?.data)
        .map(key => {
            return {
                type: `pie-${key}`,
                value: tooltip?.data[key],
                color: printColors(key),
            };
        });

    const pie = d3.pie().value(d => d.value)(pieData);
    const arcGenerator = d3.arc().innerRadius(vwToPx(1.5)).outerRadius(vwToPx(2.1)).padAngle(0.05); // Adjust for size and gap
    // const pieData = Object.keys(tooltip?.data)
    //     .map((value, index) => ({
    //         value: tooltip?.data[value],
    //         svg: { fill: printColors(value)},
    //         key: `pie-${index}`,
    //     }));

    const totale = Object.keys(tooltip.data).reduce((tt, key) => tt += tooltip.data[key], 0) / 100;

    // const Labels = ({ slices }) => {
    //     return slices.map((slice, index) => {
    //         const { labelCentroid, pieCentroid, data } = slice;
    //         if (data.value === 0) return;
    //         return (
    //             <G key={index}>
    //                 <Line
    //                     x1={labelCentroid[0]}
    //                     y1={labelCentroid[1]}
    //                     x2={pieCentroid[0]}
    //                     y2={pieCentroid[1]}
    //                 // stroke={data.svg.fill}
    //                 />
    //                 <Circle
    //                     cx={labelCentroid[0]}
    //                     cy={labelCentroid[1]}
    //                 />
    //                 {/* <Text
    //                     x={labelCentroid[0]}
    //                     y={labelCentroid[1] + widthS * 6}
    //                     // stroke={color.text}
    //                     fill={color.text}
    //                     fontSize={widthS * 8}
    //                     fontStyle={"Catamaran_300Light"}
    //                     textAnchor="middle"
    //                 >
    //                     {slice.value / totale}%
    //                 </Text> */}
    //             </G>
    //         )
    //     })
    // }
    //////

    return (
        <View nativeID='files-des'
            style={{
                position: "absolute",
                top: tooltip.y,
                left: tooltip.x,
                height: widthS * 80,
                width: widthS * 130,
                alignItems: "center",
                justifyContent: "center",
                zIndex: 3,
                writingDirection: direction
            }}
        >
            <View style={{
                width: 0,
                height: 0,
                backgroundColor: "transparent",
                borderStyle: "solid",
                borderLeftWidth: widthS * 5,
                borderRightWidth: widthS * 5,
                borderBottomWidth: widthS * 7,
                borderLeftColor: "transparent",
                borderRightColor: "transparent",
                borderBottomColor: background.infoBox,
            }} />
            <View
                style={{
                    flex: 1,
                    width: "100%",
                    borderRadius: widthS * 7,
                    backgroundColor: background.infoBox,
                    padding: widthS * 10,
                    flexDirection: "column",

                }}
            >
                <TextNative
                    style={{ fontFamily: "Catamaran_600SemiBold", fontSize: widthS * 7, color: color.text, lineHeight: widthS * 6 }}
                >{t("LEVEL_OF_PRESSURE")}</TextNative>
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    <View>
                        <View
                            style={{ flexDirection: "row", alignItems: "center", }}
                        >
                            <View style={[styles.circle, { backgroundColor: "#6A0084" }]} />
                            <TextNative style={[styles.text, { color: color.text }]}>{t("incoming")}</TextNative>
                            <TextNative style={[styles.text, { color: color.text }]}> - {Math.round(tooltip?.data?.mail / totale) || 0}%</TextNative>
                        </View>
                        <View
                            style={{ flexDirection: "row", alignItems: "center", }}
                        >
                            <View style={[styles.circle, { backgroundColor: "#D98000" }]} />
                            <TextNative style={[styles.text, { color: color.text }]}>{t("outgoing")}</TextNative>
                            <TextNative style={[styles.text, { color: color.text }]}> - {Math.round(tooltip?.data?.sent / totale) || 0}%</TextNative>
                        </View>
                        <View
                            style={{ flexDirection: "row", alignItems: "center", }}
                        >
                            <View style={[styles.circle, { backgroundColor: "#006ABC" }]} />
                            <TextNative style={[styles.text, { color: color.text }]}>{t("decision")}</TextNative>
                            <TextNative style={[styles.text, { color: color.text }]}> - {Math.round(tooltip?.data?.decision / totale) || 0}%</TextNative>
                        </View>
                    </View>
                    {/* <PieChart
                        style={{
                            height: widthS * 55,
                            width: widthS * 65,
                            position: "relative",
                        }}
                        data={pieData}
                        innerRadius={widthS * 18}
                        outerRadius={widthS * 13}
                        labelRadius={widthS * 20}
                    >
                    </PieChart> */}
                    <Svg width={vwToPx(5)} height={vwToPx(5)} style={{alignItems: "center", justifyContent: "center"}}>
                        <G x={vwToPx(2.7)} y={vwToPx(2.5)}>
                            {pie.map(slice => {
                                const arc = arcGenerator(slice);

                                return <Path key={slice.data.type} d={arc} fill={slice.data.color} />;
                            })}
                        </G>
                    </Svg>
                </View>
            </View>
        </View>
    )
}

const stylesR = (width, height, heightS, widthS, direction) => {
    return StyleSheet.create({
        circle: {
            width: widthS * 6,
            height: widthS * 6,
            borderRadius: "50%",
            marginHorizontal: widthS * 3
        },
        text: {
            fontSize: widthS * 7,
            textAlign: "center",
            fontFamily: "Catamaran_300Light",
            writingDirection: direction
        }
    });
} 