import { SET_TYPE_MINIMIZE, REMOVE_TYPE, SET_INDEX_CURRENT, MINIMIZE_NEW_MAIL, MINIMIZE_NEW_SYSTEM, BUILD_SHOW_FLAG, MINIMIZE_DASHBOARD, MAXIMIZE_MAIL } from "../actions";

export const minimizePopups = (state = {
    minimize: [],
    indexCurrent: null,
    minimizeNewSystem: [],
    minimizeNewMail: [],
    minimizeDashboard: [],
    buildShow: false,
}, action) => {
    switch (action.type) {
        case SET_TYPE_MINIMIZE:
            let temp = [...state.minimize];
            let index = state.indexCurrent;
            // if (state.buildShow) {
            //     return { ...state, buildShow: false };
            // }
            if (temp.find((e) => e.name === action.payload.name)) {
                temp.forEach((e) => {
                    if (e.name === action.payload.name) {
                        e.show = action.payload.show;
                    }
                    if (action.payload.show) {
                        temp.forEach(item => {
                            if (item.name === action.payload.name) {
                                index = item.num;
                            }
                        })
                    } else {
                        temp.forEach((item, i, arr) => {
                            if (arr[arr.length - (i + 1)].show && item.name !== action.payload.name) {
                                index = item.num;
                                return;
                            }
                        })
                    }
                })
            } else {
                temp.push({ ...action.payload });
            }
            return { ...state, minimize: [...temp], indexCurrent: index };
        case REMOVE_TYPE:
            temp = [...state.minimize];
            temp = temp.filter((e) => e.name !== action.payload);
            return { ...state, minimize: [...temp], indexCurrent: temp.length > 1 ? temp[temp.length - 1].num : 0 };
        case SET_INDEX_CURRENT:
            return { ...state, indexCurrent: action.payload };
        case MINIMIZE_NEW_MAIL: {
            // action.object = { show: true, id: Math.random(), message: message, type: "reply All" }
            if (!action.id && !action.obj && !action.show) {
                return { ...state, minimizeNewMail: [] }
            }
            if (action.delete) {
                state.minimizeNewMail = state.minimizeNewMail.filter((e) => e.id !== action.id);
                return { ...state, minimizeNewMail: [...state.minimizeNewMail] };
            }
            if (action.obj) {
                state.minimizeNewMail.push(action.obj);
                return { ...state, minimizeNewMail: [...state.minimizeNewMail] };
            } else {
                state.minimizeNewMail?.map((e) => {
                    if (e.id === action.id) {
                        e.show = action.show;
                    }
                })
                return { ...state, minimizeNewMail: [...state.minimizeNewMail] };
            }
        }
        case MINIMIZE_NEW_SYSTEM: {
            if (!action.id && !action.obj && !action.show) {
                return { ...state, minimizeNewSystem: [] }
            }
            if (action.delete) {
                state.minimizeNewSystem = state.minimizeNewSystem.filter((e) => e.id !== action.id);
                return { ...state, minimizeNewSystem: [...state.minimizeNewSystem] };
            }
            if (action.obj) {
                state.minimizeNewSystem.push(action.obj);
                return { ...state, minimizeNewSystem: [...state.minimizeNewSystem] };
            } else {
                state.minimizeNewSystem?.map((e) => {
                    if (e.id === action.id) {
                        e.show = action.show;
                    }
                })
                return { ...state, minimizeNewSystem: [...state.minimizeNewSystem] };
            }
        }
        case MINIMIZE_DASHBOARD: {
            if (!action.id && !action.obj && !action.show) {
                return { ...state, minimizeDashboard: [] }
            }
            if (action.delete) {
                state.minimizeDashboard = state.minimizeDashboard.filter((e) => e.id !== action.id);
                return { ...state, minimizeDashboard: [...state.minimizeDashboard] };
            }
            if (action.obj && !action?.id) {
                state.minimizeDashboard.push(action.obj);
                return { ...state, minimizeDashboard: [...state.minimizeDashboard] };
            } else {
                state.minimizeDashboard?.map((e) => {
                    if (e.id === action.id) {
                        e.show = action.show;
                        if (action.obj) {
                            e.message = action.obj;
                        }
                    }
                })
                return { ...state, minimizeDashboard: [...state.minimizeDashboard] };
            }
        }
        case MAXIMIZE_MAIL: {
            state.minimizeNewMail?.map((e) => {
                if (e.id === action.id) {
                    e.maximize = action.flag;
                }
            })
            return { ...state, minimizeNewMail: [...state.minimizeNewMail] };
        }
        case BUILD_SHOW_FLAG: {
            return { ...state, buildShow: action.payload };
        }
        default: return state;
    }
}