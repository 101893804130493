import { Image, Pressable, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import TextCustomize from './TextCustomize';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { listenerAdd, listenerAddBT, listenerAddText } from '../../AddListener';
import { t } from 'i18n-js';
import AnimationLoading from '../modules/AnimationLoading';

export default function OptionsListBtn({
    keyHeaderText,
    onPressCheckbox,
    upperCase,
    flagAll,
    onChangeText,
    selectedItems,
    onPressDeleteItems,
    invitePlayer,
    onPressAddItem,
    onSave,
    onCancel,
    sortPlayers,
    sortAndMail,
    listOrTable,
    disabledSave,
    loadingState,
    table,
    dark,
    isNew,
    permission
}) {

    const sortTypes = [
        { type: t("a-z"), value: "az" },
        { type: t("department"), value: "department" },
        { type: t("type"), value: "type" },
        { type: t("status"), value: "status" },
    ]

    const inputRef = useRef(null);
    const sortRef = useRef(null);
    const sortPopupRef = useRef(null);

    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);

    const images = useSelector((state) => state.style.images);

    const [showSearch, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showSort, setShowSort] = useState(false);
    const [typeSort, setTypeSort] = useState(t("sort_by"));

    useEffect(() => {
        window.addEventListener("mousedown", listenerAddText(inputRef, showSearch, () => setShowSearch(false), searchText.length > 0));
        return () => {
            window.removeEventListener("mousedown", listenerAddText(inputRef, showSearch, () => setShowSearch(false), searchText.length > 0));
        }
    }, [showSearch, searchText]);

    useEffect(() => {
        onChangeText(searchText);
    }, [searchText]);

    useEffect(() => {
        window.addEventListener("mousedown", listenerAddBT(sortPopupRef, sortRef, showSort, () => setShowSort(false)));
        return () => {
            window.removeEventListener("mousedown", listenerAddBT(sortPopupRef, sortRef, showSort, () => setShowSort(false)));
        }
    }, [showSort]);

    useEffect(() => {
        if (sortPlayers) {
            sortPlayers(sortTypes[sortTypes.findIndex((e) => e.type === typeSort)]?.value);
        }
    }, [typeSort]);

    const sortPopup = (
        <View style={styles.popupContainer(permission)} ref={sortPopupRef}>
            <View style={styles.traingle(dark, permission)} />
            <ScrollView style={styles.popupMain()}>
                <Pressable style={styles.sortItem(false, false, true)}>
                    <TextCustomize fontSize={"1vw"} fontWeight={300} text={typeSort} />
                </Pressable>
                {sortTypes.map((type, i) => {
                    if (typeSort !== type.type) {
                        return (
                            <Pressable key={i}
                                style={({ hovered }) => [styles.sortItem(hovered, dark)]}
                                onPress={() => { setTypeSort(type.type); setShowSort(false) }}>
                                <TextCustomize fontSize={"1vw"} fontWeight={300} text={type.type} />
                            </Pressable>
                        )
                    }
                })}
            </ScrollView>
        </View>
    );

    return (
        <View style={styles.top(listOrTable)}>
            <View style={styles.topLeft}>
                {listOrTable && permission && <Pressable
                    style={styles.checkBoxView(dark)}
                    onPress={onPressCheckbox}>
                    {flagAll && <View style={styles.checkBoxfill} />}
                </Pressable>}
                <TextCustomize fontSize={"1.2vw"} fontWeight={300} upperCase={upperCase} keyText={keyHeaderText} />
            </View>
            <View style={styles.topRight}>
                {!showSearch ? <Pressable
                    style={styles.btn(dark)}
                    onPress={() => setShowSearch(true)}
                >
                    <Image source={{ uri: images.searchIcon }} style={styles.iconPlus()} />
                </Pressable> :
                    <View ref={inputRef} style={styles.searchView(dark, table)}>
                        <TextInput
                            style={[styles.search, { color: color.buildText, writingDirection: direction }]}
                            onChangeText={(txt) => setSearchText(txt)}
                            placeholder={t("search")}
                            placeholderTextColor={color.buildText}
                            value={searchText}
                        />
                        <Image source={{ uri: images.searchIcon }} style={styles.iconPlus(true)} />
                    </View>
                }
                {sortAndMail && <>
                    <Pressable style={styles.btn(dark)}
                        onPress={() => {
                            if (!showSort) {
                                setShowSort("real");
                            } else {
                                setShowSort(false);
                            }
                        }} ref={sortRef}>
                        <Image source={{ uri: images.sortIcon }} style={styles.iconPlus()} />
                        {showSort === "real" && sortPopup}
                    </Pressable>
                    {permission && <Pressable
                        disabled={!permission}
                        onPress={() => {
                            invitePlayer();
                        }}
                        style={styles.btn(dark, false, false, !permission)}
                    >
                        <Image source={{ uri: images.mail }} style={styles.iconPlus()} />
                    </Pressable>}
                </>}
                {permission && <Pressable
                    disabled={selectedItems?.length === 0}
                    style={styles.btn(dark, false, false)}
                    onPress={onPressDeleteItems}
                >
                    <Image source={{ uri: images.deleteIcon }} style={styles.iconPlus()} />
                </Pressable>}
                {(!isNew && permission) && <Pressable
                    style={styles.btn(dark, isNew, false)}
                    onPress={isNew ? onSave : onPressAddItem}
                >
                    <Image source={{ uri: images.plusIcon }} style={styles.iconPlus()} />
                </Pressable>}
                {isNew && <Pressable
                    style={styles.btn(dark, isNew, false, disabledSave && !loadingState)}
                    disabled={disabledSave || loadingState}
                    onPress={isNew ? onSave : onPressAddItem}
                >
                    {loadingState ? <AnimationLoading list={true} flag={true} styleView={{
                        width: "2vw",
                        filter: "drop-shadow(0px 0px 6px black)"
                    }}
                        styleItem={{
                            marginLeft: "0.5vw",
                        }}
                    /> : <TextCustomize keyText={"save"} />}
                </Pressable>}
                {isNew && <Pressable
                    style={styles.btn(dark, isNew, true)}
                    onPress={onCancel}
                >
                    <TextCustomize keyText={"cancel"} />

                </Pressable>}
            </View>
        </View>
    )
}

const stylesR = (background, color, direction) => StyleSheet.create({
    top: (flag) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "0.6vw",
        marginRight: "1vw",
        zIndex: 6,
        marginLeft: flag ? "2.5vw" : "1vw"
    }),
    topLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row"
    },
    topRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        zIndex: 5
    },
    checkBoxView: (flag) => ({
        width: "1.2vw",
        height: "1.2vw",
        borderRadius: "0.3vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "0.6vw",
        backgroundColor: flag ? background.infoBox : background.infoBody
    }),
    checkBoxfill: {
        backgroundColor: background.count,
        width: "0.6vw",
        height: "0.6vw",
        borderRadius: "0.2vw",
    },
    btn: (flag, isNew, isCancel, disabeld) => ({
        paddingHorizontal: isNew ? "1vw" : "",
        width: isNew ? "" : "2.1vw",
        height: "2.1vw",
        borderRadius: isNew ? "0.8vw" : "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0.5vw",
        opacity: disabeld ? 0.4 : 1,
        backgroundColor: isNew && !isCancel ? background.loginFooter : flag ? background.infoBox : background.infoBody
    }),
    iconPlus: (flag) => ({
        width: "1.1vw",
        height: "1.1vw",
        resizeMode: "contain",
        position: "absolute",
        right: flag ? "0.4vw" : ""
    }),
    searchView: (flag, table) => ({
        borderRadius: "1vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: flag ? background.infoBox : background.infoBody,
        position: "absolute",
        zIndex: 6,
        ...(table && direction === "rtl" ? { right: "-13vw" } : { left: "-12vw", }),
        direction: direction
    }),
    search: {
        height: "2vw",
        width: "12vw",
        borderRadius: "1vw",
        outlineStyle: "none",
        paddingRight: "2vw",
        paddingLeft: "1vw",
        fontSize: "1vw",
        fontWeight: "300"
    },
    popupContainer: (permission) => ({
        position: "absolute",
        top: "2.2vw",
        zIndex: 6,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...(!permission && { right: "2vw" })
    }),
    traingle: (flag, permission) => ({
        borderLeftColor: "transparent",
        borderLeftWidth: "0.6vw",
        borderRightColor: "transparent",
        borderRightWidth: "0.6vw",
        borderBottomWidth: "0.8vw",
        borderTopColor: "transparent",
        borderTopWidth: 0,
        width: 0,
        height: 0,
        zIndex: 5,
        ...(!permission && { marginRight: "-12vw" }),
        borderBottomColor: flag ? background.infoBox : background.infoBody
    }),
    popupMain: (flag) => ({
        marginTop: "-0.0vw",
        left: "3vw",
        width: "10vw",
        borderRadius: "1vw",
        shadowColor: "#000000",
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.3,
        shadowRadius: 4.4,
        zIndex: 4,
        backgroundColor: flag ? background.infoBody : background.infoBox
    }),
    sortItem: (flag, dark, border) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingVertical: "1.4vw",
        paddingVertical: "0.2vw",
        borderBottomColor: color.border,
        borderBottomWidth: border ? 1 : 0,
        backgroundColor: flag ? dark ? background.infoBody : background.infoBox : ""
    }),
    headers2: {
        fontSize: "1vw",
        fontWeight: "300",
        color: color.buildText
    },
})