import { SET_COLORS, SET_DARK_MODE, SET_LIGHT_MODE, SET_COSTUM_COLORS, ADD_LOGO, ADD_DOMAIN, ADD_PRODUCT, ADD_FAVICON, SET_COSTUM_COLORS_CLIENT, ADD_LOGO_CLIENT, ADD_COUNT_DOWN_VIDEO, ADD_PRODUCT_NAME_FOR_PRIVACY } from "../actions/index";
import { images, images1 } from "../../style/images";
import { background, color } from "../../style/dark";
import { background1, color1 } from "../../style/light";
import { LightenDarkenColor } from "../../componnets/modules/LightenDarkenColor";

const setColorCurrent = (costumColor, obj) => {
  let temp = { ...obj };
  Object.keys(temp).map((key) => {
    switch (temp[key]) {
      case "1":
        return temp[key] = costumColor.colorCurrent1;
      case "2":
        return temp[key] = costumColor.colorCurrent2;
      case "3":
        return temp[key] = costumColor.colorCurrent3;
    }
  });
  return temp;
}

export const manageStyle = (
  state = {
    costumColor: {
      color1: "#4a0561",
      colorShade1: LightenDarkenColor("#4a0561", 0),
      colorCurrent1: "#4a0561",
      color2: "#d289d4",
      colorShade2: LightenDarkenColor("#d289d4", 0),
      colorCurrent2: "#d289d4",
      color3: "#fcf000",
      colorShade3: LightenDarkenColor("#fcf000", 0),
      colorCurrent3: "#fcf000",
    },
    costumColorClient: {
      color1: "#4a0561",
      colorShade1: LightenDarkenColor("#4a0561", 0),
      colorCurrent1: "#4a0561",
      color2: "#d289d4",
      colorShade2: LightenDarkenColor("#d289d4", 0),
      colorCurrent2: "#d289d4",
      color3: "#fcf000",
      colorShade3: LightenDarkenColor("#fcf000", 0),
      colorCurrent3: "#fcf000",
    },
    background: background,
    color: color,
    backgroundClient: background,
    colorClient: color,
    images: images,
    darkMode: true,
    logo: null,
    logoClient: null,
    countDownVideo: null,
    domain: "",
  },
  action
) => {
  switch (action.type) {
    case SET_COSTUM_COLORS: {
      if (action.flag) {
        let colorsObj = { ...setColorCurrent(action.payload, color) };
        let backgroundsObj = { ...setColorCurrent(action.payload, background) };
        return { ...state, color: colorsObj, background: backgroundsObj, costumColor: action.payload }
      } else {
        return { ...state, color: state.colorClient, background: state.backgroundClient, costumColor: state.costumColorClient }
      }
    }
    case SET_COSTUM_COLORS_CLIENT: {
      let colorsObj = { ...setColorCurrent(action.payload, color) };
      let backgroundsObj = { ...setColorCurrent(action.payload, background) };
      return { ...state, colorClient: colorsObj, backgroundClient: backgroundsObj, costumColorClient: action.payload }
    }
    case SET_COLORS: {
      let colorsObj = { ...setColorCurrent(state.costumColorClient, color) };
      let backgroundsObj = { ...setColorCurrent(state.costumColorClient, background) };
      return { ...state, colorClient: colorsObj, backgroundClient: backgroundsObj };
    }
    case ADD_PRODUCT: {
      return { ...state, productName: action.payload };
    }
    case ADD_PRODUCT_NAME_FOR_PRIVACY: {
      return { ...state, productNameForPrivacy: action.payload };
    };
    case ADD_FAVICON: {
      return { ...state, favicon: action.payload };
    }
    case SET_DARK_MODE: {
      return {
        background: background,
        color: color,
        images: images,
        darkMode: true,
      };
    }
    case SET_LIGHT_MODE: {
      return {
        background: background1,
        color: color1,
        images: images1,
        darkMode: false,
      };
    }
    case ADD_LOGO: {
      return { ...state, logo: action.payload };
    }
    case ADD_LOGO_CLIENT: {
      return { ...state, logoClient: action.payload };
    }
    case ADD_COUNT_DOWN_VIDEO: {
      return { ...state, countDownVideo: action.payload };
    }
    case ADD_DOMAIN: {
      return { ...state, domain: action.payload };
    }
    default:
      return state;
  }
};
