import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";

export default function PlayerCard({ index, player }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);

  const nameRef = useRef(null);

  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    // checks if the name is longer then the line

    if (nameRef.current.offsetWidth < nameRef.current.scrollWidth) {
      setIsEllipsisActive(true);
    }
  }, [nameRef.current]);

  return (
    <View
      style={[
        styles.player_card,
        {
          backgroundColor: background.gameInfoCard,
          marginRight: (index + 1) % 4 === 0 ? 0 : widthS * 20,
        },
      ]}
    >
      <View style={styles.player_card_view}>
        <Pressable style={{ cursor: "default", width: "100%" }}>
          {({ hovered }) => (
            <>
              <View
                style={[styles.name_view, { borderBottomColor: color.border }]}
              >
                <View
                  style={{
                    flex: 1.5,
                    paddingLeft: widthS * 3,
                  }}
                >
                  <View
                    style={[
                      styles.user_icon,
                      {
                        borderColor: color.gameInfoBorderCircle,
                        shadowColor: color.gameInfoBorderCircle,
                      },
                    ]}
                  >
                    <Image
                      source={{ uri: player.profile_image ? player.profile_image : images.user_image }}
                      style={[styles.user_icon_img]}
                    />
                  </View>
                </View>
                <View style={{ flex: 3 }}>
                  <Text
                    ref={nameRef}
                    numberOfLines={1}
                    selectable={false}
                    style={styles.name}
                  >
                    {player.name_for_game}
                  </Text>
                </View>
              </View>
              {hovered && isEllipsisActive && (
                <View
                  style={[
                    styles.name_view_hovered,
                    {
                      backgroundColor: background.gameInfoCard,
                    },
                  ]}
                >
                  <Text style={styles.name_hovered}>
                    {player.name_for_game}
                  </Text>
                </View>
              )}
            </>
          )}
        </Pressable>
        <Text
          numberOfLines={6}
          selectable={false}
          style={[styles.player_info, { color: color.gameInfoPlayerDes }]}
        >
          {player.player_description}
        </Text>
      </View>
    </View>
  );
}

const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    player_card: {
      width: widthS * 110,
      height: widthS * 120,
      borderRadius: widthS * 20,
      display: "flex",
      flexDirection: "column",
      marginBottom: widthS * 20,
    },
    player_card_view: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    user_icon: {
      width: widthS * 26,
      height: widthS * 26,
      resizeMode: "contain",
      overflow: "hidden",
      borderWidth: widthS * 2,
      borderRadius: widthS * 20,
      marginRight: widthS * 5,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.9,
      shadowRadius: 10,
    },
    user_icon_img: {
      width: "100%",
      height: "100%",
    },
    name_view: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-evenly",
      width: "100%",
      borderBottomWidth: 1,
      borderstyle: "solid",
      padding: widthS * 8,
      paddingBottom: heightS * 3,
    },
    name_view_hovered: {
      position: "absolute",
      minHeight: heightS * 50,
      width: widthS * 98,
      left: widthS * 6.5,
      padding: widthS * 5,
      top: heightS * 6,
      justifyContent: "center",
      alignItems: "center",
      zIndex: 2,
      shadowColor: "black",
      borderRadius: widthS * 10,
      shadowOffset: {
        height: 0,
        width: 0,
      },
      shadowOpacity: 0.5,
      shadowRadius: 10,
    },
    name: {
      color: "white",
      fontSize: widthS * 9,
      textAlign: "left",
      fontFamily: "Catamaran_300Light",
    },
    name_hovered: {
      color: "white",
      fontSize: widthS * 9,
      textAlign: "center",
      fontFamily: "Catamaran_300Light",
      width: widthS * 85,
    },
    player_info: {
      paddingTop: heightS * 5,
      textAlign: "left",
      width: widthS * 80,
      fontSize: widthS * 6.5,
      fontFamily: "Catamaran_400Regular",
    },
  });
