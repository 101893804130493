import { View, Text, StyleSheet, ScrollView, Pressable } from "react-native";
import React, { useEffect, useRef } from "react";
import UseResponsiveSize from "../../../../../modules/UseResponsiveScreen";
import UseResponsiveScreen from "../../../../../modules/UseResponsiveScreen";
import { t } from "i18n-js";
import { useSelector } from "react-redux";
import PlayerCard from "./PlayerCard";
import { useState } from "react";
import ActionPlayersSelect from "./ActionPlayersSelect";
import "./scroll.css";

export default function ActionPlayers({ inject, setActionPost, disabled, isCompletedForm, permission, isPossibletoClosePlayers, isCompletedPost }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const styles = stylesR(width, height, heightS, widthS, background, color);

  const players =
    useSelector((state) => state.gameInfoArr.gameInfoArr.players) || [];

  const [action, setAction] = useState();
  const [showTo, setShowTo] = useState(false);
  const [showFrom, setShowFrom] = useState(false);
  const [fromPlayers, setFromPlayers] = useState([...players]);
  const [toPlayers, setToPlayers] = useState([...players]);

  const addToPlayer = (players, allAdd) => {
    isPossibletoClosePlayers.current = false;
    let to_list = action?.to_list;
    let to_list_ids = action?.to_list_ids;
    const allPlayersExist = players.every(player => to_list_ids.includes(player.id));
    if (allPlayersExist) {
      players.forEach(player => {
        to_list = to_list.replace(`${player.name_for_game},`, "").replace(`${player.name_for_game}`, "");
        to_list_ids = to_list_ids.replace(`${player.id},`, "").replace(`${player.id}`, "");
      });
    } else {
      players.forEach(player => {
        if (allAdd) {
          if (to_list_ids.indexOf(player.id) === -1) {
            to_list += to_list?.endsWith(",") ? `${player.name_for_game},` : `,${player.name_for_game},`;
            to_list_ids += to_list_ids?.endsWith(",") ? `${player.id},` : `,${player.id},`;
          }
        } else {
          if (to_list_ids.indexOf(player.id) === -1) {
            to_list += to_list?.endsWith(",") ? `${player.name_for_game},` : `,${player.name_for_game},`;
            to_list_ids += to_list_ids?.endsWith(",") ? `${player.id},` : `,${player.id},`;
          } else {
            to_list = to_list.replace(`${player.name_for_game},`, "").replace(`${player.name_for_game}`, "");
            to_list_ids = to_list_ids.replace(`${player.id},`, "").replace(`${player.id}`, "");
          }
        }
      });
    }
    setAction({ ...action, to_list: to_list, to_list_ids: to_list_ids });
  };

  useEffect(() => {
    setToPlayers(
      [...players].filter((player) => player.id !== action?.from_id && player.permission_for_game === "15")
    );
  }, [action?.from_id]);

  useEffect(() => {
    const to = action?.to_list_ids?.split(",");
    if (to) {
      setFromPlayers(
        [...players].filter((player) => !to.find((to) => to.trim() === player.id) && player.permission_for_game === "15")
      );
    }
  }, [action?.to_list_ids]);

  useEffect(() => {
    setAction(inject.inject);
  }, [inject?.inject]);

  useEffect(() => {
    if (!isPossibletoClosePlayers.current) {
      if (action?.from_id?.length > 0 && action?.to_list?.length > 0) {
        setActionPost(action, action?.platform_type, false).then(() => {
          isPossibletoClosePlayers.current = true;
        });
      }
    }
  }, [action, isPossibletoClosePlayers.current])

  return (
    <View style={styles.main}>
      <View style={styles.body}>
        {inject?.inject?.platform_type !== "news" && <View style={styles.from}>
          <Text style={styles.label_for}>
            {t("from")}:
          </Text>
          {action?.from ? (
            <PlayerCard
              disabled={disabled}
              from={action?.from}
              permission={permission}
              onDelete={() => {
                isPossibletoClosePlayers.current = false;
                setAction((prev) => {
                  return { ...prev, from: "", from_id: "" };
                })
              }
              }
            />
          ) : (
            <ActionPlayersSelect
              key={fromPlayers}
              options={fromPlayers}
              show={showFrom}
              permission={permission}
              setShow={setShowFrom}
              setActionPlayers={(player) => {
                isPossibletoClosePlayers.current = false;
                setAction({ ...action, from: player[0].name_for_game, from_id: player[0].id })
              }}
            />
          )}
        </View>}
        <View
          style={styles.to(showTo || disabled)}
        >
          <Text
            style={styles.label_for}
          >
            {t("to")}:
          </Text>
          {!disabled && (
            <ActionPlayersSelect
              key={toPlayers}
              options={toPlayers}
              show={showTo}
              setShow={setShowTo}
              permission={permission}
              multiple
              setActionPlayers={addToPlayer}
              actionPlayers={action}
            />
          )}
        </View>
      </View>
      {action?.to_list_ids?.length > 0 && (
        <ScrollView
          nativeID="action_players"
          style={styles.playersScroll}
        >
          {(() => {
            return players
              .map(
                (player, i) => {
                  if (action?.to_list_ids.indexOf(player.id) >= 0)
                    return (
                      <PlayerCard
                        index={i}
                        disabled={disabled}
                        to={player}
                        key={i}
                        permission={permission}
                        onDelete={addToPlayer}
                      />
                    )
                }
              )
          })()}
        </ScrollView>
      )}
    </View>
  );
}

const stylesR = (width, height, heightS, widthS, background, color) =>
  StyleSheet.create({
    main: {
      paddingVertical: heightS * 12,
      alignItems: "center"
    },
    body: {
      paddingHorizontal: widthS * 30,
      width: "100%",
      zIndex: 1
    },
    from: {
      paddingBottom: heightS * 5,
      zIndex: 1
    },
    to: (flag) => ({
      borderBottomWidth: flag ? 0 : widthS * 1,
      borderColor: color.infoBorder,
    }),
    label_for: {
      fontSize: widthS * 10,
      fontFamily: "Catamaran_300Light",
      color: color.infoHeaderNavText
    },
    playersScroll: {
      width: "80%",
      maxHeight: "17vw",
      marginVertical: "1vw"
    },
  });
