import { USER_ID_IN_GAME } from "../../config.inc";
import { SET_GAME_INFO, SET_GAME_PARMISSION, SET_SHOW_GAME_INFO, REMOVE_BACKGROUND_FILE, REMOVE_COUNTDOWN_VIDEO, SET_CLIENTS_FOR_USER } from "../actions/index";
import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';
import moment from 'moment-timezone';

const checkStatusPlayer = (player) => {
	switch (player.active) {
		case "false":
			return "#A99BA8";
		case "true":
		case "inactive":
			return "#de82d8";
		case "active":
			return "#25af94";
	}
}

const mapPlayers = (players, flag) => {
	let temp = players;
	// .filter((e) => e.id !== sessionStorage.getItem(USER_ID_IN_GAME));
	temp.forEach((e) => {
		e.color_status = checkStatusPlayer(e);
		e.player_description = e.player_description ? checkIfIsEncodeText(e.player_description, "string") : "";
		e.name_for_game = checkIfIsEncodeText(e.name_for_game, "string");
	})
	if (flag) {
		temp = temp?.filter((e) => !e.observer_id);
		temp = temp?.filter((e) => e.permission_for_game !== "10");
	}
	return temp;
}

const convertDate = (dateString, timeZone) => {
	if (!dateString) {
		return null;
	}
	// המרת התאריך לאובייקט מסוג moment באמצעות הפונקציה moment.utc
	const utcDate = moment.utc(dateString);

	// בדיקת תקינות התאריך
	if (!utcDate.isValid()) {
		return null; // אם התאריך אינו תקין, החזרת ערך null
	}

	// המרת התאריך לאזור זמן אחר באמצעות הפונקציה tz
	const localDate = utcDate.tz(timeZone);
	// החזרת התאריך בתבנית המבוקשת
	return localDate?.format('YYYY-MM-DD HH:mm:ss');
}

function processGameInfoKey(key, value, action) {
	switch (key) {
		case 'background_story':
			return checkIfIsEncodeText(value, "string") || " ";
		case 'game_name':
			return checkIfIsEncodeText(value, "line") || "";
		case 'game_goals':
			return checkIfIsEncodeText(value, "string") || " ";
		case 'game_logo':
			return value + '?timestamp=' + new Date().getTime();
		case 'game_objectives':
			return value?.length > 0 ? checkIfIsEncodeText(value, "line") : " ";
		case 'start_time':
			return convertDate(value, action.payload?.game_time_zone);
		case 'scheduale_start_time':
			return convertDate(value, action.payload?.game_time_zone);
		case 'end_time':
			return convertDate(value, action.payload?.game_time_zone);
		case 'is_demo':
			return value === "0" ? false : true;
		case 'players':
			return value && value.length > 0 ? mapPlayers(value, true) : [];
		case 'allPlayers':
			return value && value.length > 0 ? mapPlayers(value) : [];
		case 'game_colors':
			return value || null;
		case 'client_id':
			return value || null;
		case 'game_time_zone':
			return value || null;
		case 'files':
			return value || [];
		default:
			return value; // מקרה כללי שבו אין צורך בעיבוד מיוחד
	}
}

export const gameInfoReducer = (
	state = {
		gameInfoArr: false,
		flag: false,
		permission: "",
		clients: []
	}, action) => {
	switch (action.type) {
		case SET_GAME_INFO: {
			try {
				let newValues = {};
				if (state.gameInfoArr) {
					let gameInfo = state.gameInfoArr ? { ...state.gameInfoArr } : {};

					Object.keys(action.payload).forEach((key) => {
						if (key !== "background_files") {
							newValues[key] = processGameInfoKey(key, action.payload[key], action);
						}

						if (key === "players") {
							newValues["allPlayers"] = action.payload[key]?.length > 0 ? mapPlayers(action.payload[key]) : [];
						}
						if (key === "start_time") {
							newValues["start_time_current_zone"] = convertDate(action.payload?.start_time, moment.tz.guess())
						}
						if (key === "scheduale_start_time") {
							newValues["scheduale_start_time_current_zone"] = convertDate(action.payload?.scheduale_start_time, moment.tz.guess())
						}
					});

					Object.keys(newValues).forEach(key => {
						if (newValues[key] !== gameInfo[key]) {
							gameInfo[key] = newValues[key];
						}
					});
					let files = gameInfo.files ? [...gameInfo.files] : [];

					if (action.payload.background_files) {
						action.payload.background_files.forEach(newFile => {
							const existingFileIndex = files.findIndex(file => file.id === newFile.id);

							if (existingFileIndex !== -1) {
								// אם הקובץ קיים
								if (newFile.status === "0") {
									// מחיקת הקובץ הקיים אם הסטטוס של הקובץ החדש הוא "0"
									files.splice(existingFileIndex, 1);
								} else {
									// עדכון הקובץ הקיים אם הסטטוס של הקובץ החדש הוא לא "0"
									files[existingFileIndex] = newFile;
								}
							} else {
								if ((newFile?.status && newFile?.status !== "0" || !newFile?.status)) {
									// הוספת הקובץ החדש אם הוא לא קיים
									files.push(newFile);
								}
							}
						});
					}

					gameInfo.files = files;
					console.log("gameInfo: ");
					console.log(gameInfo);
					return { ...state, gameInfoArr: gameInfo };
				} else {
					if (action?.payload) {
						newValues = {
							background_story: action.payload.background_story ? checkIfIsEncodeText(action.payload.background_story, "string") : " ",
							id: action.payload.id,
							game_name: action.payload.game_name ? checkIfIsEncodeText(action.payload.game_name, "line") : "",
							game_lang: action.payload.game_lang,
							game_goals: action.payload.game_goals ? checkIfIsEncodeText(action.payload.game_goals, "string") : " ",
							game_logo: action.payload.game_logo + '?timestamp=' + new Date().getTime(),
							game_objectives: action.payload.game_objectives?.length > 0 ? checkIfIsEncodeText(action.payload.game_objectives, "line") : " ",
							start_time: convertDate(action.payload.start_time, action.payload?.game_time_zone),
							start_time_current_zone: convertDate(action.payload.start_time, moment.tz.guess()),
							scheduale_start_time: convertDate(action.payload.scheduale_start_time, action.payload?.game_time_zone),
							scheduale_start_time_current_zone: convertDate(action.payload?.scheduale_start_time, moment.tz.guess()),
							end_time: convertDate(action.payload.end_time, action.payload?.game_time_zone),
							user_image: false,
							is_demo: sessionStorage.getItem("is_demo") === "true" ? true : false,
							countdown_video: action.payload.countdown_video,
							allPlayers: action.payload.players && action.payload.players.length > 0 ? mapPlayers(action.payload.players) : [],
							players: action.payload.players && action.payload.players.length > 0 ? mapPlayers(action.payload.players, true) : [],
							game_colors: action.payload.game_colors ? action.payload.game_colors : null,
							is_demo: action.payload?.is_demo === "0" ? false : true,
							client_id: action.payload?.client_id ? action.payload?.client_id : null,
							game_time_zone: action.payload?.game_time_zone ? action.payload.game_time_zone : null,
							files: action.payload?.background_files ? action.payload.background_files : []
						};
						return { ...state, gameInfoArr: newValues };
					} else {
						return { ...state, gameInfoArr: false };
					}
				}
			} catch (e) {
				console.log("//////////////////////////////////////////");
				console.log(e);
				console.log("//////////////////////////////////////////");
			}
		}
		case SET_SHOW_GAME_INFO: {
			return { ...state, flag: action.flag }
		}
		case SET_GAME_PARMISSION: {
			return { ...state, permission: action.permission }
		}
		case REMOVE_BACKGROUND_FILE: {
			let newGameInfo = { ...state.gameInfoArr, files: state.gameInfoArr.files.filter((x) => { return x.id !== action.id }) }
			return { ...state, gameInfoArr: newGameInfo }
		}
		case REMOVE_COUNTDOWN_VIDEO: {
			let parser = document.createElement("a");
			parser.href = state.gameInfoArr.countdown_video;
			let path = parser.pathname.split("/");
			path.pop();
			path = path.join("/") + "/";
			let url = parser.protocol + "//" + parser.hostname + parser?.port + path;
			let newGameInfo = { ...state.gameInfoArr, countdown_video: url };
			return { ...state, gameInfoArr: newGameInfo }
		}
		case SET_CLIENTS_FOR_USER: {
			if (action.payload?.length > 0) {
				return { ...state, clients: action.payload };
			}
			return { ...state, clients: [] };
		}
		default: {
			return state;
		}
	}
};
