import { Animated, Image, Pressable, StyleSheet, Text, View } from 'react-native'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import TextCustomize from '../../../../Elements/TextCustomize';
import { useSelector } from 'react-redux';
import { t } from 'i18n-js';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { vwToPx } from '../../../../modules/ConvertPXToVw';

const TooltipRowTable = React.memo(({ scrollX, time, type, platform, tooltipOpacityAnimation }) => {

    const { images, background, color } = useSelector(state => ({
        images: state.style.images,
        background: state.style.background,
        color: state.style.color,
    }));

    const tooltipRef = useRef();

    const [tooltipOpacity1] = useState(new Animated.Value(0));
    const [tooltipOpacity2] = useState(new Animated.Value(0));

    const [animationCompleted1, setAnimationCompleted1] = useState(false);
    const [animationCompleted2, setAnimationCompleted2] = useState(false);

    const styles = useMemo(() => stylesR(background, color), [background, color]);

    const [displayPlatform, setDisplayPlatform] = useState(false);
    const [display, setDisplay] = useState(false);

    const optionTypes = useMemo(() => [
        { label: t("message"), value: "Message", icon: images.messageIcon },
        { label: t("analysis"), value: "Analysis", icon: images.analyticsIcon },
        { label: t("decision"), value: "Decision", icon: images.decisionIcon },
        {
            label: `${t("call_for_action_challenges")}`,
            value: "cfaChallenges", icon: images.action
        },
        {
            label: `${t("call_for_action_questionnaire")}`,
            value: "cfaQuestionnaire",
            icon: images.questionnaireIcon
        },
    ], [images, t]);

    const platformTypes = useMemo(() => [
        { label: t("mailbox"), value: "mail", icon: images.mail },
        { label: t("social-media"), value: "sm", icon: images.social },
        { label: t("news"), value: "news", icon: images.news }], [images, t]);

    const handleHover1 = (isHovered) => {
        Animated.timing(tooltipOpacity1, {
            toValue: isHovered ? 1 : 0,
            duration: 300,
            useNativeDriver: true,
        }).start(() => {
            setAnimationCompleted1(isHovered);
        });
    };

    const handleHover2 = (isHovered) => {
        Animated.timing(tooltipOpacity2, {
            toValue: isHovered ? 1 : 0,
            duration: 300,
            useNativeDriver: true,
        }).start(() => {
            setAnimationCompleted2(isHovered);
        });
    };


    const CustomTooltipContent = ({ text, opacity }) => {
        return (
            <Animated.View style={[styles.customTooltip, { opacity }]}>
                <View style={styles.tooltipArrow} />
                <TextCustomize text={text} fontWeight={500} fontSize={"0.8vw"} numberOfLines={1} />
            </Animated.View>
        );
    };

    useEffect(() => {
        const listenerId = scrollX.addListener(({ value }) => {
            console.log(value);
            setDisplayPlatform(value > (23 * vwToPx(1)));
            setDisplay(value > (tooltipRef.current?.clientWidth || 5 * vwToPx(1)));
        });

        return () => {
            scrollX.removeListener(listenerId);
        };
    }, [scrollX, tooltipRef.current]);

    return (
        <Animated.View style={[styles.container(display, tooltipOpacityAnimation), styles.shadow]} ref={tooltipRef}>
            <TextCustomize style={styles.time} text={time?.toString().padStart(2, "0")} />
            <View style={{ zIndex: 2 }}>
                <Pressable
                    id={`type-${type}`}
                    onMouseEnter={() => handleHover1(true)}
                    onMouseLeave={() => handleHover1(false)}
                    style={styles.typeView}>
                    <Image source={{ uri: optionTypes.find((e) => e.value === type)?.icon }} style={styles.iconType} />
                </Pressable>
                {animationCompleted1 && <CustomTooltipContent text={optionTypes.find((e) => e.value === type)?.label} opacity={tooltipOpacity1} />}
            </View>
            {
                displayPlatform && <View>
                    <Pressable
                        id={`platform-${platform}`}
                        onMouseEnter={() => handleHover2(true)}
                        onMouseLeave={() => handleHover2(false)}
                        style={styles.btn}>
                        <Image source={{ uri: platformTypes.find((e) => e.value === platform)?.icon }} style={styles.icon} />
                    </Pressable>
                    {animationCompleted2 && <CustomTooltipContent text={platformTypes.find((e) => e.value === platform)?.label} opacity={tooltipOpacity2} />}
                </View>
            }
            {/* {platformTypes.map((platformItem, i) => {
                return (<ReactTooltip
                    anchorSelect={`#platform-${platformItem.value}`}
                    content={<TextCustomize text={platformItem.label} fontWeight={500} fontSize={"0.8vw"} />}
                    style={{ fontFamily: "Catamaran_400Regular" }}
                    place='right-end'
                />);
            })}
            {optionTypes.map((optionItem, i) => {
                return (<ReactTooltip
                    anchorSelect={`#type-${optionItem.value}`}
                    content={<TextCustomize text={optionItem.label} fontWeight={500} fontSize={"0.8vw"} />}
                    place='right-end'
                />);
            })} */}
        </Animated.View >
    )
}, (prevProps, nextProps) => {
    let shouldUpdate = true; // לא צריך רינדור מחדש
    Object.entries(nextProps).forEach(([key, val]) => {
        if (prevProps[key] !== val) {
            // console.log(key + ":" + val);
            shouldUpdate = false; // אם יש שינוי, עדכן את הפרופס
        }
    });
    // console.log(`${prevProps?.index}` + shouldUpdate);
    return shouldUpdate; // החזר true עבור מניעת רינדור מחדש, false לרינדור
});

export default TooltipRowTable;

const stylesR = (background, color) => StyleSheet.create({
    container: (display, tooltipOpacityAnimation) => ({
        backgroundColor: background.infoBody,
        borderRadius: "0.5vw",
        alignItems: "center",
        opacity: tooltipOpacityAnimation,
        flexDirection: "row",
        padding: "0.5vw",
        paddingHorizontal: "0.7vw",
        gap: "0.7vw",
        alignSelf: "center",
        display: display ? "" : "none",
        position: display ? "sticky" : 'absolute', left: "1vw", zIndex: 1,
        //  transform: [{ translateX: scrollX }],
    }),
    time: {
        color: color.text,
        fontSize: "0.8vw",
        fontFamily: "Catamaran_400Regular",
    },
    typeView: {
        width: "1vw",
        height: "1vw",
        alignItems: "center",
        justifyContent: "center",
    },
    iconType: {
        width: "1vw",
        height: "1vw",
        resizeMode: "contain"
    },
    btn: {
        backgroundColor: color.logoColor,
        width: "1.5vw",
        height: "1.5vw",
        borderRadius: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: {
        width: "0.8vw",
        height: "0.8vw",
        tintColor: color.text,
        resizeMode: "contain"
    },
    shadow: {
        shadowColor: "#000000",
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 5
    },
    customTooltip: {
        backgroundColor: background.infoBody,
        position: 'absolute',
        filter: "drop-shadow(0px 0px 2px black)",
        left: '2vw', // Adjust as needed
        top: "-0.5vw", // Adjust as needed,
        padding: "0.5vw",
        borderRadius: ".5vw"
    },
    tooltipArrow: {
        position: 'absolute',
        left: -5, // Half of the triangle's base width
        top: '50%', // Adjust as needed to align with the tooltip
        transform: [{ translateY: -5 }], // Half of the triangle's height
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderLeftWidth: 5, // Half of the triangle's base width
        borderRightWidth: 5, // Half of the triangle's base width
        borderBottomWidth: 10, // Triangle's height
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: background.infoBody, // Same as tooltip background
    },
})