import React, { useEffect, useMemo, useRef, useState } from "react";
import { StyleSheet, View, ImageBackground, Dimensions } from "react-native";

import DraggedPopup from "./componnets/modules/DraggedPopup";

import Right_Menu from "./componnets/menus/Right_Menu";
import Left_Menu from "./componnets/menus/Left_Menu";
import Bottom_Menu from "./componnets/menus/Bottom_Menu";
import { useSelector, useDispatch } from "react-redux";
import ShowFile from "./componnets/pop-ups/my-documents/ShowFile";
import Chat from "./componnets/pop-ups/chat/Chat";
import News from "./componnets/pop-ups/news/News";
import Social from "./componnets/pop-ups/social-media/Social";
import GameInfo from "./componnets/pop-ups/game-info/GameInfo";
import Actions from "./componnets/pop-ups/actions/Actions";
import Action_info from "./componnets/pop-ups/actions/Action_info";
import NewAction from "./componnets/pop-ups/actions/NewAction";
import SystemMessages from "./componnets/pop-ups/mailbox/SystemMessages";
import Files from "./componnets/pop-ups/my-documents/Files";
import MailBox from "./componnets/pop-ups/mailbox/MailBox";
import NewMailPopup from "./componnets/pop-ups/mailbox/NewMailPopup";
import SimualtionBuild from "./componnets/pop-ups/simulationBuild/SimulationBuildIndex";
import Thanks from "./componnets/pop-ups/actions/Thanks";

import {
  mailFlag,
  newsFlag,
  socialFlag,
  setChatFlag,
  setShowGameInfo,
  setShowActions,
  setShowSystemMessages,
  setShowDocs,
  showActionInfo,
  showNewAction,
  setNewMessageFlag,
  setNewSystemFlag,
  setPopupsCount,
  setErrorMessage,
  setIndexCurrent,
} from "./redux/actions";
import UseResponsiveSize from "./componnets/modules/UseResponsiveSize";
import ErrorMessagePopup from "./componnets/modules/ErrorMessagePopup";
import UseResponsiveScreen from "./componnets/modules/UseResponsiveScreen";
import { _LAST_ID_ } from "./config.inc";
import AnimationLoading from "./componnets/modules/AnimationLoading";
import QuestionairePlayer from "./componnets/pop-ups/mailbox/QuestionairePlayer";

export default function Main({ closeWebSocket }) {
  const { width: widthScreen, height: heightScreen } = Dimensions.get("screen");
  const { width: widthWindow, height: heightWindow } = Dimensions.get("window");

  const { width, height } = UseResponsiveSize();
  const { heightS, widthS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const dispatch = useDispatch();
  const systemPopupRef = useRef(null);
  const mailPopupRef = useRef(null);
  const containerRef = useRef(null);
  const refC = useRef(null);
  const refBack = useRef(null);

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const animationFlag = useSelector((state) => state.loadingAnimation.flag);

  const showMailBox = useSelector((state) => state.mailArr.flag);
  const questionArr = useSelector((state) => state.mailArr.questionniareArr);
  const showSocial = useSelector((state) => state.socialMedia.flag);
  const showNews = useSelector((state) => state.newsArr.flag);
  const showChat = useSelector((state) => state.chatArr.flag);
  const showFileFlag = useSelector((state) => state.filesArr.fileShow);
  const showGameInfo = useSelector((state) => state.gameInfoArr.flag);
  const showActions = useSelector((state) => state.actionsArr.flag);
  const errorMessageState = useSelector((state) => state.errorMessage.errorMessage);
  const minimizePopups = useSelector((state) => state.minimize.minimize);

  const showActionInfoFlag = useSelector((state) => state.actionsArr.actionInfoFlag);
  const showNewActionFlag = useSelector((state) => state.actionsArr.newActionsFlag);
  const showSystem = useSelector((state) => state.systemMessages.flag);
  const showDocs = useSelector((state) => state.filesArr.flag);
  const showBuild = useSelector((state) => state.build.flag);
  const showHelp = useSelector((state) => state.setting.flags.help);
  const showSetting = useSelector((state) => state.user.flag);

  const playerPermission = useSelector((state) => state.gameInfoArr.permission);

  const indexCurrent = useSelector((state) => state.minimize.indexCurrent);

  const actionsArr = useSelector((state) => state.actionsArr.actionsArr);

  const errorMessage = useMemo(() => errorMessageState, [errorMessageState]);
  const flags = [
    showMailBox,
    showSocial,
    showNews,
    showChat,
    showGameInfo,
    showActions,
    showSystem,
    showDocs,
    showHelp,
    showSetting,
    // showNewMessageFlag,
    // showNewSystemFlag,
    // showActionInfoFlag
  ];

  const [showThanksPage, setShowThanksPage] = useState(false);
  const [disabledDragged, setDisabledDragged] = useState();

  const checkMinimize = (typePopup) => {
    if (minimizePopups.find((e) => e.name === typePopup && !e.show)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    dispatch(setPopupsCount(flags.filter((flag) => flag).length));
  }, [...flags]);

  useEffect(() => {
    if (systemPopupRef.current) {
    }
  }, [
    systemPopupRef.current && systemPopupRef.current.getBoundingClientRect().y,
  ]);

  window.onbeforeunload = () => {
    sessionStorage.removeItem(_LAST_ID_);
  }

  const popups = (
    <>
      {showBuild && <SimualtionBuild flag={showBuild} />}
      {showChat && (
        <DraggedPopup
          refC={refC}
          flagDrag={disabledDragged === 1 ? true : false}
          setDrag={() => setDisabledDragged()}
          renderItem={
            <Chat setDrag={(flag) => flag ? setDisabledDragged(1) : setDisabledDragged()} setFlag={() => dispatch(setChatFlag(false))} />
          }
          setIndex={() => dispatch(setIndexCurrent(1))}
          style={{
            position: "absolute",
            bottom: heightS * 60,
            width: (playerPermission === "1" || playerPermission === "10") ? widthS * 620 : widthS * 500,
            height: heightS * 400,
            display: checkMinimize("chat") ? "none" : "",
            borderRadius: widthS * 15,
            zIndex: indexCurrent === 1 ? 4 : 3,
            shadowRadius: indexCurrent === 1 ? 20 : 5,
          }}
        />
      )}
      {showNews && (
        <DraggedPopup
          refC={refC}
          renderItem={<News setDrag={(flag) => flag ? setDisabledDragged(2) : setDisabledDragged()} setFlag={() => dispatch(newsFlag())} />}
          setIndex={() => dispatch(setIndexCurrent(2))}
          flagDrag={disabledDragged === 2 ? true : false}
          setDrag={() => setDisabledDragged()}
          style={{
            position: "absolute",
            bottom: heightS * 60,
            width: widthS * 370,
            height: heightS * 400,
            display: checkMinimize("news") ? "none" : "",
            borderRadius: widthS * 15,
            zIndex: indexCurrent === 2 ? 4 : 3,
            shadowRadius: indexCurrent === 2 ? 20 : 5,
          }}
        />
      )}
      {showSocial && (
        <DraggedPopup
          refC={refC}
          flagDrag={disabledDragged === 3 ? true : false}
          setDrag={() => setDisabledDragged()}
          renderItem={
            <Social setDrag={(flag) => flag ? setDisabledDragged(3) : setDisabledDragged()} setFlag={() => dispatch(socialFlag(false))} />
          }
          setIndex={() => dispatch(setIndexCurrent(3))}
          style={{
            position: "absolute",
            bottom: heightS * 55,
            // || playerPermission === "10"
            width: (playerPermission === "1") ? widthS * 482 : widthS * 410,
            height: heightS * 390,
            display: checkMinimize("social") ? "none" : "",
            borderRadius: widthS * 8,
            zIndex: indexCurrent === 3 ? 4 : 3,
            shadowRadius: indexCurrent === 4 ? 20 : 5,
          }}
        />
      )}
      {showGameInfo && (
        <DraggedPopup
          refC={refC}
          flagDrag={disabledDragged === 4 ? true : false}
          setDrag={() => setDisabledDragged()}
          renderItem={
            <GameInfo setDrag={(flag) => flag ? setDisabledDragged(4) : setDisabledDragged()} setFlag={() => dispatch(setShowGameInfo(false))} />
          }
          setIndex={() => dispatch(setIndexCurrent(4))}
          style={{
            width: widthS * 595,
            height: heightS * 400,
            borderRadius: widthS * 15,
            position: "absolute",
            bottom: heightS * 60,
            margin: "0 auto",
            flex: 9.5,
            display: checkMinimize("simulation") ? "none" : "",
            flexDirection: "column",
            zIndex: indexCurrent === 4 ? 4 : 3,
            shadowRadius: indexCurrent === 4 ? 20 : 5,
          }}
        />
      )}
      {showActions && (
        <DraggedPopup
          refC={refC}
          flagDrag={disabledDragged === 5 ? true : false}
          setDrag={() => setDisabledDragged()}
          renderItem={
            <Actions
              refC={refC}
              setDrag={(flag) => flag ? setDisabledDragged(5) : setDisabledDragged()}
              setFlag={() => dispatch(setShowActions(false))}
            />
          }
          setIndex={() => dispatch(setIndexCurrent(5))}
          style={{
            width: widthS * 420,
            height: heightS * 290,
            top: heightS * 80,
            borderRadius: widthS * 15,
            display: checkMinimize("challenges") ? "none" : "",
            position: "absolute",
            zIndex: indexCurrent === 5 ? 4 : 3,
            shadowRadius: indexCurrent === 5 ? 20 : 5,
          }}
        />
      )}
      {showActionInfoFlag && (
        <DraggedPopup
          refC={refC}
          flagDrag={disabledDragged === 6 ? true : false}
          setDrag={() => setDisabledDragged()}
          renderItem={<Action_info setDrag={(flag) => flag ? setDisabledDragged(6) : setDisabledDragged()} />}
          setIndex={() => dispatch(setIndexCurrent(6))}
          style={{
            position: "absolute",
            width: widthS * 380,
            height: heightS * 410,
            bottom: heightS * 55,
            display: checkMinimize("challenges") ? "none" : "",
            borderRadius: widthS * 15,
            zIndex: indexCurrent === 6 ? 6 : 3,
            shadowRadius: indexCurrent === 6 ? 20 : 5,
          }}
        />
      )}
      {showThanksPage && (
        <Thanks
          flag={showThanksPage}
          setFlag={() => setShowThanksPage(false)}
          refC={containerRef}
        />
      )}
      {errorMessage.map((errorMsg) => {
        if (errorMsg.flag) {
          return (
            <ErrorMessagePopup
              flag={errorMsg.flag}
              setFlag={() => dispatch(setErrorMessage(false))}
              title={errorMsg.title}
              body={errorMsg.body}
              refC={refC}
              deleteDraftHandler={errorMsg.deleteDraftHandler}
              clickOkFunc={errorMsg.clickOkFunc}
              success={errorMsg.success}
            />
          )
        }
      })}
      {showSystem && (
        <DraggedPopup
          refC={refC}
          flagDrag={disabledDragged === 7 ? true : false}
          setDrag={() => setDisabledDragged()}
          renderItem={
            <SystemMessages
              setFlag={() => dispatch(setShowSystemMessages(false))}
              setDrag={(flag) => flag ? setDisabledDragged(7) : setDisabledDragged()}
              popupRef={systemPopupRef}
            />
          }
          setIndex={() => dispatch(setIndexCurrent(7))}
          style={{
            width: widthS * 620,
            height: heightS * 400,
            zIndex: 2,
            position: "absolute",
            display: checkMinimize("system") ? "none" : "",
            top: heightS * 20,
            borderRadius: widthS * 15,
            borderBottomLeftRadius: widthS * 10,
            zIndex: indexCurrent === 7 ? 4 : 3,
            shadowRadius: indexCurrent === 7 ? 20 : 5,
          }}
        />
      )}
      {showDocs && (
        <DraggedPopup
          refC={refC}
          flagDrag={disabledDragged === 8 ? true : false}
          setDrag={() => setDisabledDragged()}
          renderItem={
            <Files
              setFlag={() => dispatch(setShowDocs(false))}
              setDrag={(flag) => flag ? setDisabledDragged(8) : setDisabledDragged()}
              refC={containerRef}
            />
          }
          setIndex={() => dispatch(setIndexCurrent(8))}
          style={{
            top: heightS * 75,
            borderRadius: widthS * 15,
            width: widthS * 370,
            height: heightS * 270,
            display: checkMinimize("files") ? "none" : "",
            position: "absolute",
            zIndex: indexCurrent === 8 ? 4 : 3,
            shadowRadius: indexCurrent === 8 ? 20 : 5,
          }}
        />
      )}
      {showMailBox && (
        <DraggedPopup
          refC={refC}
          flagDrag={disabledDragged === 9 ? true : false}
          setDrag={() => setDisabledDragged()}
          renderItem={
            <MailBox
              setFlag={() => dispatch(mailFlag(false))}
              setDrag={(flag) => flag ? setDisabledDragged(9) : setDisabledDragged()}
              popupRef={mailPopupRef}
            />
          }
          setIndex={() => dispatch(setIndexCurrent(9))}
          style={{
            width: widthS * 620,
            height: heightS * 400,
            zIndex: 2,
            position: "absolute",
            top: heightS * 20,
            display: checkMinimize("mail") ? "none" : "",
            borderRadius: widthS * 15,
            borderBottomLeftRadius: widthS * 10,
            zIndex: indexCurrent === 9 ? 4 : 3,
            shadowRadius: indexCurrent === 9 ? 20 : 5,
          }}
        />
      )}
      {/* player questionaire */}
      {questionArr?.length > 0 && questionArr?.map((questionObject, index) => {
        return <QuestionairePlayer questionObj={questionObject} key={index} />
      })}
      {/* {showNewSystemFlag && (
              <DraggedPopup
                refC={refC}
                flagDrag={true}
                popupRef={mailPopupRef}
                renderItem={
                  <NewMailPopup flag={showNewSystemFlag} type={"system"} />
                }
                setIndex={() => dispatch(setIndexCurrent(11))}
                style={{
                  zIndex: 5,
                  shadowRadius: indexCurrent === 11 ? 20 : 5,
                  width: widthS * 370,
                  height: heightS * 340,
                  display: checkMinimize("new-system") ? "none" : "",
                  position: "absolute",
                  right:
                    containerRef.current.getBoundingClientRect().width -
                    systemPopupRef.current.getBoundingClientRect().width -
                    systemPopupRef.current.getBoundingClientRect().x +
                    widthS * 8,
                  bottom:
                    containerRef.current.getBoundingClientRect().height -
                    systemPopupRef.current.getBoundingClientRect().height -
                    systemPopupRef.current.getBoundingClientRect().y -
                    heightS * 3,
                  borderTopLeftRadius: widthS * 9,
                  borderTopRightRadius: widthS * 9,
                }}
              />
            )} */}
    </>
  )

  return (
    <>
      <AnimationLoading flag={animationFlag} />
      {showNewActionFlag && (
        <NewAction
          flag={showNewActionFlag}
          closeAction={() => dispatch(setShowActions(false))}
          setFlag={() => dispatch(showNewAction(false))}
          actionLength={actionsArr && actionsArr.length}
          showThank={() => setShowThanksPage(true)}
          refC={containerRef}
        />
      )}
      <View style={[styles.body, { height: heightWindow < heightScreen - 200 ? heightScreen : "100%" }]} ref={refC}>
        <ImageBackground
          source={{ uri: images.backgroundImage }}
          resizeMode="cover"
          imageStyle={styles.imageBack}
          style={styles.image}
          ref={refBack}
        >
          <View
            ref={widthScreen - widthWindow > 100 ? containerRef : null}
            style={[styles.container, { justifyContent: widthScreen - widthWindow > 100 ? "center" : "space-between", }]}>
            {widthScreen - widthWindow > 100
              ?
              popups
              :
              <View
                style={{
                  position: "relative",
                  width: widthScreen - widthWindow > 100 ? "100%" : "",
                  height: widthScreen - widthWindow > 100 ? "100%" : "",
                  flex: widthScreen - widthWindow > 100 ? null : 1,
                  alignItems: "center",
                  zIndex: 3,

                }}
                ref={containerRef}
              >
                {popups}
                <View style={styles.left}>
                  <Left_Menu backgroundRef={refBack} />
                </View>
                <View
                  style={[
                    styles.bottom,
                    {
                      backgroundColor: background.bottomMenu,
                      borderColor: color.border,
                    },
                  ]}
                >
                  <Bottom_Menu />
                </View>
              </View>}
            {widthScreen - widthWindow > 100 ?
              <View style={styles.left}>
                <Left_Menu backgroundRef={refBack} />
                <View
                  style={[
                    styles.bottom,
                    {
                      backgroundColor: background.bottomMenu,
                      borderColor: color.border,
                    },
                  ]}
                >
                  <Bottom_Menu />
                </View>
              </View>
              : null}
            {/* </View> */}
            <View style={[styles.mainRight, { zIndex: 2, }]}>
              <View
                style={[styles.right]}
              >
                <Right_Menu closeWebSocket={closeWebSocket} />
              </View>
            </View>
            {showFileFlag.link.length > 0 && <ShowFile />}
          </View>
        </ImageBackground>
      </View>
    </>
  );
}
const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    body: {
      width: Dimensions.get("window").width,
    },
    imageBack: {
      height: "100%",
      width: Dimensions.get("window").width,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    image: {
      width: Dimensions.get("window").width,
      height: "100%",
    },
    container: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      zIndex: 1,
    },
    left: {
      flex: 1,
      display: "flex",
      alignSelf: "flex-start",
      width: "100%",
      height: "100%",
    },
    bottom: {
      height: heightS * 46,
      display: "flex",
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "space-around",
      borderTopLeftRadius: widthS * 10,
      borderTopEndRadius: widthS * 10,
      zIndex: 2,
    },
    right: {
      height: "100%",
      zIndex: 10,
    },
  });
};
