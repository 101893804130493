import { View, StyleSheet } from "react-native";
import React from "react";

// container for centering the popups according to left menu

export default function LeftMenuContainerPopups({ refC, children }) {
  return (
    <View
      style={[
        {
          width: refC.current?.getBoundingClientRect().width,
          height: refC.current?.getBoundingClientRect().height,
        },
        styles.refContainer,
      ]}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  refContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "center",
  },
});
