import { Animated, Dimensions, PanResponder, View } from 'react-native'
import React, { useEffect, useRef, useState } from 'react';
import { getImagePic } from '../modules/GetColorPixel';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import UseResponsiveScreen from '../modules/UseResponsiveScreen';

export default function MouseCursor({ src, x, y, color }) {

    const { heightS, widthS } = UseResponsiveScreen();

    const background = useSelector((state) => state.style.background);

    const pan = useRef(new Animated.ValueXY()).current;

    const responder = useRef(
        PanResponder.create({
            onMoveShouldSetPanResponder: () => true,
            onPanResponderGrant: () => {
                pan.setOffset({
                    x: pan.x._value,
                    y: pan.y._value
                });
            },
            onPanResponderMove: Animated.event(
                [
                    null,
                    { dx: pan.x, dy: pan.y },
                ],
                {
                    useNativeDriver: true
                }
            ),
            onPanResponderEnd: () => setOpacity(false),
            onPanResponderRelease: (event, state) => {
                pan.flattenOffset();
            }
        })
    ).current;


    useEffect(() => {
        pan.setValue({ x: color ? (x - widthS * 10) : x, y: color ? (y - widthS * 10) : y });
    }, [x, y]);

    return (
        <Animated.View
            style={{
                transform: [{ translateX: pan.x }, { translateY: pan.y }],
                position: "absolute",
                top: 0, 
                left: 0
            }}
            {...responder.panHandlers}
        >
            <View style={{ 
                background: color ? color : "", 
                width: widthS * 20, 
                height: widthS * 20, 
                borderRadius: "50%", 
                borderColor: background.count, 
                borderWidth: widthS * 1, 
                }}/>
        </Animated.View>
    );
}
