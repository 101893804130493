import { View, Text, StyleSheet, Image, Pressable } from "react-native";
import React, { useEffect, useState } from "react";
import i18n, { t } from "i18n-js";
import { useDispatch, useSelector } from "react-redux";
import UseResponsiveSize from "../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../modules/UseResponsiveScreen";
import { changeBuildFlag, changeInfoFlag } from "../../../../redux/actions/creatorActions";

export default function UserInfo({ dashboard }) {

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const colors = useSelector((state) => state.style.color)
  const styles = stylesR(width, height, heightS, widthS, colors);

  const dispatch = useDispatch();

  const images = useSelector((state) => state.style.images);
  const user = useSelector(state => state.user.user);
  const logo = useSelector(state => state.style.logo);
  const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

  const imageUser = useSelector(state => state.user.user.profile_image);

  const [logoState, setLogoState] = useState(logo);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setLogoState(logo);
  }, [logo]);

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return () => {
      clearInterval(timerID);
    };
  });

  const tick = () => {
    setDate(new Date());
  }

  return (
    <View style={[styles.container(dashboard), !dashboard && styles.border]}>
      <View style={{ flex: 1 }}>
        <Pressable style={[styles.logoContainer, { backgroundColor: colors.logoColor }]}>
          {logoState ? <Image style={styles.logo(dashboard)} source={{ uri: logoState }} /> : null}
        </Pressable>
      </View>
      {dashboard ?
        <View style={styles.viewDetailsDashboard}>
          <Text style={[styles.userName(dashboard)]}>{gameInfo?.game_name}</Text>
          <Text style={[styles.dateAndTime]}>{`${date.toLocaleTimeString('en-US',
            { hour12: true, hour: '2-digit', minute: '2-digit', second: "2-digit" })} | ${date.toDateString().slice(0, 3) + "." + date.toDateString().slice(3).replace(' 202', ', 202')}`}</Text>
        </View>
        :
        <View style={{ flex: 2.5, alignItems: "center" }}>
          <Text style={[styles.headerTitle]}>{`${i18n.t("hello")},`}</Text>
          <View style={[styles.userImageView, { shadowColor: colors.logoColor, borderColor: (colors.logoColor) }]}>
            <Image style={styles.userImage} source={{ uri: imageUser ? imageUser : images.user_image }} />
          </View>
          <Text style={[styles.userName()]}>{`${user.firstName} ${user.lastName}`}</Text>
          <Text style={[styles.userTitle]}>{t("game_creator")}</Text>
        </View>
      }
    </View>
  );
}

const stylesR = (width, height, heightS, widthS, colors) => {
  return StyleSheet.create({
    container: (flag) => ({
      alignItems: "center",
      flex: 1,
      flexDirection: flag ? "row" : ""
    }),
    logoContainer: {
      borderBottomLeftRadius: widthS * 15,
      borderBottomRightRadius: widthS * 15,
      paddingVertical: heightS * 5,
      paddingHorizontal: widthS * 14,
    },
    logo: (flag) => ({
      width: flag ? "5vw" : "6vw",
      height: flag ? "3vw" : "4vw",
      resizeMode: "contain",
    }),
    border: {
      borderRightWidth: 1,
      borderRightColor: colors.infoBorder
    },
    viewDetailsDashboard: {
      marginLeft: "1.5vw",
      alignItems: "flex-start"
    },
    headerTitle: {
      fontSize: widthS * 10,
      paddingTop: heightS * 15,
      color: colors.buildTitle
    },
    userImageView: {
      borderRadius: "50%",
      borderWidth: widthS * 1.5,
      marginTop: heightS * 10,
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.8,
      shadowRadius: 15
    },
    userImage: {
      width: widthS * 28,
      height: widthS * 26,
      borderRadius: "50%",
    },
    userName: (flag) => ({
      fontSize: "1vw",
      color: colors.buildTitle,
      marginTop: flag ? "" : heightS * 5,
      textAlign: "center",
      lineHeight: "1vw",
      fontFamily: flag ? "Catamaran_700Bold" : "Catamaran_300Light"
    }),
    userTitle: {
      fontSize: "0.9vw",
      lineHeight: "1vw",
      fontFamily: "Catamaran_300Light",
      color: colors.buildTitle
    },
    dateAndTime: {
      fontSize: "1vw",
      lineHeight: "1.2vw",
      fontFamily: "Catamaran_300Light",
      color: colors.buildTitle
    },
  });
}
