import React, { useEffect, useState } from 'react';
import { Dimensions, Pressable, View, Text, StyleSheet } from 'react-native';

import FirstItem from '../right-menu-items/FirstItem';
import DateAndTime from '../right-menu-items/DateAndTime';
import IndexCarrousel from '../right-menu-items/IndexCarrousel';
import { helpFlag, settingFlag } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Help from '../right-menu-items/Help';
import Setting from '../right-menu-items/Setting';
import { MaterialIcons } from '@expo/vector-icons';


export default function Right_Menu({ closeWebSocket }) {
      const dispatch = useDispatch();
      const color = useSelector((state) => state.style.color);
      const background = useSelector((state) => state.style.background);
      const { width: widthScreen, height: heightScreen } = Dimensions.get("screen");
      const { width: widthWindow, height: heightWindow } = Dimensions.get("window");

      const styles = stylesR(background, color);

      const [display, setDisplay] = useState(true);

      const showHelp = useSelector(state => state.setting.flags.help);
      const showSetting = useSelector(state => state.user.flag);
      const buildFlag = useSelector(state => state.build.flag);

      useEffect(() => {
            if (buildFlag || widthWindow < (widthScreen / 3) * 2) {
                  setDisplay(false);
            } else {
                  setDisplay(true);
            }
      }, [widthWindow, widthScreen === widthWindow, buildFlag]);

      return (
            <View style={styles.main(display)}>
                  <Pressable
                        style={styles.btn(display)}
                        onPress={() => setDisplay(!display)}
                  >
                        <MaterialIcons name="double-arrow" style={styles.icon(display)} />
                  </Pressable>
                  <View style={styles.container(display)}>
                        <View>
                              <FirstItem closeWebSocket={closeWebSocket} />
                        </View>
                        <View>
                              <DateAndTime />
                        </View>
                        <IndexCarrousel />
                        {<Help flag={showHelp} setFlag={() => dispatch(helpFlag())} />}
                        {<Setting flag={showSetting} setFlag={() => dispatch(settingFlag())} />}
                  </View>
            </View>
      )
}

const stylesR = (background, color) =>
      StyleSheet.create({
            main: (display) => ({
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  height: "100%",
                  width: display ? "24vw" : "2vw",
            }),
            btn: (flag) => ({
                  zIndex: 5,
                  position: "absolute",
                  top: "1vw",
                  left: flag ? "-0.7vw" : "",
                  paddingHorizontal: "0.3vw",
                  paddingVertical: "0.5vw",
                  borderTopLeftRadius: "0.4vw",
                  borderBottomLeftRadius: "0.4vw",
                  backgroundColor: background.rightMenu
            }),
            icon: (flag) => ({
                  color: color.text2,
                  fontSize: "1.1vw",
                  fontFamily: "Catamaran_600SemiBold",
                  transform: [{ rotate: flag ? "0" : "180deg" }]
            }),
            container: (display) => ({
                  width: "23vw",
                  display: display ? "" : "none",
                  backgroundColor: background.rightMenu,
                  height: "100%"
            })
      });