import { View, Text, Modal, Pressable, StyleSheet, Image } from "react-native";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ionicons } from "@expo/vector-icons";
import UseResponsiveSize from "../../../../../modules/UseResponsiveSize";
import { t } from "i18n-js";
import UseResponsiveScreen from "../../../../../modules/UseResponsiveScreen";
import { FilterSelect } from "./FilterSelect";
import { markPlayer, markPlayerFilter, setFlowFilters } from "../../../../../../redux/actions/creatorActions";

export const FilterPopup = React.forwardRef(({ show }, ref) => {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const dispatch = useDispatch();
  const playersRealState = useSelector((state) => state.players.realPlayers);
  const playersRepState = useSelector((state) => state.players.repPlayers);
  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const events = useSelector((state) => state.events.events);
  const actionsArrState = useSelector((state) => state.swimlane.actions);

  const realPlayers = useMemo(() => playersRealState, [playersRealState]);

  const [showPlayerSelect, setShowPlayerSelect] = useState();
  const [showEventSelect, setShowEventSelect] = useState();
  const [showPlatformSelect, setShowPlatformSelect] = useState();
  const [active, setActive] = useState();
  const [filters, setFilters] = useState({
    player: "",
    event: "",
    platform: "",
  });
  const [players, setPlayers] = useState([]);

  const addAll = (filter, list) => {
    let ids = "";
    list.forEach((e, i) => {
      ids += `${e.id},`;
    });
    setFilters((filters) => {
      return { ...filters, [filter]: ids };
    });
  };

  useEffect(() => {
    dispatch(setFlowFilters(filters));
  }, [filters]);

  useEffect(() => {
    setFilters({
      player: "",
      event: "",
      platform: "",
    });
  }, [actionsArrState]);

  useEffect(() => {
    setPlayers([...playersRealState, ...playersRepState]);
  }, [playersRealState, playersRepState]);

  useEffect(() => {
    dispatch(markPlayerFilter(filters.player));
  }, [filters.player]);

  return (
    <Modal animationType="none" transparent={true} visible={show}>
      <View style={styles.model}>
        <View style={styles.modelView}>
          <View
            style={[
              styles.traingular,
              { borderBottomColor: background.eventActive },
            ]}
          />
          <View
            style={[
              styles.popup,
              {
                backgroundColor: background.eventActive,
              },
            ]}
            ref={ref}
          >
            <View
              style={[
                styles.header,
                {
                  borderColor: color.infoBorder,
                },
              ]}
            >
              <Text
                style={{
                  color: color.infoHeaderNavText,
                  fontSize: widthS * 10,
                }}
              >
                <Image
                  source={{ uri: images.filterIcon }}
                  style={{ width: widthS * 10, height: widthS * 10 }}
                />{" "}
                {t("filter_by").toUpperCase()}
              </Text>
              <View style={{ flexDirection: "row" }}>
                <Pressable
                  onPress={() =>
                    setFilters({
                      player: "",
                      event: "",
                      platform: "",
                    })
                  }
                >
                  <Text
                    style={{
                      color: color.buildTitle,
                      fontSize: widthS * 9,
                    }}
                  >
                    {`${t("reset")}`}
                  </Text>
                </Pressable>
              </View>
            </View>
            <View
              style={{
                paddingVertical: heightS * 10,
                paddingLeft: widthS * 20,
                paddingRight: widthS * 5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FilterSelect
                  type={'player'}
                  show={showPlayerSelect}
                  setShow={setShowPlayerSelect}
                  title={t("specific_player")}
                  options={players}
                  active={active}
                  setActive={setActive}
                  filters={filters.player}
                  checkedFunc={() => realPlayers.every(player => filters.player.split(",").includes(player.id))}
                  names={filters.player
                    .split(",")
                    .map((id) =>
                      players
                        .find((player) => player.id === id)
                        ?.name_for_game.trim()
                    )}
                  pressAllOptionHandler={(type) => {
                    if (type) {
                      const missingRealPlayers = realPlayers.filter(player => !filters.player.split(",").includes(player.id));
                      if (missingRealPlayers.length > 0) {
                        addAll("player", [...missingRealPlayers, ...players.filter((e) => filters.player.indexOf(e.id) >= 0)]);
                      } else {
                        let playersFilter = filters.player
                          .split(",")
                          .filter(player => player && !realPlayers.find(realPlayer => realPlayer.id === player))
                          .join(",");
                        setFilters(filters => ({ ...filters, player: playersFilter }));
                      }
                    } else {
                      if (
                        filters.player.split(",").filter((id) => id.trim())
                          .length >= players.length
                      ) {
                        setFilters((filters) => {
                          return { ...filters, player: "" };
                        });
                      } else {
                        addAll("player", players);
                      }
                    }
                  }}
                  setFilters={(value) => {
                    const temp = { ...filters };
                    const ids = temp.player.split(",");
                    const idIndex = ids.findIndex((id) => id === value.id);
                    if (idIndex > -1) {
                      ids.splice(idIndex, 1);
                    } else {
                      ids.push(value.id);
                    }
                    setFilters({
                      ...temp,
                      player: ids.join(","),
                    });
                  }}
                  all={t("all_players")}
                />
                <FilterSelect
                  type={'event'}
                  show={showEventSelect}
                  setShow={setShowEventSelect}
                  title={t("event")}
                  options={events.filter((event) => event.title)}
                  active={active}
                  setActive={setActive}
                  filters={filters.event}
                  names={filters.event
                    .split(",")
                    .map((id) =>
                      events.find((event) => event.id == id)?.title?.trim()
                    )}
                  pressAllOptionHandler={() => {
                    if (
                      filters.event.split(",").filter((id) => id.trim())
                        .length >=
                      events.length - 1
                    ) {
                      setFilters((filters) => {
                        return { ...filters, event: "" };
                      });
                    } else {
                      addAll("event", events);
                    }
                  }}
                  setFilters={(value) => {
                    const temp = { ...filters };
                    const ids = temp.event.split(",");
                    const idIndex = ids.findIndex(
                      (id) => id === value.id.toString()
                    );
                    if (idIndex > -1) {
                      ids.splice(idIndex, 1);
                    } else {
                      ids.push(value.id);
                    }
                    setFilters({
                      ...temp,
                      event: ids.join(","),
                    });
                  }}
                  all={t("all_events")}
                />
                <FilterSelect
                  type={'platform'}
                  show={showPlatformSelect}
                  setShow={setShowPlatformSelect}
                  title={t("platform_type")}
                  options={[t("social_media"), t("mail"), t("news")]}
                  active={active}
                  setActive={setActive}
                  filters={filters.platform}
                  names={filters.platform
                    .split(",")
                    .map((platform) =>
                      platform === "mail"
                        ? t("mail")
                        : platform === "news"
                          ? t("news")
                          : platform === "sm"
                            ? t("social_media")
                            : undefined
                    )}
                  pressAllOptionHandler={() => {
                    if (
                      filters.platform
                        .split(",")
                        .filter((platform) => platform.trim()).length === 3
                    ) {
                      setFilters((filters) => {
                        return { ...filters, platform: "" };
                      });
                    } else {
                      setFilters((filters) => {
                        return { ...filters, platform: "mail, news, sm" };
                      });
                    }
                  }}
                  setFilters={(value) => {
                    value =
                      value === t("mail")
                        ? "mail"
                        : value === t("news")
                          ? "news"
                          : "sm";
                    const temp = { ...filters };
                    const platforms = temp.platform.split(",");
                    const platformIndex = platforms.findIndex(
                      (platform) => platform === value
                    );
                    if (platformIndex > -1) {
                      platforms.splice(platformIndex, 1);
                    } else {
                      platforms.push(value);
                    }
                    setFilters({
                      ...temp,
                      platform: platforms.join(","),
                    });
                  }}
                  all={t("all_platforms")}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
});

const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    model: {
      position: "relative",
    },
    modelView: {
      position: "absolute",
      right: 30 * widthS,
      top: 43 * heightS,
      alignItems: "center",
      justifyContent: "center",
    },
    traingular: {
      position: "absolute",
      top: heightS * -8,
      right: widthS * 57,
      width: 0,
      height: 0,
      borderLeftWidth: widthS * 8,
      borderRightWidth: widthS * 8,
      borderBottomWidth: widthS * 7,
      borderStyle: "solid",
      backgroundColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      zIndex: 3,
    },
    popup: {
      marginTop: -1,
      paddingTop: heightS * 15,
      width: widthS * 500,
      zIndex: 1,
      borderRadius: 25 * widthS,
      shadowColor: "#000000",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.7,
      shadowRadius: 6,
    },
    header: {
      width: "100%",
      flexDirection: "row",
      paddingHorizontal: widthS * 30,
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: heightS * 10,
      borderBottomWidth: 1,
    },
  });
