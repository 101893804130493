import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Modal,
  Pressable,
  Dimensions,
  ScrollView,
  Image,
  TouchableOpacity
} from "react-native";
import { t } from "i18n-js";
import { useDispatch, useSelector } from "react-redux";
import { ActionsContext } from "../../../ContextData/Actions";
import { showActionInfo } from "../../../redux/actions";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { USER_GAME_NAME, USER_ID_IN_GAME, USER_ID_IN_GAME_OBSERVER } from "../../../config.inc";
import { findCreateName } from "../../modules/FindNameById";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";
import { getDateTimeObject } from "../../../EditDate";

export default function Action_info({ setDrag }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const popupRef = useRef(null);
  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const dispatch = useDispatch();

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);

  const actionSate = useSelector((state) => state.actionsArr.actionInfo);
  const [action, setAction] = useState();

  useMemo(() => {
    setAction(actionSate);
  }, [actionSate]);

  const printSharedList = () => {
    if (action && action.action.share_list) {
      let shared = action.action.share_list;
      return (
        <View style={{ display: "flex", flexDirection: "column" }}>
          {gameInfo &&
            gameInfo.players &&
            [...gameInfo.players, { name_for_game: sessionStorage.getItem(USER_GAME_NAME), id: sessionStorage.getItem(USER_ID_IN_GAME) }].map((e, i) => {
              if (shared.indexOf(e.id) >= 0) {
                if (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) ? e.id === sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) : e.id === sessionStorage.getItem(USER_ID_IN_GAME)) return
                return (
                  <Text selectable={false} style={styles.share_name}>
                    {e.name_for_game}
                  </Text>
                );
              }
            })}
        </View>
      );
    }
  };
  return (
    <View
      ref={popupRef}
      style={[
        styles.main,
        { backgroundColor: background.popup1, borderColor: color.border },
      ]}
    >
      <View style={{ width: "100%", height: "100%" }}>
        <TouchableOpacity style={{ cursor: "grabbing" }} onPressIn={() => setDrag(true)}>
          <View style={[styles.top, { borderBottomColor: color.border }]}>
            <View style={styles.headerLeft}>
              <View
                style={[
                  styles.logo_view,
                  { backgroundColor: background.actionLogo },
                ]}
              >
                <Image source={{ uri: images.action }} style={styles.logo} />
              </View>
              <Text selectable={false} style={styles.textLogo} numberOfLines={1}>
                {action && action.action.name
                  ? action.action.name
                  : `${t("challenge")}# ${action && action.index + 1}`}
              </Text>
            </View>
            <HeaderPopupButtons
              type={true}
              close={() => dispatch(showActionInfo(false))}
            />
          </View>
        </TouchableOpacity>
        <ScrollView
          nativeID="action"
          style={styles.body_c}
          contentContainerStyle={styles.body}
        >
          <View style={[styles.detailes, { borderBottomColor: color.border }]}>
            <Text
              selectable={false}
              style={[styles.headers, { color: color.actionTh }]}
            >
              {t("action_time")}
            </Text>
            <Text selectable={false} style={styles.time}>{action &&
              (() => {
                const { date, time } = getDateTimeObject(`${action.action.time} utc`);
                return `${date} | ${time}`
              })()
            }</Text>
            {/* <Text selectable={false} style={styles.time}>{`${action &&
              new Date(
                action.action.time.slice(0, action.action.time.indexOf(" "))
              ).toDateString()
              } | ${action &&
              new Date(`${action.action.time.split(" ")[0]}` + "T" + `${action.action.time.split(" ")[1]}` + "Z").toLocaleTimeString(new Date().getTimezoneOffset(), { hour12: false, hour: "2-digit", minute: "2-digit" })
              }`}</Text> */}
            <Text
              selectable={false}
              style={[styles.headers, { color: color.actionTh }]}
            >
              {t("your-challenge")}
            </Text>
            <Text selectable={false} style={styles.description}>
              1.{" "}
              {action && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: action.action.description,
                  }}
                />
              )}
            </Text>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View>
                <Text
                  selectable={false}
                  style={[styles.headers, { color: color.actionTh }]}
                >
                  {t("related-event")}
                </Text>
                <Text selectable={false} style={styles.description}>
                  {action && action.action.event}
                </Text>
              </View>
              <View>
                <Text
                  selectable={false}
                  style={[styles.headers, { color: color.actionTh }]}
                >
                  {t("create_by")}
                </Text>
                <Text selectable={false} style={styles.description}>
                  {action && findCreateName(action.action.creator_id, gameInfo?.players || [])}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.solutions, { borderBottomColor: color.border }]}>
            <Text
              selectable={false}
              style={[styles.headers, { color: color.actionTh }]}
            >
              {t("solutions")}
            </Text>
            <View style={styles.solutions_items}>
              {action &&
                action.solutions &&
                action.solutions.sort((a, b) => a.order - b.order)
                  .map((e, i) => {
                    return (
                      <View
                        style={[
                          action.decision &&
                            e.id === action.decision.related_actions
                            ? [
                              styles.solution_hover,
                              {
                                borderColor: color.actionItemHover,
                                backgroundColor: background.actionSelect,
                              },
                            ]
                            : styles.solution,
                        ]}
                        key={i}
                      >
                        <Text selectable={false} style={styles.solution_text}>
                          {`${i + 1}. `}
                          {e.description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: e.description
                              }}
                            />
                          ) : (
                            e.name
                          )}
                        </Text>
                      </View>
                    );
                  })}
            </View>
          </View>
          <View style={styles.shared}>
            <Text
              selectable={false}
              style={[styles.headers, { color: color.actionTh }]}
            >
              {t("shared_with")}
            </Text>
            {printSharedList()}
          </View>
        </ScrollView>
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, direction) =>
  StyleSheet.create({
    main: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#00000",
      fontFamily: "Roboto",
      shadowColor: "black",
      shadowOffset: { width: -1, height: -1 },
      shadowOpacity: 0.2,
      shadowRadius: 2.6,
      elevation: 100,
      height: "100%",
      borderRadius: width > 1600 ? 34 : 25,
    },
    top: {
      paddingLeft: widthS * 16,
      marginLeft: widthS * 8,
      borderBottomWidth: widthS * 1,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: widthS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
      maxWidth: "30vw",
      fontFamily: "Catamaran_700Bold",
    },
    x: {
      fontWeight: "350",
      fontSize: widthS * 14,
    },
    body_c: {
      paddingHorizontal: widthS * 15,
      marginHorizontal: widthS * 5,
      marginVertical: heightS * 10,
    },
    body: {
      display: "flex",
      flexDirection: "column",
      height: heightS * 300,
    },
    action_name: {
      fontSize: widthS * 12,
    },
    time: {
      color: "white",
      fontSize: widthS * 9,
    },
    headers: {
      fontSize: widthS * 8,
      marginTop: heightS * 8,
    },
    detailes: {
      paddingLeft: widthS * 3,
      borderBottomWidth: widthS * 1,
      borderStyle: "solid",
      paddingBottom: heightS * 20,
    },
    description: {
      color: "white",
      fontSize: widthS * 11,
      display: "flex",
    },
    solutions: {
      paddingLeft: widthS * 3,
      borderBottomWidth: widthS * 1,
      borderStyle: "solid",
      paddingBottom: heightS * 5,
    },
    solutions_items: {
      marginTop: heightS * 3,
      minHeight: heightS * 70,
    },
    solution: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      marginTop: heightS * 1.5,
      marginBottom: heightS * 1.5,
      paddingHorizontal: widthS * 6,
      left: widthS * -10,
      width: "100%",
      borderWidth: widthS * 1,
      borderRadius: widthS * 2,
      borderColor: "transparent",
      minHeight: heightS * 23.7,
      writingDirection: direction
    },
    solution_hover: {
      borderWidth: widthS * 0.5,
      borderStyle: "solid",
      borderRadius: 2,
      minHeight: heightS * 23.7,
      marginTop: heightS * 1.35,
      marginBottom: heightS * 1.35,
      paddingHorizontal: widthS * 6,
      left: widthS * -10,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
      writingDirection: direction
    },
    solution_text: {
      color: "white",
      fontSize: widthS * 11,
      display: "flex",
    },
    shared: {
      paddingLeft: widthS * 3,
    },
    share_name: {
      color: "white",
      lineHeight: heightS * 20,
      fontSize: widthS * 9,
    },
  });
