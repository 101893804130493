export const createQuestionArr = (questions) => {
    let submitted = false;
    questions.forEach((question) => {
        question.answers.forEach((e) => {
            if(e.checked){
                submitted = true;
            }
        })
    })
    questions.submitted = submitted;
    return questions
}

export const createQuestionnaireObject = (arr) => {
    let obj = {
        ...arr,
        subject: arr.subject,
        question_count: arr.questionsArr.length,
    };

    return obj;
}   