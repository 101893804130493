import React, { createContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL_PATH, GET_ACTION_LIST, ADD_ACTION } from "@env";
import { F_PHPSESSID, USER_ID_IN_GAME, _F_PHPSESSID_ } from "../config.inc";
import { addAction, chooseSolution, setAction } from "../redux/actions/index";
import { updateLastTime } from "./contextFunction";

export const ActionsContext = createContext();
export default function ActionsProvider(props) {
  const dispatch = useDispatch();
  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const permission = useSelector((state) => state.gameInfoArr.permission);
  const eventsArr = useSelector(state => state.events.events)

  const [hasFetched, setHasFetched] = useState(false);

  const getActionsData = () => {
    const url = `${URL_PATH}${GET_ACTION_LIST}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    try {
      fetch(url, { method: "POST", body: body })
        .then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
          if (
            res.status === "true" &&
            res.actions_list &&
            res.actions_list.length > 0
          ) {
            sessionStorage.setItem("last_id_actions", res?.actions_list[0]?.id)
            if (eventsArr?.length > 0) {
              dispatch(setAction(res.actions_list, permission, eventsArr));
            }
          } else {
          }
        })
        .catch((err) => { });
    } catch (e) { }
  };

  // const chooseSolutionData = (name, index) => {
  //     if (name && index >= 0) {
  //         dispatch(chooseSolution(name, index));
  //     }
  // }

  const addActionData = (action) => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${ADD_ACTION}`;
      const body = new FormData();
      let arr = [];

      // יצירת עותקים של האובייקטים במערך solutions
      const solutionsCopy = action.solutions.map(solution => ({ ...solution }));

      // יצירת עותק של האובייקט action
      const actionCopy = { ...action.action };
      actionCopy.to_list = action.shareList;

      // הוספת האובייקט action למערך arr
      arr.push(actionCopy);

      // הוספת כל האיברים מהמערך solutionsCopy למערך arr
      arr.push(...solutionsCopy);

      // קידוד של שדות הטקסט במערך arr
      arr.forEach((e) => {
        e.subject = encodeURI(e.subject);
        e.action_body = encodeURI(e.action_body);
      });

      // הוספת הפרמטרים לגוף הבקשה
      body.append("solution", action.decision);
      body.append("actions", JSON.stringify(arr));
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));

      try {
        // ביצוע בקשת רשת וטיפול בתוצאה
        return fetch(url, { method: "post", body: body })
          .then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res[0]?.add_new_action !== "false") {
              props.sentMessageWebSocket("actions", action?.shareList);
              return resolve(true);
            } else {
              reject(false);
            }
          })
          .catch((err) => reject(false));
      } catch (e) {
        reject(false);
      }
    });
  };

  // useEffect(() => {
  //   if (props.typeRefresh === "actions") {
  //     getActionsData();
  //   }
  // }, [props.typeRefresh]);

  useEffect(() => {
    if (props.loginType !== "in") {
      dispatch(setAction([]));
    }
  }, [props.loginType]);

  useEffect(() => {
    // let myInterval;
    if (eventsArr.length > 1 && !hasFetched) {
      setHasFetched(true);
      getActionsData();
      // myInterval = setInterval(() => {
      //   getActionsData();
      // }, 30000);
    }
    // return () => {
    //   clearInterval(myInterval);
    // }
  }, [eventsArr, hasFetched]);

  return (
    <ActionsContext.Provider value={{ getActionsData, addActionData }}>
      {props.children}
    </ActionsContext.Provider>
  );
}
