import React, { useEffect, useRef } from "react";
import { StyleSheet, View, Text, Pressable, Modal, Image, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import MessagesBody from "./MessagesBody";
import { t } from "i18n-js";
import { minimizeNewSystem, removeType, setNewSystemFlag, setTypeMinimize } from "../../../redux/actions";
import { FontAwesome } from '@expo/vector-icons';
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";

export default function SystemMessages({ flag, setFlag, popupRef, setDrag }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const modalRef = useRef(null);
  const messageBodyRef = useRef(null);

  const dispatch = useDispatch();
  const sentMessages = useSelector(
    (state) => state.systemMessages.sentMessages
  );
  const messages = useSelector((state) => state.systemMessages.messages);
  const drafts = useSelector((state) => state.systemMessages.drafts);
  const arrangeInbox = useSelector(
    (state) => state.systemMessages.arrangeInbox
  );
  const arrangeSent = useSelector((state) => state.systemMessages.arrangeSent);
  const arrangeDrafts = useSelector(
    (state) => state.systemMessages.arrangeDrafts
  );

  const minimizePopups = useSelector((state) => state.minimize.minimize);

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);

  const closeMailBoxHandler = () => {
    dispatch(minimizeNewSystem());
    setFlag();
  };

  return (
    <View
      style={[styles.main, { backgroundColor: background.popup1 }]}
      ref={popupRef}
    >
      <View style={{ width: "100%", height: "100%" }}>
        <TouchableOpacity style={{ cursor: "grabbing" }} onPressIn={() => setDrag(true)}>
          <View style={[styles.header, { borderBottomColor: color.border }]}>
            <View style={styles.headerLeft}>
              <View
                style={[
                  styles.logo_view,
                  { backgroundColor: background.mailLogoColor },
                ]}
              >
                <Image source={{ uri: images.system }} style={styles.logo} />
              </View>
              <Text selectable={false} style={styles.textLogo}>
                {t("menu_messages")} {t("menu_system")}
              </Text>
            </View>
            <HeaderPopupButtons
              close={() => { closeMailBoxHandler(); dispatch(removeType("system")) }}
              minimize={() => {
                dispatch(setTypeMinimize({ name: "system", type: 1, show: false }));
              }}
            />
          </View>
        </TouchableOpacity>
        <MessagesBody
          messageBodyRef={messageBodyRef}
          messages={messages}
          sentMessages={sentMessages}
          drafts={drafts}
          type={"system"}
          arrangeInbox={arrangeInbox}
          arrangeSent={arrangeSent}
          arrangeDrafts={arrangeDrafts}
        />
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    main: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#00000",
      fontFamily: "Roboto",
      height: "100%",
      borderRadius: widthS * 15,
      borderBottomLeftRadius: widthS * 10,
      // ...style.popup2,
    },
    header: {
      paddingLeft: widthS * 17.2,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: widthS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
    },
    x: {
      fontFamily: "Catamaran_300Light",
      fontSize: widthS * 14,
    },
  });
