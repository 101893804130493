import {
  LOGIN,
  FORGOT,
  GAME_LIST,
  TOKEN,
  IN_GAME,
  START_GAME,
  FACTOR,
  POPUPS_COUNT,
  CREATOR_OPTIONS,
  NEW_GAME_CREATOR,
  END,
} from "../actions/index";
export const loginOrRegister = (
  state = { type: "login", token: false, startGame: true, popupsCount: 0,  },
  action
) => {
  switch (action.type) {
    case LOGIN: {
      return { ...state, type: "login" };
    }
    case FORGOT: {
      return { ...state, type: "forgot" };
    }
    case END: {
      return { ...state, type: "end" };
    }
    case CREATOR_OPTIONS: {
      return { ...state, type: "creator_options", token: false }
    }
    case NEW_GAME_CREATOR: {
      return { ...state, type: "new_game_creator" }
    }
    case GAME_LIST: {
      if (action.payload) {
        switch (action.payload) {
          case "real":
            return { ...state, type: "game_list_real" };
          case "demo":
            return { ...state, type: "game_list_demo" };
        }
      }
      return { ...state, type: "game_list" };
    }
    case IN_GAME: {
      return { ...state, type: "in" };
    }
    case START_GAME: {
      return { ...state, startGame: action.flag };
    }
    case TOKEN: {
      return { ...state, token: action.payload };
    }
    case FACTOR: {
      return { ...state, type: "factor" };
    }
    case POPUPS_COUNT: {
      return { ...state, popupsCount: action.count }
    }
    default:
      return state;
  }
};
