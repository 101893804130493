import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { connect, useSelector } from 'react-redux';
import UseResponsiveScreen from '../modules/UseResponsiveScreen';
import UseResponsiveSize from '../modules/UseResponsiveSize';

export default function DateAndTime() {

      const { width, height } = UseResponsiveSize();
      const { widthS, heightS } = UseResponsiveScreen();
      const styles = stylesR(width, height, heightS, widthS);

      const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);
      const color = useSelector(state => state.style.color);
      const [date, setDate] = useState(new Date());
      useEffect(() => {
            var timerID = setInterval(() => tick(), 1000);

            return () => {
                  clearInterval(timerID);
            };
      });

      const tick = () => {
            setDate(new Date());
      }
      return (
            <View style={[styles.container, { borderBottomColor: color.border }]}>
                  <Text selectable={false} style={[styles.gameName, { color: "#fefefe" }]}>{gameInfo.game_name}</Text>
                  <View>
                        <Text selectable={false} style={[styles.clock, { color: color.rightMenuClockAndDate }]}>{date.toLocaleTimeString('en-US',
                              { hour12: true, hour: 'numeric', minute: 'numeric' }
                        )}</Text>
                        <Text selectable={false} style={[styles.date, { color: color.rightMenuClockAndDate }]}> {date.toDateString().slice(0, 3) + "." + date.toDateString().slice(3).replace(' 202', ', 202')}</Text>
                  </View>
            </View>
      )
}

const stylesR = (width, height, heightS, widthS) => {
      return StyleSheet.create({
            container: {
                  zIndex: -1,
                  borderBottomWidth: 2,
                  borderStyle: "solid",
                  paddingVertical: heightS * 8,
                  paddingLeft: widthS * 20,
            },
            gameName: {
                  fontSize: widthS * 11,
                  fontFamily: "Catamaran_700Bold",
                  lineHeight: heightS * 14,
                  width: widthS * 200,
                  writingDirection: "ltr",
                  textAlign: "start",
                  marginBottom: heightS * 10
            },
            clock: {
                  marginTop: heightS * 5,
                  fontSize: widthS * 25,
                  lineHeight: heightS * 25,
            },
            date: {
                  fontFamily: "Catamaran_600SemiBold",
                  fontSize: widthS * 10,
            }
      });
}
