
export const checkIfIsEncodeText = (text, type) => {
  try {
    text = decodeURI(text);
    text = text.replace(/\\/g, "");
    return route(text, type);
  } catch (e) {
    return route(text, type);
  }
}
const route = (text, type) => {
  switch (type) {
    case "line":
      return removeNewLine(text);
    case "string":
      return removeSpacialCherectersString(text);
    case "html":
      return removeSpacialCherectersHtml(text);
      default: return removeSpacialCherectersString(text);
  }
}
//TO-DO move to a loop from config
export const removeSpacialCherectersString = (text) => {
  const regex = /%[0-9A-Fa-f]{2}/g;
  const body = text.replace(regex, match => {
    if (match.startsWith('%')) {
      return String.fromCharCode(parseInt(match.substring(1), 16));
    } else {
      return "'";
    }
  });
  // if (body?.search(/(?:\\[rn]|[\r\n])/g) >= 0) {
  //   body = body.replaceAll(/(?:\\[rn]|[\r\n])/g, "\n");
  // }
  // if (body?.search(/\'/g) >= 0) {
  //   body = body.replaceAll(/\\/g, "");
  // }
  // if (body?.search(/%2C/g) >= 0) {
  //   body = body.replaceAll(/%2C/g, ",");
  // }
  // if (body?.search(/%2F/g) >= 0) {
  //   body = body.replaceAll(/%2F/g, "/");
  // }
  // if (body?.search(/%3F/g) >= 0) {
  //   body = body.replaceAll(/%3F/g, "?");
  // }
  // if (body?.search(/%3A/g) >= 0) {
  //   body = body.replaceAll(/%3A/g, ":");
  // }
  return body;
}

export const removeSpacialCherectersHtml = (text) => {
  let body = text;
  if (body?.search(/(?:\\[rn]|[\r\n])/g) >= 0) {
    body = body.replaceAll(/(?:\\[rn]|[\r\n])/g, "\n");
  }
  if (body?.search(/\'/g) >= 0) {
    body = body.replaceAll(/\\/g, "");
  }
  return body;
}

export const removeNewLine = (body) => {
  if (body?.search(/(?:\\[rn]|[\r\n])/g) >= 0) {
    body = body.replaceAll(/(?:\\[rn]|[\r\n])/g, " ");
  }
  return body;
}