import React from "react";
import { StyleSheet, Modal, View, Text, Image, Pressable } from "react-native";
import { t } from "i18n-js";
import { useSelector } from "react-redux";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import LeftMenuContainerPopups from "../../modules/LeftMenuContainerPopups";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";

export default function Thanks({ flag, setFlag, refC }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  return (
    <Modal transparent={true} visible={flag} animationType="none">
      <View style={styles.container}>
        <LeftMenuContainerPopups refC={refC}>
          <View
            style={[
              styles.main,
              { backgroundColor: background.popup1, borderColor: color.border },
            ]}
          >
            <View style={styles.header}>
              <Text
                style={{
                  color: "white",
                  fontSize: widthS * 11,
                  textAlign: "center",
                  fontFamily: "Catamaran_400Regular",
                }}
              >
                {t("challenge-completed")}
              </Text>
              <Text
                style={{
                  color: "white",
                  fontSize: widthS * 9,
                  fontFamily: "Catamaran_300Light",
                  textAlign: "center",
                  marginTop: heightS * 10,
                }}
              >
                {t("thank-you_1")}
              </Text>
              <Text
                style={{
                  color: color.actionTh,
                  fontSize: widthS * 14,
                  fontWeight: "400",
                  textAlign: "center",
                  marginTop: heightS * 25,
                  fontFamily: "Catamaran_400Regular",
                }}
              >
                {t("thankyou")}
              </Text>
              <Image
                source={require("../../../images/check_mark_icon.png")}
                style={styles.logo}
              />
            </View>
            <View style={[styles.footer, { borderTopColor: color.border }]}>
              <Pressable
                style={[
                  styles.btn_next,
                  {
                    backgroundColor: background.actionButtonAdd,
                    borderColor: background.actionButtonAdd,
                  },
                ]}
                onPress={() => setFlag()}
              >
                <Text style={styles.btn_text}>{t("resume-simulation")}</Text>
              </Pressable>
            </View>
          </View>
        </LeftMenuContainerPopups>
      </View>
    </Modal>
  );
}
const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    container: {
      display: "flex",
      height: "100%",
      backgroundColor: "#00000057",
    },
    main: {
      minHeight: heightS * 210,
      maxWidth: widthS * 220,
      borderRadius: widthS * 11,
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.2,
      shadowRadius: 2.6,
      elevation: 100,
      paddingLeft: widthS * 10,
      paddingRight: widthS * 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "absolute",
    },
    header: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: heightS * 20,
      flex: 2,
    },
    logo: {
      width: widthS * 35,
      height: widthS * 35,
      marginVertical: heightS * 10,
      resizeMode: "contain",
    },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 0.6,
      borderTopWidth: 2,
      borderStyle: "solid",
      width: widthS * 190,
    },
    btn_next: {
      borderWidth: 1,
      width: widthS * 90,
      height: heightS * 20,
      marginVertical: "7%",
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
    },
    btn_text: {
      color: "white",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
    refContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      alignItems: "center",
      justifyContent: "center",
    },
  });
