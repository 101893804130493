import { StyleSheet, Text, View, Dimensions, TextInput, ScrollView, Pressable } from 'react-native';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { t } from "i18n-js";
import UseResponsiveSize from '../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';
import Objectives from './Objectives';

export default function StrategyBox({ game_goals, game_objectives, setInfoObj, err, permission, setIsEdit }) {

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(width, height, heightS, widthS, direction);


    const strategyRef = useRef(null);

    const color = useSelector((state) => state.style.color);
    const background = useSelector((state) => state.style.background);

    const [objectives, setObjectives] = useState([
        { text: "", height: 0 },
    ]);
    const [heightGoal, setHeightGoal] = useState(0);


    const printPost = (post) => {
        if (/"\\n\\" || "\\r\\" || "%22" || "\'t"/.test(post)) {
            post = post.replace(/(?:\\[rn]|[\r\n]+)+/g, "\n").replace(/%22/g, '"').replace(/\\'t/g, "'t");
        }
        return post;
    };

    useEffect(() => {
        if (game_objectives) {
            if (game_objectives.length > 0 && game_objectives.match(/%/g)?.length > 0) {
                let inputs = game_objectives.split("%");
                let newInputs = [];
                inputs.map((e, i) => {
                    if (e?.length > 0) {
                        newInputs.push({ text: e });
                    }
                });
                setObjectives(newInputs?.length > 0 ? [...newInputs] : [{}]);
            } else if (game_objectives.length > 0 && game_objectives.match(/@!\$&\*/g)?.length > 0) {
                let inputs = game_objectives.split("@!$&*");
                let newInputs = [];
                inputs.map((e, i) => {
                    if (e?.length > 0) {
                        newInputs.push({ text: e });
                    }
                });
                setObjectives(newInputs?.length > 0 ? [...newInputs] : [{}]);
            }
        }

    }, [game_objectives]);

    return (
        <View style={styles.container} ref={strategyRef}>
            <Text style={[styles.headers, { color: color.infoHeaderNavText }]}>{t("strategy")}</Text>
            <View style={[styles.strategyBox, { backgroundColor: background.infoBox }]}>
                <View style={styles.child}>
                    <Text style={[styles.headers1, { color: color.buildText }]}>{t("goal")}</Text>
                    <ScrollView style={{ maxHeight: heightS * 204, borderRadius: widthS * 9, }} showsVerticalScrollIndicator={false} contentContainerStyle={{ height: heightGoal, minHeight: "100%" }}>
                        <TextInput
                            nativeID='pop-up'
                            onContentSizeChange={(event) => {
                                setHeightGoal(event.nativeEvent.contentSize.height)
                            }}
                            value={game_goals ? printPost(game_goals) : ""}
                            style={[styles.boxInput, { backgroundColor: background.infoBody, color: permission ? color.buildText : color.placeholder, overflow: 'hidden' }, err && !game_goals.length > 0 && styles.mail_error]}
                            multiline={true}
                            editable={permission}
                            placeholder={`${t("simulation_goal")}`}
                            onChangeText={(txt) => { setIsEdit(true); setInfoObj(txt, "game_goals") }}
                        />
                    </ScrollView>
                </View>
                <View style={styles.child}>
                    <Text style={[styles.headers1, { color: color.buildText }]}>{t("objectives")}</Text>
                    <ScrollView style={[{ maxHeight: heightS * 204, borderRadius: widthS * 10, }]} showsVerticalScrollIndicator={false} contentContainerStyle={[styles.box, { backgroundColor: background.infoBody, color: color.buildText }]}>
                        <Objectives setIsEdit={setIsEdit} objectives={objectives} permission={permission} setInfoObj={(temp) => setInfoObj(temp, "game_objectives")} />
                    </ScrollView>
                </View>
            </View>
        </View>
    )
}

const stylesR = (width, height, heightS, widthS, direction) => {
    return StyleSheet.create({
        container: {
            height: "100%",
            width: "45%",
            // height: heightS * 205,
            // width: widthS * 340,
        },
        strategyBox: {
            flex: 0.98,
            borderRadius: widthS * 22,
            display: "flex",
            flexDirection: "row",
            // paddingTop: heightS * 5,
            justifyContent: "space-evenly",
            // paddingHorizontal: widthS * 20,
        },
        headers: {
            fontSize: widthS * 12,
            marginLeft: widthS * 20,
            marginBottom: heightS * 3,
            fontFamily: "Catamaran_500Medium"
        },
        headers1: {
            fontSize: widthS * 10,
            fontFamily: "Catamaran_500Medium",
            marginLeft: widthS * 10,
            marginBottom: heightS * 1
        },
        boxInput: {
            flex: 1,
            // height: heightS * 148,
            borderRadius: widthS * 9,
            outlineStyle: "none",
            paddingHorizontal: widthS * 10,
            paddingVertical: heightS * 7,
            fontSize: widthS * 10,
            fontFamily: "Catamaran_500Medium",
        },
        child: {
            width: "40%",
            height: "90%",
            borderRadius: widthS * 10,
            display: "flex",
            flexDirection: "column",
        },
        box: {
            minHeight: "100%",
            borderRadius: widthS * 10,
            display: "flex",
            flexDirection: "column",
            paddingHorizontal: widthS * 8,
            paddingVertical: heightS * 5,
        },
        mail_error: {
            borderColor: "red",
            borderWidth: "0.1vw",
            borderRadius: widthS * 9,
        }
    })
}