import React, { useState, useEffect, useRef, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, TextInput, Image, useWindowDimensions, TouchableOpacity } from 'react-native';
import TextCustomize from '../../../../Elements/TextCustomize';
import { vwToPx } from '../../../../modules/ConvertPXToVw';
import { listenerAdd, listenerAddWith } from '../../../../../AddListener';
import { getContent } from '../../../../modules/getContent';
import MailBody from './MailBody';
import NewsBody from './NewsBody';
import Questionaire from "../event-components/Questionaire";
import SocialBodyTable from './SocialBodyTable';
import { t } from 'i18n-js';

const BodyCell = ({ cellStyle, index, actionObject, setIsPopupOpen, isDone, scrollRef, updateAction, isNew, isBottom, popupRef, setPopupRef }) => {

    const cellRef = useRef(null);
    const popupContainerRef = useRef(null);

    const questionaireRef = useRef(null);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background, color);

    const [showPopup, setShowPopup] = useState(false);

    const [newbody, setNewBody] = useState("");
    const [newSubject, setNewSubject] = useState("");
    const [newToList, setNewToList] = useState("");

    const returnFullBody = (type, actionType) => {
        switch (type) {
            case "mail":
                if (actionType === "cfaQuestionnaire") {
                    return <Questionaire inject={{ inject: actionObject }} questionaireRef={questionaireRef} isEditing={!isDone} table={true} />
                }
                return <MailBody isNew={isNew} actionType={actionObject["type_action"]} subject={newSubject} body={newbody} setBody={setNewBody} setSubject={setNewSubject} index={index} />
            case "news":
                return <NewsBody subject={newSubject} body={newbody} setBody={setNewBody} setSubject={setNewSubject} />
            case "sm":
                return <SocialBodyTable showPopup={showPopup} body={newbody} setBody={setNewBody} setToList={setNewToList} from={actionObject["from_id"]} to_list={newToList} />
        }
    }

    const saveBody = (newBody, subject, toList) => {
        let updates = {}; // אובייקט לאגירת כל העדכונים

        if (actionObject["type_action"] === "cfaQuestionnaire") {
            let survy = questionaireRef.current.saveSurvy();
            if (survy) {
                updates["subject"] = survy?.subject;
                updates["question_count"] = survy?.question_count;
                updates["questionArr"] = survy?.questionsArr;
            } else {
                return;
            }
        } else {
            if (newBody?.length > 0) {
                updates["body"] = newBody;
            }
            if (subject?.length > 0) {
                updates["subject"] = subject;
            }
            if (toList?.length > 0) {
                updates["to_list_ids"] = toList;
            }
        }
        // קריאה אחת ל-updateAction עם כל העדכונים
        if (Object.keys(updates).length > 0) {
            updateAction(index, updates, !isNew, isNew);
        }
        setShowPopup(false);
    };


    const setState = () => {
        setNewSubject(actionObject["subject"]);
        setNewBody(actionObject["body"]);
        setNewToList(actionObject["to_list_ids"]);
    }

    const openPopup = () => {
        setIsPopupOpen(index); setShowPopup(true);
    }
    const closePopup = () => {
        setIsPopupOpen(""); setShowPopup(false);
    }

    useEffect(() => {
        setState();
    }, [actionObject?.subject, actionObject?.body, actionObject?.to_list_ids, actionObject?.type_action]);

    useEffect(() => {
        window.addEventListener("mousedown", listenerAdd(popupRef, showPopup, () => closePopup()));
        return () => {
            window.removeEventListener("mousedown", listenerAdd(popupRef, showPopup, () => closePopup()));
        }
    }, [showPopup, popupRef?.current, scrollRef?.current]);

    return (
        <View style={cellStyle("28vw")} ref={cellRef}>
            <Pressable onPress={() => openPopup()} style={styles.btnContainer} disabled={isDone}>
                <TextCustomize fontSize={"0.8vw"} style={styles.subject(direction)} numberOfLines={2} text={actionObject["type_action"] === "cfaQuestionnaire" ? t("questionnaire") : getContent(actionObject["body"])} />
            </Pressable>
            {showPopup && <View ref={setPopupRef} style={styles.popup(showPopup, isBottom && isNew)}>
                {returnFullBody(actionObject["platform_type"], actionObject["type_action"])}
                <View style={styles.bottomBtns}>
                    {actionObject["type_action"] === "cfaQuestionnaire" && <TouchableOpacity style={styles.btnAddQuest} disabled={isDone} onPress={() => {
                        questionaireRef.current.addQuest();
                    }}>
                        <TextCustomize keyText={"add_question"} fontSize={"1vw"} fontWeight={300} />
                    </TouchableOpacity>}
                    <View style={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                        <TouchableOpacity style={styles.cancelBtn} onPress={() => closePopup()}>
                            <TextCustomize fontWeight={400} fontSize={"0.9vw"} keyText={"cancel"} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.saveBtn} disabled={isDone} onPress={() => saveBody(newbody, newSubject, newToList)}>
                            <TextCustomize fontWeight={400} fontSize={"0.9vw"} keyText={"save"} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>}
        </View>
    )
};
const stylesR = (background, color) => StyleSheet.create({
    btnContainer: {
        flex: 1,
        height: "100%",
        paddingVertical: "0.5vw"
    },
    subjectInput: {
        outlineStyle: "none",
        fontFamily: "Catamaran_400Regular",
        fontSize: "0.8vw",
        color: color.text,
        flex: 1,
        height: "fit-content",
        overflow: "hidden",
        lineHeight: "1.2vw"
    },
    subject: (direction) => ({
        writingDirection: direction,
        lineHeight: "1.2vw"
    }),
    eyeBtn: (flag) => ({
        alignSelf: "flex-end",
        marginBottom: "0.5vw",
        opacity: !flag ? 0.2 : 1
    }),
    icon: {
        width: "1vw",
        height: "1vw",
        tintColor: color.text
    },
    popup: (flag, isBottom) => ({
        width: "27vw",
        position: "absolute",
        backgroundColor: background.infoBody,
        borderRadius: "0.8vw",
        display: flag ? "" : "none",
        opacity: flag ? 1 : 0,
        transition: "all .4s",
        shadowColor: "black",
        shadowOffset: { height: 0, width: 0 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        ...(!isBottom ? {
            top: '0.8vw',
            bottom: 'auto'
        } : {
            top: 'auto',
            bottom: '0.8vw'
        }),
    }),
    bottomBtns: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginHorizontal: "1vw",
        paddingVertical: "0.5vw",
        borderTopColor: color.border,
        borderTopWidth: "0.1vw"
    },
    btnAddQuest: {
        borderWidth: "0.1vw",
        borderColor: color.infoHeaderNavText,
        borderRadius: "0.5vw",
        paddingHorizontal: "0.8vw",
        paddingVertical: "0.2vw",
    },
    saveBtn: {
        backgroundColor: color.logoColor,
        paddingHorizontal: "0.7vw",
        paddingVertical: "0.2vw",
        borderRadius: "0.6vw"
    },
    cancelBtn: {
        paddingHorizontal: "0.7vw",
        paddingVertical: "0.2vw",
    }
});
export default memo(BodyCell);