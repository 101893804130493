import {
  SET_SYSTEM_MESSAGES,
  CHANGE_ARRANGE_SYSTEM,
  SET_SHOW_SYSTEM_MESSAGES,
  SET_DRAFTS_SYSTEM,
  NEW_SYSTEM_FLAG,
  NEW_SYSTEM_CURRENT,
  NEW_SYSTEM_CURRENT_TYPE,
  SET_SENT_SYSTEM,
} from "../actions/index";
import { getContent } from "../../componnets/modules/getContent";
import { createFilesArr } from "../../componnets/modules/CreateFileObject";
import { checkIfIsEncodeText } from '../../componnets/modules/removeSpacialCherectersAndSpaes';
import { removeSpacialCherectersString } from "../../componnets/modules/removeSpacialCherectersAndSpaes";

export const systemMessagesReducer = (
  state = {
    sentMessages: [],
    messages: [],
    drafts: [],
    flag: false,
    newMessageFlag: false,
    newMessageType: "",
    newMessageCurrent: {},
    arrangeSent: { name: "date", value: "Date" },
    arrangeInbox: { name: "date", value: "Date" },
    arrangeDrafts: { name: "date", value: "Date" },
  },
  action

) => {
  
  switch (action.type) {
    case SET_SYSTEM_MESSAGES: {
      let messages = [];
      let sentMessages = [];
      action.payload.forEach((e) => {
        if (true) {
          let mail = {
            id: e.id,
            message_parent_id: e.message_parent_id ? e.message_parent_id : null,
            subject: e.subject ? checkIfIsEncodeText(e.subject,"string") : null,
            body: e.body ? checkIfIsEncodeText(e.body,"html") : null,
            body_search: e.body ? getContent(e.body) : null,
            sender: {
              id: e.sender.id,
              name: checkIfIsEncodeText(e.sender.name, "string"),
            },
            to_list: e.to_list_ids,
            to_list_names: e.to_list,
            cc_list: e.cc_list_ids ? e.cc_list_ids : null,
            cc_list_names: e.cc_list ? e.cc_list : null,
            flag: e.flag && e.flag === "1" ? true : false,
            has_attachments:
              e.has_attachments && e.has_attachments === "1" ? true : false,
            attachments: e.attachments ? e.attachments : null,
            time: e.time ? e.time : null,
            is_survey: e.is_survey ? e.is_survey : "0",
            read_or_not: sessionStorage.getItem("create_game_permission") ==="1" ? e.is_admin_read ==="0"? true : false : e.read_or_not && e.read_or_not === "0" ? true : false,
          };
          
          if (e.has_attachments === "1") {
            mail.attachments = e.attachments;
          }
          if (mail.sender.id === action.id) {
            sentMessages.push(mail);
          } else {
            messages.push(mail);
          }
        }
      });
      return { ...state, messages: messages, sentMessages: sentMessages };
    }
    case SET_SENT_SYSTEM: {
      let count =0;
      let messages = [];
      let messagesUnreadArr = [];
      action.payload.forEach((e, i) => {
          e.body = e.body.replace("\\", "");
          let mail = {
            id: e.id,
            message_parent_id: e.message_parent_id ? e.message_parent_id : null,
            subject: e.subject ? checkIfIsEncodeText(e.subject,"string") : null,
            body: e.body ? checkIfIsEncodeText(e.body,"html") : null,
            body_search: e.body
                ? getContent(e.body, e.read_or_not, e.subject)
                : null,
            sender: {
              id: e.sender.id,
              name: checkIfIsEncodeText(e.sender.name, "string"),
            },
            to_list: e.to_list_ids,
            to_list_names: e.to_list,
            cc_list: e.cc_list_ids ? e.cc_list_ids : null,
            cc_list_names: e.cc_list ? e.cc_list : null,
            flag: e.flag && e.flag === "1" ? true : false,
            has_attachments:
                e.has_attachments && e.has_attachments !== "0" ? true : false,
            time: e.time ? e.time : null,
            is_survey: e.is_survey ? e.is_survey : "0",
            read_or_not: e.read_or_not === undefined || e.read_or_not && e.read_or_not === "1" ? true : false,
          };
          if (mail.has_attachments >= 1) {
            mail.attachments = createFilesArr(e.attachments);
          }
          messages.push(mail);
          
          if (!mail.read_or_not) {
            messagesUnreadArr.push({
              ...mail,
              index: messagesUnreadArr.length,
            });
            count++;
          }
      });
      return {
        ...state,
        sentMessages: [...state.sentMessages, ...messages]
      };
    }

    case CHANGE_ARRANGE_SYSTEM: {
      let newArrange = {
        name: action.name,
        value: action.value,
      };
      if (action.typeMsg === "Inbox") {
        return { ...state, arrangeInbox: newArrange };
      } else if (action.typeMsg === "Drafts") {
        return { ...state, arrangeDrafts: newArrange };
      }
      return { ...state, arrangeSent: newArrange };
    }
    case SET_SHOW_SYSTEM_MESSAGES: {
      return { ...state, flag: action.flag };
    }
    case NEW_SYSTEM_FLAG: {
      return { ...state, newMessageFlag: action.flag };
    }
    case NEW_SYSTEM_CURRENT: {
      return { ...state, newMessageCurrent: action.payload };
    }
    case NEW_SYSTEM_CURRENT_TYPE: {
      return { ...state, newMessageType: action.payload };
    }
    case SET_DRAFTS_SYSTEM: {
      const temp = [...state.drafts];
      const index = temp.findIndex((draft) => draft.id === action.payload.id);
      if (action.remove) {
        temp.splice(index, 1);
        return { ...state, drafts: temp };
      }
      action.payload.body = action.payload.body?.replace("\\", "") || null;
      let draft = {
        id: action.payload.id,
        message_parent_id: action.payload.message_parent_id
          ? action.payload.message_parent_id
          : null,
        subject: action.payload.subject ? action.payload.subject : "",
        body: action.payload.body ? removeSpacialCherectersString(action.payload.body) : null,
        body_search: action.payload.body
          ? getContent(action.payload.body)
          : null,
        to_list: action.payload.to_list ? action.payload.to_list : "",
        to_list_ids: action.payload.to_list_ids
          ? action.payload.to_list_ids
          : "",
        cc_list: action.payload.cc_list ? action.payload.cc_list : "",
        cc_list_ids: action.payload.cc_list_ids
          ? action.payload.cc_list_ids
          : "",
        flag: action.payload.flag && action.payload.flag === "1" ? true : false,
        has_attachments:
          action.payload.has_attachments &&
          action.payload.has_attachments === "1"
            ? true
            : false,
        attachments: action.payload.attachments
          ? action.payload.attachments
          : null,
        time: action.payload.time ? action.payload.time : null,
      };
      if (index >= 0) {
        temp.splice(index, 1, draft);
        return { ...state, drafts: temp };
      }
      return { ...state, drafts: [...state.drafts, draft] };
    }
    default: {
      return state;
    }
  }
};
