import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View, Pressable, Text, Image } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { removeBackgroundFile, showFile, setErrorMessage } from "../../../redux/actions";
import { ShortenFile } from "../../modules/ShortenFile";
import { DocsContext } from "../../../ContextData/Docs";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { loading } from "../../../redux/actions";
import { USER_ID_IN_GAME } from "../../../config.inc";
import { t } from "i18n-js";

export default function FileItem({
  item,
  zIndex,
  setIndex,
  i,
  type,
  refItem,
  index,
}) {
  const { widthS, heightS } = UseResponsiveScreen();
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const styles = stylesR(heightS, widthS, background, color);

  const { deleteDocData } = useContext(DocsContext);

  const dispatch = useDispatch();

  const playerPermission = useSelector((state) => state.gameInfoArr.permission);
  const images = useSelector((state) => state.style.images);
  const [file, setFile] = useState(
    ShortenFile(
      item.name.includes(item.link.slice(item.link.lastIndexOf(".") + 1))
        ? item.name
        : `${item.name}${item.link.slice(item.link.lastIndexOf("."))}`
    )
  );

  const setTypeImg = (type) => {
    switch (type) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return (
          <Image
            source={{ uri: images.filePhotoIcon }}
            style={styles.img_file}
          />
        );
      case "video/mp4":
        return (
          <Image
            source={{ uri: images.fileVideoIcon }}
            style={[styles.img_file, { marginLeft: widthS * 3 }]}
          />
        );
      case "audio/mp3":
      case "audio/mpeg":
        return (
          <Image
            source={{ uri: images.fileSoundIcon }}
            style={[styles.img_file, { marginLeft: widthS * 3 }]}
          />
        );
      default:
        return (
          <Image source={{ uri: images.fileDocIcon }} style={styles.img_file} />
        );
    }
  };

  const deleteDoc = (id) => {
    dispatch(loading(true));
    deleteDocData(id)
      .then(() => {
        dispatch(removeBackgroundFile(id));
        dispatch(loading(false));
      })
      .catch(() => {
        dispatch(loading(false));
      })
  }

  useEffect(() => {
    setFile(ShortenFile(
      item.name.includes(item.link.slice(item.link.lastIndexOf(".") + 1))
        ? item.name
        : `${item.name}${item.link.slice(item.link.lastIndexOf("."))}`
    ));
  }, [item]);

  return (
    <>
      <Pressable
        ref={(ref) => (refItem.current[i] = ref)}
        style={[styles.item_press, { zIndex: zIndex }]}
        onPress={() => {
          dispatch(showFile(item));
        }}
      >
        {({ hovered }) => {
          hovered ? setIndex(i) : i === index && setIndex();
          return (
            <View style={styles.item_view}>
              {(playerPermission === "1" || item?.uploader_id === sessionStorage.getItem(USER_ID_IN_GAME)) &&
                <Pressable style={[styles.delete_circle]} onPress={() => {
                  dispatch(setErrorMessage(
                    true,
                    t("oops"),
                    t("war_delete_action"),
                    async (e) => {
                      if (e) {
                        deleteDoc(item.id)
                      }
                    }))
                }}><Image style={[styles.x_button_icon]} source={{ uri: images.plusIcon }} /></Pressable>}
              {setTypeImg(item.type)}
              <Text
                numberOfLines={1}
                selectable={false}
                style={[styles.item_text, { color: color.filesText }]}
              >
                {file.fileName}
              </Text>
              <Text
                selectable={false}
                style={[styles.item_text, { color: color.filesText }]}
              >
                {file.fileType}
              </Text>
            </View>
          );
        }}
      </Pressable >
    </>
  );
}
const stylesR = (heightS, widthS, background, color) =>
  StyleSheet.create({
    item_press: {},
    item_view: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingVertical: heightS * 9,
      paddingHorizontal: widthS * 6,
      position: "relative",
    },
    img_file: {
      width: widthS * 15,
      height: widthS * 15,
      resizeMode: "contain",
      marginBottom: heightS * 2,
      zIndex: "1",
    },
    item_text: {
      width: widthS * 55,
      fontSize: widthS * 9,
      textAlign: "center",
      fontFamily: "Catamaran_400Regular",
      lineHeight: heightS * 10,
    },
    delete_circle: {
      borderRadius: "1vw",
      position: "absolute",
      top: "0.5vw",
      right: "2vw",
      zIndex: "2",
      width: "1vw",
      height: "1vw",
      backgroundColor: background.infoBody,
      alignItems: "center",
      justifyContent: "center",
    },
    x_button_icon: {
      tintColor: "white",
      width: "0.8vw",
      height: "0.8vw",
      resizeMode: "contain",
      transform: [{ rotate: "45deg" }]
    },
  });


//<Pressable onPress={() => {console.log("x")}}><img src={"https://w7.pngwing.com/pngs/380/139/png-transparent-x-red-mark-incorrect-thumbnail.png"} /></Pressable>