import React, { useRef, useEffect, useState, useMemo } from "react";
import { StyleSheet, View, Pressable, Modal, Text, Image, Dimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { listenerAdd } from "../../../AddListener";
import { loading, showFile } from "../../../redux/actions";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import UseResponsiveSize from "../../modules/UseResponsiveSize";

export default function ShowFile() {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const ref = useRef(null);
  const popupRef = useRef(null);
  const dispatch = useDispatch();
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);

  const dataFile = useSelector((state) => state.filesArr.fileShow);

  const [icon, setIcon] = useState(null);
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(true);

  const checkType = (type, link) => {
    if (
      type.toLowerCase().indexOf("jpg") > 0 ||
      type.toLowerCase().indexOf("jpeg") > 0 ||
      type.toLowerCase().indexOf("png") > 0 ||
      type.toLowerCase().indexOf("svg") > 0 ||
      type.toLowerCase().indexOf("webp") > 0
    ) {
      setIcon(
        <Image source={{ uri: images.filePhotoIcon }} style={styles.logo} />
      );
      setFile(<Image style={styles.img} source={{ uri: link }} />);
      return;
    }

    if (type.indexOf("mp3") > 0 || type.indexOf("mpeg") > 0) {
      setIcon(
        <Image source={{ uri: images.fileSoundIcon }} style={styles.logo} />
      );
      setFile(
        <video controls={true} id="sound_" autoPlay>
          <source src={link} />
        </video>
      );
      return;
    }

    if (type.indexOf("mp4") > 0) {
      setIcon(
        <Image source={{ uri: images.fileVideoIcon }} style={styles.logo} />
      );
      setFile(
        <video controls={true} id="video_" autoPlay>
          <source src={link} />
        </video>
      );
      return;
    }

    // if (link.indexOf("base64") >= 0) {
    //   setIcon(
    //     <Image source={{ uri: images.fileDocIcon }} style={styles.logo} />
    //   );
    //   setFile(
    //     <iframe
    //       src={link}
    //       style={{ height: "99%", width: "99%", margin: "0 auto" }}
    //     />
    //   );
    //   return;
    // }
    //  else {
    if (
      type !== "text/plain" &&
      type !== "text/html" &&
      type.indexOf("pdf") < 0 &&
      type.indexOf("txt") < 0
    ) {
      setShow(false);
      setIcon(
        <Image source={{ uri: images.fileDocIcon }} style={styles.logo} />
      );
      setFile(<iframe src={link} style={{ display: "none" }} />);
    } else {
      setIcon(
        <Image source={{ uri: images.fileDocIcon }} style={styles.logo} />
      );
      setFile(
        <iframe
          src={link}
          style={{ height: "99%", width: "99%", margin: "0 auto", backgroundColor: "white" }}
        />
      );
    }
    return;
    // }
  };

  useEffect(() => {
    checkType(dataFile.type, dataFile.link);
    dispatch(loading(false));
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        "mousedown",
        listenerAdd(popupRef, true, () => dispatch(showFile()))
      );
    }
  }, [icon, file]);

  useMemo(() => {
    if (!show) {
      setTimeout(() => {
        dispatch(showFile());
      }, 1000);
    }
  }, [show]);
  return icon && file && show ? (
    <Modal transparent={true} animationType="none" visible={true}>
      <View style={styles.container} ref={ref}>
        <View
          ref={popupRef}
          style={[
            styles.main,
            {
              backgroundColor: background.popup1,
            },
            (dataFile.type.indexOf("mp3") > 0 ||
              dataFile.type.indexOf("mpeg") > 0) && {
              height: heightS * 100,
              width: widthS * 450,
              top: "25%",
            },
          ]}
        >
          <View style={[styles.header, { borderBottomColor: color.border }]}>
            {icon}
            <Text selectable={false} style={styles.logo_text}>
              {dataFile.name}
            </Text>
            <Pressable onPress={() => dispatch(showFile())} style={styles.x}>
              <Text
                selectable={false}
                style={[styles.x_text, { color: color.x }]}
              >
                X
              </Text>
            </Pressable>
          </View>
          {file}
        </View>
      </View>
    </Modal>
  ) : (
    <>{file}</>
  );
}
const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    container: {
      display: "flex",
      alignItems: "center",
      paddingTop: heightS * 20,
      backgroundColor: "#00000057",
      height: Dimensions.get("window").height,
      flexDirection: "column",
      fontFamily: "Roboto",
    },
    main: {
      width: widthS * 650,
      height: heightS * 400,
      borderRadius: 4,
      shadowColor: "black",
      shadowOffset: {
        height: 0,
        width: 0,
      },
      shadowOpacity: 0.5,
      shadowRadius: 5,
      borderRadius: widthS * 12,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottomWidth: 1,
      borderStyle: "solid",
      paddingVertical: heightS * 8,
      paddingLeft: widthS * 15,
      paddingRight: widthS * 15,
      position: "relative",
      justifyContent: "space-between",
    },
    logo: {
      width: widthS * 20,
      height: widthS * 20,
      resizeMode: "contain",
    },
    logo_text: {
      color: "white",
      fontSize: widthS * 12,
      marginLeft: widthS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    x_text: {
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 14,
    },
    img: {
      minHeight: heightS * 350,
      width: "100%",
      resizeMode: "contain",
    },
  });
