import React, { useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  newGameCreator,
  setGameList,
} from "../../redux/actions";
import i18n from "i18n-js";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";

export default function CreatorOptions() {
  const dispatch = useDispatch();

  const { width, height } = UseResponsiveSize();
  const { heightS, widthS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const color = useSelector((state) => state.style.colorClient);
  const background = useSelector((state) => state.style.backgroundClient);

  return (
    <View
      style={[styles.body_main, { backgroundColor: background.popupLogin }]}
    >
      <View style={styles.body_main_top}>
        <Text style={[styles.body_main_textA, { color: color.createNewGame }]}>
          {`${i18n.t("hello_game_creator")}`}!
        </Text>
        <Text style={[styles.body_main_textB, { color: color.createNewGame }]}>
          {`${i18n.t("creator_options_header")}:`}
        </Text>
      </View>
      <View style={styles.actions}>
        <Pressable
          onPress={() => dispatch(setGameList("real"))}
          style={[
            styles.btn,
            { backgroundColor: background.loginFooter },
          ]}
        >
          {
            <Text style={[{ color: color.text2 }, styles.btn_text]}>
              {`${i18n.t("real_simulations")} `}
            </Text>
          }
        </Pressable>
        <Pressable
          onPress={() => dispatch(setGameList("demo"))}
          style={[
            styles.btn,
            { backgroundColor: background.loginFooter },
          ]}
        >
          {
            <Text style={[{ color: color.text2 }, styles.btn_text]}>
              {`${i18n.t("demo_simulations")}`}
            </Text>
          }
        </Pressable>
        <Text style={{ paddingTop: heightS * 8, paddingBottom: heightS * 3, color: color.createNewGame, fontFamily: "Catamaran_400Regular" }}>{i18n.t("or").toUpperCase()}</Text>
        <Pressable
          onPress={() => dispatch(newGameCreator())}
          style={[
            styles.btn,
            { backgroundColor: background.loginFooter },
          ]}
        >
          {
            <Text style={[{ color: color.text2 }, styles.btn_text]}>
              {`${i18n.t("create_new")} `}
            </Text>
          }
        </Pressable>
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    body_main: {
      display: "flex",
      flexDirection: "column",
      width: widthS * 420,
      height: heightS * 300,
      borderRadius: widthS * 25,
    },
    body_main_top: {
      marginTop: heightS * 24,
    },
    body_main_textA: {
      fontSize: widthS * 15.7,
      fontWeight: "normal",
      textAlign: "center",
      marginVertical: heightS * 7,
      fontFamily: "Catamaran_400Regular"
    },
    body_main_textB: {
      fontWeight: "300",
      lineHeight: heightS * 12,
      fontSize: widthS * 12,
      textAlign: "center",
      fontFamily: "Catamaran_400Regular"
    },
    body_main_bottom: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "flex-start",
      flex: 1,
    },
    btn: {
      borderStyle: "solid",
      padding: widthS * 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: heightS * 7,
      width: widthS * 180,
      height: heightS * 30,
      borderRadius: widthS * 10,
    },
    btn_text: {
      fontSize: widthS * 11,
      fontWeight: "normal",
      fontFamily: "Catamaran_400Regular"
    },
    actions: {
      paddingTop: heightS * 25,
      paddingBottom: heightS * 50,
      justifyContent: "center",
      alignItems: "center",
    },
  });
}
