import { useEffect } from "react";
import { listenerAdd, listenerAddBT } from "../../AddListener";

export const useClickOutside = (ref, show, setShow) => {
  useEffect(() => {
    document.addEventListener(
      "mousedown",
      listenerAdd(ref, show, setShow)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        listenerAdd(ref, show, setShow)
      );
    };
  }, [show]);
}

export const useClickWithBtnOutside = (ref1, ref2, show, setShow) => {
  useEffect(() => {
    document.addEventListener(
      "mousedown",
      listenerAddBT(ref1, ref2, show, setShow)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        listenerAddBT(ref1, ref2, show, setShow)
      );
    };
  }, [show]);
}