import React, { useContext, useEffect, useRef, useState } from "react";
import { StyleSheet, View, PixelRatio, Text, Pressable, TextInput, ScrollView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage, setInGame, setLogin, setToken } from "../../redux/actions";
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { GameInfoContext } from "../../ContextData/GameInfo";
import { GameListContext } from "../../ContextData/GamesList";
import { CHOOSE_GAME, GET_GAMES_LIST, URL_PATH } from "@env";
import { F_PHPSESSID, GAME_ID, USER_GAME_NAME, USER_ID_IN_GAME, _F_PHPSESSID_, _ID_, USER_FIRST_NAME, USER_LAST_NAME } from "../../config.inc";
import i18n, { t } from "i18n-js";
import AnimationLoading from "../modules/AnimationLoading";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import { checkIfIsEncodeText, removeSpacialCherectersString } from "../modules/removeSpacialCherectersAndSpaes";

export default function Game_List({ load, setLoad, demo, real }) {

	const { width, height } = UseResponsiveSize();
	const { heightS, widthS } = UseResponsiveScreen();
	const styles = stylesR(width, height, heightS, widthS);

	const dispatch = useDispatch();
	const searchRef = useRef(null);
	const scrollRef = useRef(null);

	const { getGameInfoData } = useContext(GameInfoContext);
	const { chooseGame } = useContext(GameListContext);

	const token = useSelector(state => state.loginType.token);
	const loginType = useSelector(state => state.loginType.type)
	const color = useSelector(state => state.style.colorClient);
	const background = useSelector(state => state.style.backgroundClient);

	const [gamesList, setGamesList] = useState([]);
	const [gamesListToPrint, setGamesListToPrint] = useState([]);
	const search = (txt) => {
		let temp = gamesList.filter((e) => e.game_name.toLowerCase().indexOf(` ${txt.toLowerCase()}`) > 0 || e.game_name.toLowerCase().indexOf(txt.toLowerCase()) === 0);
		setGamesListToPrint(temp);
		if (scrollRef.current) {
			scrollRef.current.scrollTo({ y: 0, x: 0, animated: "auto" });
		}
	}

	const sort = (type) => {
		let temp = [...gamesList];
		switch (type) {
			case "name":
				temp.sort((a, b) => {
					if (a.game_name > b.game_name) {
						return 1;
					} else if (a.game_name < b.game_name) {
						return -1;
					}
					return 0;
				});
				setGamesListToPrint([...temp]);
				return setGamesList([...temp]);
			case "time":
				temp.sort((a, b) => {
					if (a.time > b.time) {
						return 1;
					} else if (a.time < b.time) {
						return -1;
					}
					return 0;
				});
				setGamesListToPrint([...temp]);
				return setGamesList([...temp]);
		}
	}

	const cretaeTimeAndDate = (type, date) => {
		let d = date ? new Date(date) : new Date();
		if (type === "time") {
			return d.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
		} else {
			let month = (d.getMonth() + 1).toString();
			let day = d.getDate().toString();
			let year = d.getFullYear();
			if (month.length < 2) {
				month = '0' + month;
			}
			if (day.length < 2) {
				day = '0' + day;
			}
			return [month, day, year].join('/');
		}
	}
	const DecodeGameList = (arr) => {
		arr.forEach((e) => {
			e.game_name = checkIfIsEncodeText(e.game_name, "line")
		});
		return {
			arrDecode: arr
		}
	}

	useEffect(() => {
		if (window.sessionStorage.getItem(F_PHPSESSID)) {
			try {
				const url = `${URL_PATH}${GET_GAMES_LIST}?${_F_PHPSESSID_}=${window.sessionStorage.getItem(
					F_PHPSESSID
				)}`;
				const requestOptions = {
					method: "GET",
				};
				setLoad(true);
				fetch(url, requestOptions)
					.then((res) => res.text())
					.then((res) => {
						setLoad(false);
						res = JSON.parse(res);
						if (res.status === "true") {
							if (res.games_list.length > 0) {
								let { arrDecode } = DecodeGameList(res.games_list);
								setGamesList(arrDecode);
								setGamesListToPrint(arrDecode);
							} else if (sessionStorage.getItem("create_game_permission") !== "1") {
								// הודעת שגיאה
								dispatch(setErrorMessage(true, "", t("no_game_error"), false, () => {
									dispatch(setLogin());
									dispatch(setToken(false));
									sessionStorage.removeItem(F_PHPSESSID)
								}));
							}
						} else {
							dispatch(setLogin());
							dispatch(setToken(false));
						}
					}).catch((err) => {
						// expo Tel-Aviv
						setLoad(false);
						let res = {
							status: "true",
							games_list: [
								{ id: "wKG4a5OiqrU=", game_name: "Cyber-Exhibition", is_demo: "1" }
							]
						};
						if (res.status === "true") {
							setGamesList([...res.games_list]);
							setGamesListToPrint([...res.games_list]);
						}
					});
			} catch (e) { }
		} else {
			dispatch(setLogin());
			dispatch(setToken(false))
		}
	}, [loginType]);
	useEffect(() => {
		searchRef.current.focus();
	}, [scrollRef]);
	return (
		<View style={[styles.container, { backgroundColor: background.popupLogin }]}>
			<View style={styles.top}>
				<Text style={[styles.top_text_a, { color: color.text }]}>{i18n.t("hello")} {sessionStorage.getItem(USER_FIRST_NAME)},</Text>
				<Text style={[styles.top_text_b, { color: color.text }]}>{i18n.t("welcome_game_list")}</Text>
			</View>
			<View style={[styles.sort, { borderBottomColor: color.border }]}>
				<Pressable style={styles.sort_btn} onPress={() => sort("name")}>
					<Text style={[styles.sort_item, { color: color.text }]}>{t("simulation_name")}</Text>
					<AntDesign name="caretdown" size={widthS * 6} color={color.text} />
				</Pressable>
				<Pressable style={styles.sort_btn} onPress={() => sort("time")}>
					<Text style={[styles.sort_item, { color: color.text }]}>{t("start_time")}</Text>
					<AntDesign name="caretdown" size={widthS * 6} color={color.text} />
				</Pressable>
			</View>
			<View style={{ position: "relative", display: "flex", justifyContent: "center" }}>
				<TextInput
					ref={searchRef}
					placeholder={i18n.t("search_by_name")}
					placeholderTextColor={"#a1a1a1"}
					style={[styles.search, { backgroundColor: background.loginInput, color: color.text }]}
					onChangeText={(txt) => search(txt)}
				/>
				<MaterialIcons name="search" size={widthS * 16} color="#a1a1a1" style={styles.icon_search} />
			</View>
			{<ScrollView nativeID="game-list" style={styles.body_c} contentContainerStyle={styles.body_m} ref={scrollRef}>
				{load ? <AnimationLoading /> : gamesListToPrint.length > 0 && gamesListToPrint.map((game, index) => {
					if (demo && game.is_demo === "0") return;
					if (real && game.is_demo === "1") return;
					return (
						<Pressable
							key={index}
							onPress={() => {
								chooseGame(game.id, getGameInfoData, game.is_demo, (flag) => setLoad(flag))
							}}
							style={({ pressed, hovered, focused }) => [
								{ backgroundColor: hovered ? background.gameListHover : "" },
								styles.gameListItem,
							]}
						>
							{({ pressed, hovered, focused }) => (<>
								<View style={styles.gameListItem_left}>
									<Text
										style={[
											{ color: hovered ? color.gameListHover : color.text },
											styles.gameListItemName,
										]}
									>
										{game.game_name.length > 33 ? `${game.game_name.slice(0, 33)}...` : game.game_name}
									</Text>
								</View>
								<View style={styles.gameListItem_right}>
									<Text
										style={[
											{ color: hovered ? color.gameListHover : color.text },
											styles.gameListItemText,
										]}
									>
										{`${cretaeTimeAndDate("date", game.start_time)} | `}
									</Text>
									<Text
										style={[
											{ color: hovered ? color.gameListHover : color.text },
											styles.gameListItemText,
										]}
									>
										{cretaeTimeAndDate("time", game.start_time)}
									</Text>
								</View>
							</>
							)}
						</Pressable>
					)
				})}
			</ScrollView>}
		</View>
	);
}

const stylesR = (width, height, heightS, widthS) => {
	return StyleSheet.create({
		container: {
			display: "flex",
			flexDirection: "column",
			// ...style.popup7,
			width: widthS * 420,
			height: heightS * 300,
			borderRadius: widthS * 25,
		},
		top: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginTop: heightS * 20,
			marginBottom: heightS * 4.5
		},
		top_text_a: {
			fontSize: widthS * 18,
			fontWeight: "300",
			textAlign: "center",
			fontFamily: "Catamaran_400Regular",
		},
		top_text_b: {
			fontWeight: "300",
			marginTop: heightS * 7,
			fontSize: widthS * 11.5,
			textAlign: "center",
			fontFamily: "Catamaran_400Regular",
		},
		sort: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			borderBottomWidth: heightS * 1,
			paddingVertical: heightS * 2,
			marginHorizontal: widthS * 21
		},
		sort_btn: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "center"
		},
		sort_item: {
			marginRight: widthS * 3,
			fontSize: widthS * 10,
			fontWeight: "bold",
			fontFamily: "Catamaran_800ExtraBold",
		},
		body_c: {
			paddingHorizontal: widthS * 3,
			marginBottom: heightS * 18,
			// marginTop: heightS * 3,
			maxHeight: heightS * 200,
		},
		body_m: {
			minHeight: heightS * 120,
			alignItems: "center",
		},
		gameListItem: {
			paddingVertical: heightS * 7,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			paddingLeft: widthS * 18,
			paddingRight: widthS * 10.7,
			width: "100%"
		},
		gameListItem_right: {
			display: "flex",
			flexDirection: "row"
		},
		gameListItemName: {
			fontSize: widthS * 10,
			fontWeight: "normal",
			width: widthS * 160,
			fontFamily: "Catamaran_400Regular",
		},
		gameListItemText: {
			fontSize: widthS * 10,
			fontWeight: "normal",
			fontFamily: "Catamaran_400Regular",
		},
		search: {
			width: "90%",
			height: heightS * 28,
			marginVertical: heightS * 9,
			borderRadius: widthS * 7.3,
			alignSelf: "center",
			justifyContent: "space-between",
			paddingRight: widthS * 6,
			paddingVertical: heightS * 8,
			paddingLeft: widthS * 10,
			paddingRight: widthS * 28,
			fontSize: widthS * 10,
			fontWeight: "300",
			outlineStyle: "none",
			fontFamily: "Catamaran_400Regular",
		},
		icon_search: {
			position: "absolute",
			right: widthS * 28,
			top: widthS * 15
		}
	});

}