import { StyleSheet, View, Text, Pressable } from 'react-native'
import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux';
import TimeAndCheckBoxCell from './TimeAndCheckBoxCell';
import TypeActionCell from './TypeActionCell';
import EventCell from './EventCell';
import PlayersCell from './PlayersCell';
import PlatformTypeCell from './PlatformTypeCell';
import SubjectCell from './SubjectCell';
import BodyCell from './BodyCell';
import AttachmentsCell from './AttachmentsCell';
import CheckBox from '../../../../Elements/CheckBox';
import TooltipRowTable from "./TooltipRowTable";

const RowItem = React.memo(({ e, index, isDone, selected, isNew, updateAction, selectRow, setSelectRow, isPopupOpen, setIsPopupOpen, isBottom, loadingState, popupRef, setPopupRef, scrollRef, setActionsState, tooltipOpacityAnimation, scrollX }) => {

    const { images, background, color } = useSelector(state => ({
        images: state.style.images,
        background: state.style.background,
        color: state.style.color
    }));

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = stylesR(background, color, direction);

    const kpiCheckBox = (is_kpi, index, isDone, isNew) => {
        return <Pressable
            style={[styles.cell("3vw"), styles.kpiCell, { opacity: isDone ? 0.4 : 1, }]}
            disabled={isDone}
            onPress={() => {
                updateAction(index, { is_kpi: !is_kpi }, true, isNew);
            }}>
            <CheckBox
                checked={is_kpi}
                big={true}
                isDone={false}
                background={background.infoBody}
                colorFill={background.count} />
        </Pressable>
    }

    return (
        <Pressable
            style={styles.row(index % 2 === 0,
                selected, isDone, isBottom, e?.unfinished
            )}
            key={index}>
            {({ hovered }) => (
                <>
                    <TooltipRowTable
                        scrollX={scrollX}
                        time={e["time"]}
                        platform={e["platform_type"]}
                        type={e["type_action"]}
                        tooltipOpacityAnimation={tooltipOpacityAnimation}
                    />
                    <TimeAndCheckBoxCell
                        time={e["time"]}
                        item={e}
                        id={e["id"]}
                        index={index}
                        hovered={hovered}
                        isDone={isDone || (loadingState && !e?.id)}
                        isNew={isNew}
                        updateAction={updateAction}
                        selectRow={selectRow}
                        setSelectRow={setSelectRow}
                        isPopupOpen={isPopupOpen}
                        setIsPopupOpen={setIsPopupOpen}
                        key={e?.id}
                    />
                    <TypeActionCell
                        isBottom={isBottom}
                        popupRef={popupRef}
                        setPopupRef={setPopupRef}
                        isDone={isDone || (loadingState && !e?.id)}
                        updateAction={updateAction}
                        scrollRef={scrollRef}
                        setIsPopupOpen={(i) => setIsPopupOpen(i)}
                        cellStyle={styles.cell}
                        type={e["type_action"]}
                        index={index}
                        isNew={!e?.id} />
                    <EventCell
                        isBottom={isBottom}
                        popupRef={popupRef}
                        setPopupRef={setPopupRef}
                        isDone={isDone || (loadingState && !e?.id)}
                        updateAction={updateAction}
                        cellStyle={styles.cell}
                        index={index}
                        isNew={!e?.id}
                        scrollRef={scrollRef}
                        setIsPopupOpen={setIsPopupOpen}
                        type={e["event_id"]} />
                    <PlatformTypeCell
                        typeAction={e["type_action"]}
                        isDone={isDone || (loadingState && !e?.id)}
                        updateAction={updateAction}
                        cellStyle={styles.cell}
                        index={index}
                        isNew={!e?.id}
                        setActionsState={setActionsState}
                        type={e["platform_type"]} />
                    {kpiCheckBox(e.is_kpi, index, isDone || (loadingState && !e?.id) || e.type_action === "cfaQuestionnaire" || e.type_action === "cfaChallenges", !e?.id)}
                    <PlayersCell
                        key={"from"}
                        isBottom={isBottom}
                        popupRef={popupRef}
                        setPopupRef={setPopupRef}
                        keyName={"from_name"}
                        keyIds={"from_id"}
                        updateAction={updateAction}
                        width={"12vw"}
                        disabeld={e.platform_type === "news"}
                        isDone={isDone || (loadingState && !e?.id)}
                        actionsArrState={e}
                        cellStyle={styles.cell}
                        index={index}
                        isNew={!e?.id}
                        scrollRef={scrollRef}
                        setIsPopupOpen={setIsPopupOpen}
                        type={e["from_id"]}
                        isFrom={true} />
                    <PlayersCell
                        key={"to"}
                        isBottom={isBottom}
                        popupRef={popupRef}
                        setPopupRef={setPopupRef}
                        keyName={"to_list"}
                        keyIds={"to_list_ids"}
                        updateAction={updateAction}
                        isDone={isDone || (loadingState && !e?.id)}
                        width={"16vw"}
                        disabeld={(loadingState && !e?.id)}
                        actionsArrState={e}
                        cellStyle={styles.cell}
                        index={index}
                        isNew={!e?.id}
                        scrollRef={scrollRef}
                        setIsPopupOpen={setIsPopupOpen}
                        type={e["to_list_ids"]} />
                    <PlayersCell
                        key={"cc"}
                        isBottom={isBottom}
                        popupRef={popupRef}
                        setPopupRef={setPopupRef}
                        keyName={"cc_list"}
                        keyIds={"cc_list_ids"}
                        updateAction={updateAction}
                        width={"16vw"}
                        disabeld={e.platform_type !== "mail"}
                        isDone={isDone || (loadingState && !e?.id)}
                        actionsArrState={e}
                        cellStyle={styles.cell}
                        index={index}
                        isNew={!e?.id}
                        scrollRef={scrollRef}
                        setIsPopupOpen={setIsPopupOpen}
                        type={e["cc_list_ids"]} />
                    <PlayersCell
                        key={"bcc"}
                        isBottom={isBottom}
                        popupRef={popupRef}
                        setPopupRef={setPopupRef}
                        keyName={"bcc_list"}
                        keyIds={"bcc_list_ids"}
                        updateAction={updateAction}
                        width={"16vw"}
                        disabeld={e.platform_type !== "mail"}
                        isDone={isDone || (loadingState && !e?.id)}
                        actionsArrState={e}
                        cellStyle={styles.cell}
                        index={index}
                        isNew={!e?.id}
                        scrollRef={scrollRef}
                        setIsPopupOpen={setIsPopupOpen}
                        type={e["bcc_list_ids"]} />
                    <SubjectCell
                        updateAction={updateAction}
                        isBottom={isBottom}
                        setPopupRef={setPopupRef}
                        subjectText={e["subject"]}
                        setIsPopupOpen={setIsPopupOpen}
                        isDone={isDone || (loadingState && !e?.id)}
                        index={index}
                        isNew={!e?.id}
                        cellStyle={styles.cell}
                    />
                    <BodyCell
                        isBottom={isBottom}
                        popupRef={popupRef}
                        setPopupRef={setPopupRef}
                        updateAction={updateAction}
                        cellStyle={styles.cell}
                        index={index}
                        isNew={!e?.id}
                        setIsPopupOpen={setIsPopupOpen}
                        isDone={isDone || (loadingState && !e?.id)}
                        actionObject={e}
                        scrollRef={scrollRef}
                    />
                    <AttachmentsCell
                        isDone={isDone}
                        platform={e["platform_type"]}
                        disabeld={e["type_action"] === "cfaQuestionnaire" || e["type_action"] === "cfaChallenges" || (loadingState && !e?.id)}
                        index={index}
                        isNew={!e?.id}
                        updateAction={updateAction}
                        files={e.file}
                        cellStyle={styles.cell} />
                </>
            )}
        </Pressable>

    )
}, (prevProps, nextProps) => {
    let shouldUpdate = true; // לא צריך רינדור מחדש
    Object.entries(nextProps).forEach(([key, val]) => {
        if (prevProps[key] !== val) {
            shouldUpdate = false; // אם יש שינוי, עדכן את הפרופס
        }
    });
    return shouldUpdate; // החזר true עבור מניעת רינדור מחדש, false לרינדור
}
);

export default RowItem;

const stylesR = (background, color, direction) => StyleSheet.create({
    row: (flag, selected, done, sticky, unfinished) => ({
        flexDirection: "row",
        minHeight: "3.4vw",
        opacity: done ? 0.4 : 1,
        borderColor: unfinished ? "red" : "",
        borderWidth: unfinished ? "0.1vw" : "0",
        cursor: "auto",
        backgroundColor: selected ? background.infoHeader : done ? background.realPlayersBackground : !flag ? background.leftInfo : background.infoBox,
        ...(sticky ? {
            shadowColor: "#000000",
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.6,
            shadowRadius: 8,
            zIndex: 1000
        } : {})
    }),
    cell: (width, flag, notCenter) => ({
        width: width,
        flexDirection: "row",
        alignItems: notCenter ? "" : "center",
        position: "relative",
        borderRight: "0.1vw",
        borderStyle: "solid",
        paddingVertical: notCenter ? "0.4vw" : "",
        borderRightColor: color.border,
        paddingHorizontal: "0.5vw",
        zIndex: flag ? 1 : 0,
        direction: direction
    }),
});