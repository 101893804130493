export const images = {
    backgroundImageLogin: require("../images/bckg_image.jpg"),
    backgroundImage: require("../images/back_image.jpg"),
    stickMan: require("../images/stick_man.svg"),
    logo: require("../images/logo_cinten.svg"),
    user_image: require("../images/user_image.svg"),
    simulationInfo: require("../images/info_icon.svg"),
    simulationInfoDark: require("../images/info_icon_dark.svg"),
    action: require("../images/actions_icon.svg"),
    build: require("../images/build_icon.svg"),
    buildDark: require("../images/build_icon_dark.svg"),
    system: require("../images/system_icon.svg"),
    systemWhite: require("../images/system_white_icon.png"),
    files: require("../images/my_doc_icon.svg"),
    mail: require("../images/mail_icon.svg"),
    social: require("../images/social_icon.svg"),
    news: require("../images/news_icon.svg"),
    mailBlack: require("../images/mail_icon_black.svg"),
    socialBlack: require("../images/social_icon_black.svg"),
    newsBlack: require("../images/news_icon_black.svg"),
    newsFake: require("../images/news_fake.svg"),
    chat: require("../images/chat_icon.svg"),
    searchIcon: require("../images/search_icon.svg"),
    searchIconSocial: require("../images/search_icon_social.svg"),
    systemFlagOrange: require("../images/flag_icon_true.svg"),
    systemFlagGray: require("../images/flag_icon.svg"),
    mailReadIcon: require("../images/mail_read_icon.svg"),
    mailUnreadIcon: require("../images/mail_unread_icon.svg"),
    replyIcon: require("../images/reply_icon.svg"),
    replyAllIcon: require("../images/reply_all_icon.svg"),
    forwardIcon: require("../images/forward_icon.svg"),
    replyIconHover: require("../images/reply_icon_hover.svg"),
    replyAllIconHover: require("../images/reply_all_icon_hover.svg"),
    forwardIconHover: require("../images/forward_icon_hover.svg"),
    filePhotoIcon: require("../images/photo_icon.svg"),
    fileVideoIcon: require("../images/video_icon.svg"),
    fileDocIcon: require("../images/doc_icon.svg"),
    fileSoundIcon: require("../images/sound_icon.svg"),
    fileSoundIconBlack: require("../images/sound_icon_black.svg"),
    socialProfile: require("../images/profile-picture.webp"),
    likeSelectIcon: require("../images/like_selected_icon.svg"),
    likeIcon: require("../images/like_icon.svg"),
    commentIcon: require("../images/comment_icon.svg"),
    shareIcon: require("../images/share_icon.svg"),
    shareIconSocial: require("../images/share_icon_social.svg"),
    likeCount: require("../images/like.svg"),
    commentCount: require("../images/comment_icon_fill.png"),
    attachmentIcon: require("../images/attachment_icon.svg"),
    attachmentIconUnread: require("../images/attachment_icon_unread.svg"),
    attachmentIconLogo: require("../images/attachment_icon_logo.svg"),
    attachmentIconLogoEvent: require("../images/attachment_icon_logo_event.svg"),
    attachmentIconBody: require("../images/attachment_icon_body.svg"),
    newsReadIcon: require("../images/read_news.png"),
    back: require("../images/back.png"),
    plusIcon: require("../images/plus_icon.svg"),
    greyPlusIcon: require("../images/grey_plus_icon.svg"),
    facebook: require("../images/facebook.png"),
    twitter: require("../images/twitter.png"),
    linkedin: require("../images/linkedin.png"),
    calendarIcon: require("../images/calendar_icon.svg"),
    deleteIcon: require("../images/delete_icon.svg"),
    deleteIconGray: require("../images/delete_icon_gray.svg"),
    deleteIconBlack: require("../images/delete_icon_black.svg"),
    menuIcon: require("../images/menu_icon.svg"),
    sortIcon: require("../images/sort_icon.svg"),
    filterIcon: require("../images/filter_icon.svg"),
    filterIconActive: require("../images/filter_icon_active.svg"),
    addEventIcon: require("../images/add_event_icon.svg"),
    importIcon: require("../images/import_icon.svg"),
    exportIcon: require("../images/export_icon.svg"),
    analyticsIcon: require("../images/analytics_icon.svg"),
    analyticsIconDark: require("../images/analytics_icon_dark.svg"),
    maximize: require("../images/maximize.svg"),
    minimize: require("../images/minimize.svg"),
    xIcon: require("../images/x.svg"),
    grid: require("../images/grid.svg"),
    gridDark: require("../images/grid-dark.svg"),
    kpis: require("../images/kpis.svg"),
    influential: require("../images/influential.svg"),
    pressure: require("../images/pressure.svg"),
    wordMap: require("../images/wordMap.svg"),
    inactive: require("../images/inactive.svg"),
    duplicate: require("../images/duplicate.svg"),
    eyeIcon: require("../images/edit_icon.svg"),
    reset: require("../images/reset.svg"),
    messageIcon: require("../images/message.svg"),
    decisionIcon: require("../images/decision.svg"),
    questionnaireIcon: require("../images/questionnaire.svg"),
}
export const images1 = {
    backgroundImage: "https://www.hdnicewallpapers.com/Walls/Normal/Sunset/Amazing_Nature_Sunset_Pic_of_Trees_in_Jungle_HD_Desktop_Background_Wallpaper.jpg",
    logo: "https://www.9to5carwallpapers.com/wp-content/uploads/2014/01/Bmw-Logo-HD-Wallpaper-1024x576.jpg",
    user_image: require("../images/user_image.svg"),
    simulationInfo: require("../images/info_icon.svg"),
    build: require("../images/build_icon.svg"),
    action: require("../images/actions_icon.png"),
    system: require("../images/system_icon.png"),
    files: require("../images/my_doc_icon.png"),
    mail: require("../images/mail_icon.png"),
    social: require("../images/f.svg"),
    news: require("../images/news_icon.png"),
    chat: require("../images/chat_icon.png"),
    systemFlagOrange: require("../images/flag_icon_true.svg"),
    systemFlagGray: require("../images/flag_icon.svg"),
    mailReadIcon: require("../images/read_icon.png"),
    mailUnreadIcon: require("../images/unread_icon.png"),
    replyIcon: require("../images/reply_icon.svg"),
    replyAllIcon: require("../images/reply_all_icon.png"),
    forwardIcon: require("../images/forward_icon.png"),
    filePhotoIcon: require("../images/photo_icon.png"),
    fileVideoIcon: require("../images/video_icon.png"),
    fileDocIcon: require("../images/doc_icon.png"),
    socialProfile: require("../images/profile-picture.webp"),
    likeSelectIcon: require("../images/like_selected_icon.svg"),
    likeIcon: require("../images/like_icon.svg"),
    commentIcon: require("../images/comment_icon.svg"),
    shareIcon: require("../images/share_icon.svg"),
    attachmentIcon: require("../images/attachment_icon.png"),
    newsReadIcon: require("../images/read_news.png"),
    back: require("../images/back.png"),
    maximize: require("../images/maximize.svg"),
    minimize: require("../images/minimize.svg"),
}