import * as FileSaver from "file-saver";
import XLSX from 'sheetjs-style';

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

let styleTH = {
    alignment: {
        vertical: 'center',
        horizontal: 'center',
    },
    border: {
        top: { style: 'thin', color: { rgb: '000000' } }, // Add top border
        bottom: { style: 'thin', color: { rgb: '000000' } }, // Add bottom border
        left: { style: 'thin', color: { rgb: '000000' } }, // Add left border
        right: { style: 'thin', color: { rgb: '000000' } }, // Add right border
    },
    font: {
        bold: true,
    },
};

let styleTD = {
    alignment: {
        vertical: 'center',
        horizontal: 'center',
    },
    font: {
        bold: true,
    },
};

let styleTDEmpty = {
    alignment: {
        vertical: 'center',
        horizontal: 'center',
    },
};

const convertObject = (data) => {
    const times = Object.keys(data[0])
        .filter(k => k !== '0')
        .map(t => parseInt(t))
        .sort((a, b) => b - a);

    // Convert to 2D array
    const arr = [
        [""].concat(times)
    ];

    data.forEach(row => {
        const newRow = [row['0']];
        times.forEach(time => {
            newRow.push(row[time]);
        })
        arr.push(newRow);
    });
    return arr;
}

export const exportExcel = async (excelData, fileName, expected) => {
    return new Promise((resolve, reject) => {
        try {
            // if(expected){
            //     excelData = convertObject(excelData);
            // }
            const ws = XLSX.utils.json_to_sheet(excelData, { skipHeader: true });
            Object.keys(ws).forEach((cell) => {
                if (cell !== '!ref') {
                    if (/^A\d/.test(cell)) {
                        ws[cell].s = styleTH;
                    } else {
                        const numericValue = parseFloat(cell);
                        if (!isNaN(numericValue) && numericValue > 1) {
                            ws[cell].s = styleTH;
                        } else {
                            let value = parseFloat(ws[cell]?.v);
                            if (value > 0) {
                                ws[cell].s = styleTD;
                            } else {
                                ws[cell].s = styleTDEmpty;
                            }
                        }
                    }
                }
            });
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
            resolve(true);
        } catch (e) {
            console.log(e);
            reject(false);
        }
    })
}

export const exportFlowExcel = async (arr, fileName) => {

    const XLSX = require('xlsx');

    const headersKeys = [{ value: 'A', name: 'Time Number' }, { value: 'B', name: 'Type' }, { value: 'C', name: 'Event' }, { value: 'D', name: 'Platform' }, { value: 'E', name: 'KPI' }, { value: 'F', name: 'From' }, { value: 'G', name: 'To' }, { value: 'H', name: 'CC' }, { value: 'I', name: 'BCC' }, { value: 'J', name: 'Subject' }, { value: 'K', name: 'Body' }, { value: 'L', name: 'Files for injects' },];

    // Create a new workbook and sheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headersKeys.map((header) => header.name), ...arr]);

    // Add the sheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Write the workbook to a file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
}