import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { convertByte } from '../modules/ConvertByte';
import { MAX_FILES_SIZE } from "@env";
import { useDispatch } from 'react-redux';
import { setErrorMessage } from '../../redux/actions';
import { t } from 'i18n-js';
import { useEffect } from 'react';
import { useState } from 'react';

export default function DropFiles({ dropRef, setDrag, setImage, allowMultipleFiles, typesProps, errorTypeProps, children }) {

    const dispatch = useDispatch();
    const [types, setTypes] = useState([]);
    const [errorType, setErrorType] = useState(false);

    const setImageFromDrop = (file, allowMultipleFiles = false) => {
        const uploadedFiles = Array.from(file.dataTransfer.files);
        file.preventDefault();
        file.dataTransfer.dropEffect = 'copy';
        // אם ההעלאה של מספר קבצים אסורה ויש יותר מקובץ אחד
        if (!allowMultipleFiles && uploadedFiles.length > 1) {
            setDrag(false);
            return dispatch(setErrorMessage(true, false, t("err_multiple_files_not_allowed")));
        }
        const images = [];

        for (const uploadedFile of uploadedFiles) {
            if (types?.indexOf(uploadedFile.type) < 0) {
                setDrag(false);
                // return dispatch(setErrorMessage(true, false, t(errorType)));
                return;
            }

            const fileReader = new FileReader();

            fileReader.onload = function (fileLoadedEvent) {
                const srcData = fileLoadedEvent.target.result;
                const size = convertByte(fileLoadedEvent.total);
                if (size > MAX_FILES_SIZE) {
                    setDrag(false);
                    return dispatch(setErrorMessage(true, false, t("err_fileSize_mail")));
                }

                images.push({
                    uri: srcData,
                    name: uploadedFile.name,
                    file: uploadedFile
                });

                // אם זה הקובץ האחרון
                if (images.length === uploadedFiles.length) {
                    setDrag(false);
                    if (allowMultipleFiles) {
                        setImage(images);
                    } else {
                        setImage(images[0]);  // מחזיר את האובייקט הראשון בלבד
                    }
                }
            }

            fileReader.readAsDataURL(uploadedFile);
        }
    }


    useEffect(() => {
        if (typesProps) {
            setTypes(typesProps);
        }
        if (errorTypeProps) {
            setErrorType(errorTypeProps);
        }
    }, [typesProps, errorTypeProps]);

    return children ? (
        <div style={{ width: "100%", flex: 1 }} ref={dropRef}
            onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setDrag(true);
            }}
            onDragLeave={(e) => {
                e.stopPropagation();
                setDrag(false);
            }}
            onDrop={(e) => {
                e.stopPropagation();
                setImageFromDrop(e, allowMultipleFiles);
            }}
        >
            {children}
        </div>
    ) : (
        <div style={{ width: "100%", height: "100%", position: "absolute", zIndex: 100 }} ref={dropRef}
            onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setDrag(true);
            }}
            onDragLeave={(e) => {
                e.stopPropagation();
                setDrag(false);
            }}
            onDrop={(e) => {
                e.stopPropagation();
                setImageFromDrop(e);
            }}
        />
    )
}

const styles = StyleSheet.create({})