import React, { useState, useContext } from "react";
import { SocialContext } from "../../ContextData/Social";
import { StyleSheet, View, Text, Pressable, Image } from "react-native";
import Carousel from "./Carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  socialFlag,
  setTypeMinimize,
  setIndexCurrent,
} from "../../redux/actions";
import { t } from "i18n-js";
import { getContent } from "../modules/getContent";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";
import { removeNewLine } from "../modules/removeSpacialCherectersAndSpaes";
import { getDateTimeObject } from "../../EditDate";

export default function SocialCarousel({ data }) {
  const { markPostAsRead } = useContext(SocialContext);

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const dispatch = useDispatch();
  const background = useSelector((state) => state.style.background);
  const colors = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);
  const showSocial = useSelector((state) => state.socialMedia.flag);
  const popupsCount = useSelector((state) => state.loginType.popupsCount);
  const minimizePopups = useSelector((state) => state.minimize.minimize);
  const minimizePopupsIndex = useSelector(
    (state) => state.minimize.indexCurrent
  );
  const [index, setIndex] = useState(0);
  const [socialData, setSocialData] = useState(data);

  const deleteItemSocial = () => {
    const temp = [...socialData];
    const updatedData = [];
    temp.splice(index, 1);
    temp.forEach((item, i) => updatedData.push({ ...item, index: i }));
    setSocialData(updatedData);
    if (index === 0) {
      setIndex(socialData.length - 2);
    } else {
      setIndex(index - 1);
    }
  };

  const renderItem = (data) => {
    if (data.length > 0 && data[index]) {
      let printTitle = "";
      let printText = "";
      printTitle = data[index].sender_by.name;
      if (printTitle && printTitle.length > 22) {
        printTitle = `${printTitle.slice(0, 22)}...`;
      }
      printText = getContent(data[index].text);
      if (printText && printText.length > 81) {
        printText = `${printText.slice(0, 81)}...`;
      }

      const onPressHandler = (flag, setFlag, type, num) => {
        if (!flag && popupsCount >= 3) {
          return dispatch(
            setErrorMessage(
              true,
              t("oops"),
              t("popup_error")
            )
          );
        }
        if (minimizePopups.find((e) => e.name === type && !e.show)) {
          dispatch(
            setTypeMinimize({ name: type, type: 2, show: true, num: num })
          );
          dispatch(setIndexCurrent(num));
          return;
        } else if (
          minimizePopups.find(
            (e) => e.name === type && e.show && minimizePopupsIndex !== num
          )
        ) {
          dispatch(setIndexCurrent(num));
          return;
        } else {
          dispatch(setFlag(true));
          dispatch(
            setTypeMinimize({ name: type, type: 2, show: true, num: num })
          );
          dispatch(setIndexCurrent(num));
          return;
        }
      };

      return (
        <Pressable
          style={[
            styles.container_item,
            { backgroundColor: background.rightMenuPopupMailAndSocial },
          ]}
          onPress={() => {
            onPressHandler(showSocial, socialFlag, "social", 3);
            markPostAsRead(data[index].id);
          }}
        >
          <View
            style={[styles.header_item, { borderBottomColor: colors.border }]}
          >
            <View
              style={[
                styles.view_img,
                { backgroundColor: background.socialLogoColor },
              ]}
            >
              <Image source={{ uri: images.social }} style={styles.img_item} />
            </View>
            <Text style={styles.text_logo}>{t("social-media")}</Text>
            <Pressable style={styles.x_btn} onPress={() => deleteItemSocial()}>
              <Text style={[styles.x_btn_text, { color: colors.x }]}>X</Text>
            </Pressable>
          </View>
          <View style={styles.body_item}>
            <View
              style={{
                height: heightS * 35,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Text style={styles.title_item}>{printTitle}</Text>
              <Text style={[styles.body_item_text, { color: colors.x }]}>
                <div dangerouslySetInnerHTML={{ __html: removeNewLine(printText) }} />
              </Text>
            </View>
            <View>
              <Text style={[styles.time, { color: colors.x }]}>
                {(() => {
                  const { date, time } = getDateTimeObject(`${data[index].time} utc`);
                  return `${date} | ${time}`
                })()}
              </Text>
            </View>
          </View>
        </Pressable>
      );
    }
  };
  return (
    <View style={styles.container}>
      <Carousel
        dataArr={socialData}
        data={socialData[index]}
        index={index}
        setIndex={(i) => setIndex(i)}
        renderItem={() => renderItem(socialData)}
        widthP={193}
      />
    </View>
  );
}
const stylesR = (width, height, heightS, widthS) => {
  return StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: widthS * 20,
      paddingTop: heightS * 5,
    },
    container_item: {
      width: widthS * 190,
      height: heightS * 70,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: widthS * 7,
      margin: widthS * 1.5,
    },
    header_item: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      borderTopLeftRadius: widthS * 7,
      borderTopRightRadius: widthS * 7,
      paddingRight: widthS * 25,
      borderBottomWidth: 1,
    },
    text_logo: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 10,
    },
    view_img: {
      borderRadius: widthS * 25,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: widthS * 2,
      marginHorizontal: widthS * 7,
    },
    img_item: {
      margin: widthS * 2,
      width: widthS * 8,
      height: widthS * 8,
      resizeMode: "contain",
    },
    x_btn: {
      position: "absolute",
      right: widthS * 2,
      top: heightS * 3.5,
      borderRadius: 15,
      paddingBottom: heightS * 1,
      width: widthS * 11,
      height: widthS * 11,
      dispaly: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    x_btn_text: {
      fontSize: widthS * 7,
      fontFamily: "Catamaran_400Regular",
    },
    body_item: {
      width: widthS * 190,
      display: "flex",
      height: heightS * 45,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title_item: {
      marginTop: heightS * 4,
      width: widthS * 167,
      fontSize: widthS * 7.5,
      fontFamily: "Catamaran_600SemiBold",
      color: "white",
      lineHeight: heightS * 8,
    },
    body_item_text: {
      width: widthS * 167,
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
      lineHeight: heightS * 10,
      color: "white",
    },
    time: {
      fontSize: widthS * 7,
      fontWeight: "400",
      marginTop: heightS * 2,
      lineHeight: heightS * 10,
      width: widthS * 167,
    },
  });
};
