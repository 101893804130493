import React, { useMemo, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  TextInput,
  ScrollView,
} from "react-native";
import { useSelector } from "react-redux";
import { t } from "i18n-js";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";

export default function Step2({
  nextStep,
  newChallenge,
  actionLength,
  addSolutions,
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, direction);

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  // const newChallenge = useSelector(state => state.actionsArr.currentAction);
  const [solutions, setSolutions] = useState(
    newChallenge.solutions.length > 0
      ? newChallenge.solutions
      : [
        {
          action_body: null,
        },
        {
          action_body: null,
        },
      ]
  );
  const [colorSolutionsErr, setColorSolutionsErr] = useState(false);

  const addSolutionRow = () => {
    let flag = true;
    solutions.forEach((e) => {
      if (e.action_body && e.action_body.length === 0) {
        flag = false;
      }
    });
    if (flag) {
      setSolutions([
        ...solutions,
        {
          action_body: null,
        },
      ]);
    }
  };
  const addSolutionsStep = (next) => {
    let solutionsArr = [];
    solutions.forEach((e, i) => {
      if (e.action_body && e.action_body.length > 0) {
        solutionsArr.push(e);
      }
    });
    if (solutionsArr.length > 0) {
      addSolutions(solutionsArr, next);
      if (next) setSolutions([]);
      // nextStep("+");
    } else {
      if (next) setColorSolutionsErr(true);
    }
  };
  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <View style={{ borderBottomColor: color.border, borderBottomWidth: 2 }}>
          <Text style={styles.text_header}>{t("new-action_q2")}</Text>
        </View>
        <View style={{ width: "100%", flex: 1 }}>
          <ScrollView
            style={{
              maxHeight: heightS * 185,
              flex: 1,
              paddingHorizontal: widthS * 5,
            }}
            nativeID="action"
          >
            <View style={styles.top}>
              <Text style={[styles.headers, { color: color.actionTh }]}>
                {t("your-challenge")}
              </Text>
              <Text style={styles.textA}>
                <Text style={styles.num}>{`${actionLength + 1}.`}</Text>
                <Text>
                  {newChallenge &&
                    newChallenge.action &&
                    newChallenge.action.action_body}
                </Text>
              </Text>
              <Text style={[styles.headers, { color: color.actionTh }]}>
                {t("related-event")}
              </Text>
              <Text style={styles.textB}>
                {newChallenge?.action?.event_name}
              </Text>
            </View>
            <View style={[styles.main, { borderTopColor: color.border }]}>
              {solutions.map((e, i) => {
                return (
                  <View style={styles.solutions} key={i}>
                    <Text style={styles.numSolution}>{`${i + 1}.  `}</Text>
                    <Pressable
                      style={[
                        styles.solution,
                        {
                          borderColor:
                            i === 0 && colorSolutionsErr ? "red" : "",
                          borderWidth: i === 0 && colorSolutionsErr ? 1 : 0,
                          backgroundColor: background.actionInput,
                        },
                      ]}
                    >
                      <TextInput
                        autoFocus={
                          solutions.length > 2 && i === solutions.length - 1
                        }
                        onChangeText={(text) => {
                          let temp = [...solutions];
                          temp[i].action_body = text;
                          setSolutions([...temp]);
                          addSolutionsStep();
                        }}
                        multiline={true}
                        style={styles.inp}
                        value={e.action_body ? e.action_body : ""}
                      />
                    </Pressable>
                  </View>
                );
              })}
              <Pressable
                onPress={() => addSolutionRow()}
                style={[
                  styles.btn_add,
                  {
                    backgroundColor: background.actionButtonAdd,
                    borderColor: background.actionButtonAdd,
                  },
                ]}
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: widthS * 10,
                    fontWight: "400",
                  }}
                >
                  {"+ "}
                  {t("add")}
                </Text>
              </Pressable>
            </View>
          </ScrollView>
        </View>
        <View style={[styles.footer, { borderTopColor: color.border }]}>
          <Pressable
            style={[
              styles.btn_back,
              { borderColor: color.actionBtnBackBorder },
            ]}
            onPress={() => nextStep("-")}
          >
            <Text style={styles.btn_text}>
              {"<"}
              {t("back")}
            </Text>
          </Pressable>
          <Pressable
            style={[
              styles.btn_next,
              {
                backgroundColor: background.actionButtonAdd,
                borderColor: background.actionButtonAdd,
              },
            ]}
            onPress={() => addSolutionsStep(true)}
          >
            <Text style={styles.btn_text}>{t("next")}</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS, direction) =>
  StyleSheet.create({
    container: {
      display: "flex",
      // minHeight: heightS * 280,
      flex: 1,
    },
    text_header: {
      color: "white",
      fontSize: widthS * 13,
      fontFamily: "Catamaran_300Light",
      marginBottom: heightS * 20,
      paddingRight: widthS * 20,
      paddingLeft: widthS * 3,
    },
    top: {
      borderStyle: "solid",
      paddingHorizontal: widthS * 3,
      paddingBottom: heightS * 12,
    },
    headers: {
      fontSize: widthS * 8,
      marginTop: heightS * 10,
      fontWeight: "200",
    },
    textA: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      writingDirection: direction,
    },
    textB: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      writingDirection: direction,
    },
    num: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      ...(direction === "rtl" ? { paddingLeft: widthS * 5, } : { paddingRight: widthS * 5, }),
      width: "auto",
    },
    numSolution: {
      color: "white",
      writingDirection: direction,
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      ...(direction === "rtl" ? { paddingLeft: widthS * 5, } : { paddingRight: widthS * 5, }),
      width: "1.5vw",
    },
    main: {
      borderTopWidth: 2,
      paddingLeft: widthS * 3,
      paddingRight: widthS * 3,
      paddingTop: heightS * 10,
      paddingBottom: heightS * 10,
      minHeight: heightS * 115,
      writingDirection: direction,
    },
    solutions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    solution: {
      borderRadius: widthS * 5,
      flex: 1,
      minHeight: heightS * 22,
      marginTop: 3,
      marginBottom: 3,
      color: "white",
    },
    inp: {
      minHeight: heightS * 25,
      color: "white",
      fontSize: widthS * 10,
      paddingHorizontal: widthS * 5,
      outlineStyle: "none",
      fontFamily: "Catamaran_400Regular",
      paddingTop: "0.3vw"
    },
    footer: {
      borderTopWidth: 2,
      borderStyle: "solid",
      display: "flax",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: heightS * 50,
      writingDirection: direction,
    },
    btn_add: {
      alignSelf: "flex-start",
      borderWidth: 1,
      width: widthS * 60,
      height: heightS * 20,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
      marginLeft: widthS * 20,
      marginTop: heightS * 4,
    },
    btn_next: {
      borderWidth: 1,
      width: widthS * 72,
      height: heightS * 20,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
    },
    btn_back: {
      borderWidth: 1,
      width: widthS * 72,
      height: heightS * 20,
      borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 5,
    },
    btn_text: {
      color: "white",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
  });
