import React, { useState, useEffect, useRef, useMemo, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { View, Pressable, StyleSheet, Image } from 'react-native';
import TextCustomize from '../../../../Elements/TextCustomize';
import { t } from 'i18n-js';
import { listenerAdd, listenerAddBT } from '../../../../../AddListener';
import { AntDesign } from '@expo/vector-icons';

const TypeActionCell = ({ type, cellStyle, index, isBottom, setIsPopupOpen, updateAction, isDone, isNew, popupRef, setPopupRef }) => {

    const cellRef = useRef(null);
    const btnRef = useRef(null);

    const [showOptions, setShowOptions] = useState(false);

    const { images, background, color } = useSelector(state => ({
        images: state.style.images,
        background: state.style.background,
        color: state.style.color,
    }));

    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

    const styles = useMemo(() => stylesR(background, color), [background, color]);

    const optionTypes = useMemo(() => [
        { label: t("message"), value: "Message", icon: images.messageIcon },
        { label: t("analysis"), value: "Analysis", icon: images.analyticsIcon },
        { label: t("decision"), value: "Decision", icon: images.decisionIcon },
        {
            label: `${t("call_for_action_challenges")}`,
            value: "cfaChallenges", icon: images.action
        },
        {
            label: `${t("call_for_action_questionnaire")}`,
            value: "cfaQuestionnaire",
            icon: images.questionnaireIcon
        },
    ], [images, t]);

    const toggleShowOptions = useCallback(() => {
        setShowOptions(prevState => !prevState);
        setIsPopupOpen(index);
    }, [index, setIsPopupOpen]);

    const closePopup = useCallback(() => {
        setShowOptions(false);
        setIsPopupOpen("");
    }, [setIsPopupOpen]);

    useEffect(() => {
        const eventListener = listenerAddBT(popupRef, btnRef, showOptions, closePopup);
        window.addEventListener("mousedown", eventListener);
        return () => {
            window.removeEventListener("mousedown", eventListener);
        }
    }, [showOptions, popupRef]);

    const currentOption = optionTypes.find((e) => e.value === type);



    return (
        <View style={cellStyle("7vw", showOptions)} ref={cellRef}>
            <Pressable
                disabled={isDone}
                ref={btnRef}
                style={[styles.actionTypeCell]}
                onPress={toggleShowOptions}
            >
                <View style={styles.actionTypeCellChild}>
                    <Image source={{ uri: currentOption?.icon }} style={styles.iconType} />
                    <TextCustomize numberOfLines={1} fontSize={"0.9vw"} text={currentOption?.label} />
                </View>
                <AntDesign name="caretdown" style={styles.icon} />
            </Pressable>
            {showOptions && <View ref={setPopupRef} style={styles.popup(showOptions, isBottom && isNew, direction)}>
                {optionTypes.map((e, i) => {
                    if (e.value === type) return null;
                    return (
                        <Pressable
                            key={i}
                            style={({ hovered }) => [styles.optionBtn(hovered)]}
                            onPress={() => {
                                let valueUpdate = { type_action: e.value };
                                if (e.value !== "Message") {
                                    valueUpdate.platform_type = "mail";
                                }
                                if (e.value === "cfaChallenges") {
                                    valueUpdate.body = `<div>${t("cfa_challenges_msg")}</div>`;
                                }
                                if (e.value === "cfaQuestionnaire" || e.value === "cfaChallenges") {
                                    valueUpdate.is_kpi = false;
                                }
                                updateAction(index, valueUpdate, true, isNew);
                                closePopup();
                            }}>
                            <View style={styles.actionTypeCellChild}>
                                <Image source={{ uri: e.icon }} style={styles.iconType} />
                                <TextCustomize text={e.label} fontSize={"0.9vw"} style={{ width: "max-content" }} />
                            </View>
                        </Pressable>
                    )
                })}
            </View>}
        </View>
    )
};

export default memo(TypeActionCell);

const stylesR = (background, color) => StyleSheet.create({
    actionTypeCell: {
        justifyContent: "space-around",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    actionTypeCellChild: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        gap: "0.5vw"
    },
    icon: {
        fontSize: "0.5vw",
        color: color.text
    },
    iconType: {
        width: "1vw",
        height: "1vw",
        resizeMode: "contain"
    },
    popup: (flag, isBottom, direction) => ({
        position: "absolute",
        backgroundColor: background.infoBody,
        borderRadius: "0.8vw",
        display: flag ? "" : "none",
        opacity: flag ? 1 : 0,
        transition: "all 2s",
        shadowColor: "black",
        shadowOffset: { height: 0, width: 0 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        direction: direction,
        ...(!isBottom ? {
            top: '0.8vw',
            bottom: 'auto'
        } : {
            top: 'auto',
            bottom: '0.8vw'
        }),
        paddingVertical: "1vw"
    }),
    optionBtn: (hovered) => ({
        paddingVertical: "0.3vw",
        paddingHorizontal: "1vw",
        backgroundColor: hovered ? background.lineHover : ""
    }),
});
