import { Dimensions, StyleSheet, Text, View } from 'react-native'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from 'react-native-svg-circular-progress'
import Widget from '../Elements-btn/Widget';
import { useState } from 'react';
import { t } from 'i18n-js';
import { useEffect } from 'react';
import { WordMapFuncContext } from '../Analytics/analyticsFunction/WordsMapFunc';
import { useContext } from 'react';
import { analyticsPlayers } from '../../../../redux/actions/creatorActions';

export default function KPIAndWordMap() {

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);

    const { width } = Dimensions.get("screen");

    const dispatch = useDispatch();

    const {
        arrScoreReal,
        filterStatistics,
        refresh,
    } = useContext(WordMapFuncContext);

    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

    const kpisState = useSelector(state => state.swimlane.kpis);
    const [currentType, setCurrentType] = useState("1");
    const [kpis, setKpis] = useState(null);

    const [wordArr, setWordArr] = useState([]);

    const colors = ["#006ABA", "#FCF000", "#6A0084"]

    const setFontSize = (i) => {
        switch (i) {
            case 0:
                return 2;
            case 1:
                return 1.6;
            case 2:
                return 1.3;
        }
    }

    useEffect(() => {
        if (kpisState?.length > 0) {
            let doneArr = kpisState.filter(e => e.is_done);
            let percentage = Math.floor((doneArr.length / kpisState.length) * 100);
            setKpis(`${percentage}`);
        }
    }, [kpisState]);

    useEffect(() => {
        if (gameInfo?.players?.length > 0) {
            let ids = [...gameInfo.players.map((e) => e.id)].join(",");
            dispatch(analyticsPlayers(ids))
        }
    }, [gameInfo?.players]);

    useEffect(() => {
        setWordArr([...arrScoreReal])
    }, [arrScoreReal]);

    useEffect(() => {
        filterStatistics([], "real", "");
    }, [refresh]);


    const component = (type) => {
        switch (type) {
            case "1":
                return (
                    <View style={styles.kpisView}>
                        <Text style={styles.headerText}>{t("kpis_widget_title")}</Text>
                        <View style={styles.circleView}>
                            <CircularProgress
                                style={{ height: "7vw" }}
                                percentage={Number(kpis)}
                                fillColor={background.infoBox}
                                donutColor={"white"}
                                blankColor={background.infoBody}
                                progressWidth={(width / 1000) * 30}
                                size={(width / 1000) * 70}
                            />
                            <Text style={styles.textKpi(kpis?.length > 2)}>{kpis}<Text style={styles.percentageIcon(kpis?.length > 2)}>%</Text></Text>
                        </View>
                    </View>
                );
            case "2":
                return (
                    <View>
                        <Text style={styles.headerText}>{t("word_widget_title")}</Text>
                        <View>
                            {wordArr.map((word, i) => {
                                if (i <= 2) {
                                    return (
                                        <Text numberOfLines={1} style={styles.word(setFontSize(i), colors[i])}>{word.text}</Text>
                                    )
                                }
                            })}
                        </View>
                    </View>
                )
        }
    }

    return (
        <View style={styles.container}>
            <Widget
                icon={images.kpis}
                title={t("kpis")}
                currentType={currentType}
                type={"multiple"}
                setCurrentType={setCurrentType}
                icon2={images.wordMap}
                title2={t("word_map")}
                component={component(currentType)}
            >

            </Widget>
        </View>
    )
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            height: "100%",
            writingDirection: direction,
        },
        kpisView: {

        },
        headerText: {
            color: color.text2,
            fontSize: "0.8vw",
            lineHeight: "1.2vw",
            marginBottom: "0.2vw",
            fontFamily: "Catamaran_400Regular",
        },
        word: (fontSize, color) => ({
            fontSize: `${fontSize}vw`,
            color: color,
            lineHeight: `${fontSize + 0.5}vw`,
            fontFamily: "Catamaran_600SemiBold",
            writingDirection: direction,
        }),
        circleView: {
            alignItems: "center",
            justifyContent: "center",
            position: "relative"
        },
        textKpi: (flag) => ({
            position: "absolute",
            fontSize: flag ? "2vw" : "3vw",
            color: background.count
        }),
        percentageIcon: (flag) => ({
            fontSize: flag ? "1.5vw" : "2vw"
        })
    })
};