import React, { memo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import NewMail from "./NewMail";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { maximizeMail, maximizeSystem } from "../../../redux/actions";

const NewMailPopup = memo(
  ({
    type,
    message,
    messageType,
    indexPopupMail,
    indexPopupSystem,
    minimizeDashboard,
    maximize,
    show,
    id,
    dashboard,
    setFlag,
    isMinimizeDashboard,
    minimizeDashboardObject
  }) => {
    const { widthS, heightS } = UseResponsiveScreen();
    const background = useSelector((state) => state.style.background);
    const styles = stylesR(heightS, widthS, background);

    const dispatch = useDispatch();

    return (
      <View
        key={id}
        style={[
          styles.container_child(
            dashboard,
            maximize,
            show,
            !show,
            (type === "mail" ? indexPopupMail : indexPopupSystem)
          ),
        ]}
      >
        <NewMail
          key={id}
          type={type}
          messageType={messageType}
          messageCurrent={message || {}}
          // setFlag={() =>
          //   dispatch(
          //     type === "mail" ? setNewMessageFlag(false) : setNewSystemFlag(false)
          //   )
          // }
          minimizeDashboard={minimizeDashboard}
          setMaximize={() => dispatch(type === "mail" ? maximizeMail(!maximize, id) : maximizeSystem(!maximize, id))}
          maximize={maximize}
          minimize={!show}
          indexPopup={type === "mail" ? indexPopupMail : indexPopupSystem}
          id={id}
          dashboard={dashboard}
          setFlag={setFlag}
          isMinimizeDashboard={isMinimizeDashboard}
          minimizeDashboardObject={minimizeDashboardObject}
        />
      </View>
    );
  }
);

export default NewMailPopup;

const stylesR = (heightS, widthS, background) =>
  StyleSheet.create({
    container_child: (dashboard, maximize, show, notShow, indexPopup) => ({
      borderTopLeftRadius: "1vw",
      borderTopRightRadius: "1vw",
      shadowRadius: 5,
      shadowColor: "black",
      shadowOffset: {
        height: 0,
        width: 0,
      },
      shadowOpacity: 0.5,
      shadowRadius: 5,
      position: dashboard ? "" : "absolute",
      transition: "all, 0.5s",
      backgroundColor: background.popup1,
      minHeight: dashboard ? "90%" : (maximize && show) ? "81vh" : notShow ? "0" : "66vh",
      width: maximize && show ? "100%" : dashboard ? "60%" : notShow ? "13vw" : "37vw",
      maxHeight: "95%",
      zIndex: show ? 5 : 4,
      bottom: maximize && show ? "1vh" : notShow ? "0" : "1vh",
      right: maximize && show ? 0 : show ? (widthS * 130) * indexPopup + widthS * 14 : indexPopup > 0 ? "13vw" : widthS * 8,
    }),
  });
