import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function Widget({ imgColor, icon, title, type, currentType, setCurrentType, icon2, title2, component, heatMap }) {

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const lang = useSelector((state) => state.setting.setting.lang);
    const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
    const styles = stylesR(background, color, direction);

    const changeType = () => {
        setCurrentType(currentType === "2" ? "1" : "2");
    };

    useEffect(() => {
        // if (setCurrentType) {
        //     let myInterval = setInterval(() => changeType(), 10000);
        //     return () => {
        //         clearInterval(myInterval);
        //     }
        // }
    });

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={styles.imgView}>
                    <Image source={{ uri: currentType === "2" ? icon2 : icon }} style={styles.img(imgColor)} />
                </View>
                <Text style={styles.titleText}>{currentType === "2" ? title2?.toUpperCase() : title?.toUpperCase()}</Text>
            </View>
            <View style={styles.box(heatMap)}>
                {component}
            </View>
            <View style={styles.dotView(type)}>
                <TouchableOpacity onPress={() => setCurrentType("1")} style={styles.dot(currentType === "1")} />
                <TouchableOpacity onPress={() => setCurrentType("2")} style={styles.dot(currentType === "2")} />
            </View>
        </View>
    )
}

const stylesR = (background, color, direction) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            height: "100%"
        },
        header: {
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "0.3vw"
        },
        imgView: {
            backgroundColor: background.infoBox,
            borderRadius: "100%",
            width: "1.4vw",
            height: "1.4vw",
            alignItems: "center",
            justifyContent: "center",
            ...(direction === "rtl" ? { marginLeft: "0.5vw" } : { marginRight: "0.5vw" })
        },
        img: (flag) => ({
            width: "0.8vw",
            height: "0.8vw",
            resizeMode: "contain",
            tintColor: flag ? "" : color.text2
        }),
        titleText: {
            color: color.text2,
            fontSize: "1vw",
            fontFamily: "Catamaran_400Regular",
        },
        box: (flag) => ({
            backgroundColor: background.popup1,
            flex: 1,
            borderRadius: "1vw",
            padding: flag ? "" : "1.2vw",
            minHeight: "11.5vw",
            maxHeight: "11.5vw",
            direction: "ltr"
        }),
        dotView: (flag) => ({
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            visibility: flag !== "single" ? "visible" : "hidden"
        }),
        dot: (flag) => ({
            transition: "all 1s",
            width: "0.6vw",
            height: "0.6vw",
            marginTop: "0.4vw",
            marginHorizontal: "0.1vw",
            borderRadius: "100%",
            backgroundColor: flag ? background.count : color.x
        })
    })
};