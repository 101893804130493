import { Platform, StyleSheet, Text, View } from 'react-native';
import React from 'react';

export default function EditorStyle({ colorSkin, children }) {
  // Only import CSS in a web environment
  if (Platform.OS === 'web') {
    import("../editor/myui/content.css");
    import("../editor/myui/skin.css");
  }

  return (
    <View id={"lightSkin"} style={{maxWidth: "100%", width: "100%"}}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({});
