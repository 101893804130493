import React, { useContext, useEffect, useMemo, useState } from "react";
import { StyleSheet, View, Text, TextInput, Pressable, Linking } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNetInfo } from "@react-native-community/netinfo";
import {
  setGameList,
  setForgot,
  setUser,
  setFactor,
  setCreatorOptions,
  setErrorMessage,
} from "../../redux/actions";
import { URL_PATH, LOGIN, DOMAIN } from "@env";
import {
  AUTH,
  F_PHPSESSID,
  USER,
  USER_FIRST_NAME,
  USER_LAST_NAME,
  _EMAIL_,
  _PASSWORD_,
  _DOMAIN_,
  FACTOR,
  CREATE_GAME_PERMISSION,
  USER_ID_IN_GAME
} from "../../config.inc";
import i18n, { t } from "i18n-js";
import AnimationLoading from "../modules/AnimationLoading";
import UseResponsiveSize from "../modules/UseResponsiveSize";
import UseResponsiveScreen from "../modules/UseResponsiveScreen";

export default function Login({ load, setLoad }) {
  const { height, width } = UseResponsiveSize();
  const { heightS, widthS } = UseResponsiveScreen();
  const color = useSelector((state) => state.style.colorClient);
  const background = useSelector((state) => state.style.backgroundClient);
  const styles = stylesR(color, background, heightS, widthS);

  const dispatch = useDispatch();

  const Internet = useNetInfo();
  const DomainState = useSelector((state) => state.style.domain);
  const productNameState = useSelector((state) => state.style?.productName);

  const productName = useMemo(() => productNameState, [productNameState]);
  const [dataUser, setDataUser] = useState({});
  const [err, setErr] = useState();

  let domain = DOMAIN;
  Linking.getInitialURL().then(domainUrl => {
    if (domainUrl != domain) {
      domain = domainUrl
    }
  });

  const submitLogin = () => {
    if (Object.keys(dataUser).length === 2) {
      try {
        const url = `${URL_PATH}${LOGIN}`;
        const body = new FormData();
        body.append(_EMAIL_, dataUser.email);
        body.append(_PASSWORD_, dataUser.password);
        body.append(_DOMAIN_, domain);
        const requestOptions = {
          method: "post",
          body: body
        };
        setLoad(true);
        fetch(url, requestOptions)
          .then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.logged_in) {
              if (res?.user?.token) {
                dispatch(setErrorMessage(true, "", t("err_login_password"), false, () => {
                  window.location.href = `${window.location.origin}/forgotPassword?token=${res?.user?.token}`;
                }));
                return;
              }
              window.sessionStorage.setItem(F_PHPSESSID, res.session_id);
              window.sessionStorage.setItem(AUTH, "true");
              window.sessionStorage.setItem(FACTOR, true);
              window.sessionStorage.setItem(
                USER_FIRST_NAME,
                res.user.first_name
              );
              window.sessionStorage.setItem(USER_LAST_NAME, res.user.last_name);
              window.sessionStorage.setItem(USER, JSON.stringify(res));
              dispatch(setUser(res));
              if (res.user.create_game_permission === "1") {
                window.sessionStorage.setItem(CREATE_GAME_PERMISSION, res.user.create_game_permission);
                dispatch(setCreatorOptions());
              } else {
                dispatch(setGameList());
              }
            } else {
              if (res?.user?.token) {
                window.sessionStorage.setItem("token_password", res?.user?.token);
              }
              if (res.session_id) {
                window.sessionStorage.setItem(F_PHPSESSID, res.session_id);
                setLoad(false);
                dispatch(setFactor());
              } else {
                setLoad(false);
                if (res.error?.email?.error_code === "3001") {
                  return setErr(i18n.t("err_login_2"));
                }
                if (res.error?.password?.error_code === "1006") {
                  return setErr(i18n.t("err_login_3"));
                }
                if (res.error?.email?.error_code === "1001") {
                  return setErr(i18n.t("err_login_1"));
                } else if (res.error.general.error_code === 1) {
                  return setErr(i18n.t("err_login"));
                } else if (res.error.general.error_code === 3) {
                  return setErr(i18n.t("TF_error_code"));
                }
              }
            }
          })
          .catch((err) => {
            // for expo Tel-Aviv
            setLoad(false);
            if (
              dataUser.email === "Be-strategic@solutions.com" &&
              dataUser.password === "12345678!"
            ) {
              let res = {
                success: true,
                session_id: "sjfed0hlqm37avp6lp60bp83r6",
                user: {
                  id: "wKG4a5OiqrU=",
                  first_name: "Dotan",
                  last_name: "Sagi",
                  email: "Be-strategic@solutions.com",
                },
                error: [],
              };
              window.sessionStorage.setItem(F_PHPSESSID, res.session_id);
              window.sessionStorage.setItem(AUTH, "true");
              window.sessionStorage.setItem(
                USER_FIRST_NAME,
                res.user.first_name
              );
              window.sessionStorage.setItem(USER_LAST_NAME, res.user.last_name);
              window.sessionStorage.setItem(USER, JSON.stringify(res));
              dispatch(setUser(res));
              dispatch(setFactor());
            } else {
              if (!Internet.isConnected) {
                setErr(i18n.t("internet_err"))
              } else {
                setErr(i18n.t("server_fail"));
              }
            }
          });
      } catch (e) { }
    }
  };


  return (
    <View
      style={[styles.body_main, { backgroundColor: background.popupLogin }]}
    >
      <View style={styles.body_main_top}>
        <Text style={[styles.body_main_textA, { color: color.text }]}>
          {i18n.t("welcome")}!
        </Text>
        <Text style={[styles.body_main_textB, { color: color.text }]}>
          {i18n.t("welcome_a1")} {productName} {i18n.t("welcome_a2")}
        </Text>
      </View>
      <View style={styles.body_main_bottom}>
        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: load ? "center" : "",
            flex: 1,
            // marginTop: load ? 0 : heightS * 17.5,
          }}
        >
          {load ? (
            <AnimationLoading />
          ) : (
            <>
              {<Text style={styles.err}>{err && err}</Text>}
              <TextInput
                autoComplete="off"
                id="input-login"
                placeholderTextColor={color.loginPlaceholder}
                placeholder={i18n.t("email")}
                value={dataUser.email}
                style={[
                  styles.inputs,
                  { backgroundColor: background.loginInput, color: color.text },
                ]}
                textContentType="emailAddress"
                onChangeText={(e) => setDataUser({ ...dataUser, email: e })}
              />
              <TextInput
                id="input-login-password"
                placeholder={i18n.t("password")}
                placeholderTextColor={color.loginPlaceholder}
                value={dataUser.password}
                style={[
                  styles.inputs,
                  {
                    backgroundColor: background.loginInput,
                    color: color.text,
                    fontSize: dataUser.password ? widthS * 26 : widthS * 9.5,
                  },
                ]}
                secureTextEntry={true}
                textContentType="password"
                onSubmitEditing={submitLogin}
                onChangeText={(e) => setDataUser({ ...dataUser, password: e })}
              />
              <Pressable
                onPress={() => submitLogin()}
                style={[
                  {
                    backgroundColor:
                      dataUser && dataUser.password && dataUser.email
                        ? background.buttonLoginHover
                        : "",
                    borderColor: color.text2,
                    borderWidth:
                      (dataUser && !dataUser.password) ||
                        (dataUser && !dataUser.email)
                        ? widthS * 1.5
                        : 0,
                  },
                  styles.btn,
                ]}
              >
                {
                  <Text style={[{ color: color.text2 }, styles.btn_text]}>
                    {i18n.t("enter_game")}
                  </Text>
                }
              </Pressable>
            </>
          )}
        </View>
        <Pressable
          onPress={() => {
            dispatch(setForgot());
            setLoad(false);
          }}
        >
          {({ pressed, hovered, focused }) => (
            <Text style={styles.text_forgot}>{i18n.t("forgot")}?</Text>
          )}
        </Pressable>
      </View>
    </View>
  );
}
const stylesR = (color, background, heightS, widthS) => {
  return StyleSheet.create({
    body_main: {
      display: "flex",
      flexDirection: "column",
      // ...style.popup7,
      width: widthS * 420,
      minHeight: heightS * 300,
      borderRadius: widthS * 25,
    },
    body_main_top: {
      marginTop: heightS * 24,
      flex: 0.3,
    },
    body_main_textA: {
      fontSize: widthS * 18,
      fontWeight: "100",
      textAlign: "center",
      fontFamily: "Catamaran_400Regular",
    },
    body_main_textB: {
      marginTop: heightS * 6,
      fontWeight: "300",
      lineHeight: heightS * 12,
      fontSize: widthS * 10,
      textAlign: "center",
      fontFamily: "Catamaran_400Regular",
    },
    body_main_bottom: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginBottom: "4vw",
      flex: 1,
    },
    inputs: {
      width: widthS * 220,
      height: heightS * 28,
      borderRadius: widthS * 7.3,
      paddingLeft: widthS * 10,
      marginBottom: widthS * 4.5,
      fontSize: widthS * 9.5,
      fontWeight: "300",
      outlineStyle: "none",
      fontFamily: "Catamaran_400Regular",
    },
    btn: {
      borderStyle: "solid",
      padding: widthS * 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginVertical: heightS * 10.2,
      width: widthS * 220,
      height: heightS * 28,
      borderRadius: widthS * 7.3,
    },
    btn_text: {
      fontSize: widthS * 11,
      fontWeight: "normal",
      fontFamily: "Catamaran_400Regular",
    },
    text_forgot: {
      alignSelf: "flex-end",
      color: color.text2,
      fontSize: widthS * 11,
      fontWeight: "400",
      marginBottom: heightS * 17,
      textDecorationStyle: "solid",
      textDecorationColor: "#a69ba6",
      textDecorationLine: "underline",
      fontFamily: "Catamaran_400Regular",
    },
    err: {
      marginHorizontal: "5vw",
      marginVertical: "0.5vw",
      fontWeight: "300",
      fontSize: widthS * 9,
      minHeight: widthS * 9,
      lineHeight: widthS * 14,
      textAlign: "center",
      color: "#d2c935",
      // top: widthS * -15,
      fontFamily: "Catamaran_400Regular",
    },
  });
};
